// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { withRouter } from "react-router";

// Components
import UpcomingBookings from "../upcomingBookings/UpcomingBookings";
import WaitingShifts from "./WaitingShifts";
import UpcomingShifts from "./UpcomingShifts";
import FinishedShifts from "./FinishedShifts";

// Styles
import colors from "../../../styles/colors";
import color_V1 from "../../../styles/colors-V-1.0";
import { breakPoint } from "../../../styles/breakPoints";

// Utilities
import req from "../../../utilities/request-utility";

const ShiftsOverview = (props) => {
  const [stepper, setStepper] = useState(0);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  // Gets the upcoming bookings
  useEffect(() => {
    req()
      .get("employees/bookings")
      .then(({ data: bookings }) => {
        setBookings(bookings);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (props.location.state?.step) {
      setStepper(props.location.state?.step);
    }
    window.history.replaceState({}, document.title);
  }, [props.location.state]);

  return (
    <div className={`${componentStyle()}`}>
      <ul className="flex px-4 mb-1 finish-tab">
        <li className="mr-10  px-2 py-0">
          <button
            onClick={() => setStepper(0)}
            className={stepper === 0 ? "selected" : "not-selected"}
          >
            JOBOVERSIGT({bookings.length})
          </button>
        </li>
        <li className="mr-10  px-2 py-0">
          <button
            onClick={() => setStepper(1)}
            className={stepper === 1 ? "selected" : "not-selected"}
          >
            Ansøgte jobs({props.meta.appliedShiftsCount})
          </button>
        </li>
        <li className="mr-10  px-2 py-0">
          <button
            onClick={() => setStepper(2)}
            className={stepper === 2 ? "selected" : "not-selected"}
          >
            Mine jobs({props.meta.upcomingShiftsCount})
          </button>
        </li>

        <li className="mr-10 px-2 py-0 relative">
          <button
            onClick={() => setStepper(3)}
            className={stepper === 3 ? "selected" : "not-selected"}
          >
            Afsluttede jobs &nbsp;
            {props.checkoutNotifications > 0 && (
              <span className="badge">{props.checkoutNotifications}</span>
            )}
          </button>
        </li>
      </ul>

      <hr className="border border-gray-300 border-t-0" />

      {stepper === 0 && (
        <UpcomingBookings bookings={bookings} loading={loading} />
      )}
      {stepper === 1 && <WaitingShifts />}
      {stepper === 2 && <UpcomingShifts />}
      {stepper === 3 && <FinishedShifts />}
    </div>
  );
};

const componentStyle = () => css`
  .finish-tab {
    @media screen and (max-width: 575px) {
      padding: 0px;
    }
    li{
      @media screen and (max-width: 575px) {
        margin-right:0px;
        .badge{
          right:0;
        }
      }
    }
  }
  .badge {
    position: absolute;
    top: -9px;
    border-radius: 50%;
    background: ${color_V1.red_20};
    color: ${color_V1.white};
    line-height: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    font-weight: 600;

    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  ul {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0.25rem;

    li {
      margin-right: 1.5rem;
      padding: 0 0.5rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        margin-right: 1rem;
      }

      button.selected,
      button.not-selected {
        font-size: 0.75rem;
        font-weight: 700;
        text-transform: uppercase;
        color: ${colors.primeBlue};
        outline: none;

        @media screen and (max-width: ${breakPoint.sm}px) {
          font-size: 0.55rem;
        }
      }

      @media screen and (max-width: ${breakPoint.sm}px) {
        button.selected {
          font-size: 0.6rem;
        }
      }

      button.selected {
        font-weight: bold;
      }

      button.not-selected {
        color: ${colors.darkGrey};
      }

      button.not-selected:hover {
        color: ${colors.primeBlue};
      }
    }
  }
`;

export default withRouter(ShiftsOverview);
