// Utilities
import req from "../utilities/request-utility";

// Config
import { upcoming, finished } from "../config/bookingStatus";

// Action types
import {
  ADD_TOAST,
  END_OF_ADMIN_BOOKINGS,
  END_OF_BOOKINGS,
  GET_ADMIN_BOOKINGS,
  GET_ADMIN_BOOKINGS_FAILURE,
  GET_ADMIN_BOOKINGS_SUCCESS,
  GET_BOOKINGS,
  GET_BOOKINGS_FAILURE,
  GET_BOOKINGS_SUCCESS,
  RESET_BOOKINGS,
  GET_ADMIN_BOOKINGS_WITH_SAME_SEARCH_TERM_SUCCESS,
  GET_ADMIN_BOOKINGS_WITH_NEW_SEARCH_TERM_SUCCESS,
  CHANGE_WEEK_NUMBER,
  CHANGE_TAB,
  CHANGE_ALL_GRAVY_STATUS
} from "./actionTypes";

export function getBookings({
  type = upcoming,
  limit = 10,
  offset = null,
  weekNumber,
} = {}) {
  return async function (dispatch, getState) {
    try {
      let state = getState().booking[type];

      let bookings = [];
      let totalBookings = null;

      if (offset === null) offset = state.bookings.length;

      // Safe-guard to prevent duplicate post-fetchings!
      if (state.loading || state.error || state.endOfFeed) return;

      dispatch({ type: GET_BOOKINGS, payload: { type } });

      if (type === upcoming && weekNumber) {
        const { data: openBookingsWeek } = await req()(
          `corporations/bookings/upcoming?weekNumber=${weekNumber}`
        );
        bookings = openBookingsWeek;
      }
      // Open/Active bookings
      if (type === upcoming && !weekNumber) {
        const { data: openBookings } = await req()(
          `corporations/bookings/upcoming`
        );
        bookings = openBookings;
        totalBookings = openBookings.length;
      }


      if (type === finished) {
        const { data: finishedBookings } = await req()(
          `corporations/bookings/finished`
        );
        bookings = finishedBookings;
      }

      if (bookings.length || (type === upcoming && weekNumber)) {
        dispatch({
          type: GET_BOOKINGS_SUCCESS,
          payload: { bookings, type, totalBookings },
        });
      } else {
        dispatch({
          type: END_OF_BOOKINGS,
          payload: { type },
        });
      }
    } catch (err) {
      dispatch({ type: GET_BOOKINGS_FAILURE, payload: type });
      dispatch({
        type: ADD_TOAST,
        payload: {
          message:
            "Noget gik galt, da vi prøvede at hente bookingerne. Hvis fejlen gentager sig, bedes do kontakte TjenerTeamet",
          type: "error",
          autoDismiss: true,
        },
      });
    }
  };
}

export function getAdminBookings({
  type = "admin_upcoming_bookings",
  limit = 10,
  offset = null,
  searchTerm = null,
  startDate = "",
  endDate = ""
} = {}) {
  return async function (dispatch, getState) {
    try {
      let state = getState().booking[type];

      let bookings = [];

      if (offset === null) offset = state.bookings.length;

      // Safe-guard to prevent duplicate post-fetchings!
      if (state.loading || state.error || state.endOfFeed) return;

      dispatch({ type: GET_ADMIN_BOOKINGS, payload: { type } });

      // Upcoming bookings
      if (type === "admin_upcoming_bookings") {
        const { data: upcomingBookings } = await req()(
          `admin/bookings/upcoming-bookings?limit=${limit}&offset=${offset}`
        );
        bookings = upcomingBookings;
        if (bookings.length) {
          dispatch({
            type: GET_ADMIN_BOOKINGS_SUCCESS,
            payload: { bookings, type }
          });
        } else {
          dispatch({
            type: END_OF_ADMIN_BOOKINGS,
            payload: { type }
          });
        }
      }

      // Old bookings
      if (type === "admin_old_bookings") {
        if (searchTerm === state.searchTerm && searchTerm) {
          const { data: oldBookings } = await req()(
            `admin/bookings/old-bookings?limit=${limit}&offset=${offset}&startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`
          );
          bookings = oldBookings;
          if (bookings.length) {
            dispatch({
              type: GET_ADMIN_BOOKINGS_WITH_SAME_SEARCH_TERM_SUCCESS,
              payload: { bookings, searchTerm,type },
            });
          } else {
            dispatch({
              type: END_OF_ADMIN_BOOKINGS,
              payload: {type}
            });
          }
        } else if (searchTerm) {
          const { data: oldBookings } = await req()(
            `admin/bookings/old-bookings?limit=${limit}&offset=${offset}&startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`
          );
          bookings = oldBookings;
          if (bookings.length) {
            dispatch({
              type: GET_ADMIN_BOOKINGS_WITH_NEW_SEARCH_TERM_SUCCESS,
              payload: { bookings, searchTerm, type },
            });
          } else {
            dispatch({
              type: END_OF_ADMIN_BOOKINGS,
              payload : {type}
            });
          }
        } else {
          const { data: oldBookings } = await req()(
            `admin/bookings/old-bookings?limit=${limit}&offset=${offset}&startDate=${startDate}&endDate=${endDate}`
          );
          bookings = oldBookings;
          
          if (bookings.length) {
            dispatch({
              type: GET_ADMIN_BOOKINGS_SUCCESS,
              payload: { bookings, type },
            });
          } else {
            dispatch({
              type: END_OF_ADMIN_BOOKINGS,
              payload: { type },
            });
          }
        }
      }
    } catch (err) {
      dispatch({ type: GET_ADMIN_BOOKINGS_FAILURE, payload: { type } });
      dispatch({
        type: ADD_TOAST,
        payload: {
          message:
            "Noget gik galt, da vi prøvede at hente bookingerne. Hvis fejlen gentager sig, bedes do kontakte TjenerTeamet",
          type: "error",
          autoDismiss: true,
        },
      });
    }
  };
}

export function resetBookings() {
  return async function (dispatch, getState) {
    dispatch({ type: RESET_BOOKINGS });
  };
}

export function changeWeekNumber(payload) {
  return async function (dispatch, getState) {
    dispatch({ type: CHANGE_WEEK_NUMBER, payload });
  };
}
export function changeTab(payload) {
  return async function (dispatch, getState) {
    dispatch({ type: CHANGE_TAB, payload });
  };
}
export function changeAllGravyStatus(payload) {
  return async function (dispatch, getState) {
    dispatch({ type: CHANGE_ALL_GRAVY_STATUS, payload });
  };
}
