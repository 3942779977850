import React from "react";
import colors from "../styles/colors";

export const getToolTipStaffCardDescription = (
  staffType,
  questionNumber,
  difficulty
) => {
  if (staffType === "waiter") {
    return getWaiterDescription(questionNumber, difficulty);
  }

  if (staffType === "bartender") {
    return getBartenderDescription(questionNumber, difficulty);
  }
};

const getWaiterDescription = (questionNumber, difficulty) => {
  let question,
    answer = ``;

  if (questionNumber === 1) {
    question = `Kan du gå med 3 anrettede tallerkner?`;
    switch (difficulty) {
      case 1:
        answer = `Det har jeg ikke prøvet før`;
        break;
      case 2:
        answer = `Jeg har prøvet det få gange`;
        break;
      case 3:
        answer = `Jeg har prøvet det en del gange`;
        break;
      case 4:
        answer = `Det har jeg helt styr på`;
        break;
      case 5:
        answer = `Jeg kan endda tage 4-5 tallerkner`;
      default:
        break;
    }
  }
  if (questionNumber === 2) {
    question = `Kan du håndtere en tjenerbakke med champagneglas?`;
    switch (difficulty) {
      case 1:
        answer = `Det har jeg ikke prøvet før`;
        break;
      case 2:
        answer = `Jeg har prøvet det få gange`;
        break;
      case 3:
        answer = `Jeg har prøvet det en del gange`;
        break;
      case 4:
        answer = `Det har jeg helt styr på`;
        break;
      case 5:
        answer = `Ja, 15 fyldte glas og trapper`;
      default:
        break;
    }
  }
  if (questionNumber === 3) {
    question = `Kan du stable tallerkner på én arm, når du rydder af?`;
    switch (difficulty) {
      case 1:
        answer = `Det har jeg ikke prøvet før`;
        break;
      case 2:
        answer = `Jeg har prøvet det få gange`;
        break;
      case 3:
        answer = `Jeg har prøvet det en del gange`;
        break;
      case 4:
        answer = `Det har jeg helt styr på`;
        break;
      case 5:
        answer = `Jeg kan rydde af 8-12 ad gangen`;
      default:
        break;
    }
  }
  if (questionNumber === 4) {
    question = `Hvor meget erfaring har du med at skænke vin?`;
    switch (difficulty) {
      case 1:
        answer = `Det har jeg ikke prøvet før`;
        break;
      case 2:
        answer = `Jeg har prøvet det få gange`;
        break;
      case 3:
        answer = `Jeg har prøvet det en del gange`;
        break;
      case 4:
        answer = `Det har jeg helt styr på`;
        break;
      case 5:
        answer = `Jeg kan med begge hænder`;
      default:
        break;
    }
  }
  if (questionNumber === 5) {
    question = `Hvad er din samlede erfaring som tjener?`;
    switch (difficulty) {
      case 1:
        answer = `0-1 vagter jobs`;
        break;
      case 2:
        answer = `2-5 vagter jobs`;
        break;
      case 3:
        answer = `6-19 vagter jobs`;
        break;
      case 4:
        answer = `20+ vagter jobs`;
        break;
      case 5:
        answer = `60+ vagter jobs (min. 2 års erfaring)`;
      default:
        break;
    }
  }

  return (
    <div>
      <p
        style={{
          fontSize: "0.65rem",
          fontWeight: "bold",
          padding: `0.5rem 1rem`,
          marginBottom: "0.2rem",
          maxWidth: "16rem"
        }}
      >
        {question}
      </p>
      <p
        style={{
          fontSize: "0.65rem",
          backgroundColor: colors.white,
          color: colors.black,
          fontWeight: "bold",
          padding: `0.5rem 1rem`,
          borderRadius: "3px"
        }}
      >
        {answer}
      </p>
    </div>
  );
};

const getBartenderDescription = (questionNumber, difficulty) => {
  let question,
    answer = ``;

  if (questionNumber === 1) {
    question = `Hvor meget erfaring har du som øl/vin/vand tender?`;
    switch (difficulty) {
      case 1:
        answer = `Det har jeg ikke prøvet før`;
        break;
      case 2:
        answer = `Jeg har prøvet det få gange`;
        break;
      case 3:
        answer = `Jeg har prøvet det en del gange`;
        break;
      case 4:
        answer = `Det har jeg helt styr på`;
        break;
      case 5:
        answer = `Jeg er ekspert`;
    }
  }
  if (questionNumber === 2) {
    question = `Hvor meget erfaring har du som sjusbartender?`;
    switch (difficulty) {
      case 1:
        answer = `Det har jeg ikke prøvet før`;
        break;
      case 2:
        answer = `Jeg har prøvet det få gange`;
        break;
      case 3:
        answer = `Jeg har prøvet det en del gange`;
        break;
      case 4:
        answer = `Det har jeg helt styr på`;
        break;
      case 5:
        answer = `Jeg er ekspert`;
    }
  }
  if (questionNumber === 3) {
    question = `Hvor meget erfaring har du som cocktailbartender?`;
    switch (difficulty) {
      case 1:
        answer = `Det har jeg ikke prøvet før`;
        break;
      case 2:
        answer = `Jeg har prøvet det få gange`;
        break;
      case 3:
        answer = `Jeg har prøvet det en del gange`;
        break;
      case 4:
        answer = `Det har jeg helt styr på`;
        break;
      case 5:
        answer = `Jeg er ekspert`;
    }
  }
  if (questionNumber === 4) {
    question = `Har du sat en bar op og pakket den ned?`;
    switch (difficulty) {
      case 1:
        answer = `Det har jeg ikke prøvet før`;
        break;
      case 2:
        answer = `Jeg har prøvet det få gange`;
        break;
      case 3:
        answer = `Jeg har prøvet det en del gange`;
        break;
      case 4:
        answer = `Det har jeg helt styr på`;
        break;
      case 5:
        answer = `Jeg har set de fleste kørelister og kan let forstå dem`;
    }
  }
  if (questionNumber === 5) {
    question = `Hvad er din samlede erfaring bag baren?`;
    switch (difficulty) {
      case 1:
        answer = `0-1 vagter jobs`;
        break;
      case 2:
        answer = `2-5 vagter jobs`;
        break;
      case 3:
        answer = `6-19 vagter jobs`;
        break;
      case 4:
        answer = `20+ vagter jobs`;
        break;
      case 5:
        answer = `60+ vagter jobs (min. 2 års erfaring)`;
    }
  }

  return (
    <div>
      <p
        style={{
          fontSize: "0.65rem",
          fontWeight: "bold",
          padding: `0.5rem 1rem`,
          marginBottom: "0.2rem",
          maxWidth: "16rem"
        }}
      >
        {question}
      </p>
      <p
        style={{
          fontSize: "0.65rem",
          backgroundColor: colors.white,
          color: colors.black,
          fontWeight: "bold",
          padding: `0.5rem 1rem`,
          borderRadius: "3px"
        }}
      >
        {answer}
      </p>
    </div>
  );
};
