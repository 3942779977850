// Libs
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";

// Style
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import CashMultipleIcon from "mdi-react/CashMultipleIcon";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import CancelIcon from "mdi-react/CancelIcon";
import SendIcon from "mdi-react/SendIcon";
import colors from "../../../styles/colors";

// Components
import ReactStars from "react-stars";
import NavigationBar from "../../NavigationBar";
import PageContainer from "../../ui/PageContainer";
import NavigateBackArrow from "../../ui/NavigateBackArrow";
import Spinner from "../../ui/Spinner";
import DateViewer from "../../ui/DateViewer";
import BeforeShiftModal from "./BeforeShiftModal";
import SkillsModal from "../editEmployee/SkillsModal";
import SimpleCancelShiftsModal from "./SimpleCancelShiftsModal";
import CancelShiftModal from "./CancelShiftModal";
import ErrorBox from "../../ui/ErrorBox";

// Utilities
import req from "../../../utilities/request-utility";
import { transportWageTimeConverter } from "../../../utilities/transport-wage-time-converter";
import { staffTypeLanguage } from "../../../utilities/language-translator";
import { doesWorkSkillExist } from "../../../utilities/check-if-workSkill-exists";
import { getWorkSkillQuestion } from "../../../utilities/get-workskill-question";
import {addCommas} from "../../../utilities/add_commas";
import {remove_commas} from "../../../utilities/remove_commas";
import {workHours} from "../../../utilities/get-work-hours-total";

// Actions
import { addToast, hideModal, showModal } from "../../../actions/uiActions";
import { updateWorkSkill } from "../../../actions/authActions";

// Config
import shiftTypes from "../../../config/shiftTypes";

// Styles
import { breakPoint } from "../../../styles/breakPoints";

const BookingInformation = props => {
  const { bookingId } = props.match.params;
  const history = useHistory();

  // State
  const [booking, setBooking] = useState(null);


  // User information
  const { workSkills } = useSelector(state => state.auth.user);

  // Page state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    await req()
      .get(`employees/bookings/${bookingId}`)
      .then(({ data: booking }) => {
        setBooking(booking);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
        props.addToast({
          message: "Kunne ikke hente information",
          type: "error",
          autoDismiss: true
        });
      });
  };

  const deleteShift = () => {
    setLoading(true);
    req()
      .put(`shifts/employee/cancel/${bookingId}`)
      .then(() => {
        history.push("/");
      })
      .catch(() => {
        props.addToast({
          message: "Noget gik galt",
          type: "error",
          autoDismiss: true
        });
      });
  };

  const applyForBooking = () => {
    if (doesWorkSkillExist(booking.staff_type, workSkills)) {
      props.showModal({
        title: "ANSØG JOB",
        content: (
          <BeforeShiftModal
            setLoading={setLoading}
            getData={() => getData()}
            booking={booking}
          />
        )
      });
    } else {
      props.showModal({
        hideTop: true,
        disableBackgroundCancel: true,
        content: (
          <SkillsModal
            update={workSkills => {
              props.updateWorkSkill(workSkills, () =>
                props.showModal({
                  title: "ANSØG JOB",
                  content: (
                    <BeforeShiftModal
                      setLoading={setLoading}
                      getData={() => getData()}
                      booking={booking}
                    />
                  )
                })
              );
            }}
            workSkillQuestions={getWorkSkillQuestion("dk", booking.staff_type)}
          />
        )
      });
    }
  };

  return (
    <PageContainer NavigationBar={true}>
      <NavigationBar />
      <NavigateBackArrow navigateURL={() => history.push("/")} />

      {loading && !error && <Spinner title={"Indlæser..."} />}
      {!loading && error && <ErrorBox />}
      {!loading && !error && (
        <div className={componentStyle()}>
          <div className="top-section-container">
            <div className="arrangement-box">
              <p className="arrangement-title">{booking.arrangement_type}</p>
              <div className="address-box">
                <MapMarkerIcon />
                <div>
                  <p>{`${booking.address}, ${booking.zip_code}`}</p>
                  <p>{booking.city}</p>
                </div>
              </div>
            </div>
            <div className="corporation-box">
              {booking.corporation.icon_image && (
                <div className="image-container">
                  <img
                    src={`${booking.corporation.baseURL_image}h_100,w_100/dpr_2.0/r_max/${booking.corporation.icon_image}`}
                    alt="icon"
                  />
                </div>
              )}
              <p className="corporation-name">{booking.corporation.name}</p>
              <div className="rating-container">
                <ReactStars
                  count={5}
                  edit={false}
                  value={booking.corporation.rating.rating}
                  size={16}
                />
                <p>
                  {booking.corporation.rating.nrOfRatings > 1
                    ? `(${booking.corporation.rating.nrOfRatings} ratings)`
                    : `(${booking.corporation.rating.nrOfRatings} rating)`}
                </p>
              </div>
            </div>
          </div>

          <div className="middle-section">
            <div className="date-container">
              <DateViewer
                className="date-style"
                style={{ marginRight: "0.5rem" }}
                date={booking.date}
                size={22}
              />
              <div className="date-box">
                <div>
                  <p className="staff-type-label">
                    {`${staffTypeLanguage(booking.staff_type, "dk")}`}
                  </p>
                </div>
                <p className="time-title">{`${booking.start_time}:${booking.end_time}`}</p>
                <div className="payment-box">
                  <CashMultipleIcon className="money-icon" />
                  <p className="wage-total">
                    {`DKK ${addCommas(
                        remove_commas(booking.hourly_wage) *
                        workHours({
                          start_time: booking.start_time,
                          end_time: booking.end_time
                        })
                    )}`}
                  </p>
                  <p className="wage-title">
                    {`(${booking.hourly_wage} dkk/t)`}{" "}
                  </p>
                  <p>{}</p>
                </div>
                {booking.transport_wage > 0 && (
                  <div className="transport-fee-container">
                    <CheckCircleIcon className="check-icon" />
                    <p className="transport-fee-title">{`${transportWageTimeConverter(
                      booking.transport_wage,
                      "dk"
                    )} transport tillæg`}</p>
                  </div>
                )}
                <div className="payment-date">
                  <p>{`(Udbetales d. ${booking.paymentDate})`}</p>
                </div>
              </div>
            </div>

            <div className="job-description">
              <p>{booking.job_description || " "}</p>
            </div>

            <div className="booking-checkpoints-container">
              <div className="column-box">
                <p>Sko:</p>
                <p>
                  {booking.shoes_dress_code
                    ? booking.shoes_dress_code
                    : booking.shoes_dress_code_other}
                </p>
              </div>
              <div className="column-box">
                <p>Buks:</p>
                <p>
                  {booking.lower_dress_code
                    ? booking.lower_dress_code
                    : booking.lower_dress_code_other}
                </p>
              </div>
              <div className="column-box">
                <p>Overdel:</p>
                <p>
                  {booking.upper_dress_code
                    ? booking.upper_dress_code
                    : booking.upper_dress_code_other}
                </p>
              </div>
              <div className="column-box">
                <p>Medbring:</p>
                <p>{booking.item_to_bring && booking.item_to_bring}</p>
              </div>
              <div className="column-box">
                <p>Overarbejde:</p>
                <p>
                  {booking.extra_work_hours > 0 ? (
                    `Der kan forekomme ${transportWageTimeConverter(
                      booking.extra_work_hours,
                      "dk"
                    )} ekstra arbejde`
                  ) : (
                    <CancelIcon className="cancel-icon" />
                  )}
                </p>
              </div>
              <div className="column-box">
                <p>Personalemad:</p>
                <p>
                  {booking.food_included ? (
                    <CheckCircleIcon className="check-icon" />
                  ) : (
                    <CancelIcon className="cancel-icon" />
                  )}
                </p>
              </div>
              <div className="column-box">
                <p style={{paddingRight: '7px'}}>Language <br />Requirements: </p>
                <p>{booking.language ? booking.language === 'Engelsk' ? ` English 🇬🇧` : booking.language === 'Dansk' ? `Danish 🇩🇰` : `Danish 🇩🇰 or English 🇬🇧`: ``}</p>
              </div>
            </div>
          </div>

          {booking.shift && booking.shift.status === shiftTypes.selected ? (
            <div className="approved-container">
              <div className="column-box" style={{ marginBottom: "0.5rem" }}>
                <p>Kontakt person:</p>
                <p>{booking.contact_person}</p>
              </div>

              <p style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
                Hvordan kommer jeg ind?
              </p>
              <p style={{ marginBottom: "0.5rem" }}>
                {booking.access_information
                  ? booking.access_information
                  : booking.access_information_other}
              </p>
              <p style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
                Vigtigt!
              </p>
              <p>
                I TjenerTeamet kommer vi altid 15 før mødetidspunkt. Vær i god
                tid så du evt. kan nå at klæde om. Du skal være omklædt og klar
                til at arbejde til mødetiden og kom ikke på tom mave!!
              </p>
            </div>
          ) : (
            <div className="not-approved-yet-container">
              <p>
                DETTE FELT BLIVER SYNLIGT, HVIS DU BLIVER GODKENDT TIL JOBBET
              </p>
            </div>
          )}

          <div className="btn-section-container">
            <div>
              {(booking.shift &&
                booking.shift.status === shiftTypes.selected) ||
              (booking.shift && booking.shift.status === shiftTypes.applied) ? (
                <Fragment>
                  {booking.shift &&
                    booking.shift.status === shiftTypes.selected && (
                      <button
                        onClick={() =>
                          props.showModal({
                            content: <CancelShiftModal bookingId={bookingId} />
                          })
                        }
                        className="cancel-policy-btn"
                      >
                        Afbudspolitik
                      </button>
                    )}
                  <button
                    onClick={
                      booking.shift.status === shiftTypes.selected
                        ? () =>
                            props.showModal({
                              content: (
                                <CancelShiftModal bookingId={bookingId} />
                              )
                            })
                        : () =>
                            props.showModal({
                              content: (
                                <SimpleCancelShiftsModal
                                  cancelShift={() => deleteShift()}
                                />
                              )
                            })
                    }
                    className="cancel-btn"
                  >
                    Annuller ansøgning
                  </button>
                </Fragment>
              ) : (
                <button
                  onClick={() => applyForBooking()}
                  className="submit-btn"
                >
                  <span>Send ansøgning</span>
                  <SendIcon />
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  updateWorkSkill: bindActionCreators(updateWorkSkill, dispatch)
});

const componentStyle = props => css`
  div.approved-container {
    padding: 0.5rem 2.5rem;
    background-color: ${colors.lighterGreen};
    display: flex;
    flex-direction: column;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.65rem;
    }
  }

  div.btn-contain {
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
  }

  .top-section-container {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 2rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      align-items: flex-end;
      padding: 0.5rem 0.5rem;
    }

    .arrangement-box {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .arrangement-title {
        font-size: 1.5rem;
        font-weight: 500;

        @media screen and (max-width: ${breakPoint.sm}px) {
          font-size: 1.12rem;
        }
      }

      .address-box {
        display: flex;
        align-items: center;

        p {
          font-size: 0.875rem;
          font-weight: 500;

          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 0.65rem;
          }
        }

        svg {
          height: 2rem;
          width: auto;
          margin-right: 0.5rem;

          @media screen and (max-width: ${breakPoint.sm}px) {
            height: 1rem;
          }
        }
      }
    }

    .corporation-box {
      display: flex;
      flex-direction: column;

      .image-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media screen and (max-width: ${breakPoint.sm}px) {
          margin-bottom: 0.5rem;
          align-items: flex-end;
        }

        img {
          width: 6.4rem;
          height: 6.4rem;
          border-radius: 9999px;

          @media screen and (max-width: ${breakPoint.sm}px) {
            width: 4rem;
            height: 4rem;
          }
        }
      }

      .corporation-name {
        font-size: 1.5rem;
        font-weight: 500;

        @media screen and (max-width: ${breakPoint.sm}px) {
          font-size: 0.85rem;
        }
      }

      .rating-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        p {
          margin-left: 0.25rem;
          font-size: 0.75rem;
        }
      }
    }
  }

  .middle-section {
    background-color: ${colors.white};
    padding: 0.5rem 2rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      padding: 0.5rem 0.5rem;
    }

    .date-container {
      display: flex;
      margin-bottom: 1rem;

      .date-style {
        .day,
        .month {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 17px;
          }
        }

        .date {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 20px;
          }
        }
      }

      .date-box {
        display: flex;
        flex-direction: column;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        margin-left: 0.5rem;

        .transport-fee-container {
          display: flex;
          align-items: center;
          margin-top: 0.25rem;

          @media screen and (max-width: ${breakPoint.sm}px) {
            svg {
              height: 1rem;
              width: 1rem;
            }
          }

          .transport-fee-title {
            font-size: 0.875rem;
            font-weight: 700;

            @media screen and (max-width: ${breakPoint.sm}px) {
              font-size: 0.55rem;
            }
          }
        }

        .staff-type-label {
          text-align: center;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          background-color: ${colors.lightBlue};
          font-size: 0.75rem;
          border-radius: 0.25rem;
          color: ${colors.white};
          font-weight: 500;
          letter-spacing: 0.05em;
          width: auto;
          display: inline-block;
          text-transform: uppercase;

          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 0.65rem;
          }
        }

        .time-title {
          font-size: 1.25rem;
          letter-spacing: 0.05em;

          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 1rem;
          }
        }

        .payment-box {
          display: flex;
          align-items: center;

          .money-icon {
            height: 1.5rem;
            width: auto;
            margin-right: 0.5rem;

            @media screen and (max-width: ${breakPoint.sm}px) {
              height: 1rem;
              width: 1rem;
            }
          }

          .wage-total {
            font-size: 0.875rem;
            font-weight: 700;
            margin-right: 0.25rem;
            letter-spacing: 0.05em;

            @media screen and (max-width: ${breakPoint.sm}px) {
              font-size: 0.65rem;
            }
          }

          .wage-title {
            font-size: 0.875rem;
            margin-right: 0.5rem;
            letter-spacing: 0.05em;

            @media screen and (max-width: ${breakPoint.sm}px) {
              font-size: 0.75rem;
            }
          }
        }

        .payment-date {
          margin-top: 0.25rem;

          @media screen and (max-width: ${breakPoint.sm}px) {
            p {
              font-size: 0.75rem;
            }
          }
        }
      }
    }

    .job-description {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-bottom: 2rem;
      overflow-wrap: break-word;
      white-space: pre-line;

      @media screen and (max-width: ${breakPoint.sm}px) {
        margin-bottom: 1rem;
        p {
          font-size: 0.8rem;
        }
      }
    }

    .booking-checkpoints-container {
      display: flex;
      flex-direction: column;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .column-box {
    display: flex;
    justify-content: start;
    margin-bottom: 0.25rem;
    align-items: center;
    
    p:first-child {
      min-width: 9.6rem;
      font-weight: 600;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.65rem;
      }
    }

    p:last-child {
      font-weight: 100;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.65rem;

        svg {
          height: 1rem;
        }
      }
    }
  }

  .not-approved-yet-container {
    background-color: ${colors.mediumGrey};
    padding: 1.5rem 2rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      p {
        font-size: 0.85rem;
      }
    }
  }

  .btn-section-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: ${colors.white};
    padding: 1.25rem 2rem 2rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      padding: 1.25rem 0.5rem;
    }

    .countdown-container {
      max-width: 20rem;
      margin-right: 1rem;

      p {
        background-color: ${colors.darkerRed};
        color: ${colors.white};
        border-radius: 0.25rem;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 700;
        padding: 0.38rem 1rem;
        white-space: nowrap;
      }
    }

    .cancel-policy-btn,
    .submit-btn,
    .cancel-btn {
      font-size: 0.875rem;
      border: 1px solid ${colors.lightRed};
      color: ${colors.lightRed};
      box-sizing: border-box;
      padding: 0.25rem 1rem;
      border-radius: 5px;
      outline: none;
      margin-right: 0.5rem;
      font-weight: bold;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.65rem;
      }
    }
    .cancel-policy-btn:hover {
      background-color: ${colors.lightGrey};
    }

    .submit-btn {
      display: flex;
      align-items: center;
      border: 1px solid ${colors.darkerGreen};
      background-color: ${colors.green};
      color: ${colors.white};
      font-weight: bold;

      span {
        margin-right: 0.5rem;
      }
    }

    .submit-btn:hover {
      background-color: ${colors.darkerGreen};
    }

    .cancel-btn {
      border: 1px solid ${colors.red};
      background-color: ${colors.darkerRed};
      color: ${colors.white};
      font-weight: bold;
    }
    .cancel-btn:hover {
      background-color: ${colors.red};
    }
  }

  .check-icon {
    height: 1.5rem;
    fill: ${colors.green};
    margin-right: 0.25rem;
  }

  .cancel-icon {
    height: 1.5rem;
    fill: ${colors.red};
    margin-right: 0.25rem;
  }
`;

export default connect(null, mapDispatchToProps)(BookingInformation);
