// Modules
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { css } from "emotion";

// Styles
import "moment/locale/da";
import colors from "../../styles/colors";

// Utilities and config
import { addToast } from "../../actions/uiActions";
import StatusBar from "../ui/StatusBar";
import userTypes from "../../config/userTypes";
import req from "../../utilities/request-utility";

// Components
import MyBookingsOverview from "./bookings/MyBookingsOverview";
import PageContainer from "../ui/PageContainer";
import { breakPoint } from "../../styles/breakPoints";

const CorporationHome = props => {
  const history = useHistory();
  const [stepper, setStepper] = useState(0);

  const { user } = useSelector(state => state.auth);
  const [meta, setMeta] = useState({});

  useEffect(() => {
      (async function getCorporationMeta() {
        try {
          const { data: data } = await req()(`corporations/${user.id}/meta`);
          setMeta(data);
        } catch (error) {
          console.log(error);
        }
      })();
  }, []);

  return (
    <PageContainer showNavigation={true}>
      <div className={componentStyle(colors)}>
        <div>
          <p className="header-title">{user.name}</p>
          <p className="sub-title">
            Her kan du book personale til dit arrangement
          </p>
          <button
            onClick={() => {
              history.push("/newBooking");
            }}
            className="new-booking-btn"
          >
            BOOK PERSONALE
          </button>
        </div>

        <div className="home-container">
          <div className="status-container">
            <StatusBar staffType={userTypes.corporation} meta={meta} />
          </div>
        </div>
        <div>{stepper === 0 && <MyBookingsOverview />}</div>
      </div>
    </PageContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch)
});

const componentStyle = colors => css`
  padding-top: 2rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  @media screen and (max-width: ${breakPoint.sm}px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .header-title {
    font-size: 1.875rem;
  }

  .sub-title {
    margin-bottom: 0.5rem;
    color: ${colors.darkerGrey};
  }

  .home-container {
    margin-bottom: 0.75rem;

    .status-container {
      margin-bottom: 3.25rem;
    }
  }

  .notification-icon {
    position: relative;
    top: -13px;
    left: -16px;
    background-color: ${colors.darkRed};
    color: ${colors.white};
    font-weight: 900;
    padding: 0.12rem 0.4rem;
    border-radius: 50%;
    font-size: 0.8rem;
  }

  .new-booking-btn {
    outline: none;
    background-color: ${colors.lightBlue};
    padding: 0.75rem;
    border-radius: 0.25rem;
    color: ${colors.white};
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .new-booking-btn:hover {
    background-color: ${colors.blue};
  }

  .status-container {
    margin-bottom: 1.25rem;
    margin-left: 0.75rem;
  }

  .navigation-container {
    ul {
      display: flex;
      border-bottom-width: 1px;
    }

    li {
      margin-bottom: -1px;
    }

    li:first-child {
      margin-right: 0.25rem;
    }

    .tab {
      min-width: 10rem;
      text-align: center;
      background-color: ${colors.darkGrey};
      display: inline-block;
      border-left-width: 1px;
      border-right-width: 1px;
      border-top-width: 1px;
      border-color: ${colors.grey};
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      padding: 0.5rem 1rem;
      font-weight: 700;
      font-size: 0.75rem;
      color: ${colors.white};
      cursor: pointer;
    }

    .selected-tab {
      min-width: 10rem;
      text-align: center;
      background-color: ${colors.white};
      display: inline-block;
      border-left-width: 1px;
      border-right-width: 1px;
      border-top-width: 1px;
      border-color: ${colors.grey};
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      padding: 0.5rem 1rem;
      font-weight: 700;
      font-size: 0.75rem;
      color: ${colors.darkGrey};
      cursor: default;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.65rem;
      }
    }
  }
`;

export default connect(null, mapDispatchToProps)(CorporationHome);
