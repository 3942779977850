// Libs
import React, { useState } from "react";
import { css } from "emotion";
import { useDispatch } from "react-redux";

// Utilities
import req from "../../../utilities/request-utility";

// Components
import Button_DEPRECATED from "../../ui/Button_DEPRECATED";

// Actions
import { hideModal } from "../../../actions/uiActions";

// Style
import colors from "../../../styles/colors";

const RemoveStaffModal = props => {
  const { setShift, shift } = props;
  const [removeReason, setRemoveReason] = useState("");

  const dispatch = useDispatch();

  return (
    <div className={componentStyle()}>
      <p className="title">{`Hvorfor ønsker du at fjerne ${shift.employee.first_name} fra bookingen?`}</p>

      <textarea
        rows={5}
        placeholder={"Angiv begrundelse"}
        onChange={e => setRemoveReason(e.target.value)}
      />
      <hr />
      <div className="btn-container">
        <Button_DEPRECATED
          className="cancel-btn"
          title={"Annullér"}
          onClick={() => dispatch(hideModal())}
        />
        <Button_DEPRECATED
          disabled={removeReason.length === 0}
          className={`${removeReason.length === 0 ? "not-valid" : "remove"}`}
          onClick={() => {
            setShift(shift.id, "removed", removeReason);
            dispatch(hideModal());
          }}
        >
          {`Fjern ${shift.employee.first_name}!`}
        </Button_DEPRECATED>
      </div>
    </div>
  );
};
const componentStyle = () => css`
  padding: 1rem;

  .title {
    font-size: 1rem;
    font-weight: 500;
    color: ${colors.darkerGrey};
    margin-bottom: 1rem;
  }

  hr {
    margin: 0.5rem;
  }

  textarea {
    cursor: text;
    display: block;
    font-size: 0.75rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
  }

  input:hover {
    border: 1px solid ${colors.black};
  }

  input::placeholder {
    color: ${colors.darkerGrey};
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
  }

  .cancel-btn {
    width: auto;
    padding: 0.25rem 1rem;
    margin: 0 0.5rem;
    background-color: ${colors.white} !important;
    border: 1px solid ${colors.grey};
    color: ${colors.darkerGrey};
    font-size: 0.85rem;
  }
  .cancel-btn:hover {
    cursor: pointer;
    background-color: ${colors.lightGrey} !important;
  }

  .remove,
  .not-valid {
    width: auto;
    padding: 0.25rem 1rem;
    margin: 0 0.5rem;
    font-size: 0.85rem;
    background-color: ${colors.darkerRed} !important;
  }

  .not-valid {
    background-color: ${colors.grey} !important;
  }

  .remove:hover {
    cursor: pointer;
    background-color: ${colors.red} !important;
  }

  svg {
    height: 1.25rem;
    margin-right: 0.5rem;
  }
`;

export default RemoveStaffModal;
