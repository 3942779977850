// Libs
import React from "react";
import { css } from "emotion";

// Components
import Logo from "../../ui/Logo";
import CorporationNameAndRating from "../../ui/CorporationNameAndRating";
import DateViewer from "../../ui/DateViewer";
import CorporationBookingOverview from "../../ui/CorporationBookingOverview";
import moneyBagSVG from "../../../styles/icons/money-bag.svg";

// Utilities
import { staffTypeLanguage } from "../../../utilities/language-translator";

// Styles
import colors from "../../../styles/colors-V-1.0";
import { useSelector } from "react-redux";
import { breakPoint } from "../../../styles/breakPoints";
import { remove_commas } from "../../../utilities/remove_commas";
import { workHours } from "../../../utilities/get-work-hours-total";
import { addCommas } from "../../../utilities/add_commas";

const BookingOverview = props => {
  // Props
  const {
    id: bookingId,
    corporation,
    date,
    arrangement_type,
    address,
    zip_code,
    city,
    start_time,
    end_time,
    hourly_wage,
    staff_type,
    disabledBooking,
    language
  } = props;

  // Redux state
  const { workSkills } = useSelector(state => state.auth.user);

  return (
    <div
      onClick={() =>
        props.showBooking({
          bookingId: bookingId,
          staffType: staff_type,
          employeeWorkSkills: workSkills
        })
      }
      style={props.style && props.style}
      className={`${componentStyle(props)} ${
        props.className ? props.className : ""
      }`}
    >
      {/** First section **/}
      <div className="first-wrapper">
        <div className="logo-wrapper">
          <Logo
            className="logo"
            size={50}
            src={
              corporation.baseURL_image &&
              corporation.icon_image &&
              `${corporation.baseURL_image}h_100,w_100/dpr_2.0/r_max/${corporation.icon_image}`
            }
          />
        </div>
        <CorporationNameAndRating
          className="corporation-name-and-rating"
          name={corporation.name}
          numberOfRatings={corporation.rating.nrOfRatings}
          value={corporation.rating.rating}
        />
      </div>
      {/** Second section **/}
      <div className="second-wrapper">
        <div className="date-wrapper">
          <DateViewer className="date-style" size={22} date={date} />
        </div>
        <div className="info-wrapper">
          <CorporationBookingOverview
            arrangementType={arrangement_type}
            address={`${address}, ${zip_code} ${city} `}
            time={`Kl. ${start_time} - ${end_time}`}
            price={`DKK ${addCommas(
              remove_commas(hourly_wage) *
                workHours({
                  start_time,
                  end_time
                })
            )} (${hourly_wage} /t)`}
            staffType={`${staffTypeLanguage(staff_type, "dk")}`}
            priceIcon={moneyBagSVG}
            disabledBooking={disabledBooking}
            className={disabledBooking && 'disabled'}
            language={language}
          />
        </div>
      </div>
    </div>
  );
};

const componentStyle = props => css`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  cursor: pointer;
  .disabled {
    color: #ccc;
  }

  &:hover {
    border: 1px solid ${colors.grey_10};
  }

  div.first-wrapper {
    display: flex;

    .logo-wrapper {
      width: 4.5rem;
      display: flex;
      justify-content: start;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2.5rem;
      }

      .logo {
        @media screen and (max-width: ${breakPoint.sm}px) {
          height: ${props.size - 10 || 30}px;
          width: ${props.size - 10 || 30}px;
        }
      }
    }

    .corporation-name-and-rating {
      display: flex;
      justify-content: center;
    }
  }

  div.second-wrapper {
    display: flex;

    .date-wrapper {
      padding-top: 0.5rem;
      display: flex;
      align-items: center;
      width: 4.5rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2.5rem;
      }

      .date-style {
        .day,
        .month {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: ${props.size - 6 || 12}px;
          }
        }

        .date {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: ${props.size - 3 || 25}px;
          }
        }
      }
    }

    .info-wrapper {
      flex: 1;
      background-color: ${colors.white};
      border-radius: 3px;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
    }
  }
`;

export default BookingOverview;
