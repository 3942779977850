// Libs
import React from "react";
import { css } from "emotion";

// Style
import colors from "../../styles/colors-V-1.0";

const Label = props => {
  return (
    <label
      id={props.id}
      style={props.style && props.style}
      className={`${componentStyle(props)} ${
        props.className ? props.className : ""
      }`}
    >
      {props.notifications && (
        <span className="notification">{props.notifications}</span>
      )}
      {props.value}
    </label>
  );
};

const componentStyle = props => css`
  letter-spacing: 0.025em;
  font-size: ${props.size || 12}px;
  position: relative;
  cursor: inherit;

  .notification {
    position: absolute;
    top: -9px;
    right: -5px;
    border-radius: 50%;
    background: ${colors.red_20};
    color: ${colors.white};
    line-height: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 6px;

    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
`;

export default Label;
