// Libs
import React, { useState } from "react";
import Select from 'react-select';

// Styles
import { css } from "emotion";
import colors from "../../styles/colors";

// Components
import AccountIcon from "mdi-react/AccountIcon";
import CustomMenuList from "./CustomMenuList"

const UserPicker = props => {
    const {allEmployees, onSubmit} = props
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    return (
        <div className={componentStyle(isMenuOpen)}>
            {/* TID */}
            <div
                className="outer-container"
            >
                <div className="select-employee-container">
                    <Select
                        onMenuClose={() => {
                            setIsMenuOpen(false)
                        }}
                        onMenuOpen={() => {
                            setIsMenuOpen(true)
                        }}
                        defaultMenuIsOpen={true}
                        menuShouldScrollIntoView={true}
                        defaultValue={selectedEmployee}
                        onChange={(employee) => setSelectedEmployee(employee.value)}
                        options={allEmployees}
                        isSearchable={true}
                        components={{
                          MenuList: CustomMenuList
                        }}
                        formatOptionLabel={employee => (
                            <div className="select-employee" style={{ display: 'flex', alignItems: 'center' }}>
                                {employee.baseURL_image ?
                                    <img src={`${employee.baseURL_image}c_thumb,g_face,h_160,w_160/dpr_2.0,f_auto/${employee.profile_image}`} style={{ height: '2rem', width: '2rem' }} alt="employee-image" /> :
                                    <AccountIcon size={32} />
                                }
                                <span style={{ marginLeft: '0.5rem', fontSize: '13px' }}>{employee.label}</span>
                            </div>
                        )}
                    />
                </div>

            </div>

            <button onClick={() => onSubmit(selectedEmployee)} className="submit-btn">
                Tilføj medarbejder
            </button>
        </div>
    );
};

const componentStyle = (isMenuOpen) => css`
  padding: 0 1rem 1rem;

  .outer-container {
    .select-employee-container{
        width: 25vw; 
        height: ${isMenuOpen ? '40vh' : 'auto'};
        overflow: hidden
    }
    .staff-container {
      margin-right: 0.5rem;
    }

    .date-container {
      margin-right: 0.5rem;
    }

    .amount-staff-container {
      width: 3.3rem;
    }
  }

  .colon {
  }

  div.rating-container {
    div.rating {
      display: flex;
      justify-content: center;
    }
  }

  div.comment-container {
    margin-bottom: 1rem;
  }

  button.submit-btn {
    text-align: center;
    width: 100%;
    font-size: 0.85rem;
    margin-top: 0.5rem;
    background-color: ${colors.lightBlue};
    color: ${colors.white};
    padding: 0.35rem 0rem;
    font-weight: bold;
    border-radius: 5px;
  }

  button.submit-btn:hover {
    background-color: ${colors.blue};
  }
`;

export default UserPicker;
