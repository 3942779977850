// Libs
import React, { Fragment } from "react";
import { css } from "emotion";

// Styles
import colors from "../../../styles/colors";
import StepBar from "../../ui/StepBar";
import { breakPoint } from "../../../styles/breakPoints";

const IntroductionVideo = props => {
  return (
    <Fragment>
      <div className={componentStyle()}>
        <div className="upper-section">
          <p className="title">
            Vi har lavet en kort introduktionsvideo, som fortæller om os,
            hvordan vi arbejder og, hvad vi kan tilbyde!
          </p>
          <p className="sub-title">
            Det er meget vigtigt, at du tager dig tid til at se og høre denne
            video
          </p>
        </div>
        {/*<StepBar*/}
        {/*  currentStep={3}*/}
        {/*  steps={4}*/}
        {/*  titles={["VÆLG JOBS", "VÆLG OMRÅDER", "VEJLEDNING", "OM DIG"]}*/}
        {/*/>*/}

        <div className="container-video">
          <video controls>
            <source
              type="video/mp4"
              src={
                "https://res.cloudinary.com/personaleland-aps/video/upload/v1614775178/Intro_TjenerTeamet_2_lkh1po.mp4"
              }
            />
          </video>

          <div className="btn-container">
            <button onClick={() => props.moveBackward()} className="disabled">
              TILBAGE
            </button>
            <button onClick={() => props.moveForward()} className="submit">
              VIDERE
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const componentStyle = () => css`
  div.upper-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;

    p.title {
      margin-top: 3rem;
      text-align: center;
      font-size: 1.25rem;
      margin-bottom: 1.25rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.85rem;
        margin-bottom: 1rem;
      }
    }

    p.sub-title {
      font-size: 0.75rem;
      font-weight: 200;
      text-align: center;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.65rem;
        text-align: center;
      }
    }
  }

  div.container-video {
    border-radius: 0.25rem;
    overflow: hidden;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    background-color: ${colors.white};
    margin: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;

    video {
      margin-bottom: 2rem;
    }
  }

  div.btn-container {
    display: flex;
    justify-content: space-between;

    button.submit,
    button.disabled {
      padding: 0.5rem 3rem;
      color: ${colors.white};
      border-radius: 5px;
      outline: none;
      background-color: ${colors.lightBlue};
      margin: 0 2rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.8rem;
        margin: 0.25rem;
      }
    }

    button.submit:hover {
      background-color: ${colors.blue};
    }

    button.disabled {
      background-color: ${colors.grey};
    }

    button.disabled:hover {
      background-color: ${colors.darkGrey};
    }
  }
`;

export default IntroductionVideo;
