import React, { Fragment } from "react";
import { css } from "emotion";
import colors from "../../styles/colors";
import { breakPoint } from "../../styles/breakPoints";

const StepBar = props => {
  const { steps = 3, currentStep = 1 } = props;
  const lastStep = steps - 1;

  return (
    <div className={componentStyle(colors)} style={props.style}>
      <div className="top">
        {[...Array(steps)].map((_, index) => (
          <p>{index + 1}</p>
        ))}
      </div>
      <div className="stepper">
        {[...Array(steps)].map((_, index) => (
          <Fragment>
            <div
              className={currentStep >= index + 1 ? "check number" : "number"}
            />
            {index < lastStep && (
              <div className="flex flex-col justify-center w-full">
                <div className="w-full border-t-2 border-gray-400 bg-blue-600" />
              </div>
            )}
          </Fragment>
        ))}
      </div>
      {window.innerWidth > breakPoint.sm && (
        <div className="titles">
          {props.titles.map(title => (
            <p>{title}</p>
          ))}
        </div>
      )}
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;

  div.titles,
  div.top {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5rem 4.5rem 0 4.5rem;


    p {
      font-size: 0.75rem;
      width: 9rem;
      text-align: center;
      font-weight: bold;
    }
  }

  div.top {
    margin-bottom: 0.25rem;
    p {
      width: 8.4rem;
    }
  }

  div.stepper {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 8rem 0 8rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      margin: 1rem 1rem 0 1rem;
    }
  }

  .number {
    border-radius: 50%;
    padding: 0.5rem;
    background-color: #ffffff;
    border: 2px solid #2a6cb0;
    color: #666;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: ${breakPoint.sm}px) {
      padding: 0.25rem;
    }

    p.step-nr {
      width: 1.5rem;
    }
  }

  .check {
    background-color: ${colors.lightBlue};
  }

  svg {
    color: ${colors.lightBlue};
  }
`;

export default StepBar;
