import moment from "moment";

export function getJobTitle(language) {
  if (language === "dk") {
    return [
      { value: "Tjener" },
      { value: "Bartender" },
      { value: "Cocktailbartender" },
      { value: "Værtinde" },
      { value: "Runner" },
      { value: "Kok" },
      { value: "Køkkenmedhjælper" },
      { value: "Opvasker" },
      { value: "Eventpersonale" },
      { value: "Garderobepersonale" }
    ];
  }
}

export function getYear() {
  let startYear = parseInt(moment().format("YYYY"));
  const yearArr = [];

  for (let i = 20; i >= 0; i--) {
    yearArr.push({ value: startYear });
    startYear = startYear - 1;
  }
  return yearArr;
}

export function getMonth(language) {
  if (language === "dk") {
    return [
      {
        value: "Januar"
      },
      {
        value: "Februar"
      },
      {
        value: "Marts"
      },
      {
        value: "April"
      },
      {
        value: "Maj"
      },
      {
        value: "Juni"
      },
      {
        value: "Juli"
      },
      {
        value: "August"
      },
      {
        value: "September"
      },
      {
        value: "Oktober"
      },
      {
        value: "November"
      },
      {
        value: "December"
      }
    ];
  }
}

export function getExperienceType(language) {
  if (language === "dk") {
    return [
      {
        value: "Vikar"
      },
      {
        value: "Fuldtid"
      },
      {
        value: "Deltid"
      }
    ];
  }
}

export function getNumberOfJobs(language) {
  if (language === "dk") {
    return [
      {
        value: "1 vagt"
      },
      {
        value: "2-5 vagter"
      },
      {
        value: "6-19 vagter"
      },
      {
        value: "20+ vagter"
      },
      {
        value: "60+ vagter"
      }
    ];
  }
}
