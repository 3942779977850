export const newPasswordFormValidator = [
  {
    field: "newPassword",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler kodeord"
  },
  {
    field: "newPassword",
    method: "isLength",
    args: [{ min: 8, max: 20 }],
    validWhen: true,
    message: "Kodeordet skal være længere"
  },
  {
    field: "confirmPassword",
    method: "isEmpty",
    validWhen: false,
    message: "Bekræft kodeord"
  },
  {
    field: "confirmPassword",
    method: "isLength",
    args: [{ min: 8, max: 20 }],
    validWhen: true,
    message: "Kodeordet skal være længere"
  },
  {
    field: "confirmPassword",
    method: (confirmation, state) => state.newPassword === confirmation,
    validWhen: true,
    message: "Kodeordene stemmer ikke overens"
  }
];
