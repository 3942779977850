import moment from "moment";

export const bookingValidator = [
  {
    field: "staff_type",
    method: "isEmpty",
    validWhen: false,
    message: "Angiv personale"
  },
  {
    field: "staff_type",
    method: "isInt",
    validWhen: false,
    message: "Ikke gyldigt"
  },
  {
    field: "date",
    method: date => {
      let selectedDate = new Date(date);
      let newDate = new Date();

      if (selectedDate.getFullYear() < newDate.getFullYear()) {
        return true;
      } else if (
        selectedDate.getFullYear() === newDate.getFullYear() &&
        selectedDate.getMonth() < newDate.getMonth()
      ) {
        return true;
      } else if (
        selectedDate.getFullYear() === newDate.getFullYear() &&
        selectedDate.getMonth() === newDate.getMonth() &&
        selectedDate.getDate() < newDate.getDate()
      ) {
        return true;
      } else if (
        selectedDate.getFullYear() === newDate.getFullYear() &&
        selectedDate.getMonth() === newDate.getMonth() &&
        selectedDate.getDate() === newDate.getDate()
      ) {
        return false;
      } else {
        return false;
      }
    },
    validWhen: false,
    message: "Vælg anden dato"
  },
  {
    field: "date",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler dato"
  },
  {
    field: "start_time",
    method: (_, booking) => {
      let selectedDate = moment(booking.date);
      let newDate = moment();

      if (
        selectedDate.year() === newDate.year() &&
        selectedDate.month() === newDate.month() &&
        selectedDate.date() === newDate.date()
      ) {
        // Get the current hour
        let start_time_hours = parseInt(booking.start_time.substring(0, 2));
        let current_time_hours = newDate.hours();

        return start_time_hours <= current_time_hours;
      } else {
        return false;
      }
    },
    validWhen: false,
    message: "Vælg nyere tid"
  },
  {
    field: "booking_person",
    method: "isEmpty",
    validWhen: false,
    message: "Angiv navn på kontakt person"
  },
  {
    field: "booking_person_nr",
    method: "isEmpty",
    validWhen: false,
    message: "Angiv nr. til den ansvarlige"
  },
  {
    field: "address",
    method: "isEmpty",
    validWhen: false,
    message: "Angiv en addresse for at oprette booking"
  },
  {
    field: "city",
    method: "isEmpty",
    validWhen: false,
    message: "Angiv by"
  },
  {
    field: "zip_code",
    method: "isEmpty",
    validWhen: false,
    message: "Angiv post nr."
  },
  {
    field: "hourly_wage",
    method: "isEmpty",
    validWhen: false,
    message: "Angiv timeløn"
  },
  {
    field: "hourly_wage",
    method: "isInt",
    args: [{ min: 1, max: 9999 }],
    validWhen: true,
    message: "Der må ikke angives 0"
  },
  {
    field: "hourly_wage",
    method: "isNumeric",
    args: [{ no_symbols: true }],
    validWhen: true,
    message: "Kommatal er ikke muligt"
  },
  {
    field: "contact_person",
    method: "isEmpty",
    validWhen: false,
    message: "Angiv kontaktperson"
  },
  {
    field: "extra_work_hours",
    method: "isEmpty",
    validWhen: false,
    message: "Angiv kontaktperson"
  },
  {
    field: "food_included",
    method: "isEmpty",
    validWhen: false,
    message: "Angiv personale mad"
  },
  {
    field: "transport_wage",
    method: "isEmpty",
    validWhen: false,
    message: "Angiv transporttillæg"
  },
  {
    field: "language",
    method: "isEmpty",
    validWhen: false,
    message: "Vælg sprog"
  }
];
