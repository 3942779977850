// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { useHistory } from "react-router";

// Utilities
import req from "../../../utilities/request-utility";

// Styles
import { breakPoint } from "../../../styles/breakPoints";
import colors from "../../../styles/colors";

// Components
import Spinner from "../../ui/Spinner";
import UpcomingOverviewEmployee from "./UpcomingOverviewEmployee";

const UpcomingShifts = props => {
  const history = useHistory();

  const [shifts, setShifts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    req()
      .get("shifts/employee/upcoming-shifts")
      .then(({ data: shifts }) => {
        setShifts(shifts);
        setLoading(false);
      })
      .catch(err => {});
    // eslint-disable-next-line
  }, []);

  const showBooking = ({ bookingId }) => {
    history.push(`/bookinginformation/${bookingId}`);
  };

  return (
    <div className={componentStyle()}>
      {loading && <Spinner style={{ marginTop: "2rem" }} />}
      {!loading && shifts.length > 0 && (
        <div className="wrapper">
          {shifts.map(shift => (
            <UpcomingOverviewEmployee
              showBooking={showBooking}
              className="upcoming-overview"
              shift={shift}
            />
          ))}
        </div>
      )}
      {!loading && shifts.length === 0 && (
        <div className="local-container">
          <p>
            Du har ingen bekræftet jobs. <br /> Bekræftet jobs vises her
          </p>
        </div>
      )}
    </div>
  );
};

const componentStyle = () => css`
  div.upcoming-overview {
    margin: 1rem 0;
  }

  div.local-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    p {
      text-align: center;
      font-size: 1rem;
      color: ${colors.darkerGrey};

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.65rem;
      }
    }
  }
`;

export default UpcomingShifts;
