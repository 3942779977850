// Libs
import React from "react";
import { css } from "emotion";

const Selector = props => {
  return (
    <select
      id={props.id}
      name={props.name}
      className={`${componentStyle(props)} ${
        props.className ? props.className : ""
      }`}
      value={props.selected}
      onChange={e => props.onChange(e)}
      style={props.style && props.style}
      placeholder={props.placeholder}
      disabled={props.disabled}
    >
      <option value='' disabled> {props.defaultOption || 'Vælg'} </option>
      {props.values.map((value, index) => (
        <option>{value}</option>
      ))}
    </select>
  );
};

const componentStyle = props => css`
  cursor: pointer;
  outline: none;
`;

export default Selector;
