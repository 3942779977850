export function getWorkSkillQuestion(language, skillId) {
  if (language === "dk") {
    if (skillId === 1 || skillId === "waiter") {
      return {
        job: "TJENER",
        title: [
          "SERVERING AF RETTER",
          "TJENERBAKKE",
          "TAGE AF BORDE",
          "SKÆNKE VIN TIL GÆST",
          "ERFARING"
        ],
        questions: [
          {
            question: "Kan du gå med 3 anrettede tallerkner?",
            answers: [
              `Det har jeg ikke prøvet før`,
              `Jeg har prøvet det få gange`,
              `Jeg har prøvet det en del gange`,
              `Det har jeg helt styr på`,
              `Jeg kan endda tage 4-5 tallerkner`
            ]
          },
          {
            question: "Kan du håndtere en tjenerbakke med champagneglas?",
            answers: [
              `Det har jeg ikke prøvet før`,
              `Jeg har prøvet det få gange`,
              `Jeg har prøvet det en del gange`,
              `Det har jeg helt styr på`,
              `Ja, 15 fyldte glas og trapper`
            ]
          },
          {
            question: `Kan du stable tallerkner på én arm, når du rydder af?`,
            answers: [
              `Det har jeg ikke prøvet før`,
              `Jeg har prøvet det få gange`,
              `Jeg har prøvet det en del gange`,
              `Det har jeg helt styr på`,
              `Jeg kan rydde af 8-12 ad gangen`
            ]
          },
          {
            question: `Hvor meget erfaring har du med at skænke vin?`,
            answers: [
              `Det har jeg ikke prøvet før`,
              `Jeg har prøvet det få gange`,
              `Jeg har prøvet det en del gange`,
              `Det har jeg helt styr på`,
              `Jeg kan med begge hænder`
            ]
          },
          {
            question: `Hvad er din samlede erfaring som tjener?`,
            answers: [
              `0-1 vagter jobs`,
              `2-5 vagter jobs`,
              `6-19 vagter jobs`,
              `20+ vagter jobs`,
              `60+ vagter jobs (min. 2 års erfaring)`
            ]
          }
        ],
        values: [
          "serving_plates",
          "serving_glass",
          "clean_tables",
          "pour_wine",
          "experience_as_waiter"
        ]
      };
    }
    if (skillId === 2 || skillId === "bartender") {
      return {
        job: "BARTENDER",
        title: [
          "BLØD BAR",
          "HÅRD BAR",
          "COCKTAILBAR",
          "SELVE BAREN",
          "ERFARING"
        ],
        questions: [
          {
            question: `Hvor meget erfaring har du som øl/vin/vand tender?`,
            answers: [
              `Det har jeg ikke prøvet før`,
              `Jeg har prøvet det få gange`,
              `Jeg har prøvet det en del gange`,
              `Det har jeg helt styr på`,
              `Jeg er ekspert`
            ]
          },
          {
            question: `Hvor meget erfaring har du som sjusbartender?`,
            answers: [
              `Det har jeg ikke prøvet før`,
              `Jeg har prøvet det få gange`,
              `Jeg har prøvet det en del gange`,
              `Det har jeg helt styr på`,
              `Jeg er ekspert`
            ]
          },
          {
            question: `Hvor meget erfaring har du som cocktailbartender?`,
            answers: [
              `Det har jeg ikke prøvet før`,
              `Jeg har prøvet det få gange`,
              `Jeg har prøvet det en del gange`,
              `Det har jeg helt styr på`,
              `Jeg er ekspert`
            ]
          },
          {
            question: `Har du sat en bar op og pakket den ned?`,
            answers: [
              `Det har jeg ikke prøvet før`,
              `Jeg har prøvet det få gange`,
              `Jeg har prøvet det en del gange`,
              `Det har jeg helt styr på`,
              `Jeg har set de fleste kørelister og kan let forstå dem`
            ]
          },
          {
            question: `Hvad er din samlede erfaring bag baren?`,
            answers: [
              `0-1 vagter jobs`,
              `2-5 vagter jobs`,
              `6-19 vagter jobs`,
              `20+ vagter jobs`,
              `60+ vagter jobs (min. 2 års erfaring)`
            ]
          }
        ],
        values: [
          "soft_bar",
          "hard_bar",
          "cocktail_bar",
          "bar_maintenance",
          "experience_as_bartender"
        ]
      };
    }
  }
}
