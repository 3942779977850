// Libs
import React, { useState } from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { css } from "emotion";

// Styles
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import colors from "../../../styles/colors";

// Utilities
import req from "../../../utilities/request-utility";

// Components
import EmployeeContractModal from "../EmployeeContractModal";

// Actions
import { addToast, hideModal, showModal } from "../../../actions/uiActions";

const BeforeShiftModal = props => {
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { booking } = props;

  const applyForBooking = () => {
    props.hideModal();
    props.setLoading(true);
    req()
      .post(`/shifts/${booking.id}`)
      .then(() => {
        props.addToast({
          message:
            "Kanon! Du har ansøgt om en booking. Se dine ansøgte bookings under 'Vagter'",
          type: "success",
          autoDismiss: true
        });
        props.getData();
      })
      .catch(err => {
        props.setLoading(false);
        if (err.response.status === 403) {
          props.addToast({
            message:
              "Du har i forvejen en vagt på den samme dag. Det er ikke muligt at tage 2 vagter samtidigt.",
            type: "warning",
            autoDismiss: true
          });
        } else {
        }
        props.addToast({
          message: "Noget gik galt, da vi prøvede at oprette din ansøgning",
          type: "error",
          autoDismiss: true
        });
      });
  };

  return (
    <div className={componentStyle()}>
      <div className="divider" />
      <p className="header-sub-title">
        Hvad sker der, når ansøgningen er sendt?
      </p>
      <div className="information-container">
        <p className="first-title">Godkendelses process</p>
        <p className="second-title">
          <span className="third-title">{"1. "}</span>Virksomheden vil gennemgå
          de profiler, der har ansøgt jobbet og hyre dem som passer bedst til
          dette job.
        </p>

        <p className="first-title">Godkendt til jobbet!</p>
        <p className="second-title">
          <span className="third-title">{"2. "}</span> Du vil få besked via sms
          og email, hvis du har fået jobbet, som vil fremgå på din profil.
        </p>

        {/*<p className="first-title">Meld dig klar</p>*/}
        {/*<p className="second-title">*/}
        {/*  <span className="third-title">{"3. "}</span> Senest 24 timer inden*/}
        {/*  jobbet starter skal du melde dig klar. Dvs. at du skal give dig*/}
        {/*  tilkende, at du er klar og læst alt information til jobbet.*/}
        {/*</p>*/}

        <p className="first-title">Jobbet gennemført!</p>
        <p className="second-title">
          Når jobbet er gennemført, bliver du bedt om at stemple ud af vagten.
          Dette gør du via din profil. VIGTIGT senest 24 timer efter.
        </p>

        <p className="first-title">AFBUD/SYGDOM</p>
        {/*<p className="second-title">*/}
        {/*  Hvis du aflyser et bekræftet job, vil det fremgå af din profil. Din*/}
        {/*  profil får derved et gult kort, hvilket fremtidige arbejdsgiver kan*/}
        {/*  se.*/}
        {/*</p>*/}

        <p className="second-title">
          Hvis du aflyser et bekræftet job, vil det fremgå af din profil.
          Virksomheder vil også se antallet af afmeldinger, når du søger jobs.
        </p>

        <p className="first-title">Annullere ansøgning</p>
        <p className="second-title">
          Du kan altid trække din ansøgning tilbage så længe, at du{" "}
          <span className="font-bold">ikke</span> er bekræftet.
        </p>

        <p className="first-title">Dobbelt booking</p>
        <p className="second-title">
          Søg gerne flere vagter på én gang. Vores system sørger for, at du
          altid kun kan blive booket til en vagt ad gangen. Så du kan bare søge
          løs - også selvom du søger vagter på samme tidspunkt.
        </p>
      </div>

      <div className="lower-section-container">
        <p className="check-calender-title">Husk at tjekke din kalender</p>
        <div className="calender-container">
          <CalendarMonthIcon color={"#b83d53"} size={23} />
          <p className="calender-title">
            {`${moment(booking.date)
              .format("dddd")
              .slice(0, 3)} ${moment(booking.date).format("DD/MM")} ${
              booking.start_time
            } - ${booking.end_time} (${booking.city})`}
          </p>
        </div>

        <div className="divider" />

        <div className="terms-and-conditions-container">
          <p className="terms-accepted-title">
            Jeg har læst og accepteret{" "}
            <span
              onClick={() =>
                props.showModal({
                  content: <EmployeeContractModal />
                })
              }
            >
              kontrakten
            </span>
          </p>
          <div className="input-checkbox">
            <input
              onClick={() => setTermsAccepted(!termsAccepted)}
              checked={termsAccepted}
              type="checkbox"
            />
          </div>
        </div>

        <div className="btn-container">
          <button onClick={() => props.hideModal()} className="close-btn">
            ANNULLER
          </button>
          <button
            onClick={() => applyForBooking()}
            disabled={!termsAccepted}
            className={termsAccepted ? "submit-btn" : "disabled-btn"}
          >
            ANSØG JOB
          </button>
        </div>
      </div>
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  width: 19.2rem;
  padding: 0.5rem;
  display: inline-block;

  p.header-title {
    text-align: center;
    margin-bottom: 0.25rem;
    font-weight: 500;
  }
  div.divider {
    border-width: 1px;
    border-top-width: 0;
    border-color: ${colors.grey};
    margin-bottom: 0.5rem;
  }
  p.header-sub-title {
    font-weight: 700;
    font-size: 0.875rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  div.information-container {
    padding: 0.5rem 0.75rem;

    p.first-title {
      font-size: 0.75rem;
      font-weight: 700;
    }
    p.second-title {
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 1.25rem;
    }
    span.third-title {
      font-weight: 700;
    }
  }

  div.lower-section-container {
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 5px;

    p.check-calender-title {
      font-size: 0.75rem;
      font-weight: 700;
      text-align: center;
      color: ${colors.red};
      background-color: ${colors.lighterRed};
    }

    div.calender-container {
      display: flex;
      padding-top: 1rem;
      padding-bottom: 1rem;
      justify-content: center;
      align-items: center;

      p.calender-title {
        padding-left: 0.25rem;
        font-size: 0.75rem;
        font-weight: 700;
      }
    }
  }

  div.terms-and-conditions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.25rem;

    p.terms-accepted-title {
      font-size: 0.75rem;
      font-weight: 700;

      span {
        color: ${colors.blue};
        font-weight: bold;
        cursor: pointer;
      }
    }

    div.input-checkbox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 0.5rem;
      padding: 0.25rem;
      background-color: ${colors.mediumGrey};
      border: 1px solid ${colors.darkGrey};
      border-radius: 3px;
      cursor: pointer;

      input {
        cursor: pointer;
      }
    }
  }

  div.btn-container {
    display: flex;
    justify-content: center;

    button.close-btn {
      padding: 0.5rem 1.25rem;
      color: ${colors.blue};
      font-size: 0.875rem;
      border-radius: 0.25rem;
      font-weight: 500;
      outline: none;
    }
    button.close-btn:hover {
      color: ${colors.darkerBlue};
    }

    button.submit-btn,
    button.disabled-btn {
      padding: 0.5rem 1.25rem;
      color: ${colors.white};
      font-size: 0.875rem;
      border-radius: 0.25rem;
      font-weight: 500;
      outline: none;
      background-color: ${colors.green};
    }

    button.submit-btn:hover {
      background-color: ${colors.darkerGreen};
    }

    button.disabled-btn {
      background-color: ${colors.grey};
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch),
  showModal: bindActionCreators(showModal, dispatch)
});

export default connect(null, mapDispatchToProps)(BeforeShiftModal);
