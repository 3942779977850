// libs
import React from "react";
import { css } from "emotion";

const TextArea = props => {
  return (
    <textarea
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      style={props.style && props.style}
      rows={props.rows || 2}
      className={`${componentStyle(props)} ${
        props.className ? props.className : ""
      }`}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
    />
  );
};

const componentStyle = () => css`
  outline: none;
`;

export default TextArea;
