// Libs
import axios from "axios";
import store from "../store";

// Utilities
import req from "../utilities/request-utility";

// Action types
import {
  ADD_TOAST,
  CREATE_CORPORATION_AND_BOOKING_FAIL,
  CREATE_CORPORATION_AND_BOOKING_SUCCESS,
  CREATE_CORPORATION_FAIL,
  CREATE_CORPORATION_SUCCESS,
  END_SPINNER,
  REMOVE_LOADING_SCREEN,
  SIGN_IN_CORPORATION_SUCCESS,
  SIGN_IN_CORPORATION_FAIL,
  SIGN_OUT,
  START_SPINNER,
  SIGN_IN_EMPLOYEE_FAIL,
  SIGN_IN_EMPLOYEE_SUCCESS,
  AUTO_SIGN_IN_CORPORATION_SUCCESS,
  AUTO_SIGN_IN_EMPLOYEE_SUCCESS,
  AUTO_SIGN_IN_CORPORATION_FAIL,
  AUTO_SIGN_IN_EMPLOYEE_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_WORK_LOCATIONS_SUCCESS,
  UPDATE_WORK_SKILLS_SUCCESS,
  UPDATE_WORK_SKILLS_FAIL,
  DELETE_WORK_SKILL_SUCCESS,
  DELETE_WORK_SKILL_FAIL,
  TAKEN_OVER_EMPLOYEE_SUCCESS,
  AUTO_SIGN_IN_TAKEN_OVER_EMPLOYEE_SUCCESS,
  AUTO_SIGN_IN_TAKEN_OVER_EMPLOYEE_FAIL,
  TAKEN_OVER_EMPLOYEE_FAIL,
  DISABLE_TAKE_OVER_CORPORATION_SUCCESS,
  DISABLE_TAKE_OVER_EMPLOYEE_SUCCESS,
  DISABLE_TAKE_OVER_CORPORATION_FAIL,
  DISABLE_TAKE_OVER_EMPLOYEE_FAIL,
  TAKEN_OVER_CORPORATION_SUCCESS,
  TAKEN_OVER_CORPORATION_FAIL,
  AUTO_SIGN_IN_TAKEN_OVER_CORPORATION_SUCCESS,
  AUTO_SIGN_IN_TAKEN_OVER_CORPORATION_FAIL,
  UPDATE_WORK_SKILLS,
  CORPORATION_ALREADY_EXISTS,
  CREATE_CORPORATION_STARTED
} from "./actionTypes";

// Actions
import { addToast } from "./uiActions";

export function createCorporation(corporation) {
  return async dispatch => {
    const localCorporation = {
      name: corporation.name,
      address: corporation.address,
      email: corporation.email,
      billing_email: corporation.billing_email,
      department: corporation.department,
      password: corporation.password,
      confirmPassword: corporation.confirmPassword,
      city: corporation.city,
      city_zip: corporation.city_zip,
      cvr_nr: corporation.cvr_nr,
      phone: corporation.phone,
      contact_person: corporation.contact_person,
      terms_accepted_at: corporation.terms_accepted_at,
      ean: corporation.ean
    };

    // Indicate that the request has been made
    dispatch({
      type: CREATE_CORPORATION_STARTED
    });

    req()
      .post(`corporations/`, localCorporation)
      .then(({ data }) => {
        // Set the access token
        localStorage.setItem("corporation_jwt_access_token", data.token);
        dispatch({
          type: CREATE_CORPORATION_SUCCESS,
          payload: {
            token: data.token,
            user: data.corporation
          }
        });
        dispatch({
          type: ADD_TOAST,
          payload: {
            message: "Velkommen til TjenerTeamet",
            type: "success",
            autoDismiss: true
          }
        });
      })
      .catch(error => {
        if (error.response.status === 409) {
          dispatch({
            type: CORPORATION_ALREADY_EXISTS
          });
          dispatch({
            type: ADD_TOAST,
            payload: {
              message: "Profilen eksister allerede i systemet",
              type: "warning",
              autoDismiss: true
            }
          });
        } else {
          dispatch({
            type: CREATE_CORPORATION_FAIL
          });
          dispatch({
            type: ADD_TOAST,
            payload: {
              message:
                "Noget gik galt, da vi prøvede at oprette dig i systemet. Hvis fejlen gentager sig, bedes do kontakte tjenerteamet",
              type: "error",
              autoDismiss: true
            }
          });
        }
      });
  };
}

export function createCorporationAndBooking(corporationAndBooking) {
  return async dispatch => {
    // Indicate that the request has been made
    dispatch({
      type: CREATE_CORPORATION_STARTED
    });

    req()
      .post("corporations/corporation-and-booking", corporationAndBooking)
      .then(({ data }) => {
        localStorage.setItem("corporation_jwt_access_token", data.token);
        dispatch({
          type: CREATE_CORPORATION_AND_BOOKING_SUCCESS,
          payload: {
            user: data.corporation,
            token: data.token
          }
        });
        dispatch({
          type: ADD_TOAST,
          payload: {
            message:
              "Velkommen til TjenerTeamet! Fedt, at du allerede har oprettet dit første job!",
            type: "success",
            autoDismiss: true
          }
        });
      })
      .catch(error => {
        if (error.response.status === 409) {
          dispatch({
            type: CORPORATION_ALREADY_EXISTS
          });
          dispatch({
            type: ADD_TOAST,
            payload: {
              message: "Profilen eksister allerede i systemet",
              type: "warning",
              autoDismiss: true
            }
          });
        } else {
          dispatch({
            type: CREATE_CORPORATION_AND_BOOKING_FAIL
          });
          dispatch({
            type: ADD_TOAST,
            payload: {
              message:
                "Noget gik galt, da vi prøvede at oprette dig i systemet. Hvis fejlen gentager sig, bedes do kontakte TjenerTeamet",
              type: "error",
              autoDismiss: true
            }
          });
        }
      });
  };
}

export function signOut() {
  return dispatch => {
    localStorage.removeItem("employee_jwt_access_token");
    localStorage.removeItem("corporation_jwt_access_token");

    localStorage.removeItem("taken_over_corporation_token");
    localStorage.removeItem("taken_over_employee_token");

    dispatch({
      type: SIGN_OUT
    });

    dispatch({
      type: ADD_TOAST,
      payload: {
        message: "Farvel, og tak for denne gang!",
        type: "info",
        autoDismiss: true
      }
    });
    dispatch({
      type: REMOVE_LOADING_SCREEN
    });
  };
}

export function signInCorporation(credentials) {
  return async dispatch => {
    // Start the spinner
    dispatch({
      type: START_SPINNER
    });

    // Sign in
    req()
      .post("corporations/sign-in", credentials)
      .then(({ data }) => {
        if (data.status === "error") {
          dispatch({
            type: ADD_TOAST,
            payload: {
              message: "Email eller adgangskode er forkert. Prøv igen!",
              type: "error",
              autoDismiss: true
            }
          });
        } else {
          const token = data.token;
          localStorage.setItem("corporation_jwt_access_token", token);
          dispatch({
            type: SIGN_IN_CORPORATION_SUCCESS,
            payload: {
              user: data.corporation,
              token: token
            }
          });
          dispatch({
            type: REMOVE_LOADING_SCREEN
          });
        }

        // End the spinner
        dispatch({
          type: END_SPINNER
        });
      })
      .catch(err => {
        dispatch({ type: SIGN_IN_CORPORATION_FAIL });
        // End the spinner
        dispatch({
          type: END_SPINNER
        });

        if (err.response && err.response.status === 401) {
          dispatch({
            type: ADD_TOAST,
            payload: {
              message: "Email eller adgangskode er forkert",
              type: "warning",
              autoDismiss: true
            }
          });
        } else {
          dispatch({
            type: ADD_TOAST,
            payload: {
              message:
                "Kunne ikke få forbindelse til serveren. Kontakt TjenerTeamet, hvis fejlen gentager sig",
              type: "error",
              autoDismiss: true
            }
          });
        }
      });
  };
}

export function updateSignInEmployee({ employee, token }) {
  return async dispatch => {
    dispatch({
      type: SIGN_IN_EMPLOYEE_SUCCESS,
      payload: {
        user: employee,
        token: token
      }
    });
  };
}

export function signInEmployee(credentials) {
  return async dispatch => {
    // Start the spinner
    dispatch({
      type: START_SPINNER
    });

    req()
      .post("employees/sign-in", credentials)
      .then(({ data }) => {
        if (data.status === "error") {
          dispatch({
            type: ADD_TOAST,
            payload: {
              message: "Email eller adgangskode er forkert. Prøv igen!",
              type: "error",
              autoDismiss: true
            }
          });
        } else if (data.status !== "error") {
          const token = data.token;
          localStorage.setItem("employee_jwt_access_token", token);
          dispatch({
            type: SIGN_IN_EMPLOYEE_SUCCESS,
            payload: {
              user: data.employee,
              token: token
            }
          });
          dispatch({
            type: REMOVE_LOADING_SCREEN
          });
          // End the spinner
          dispatch({
            type: END_SPINNER
          });
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          dispatch({
            type: ADD_TOAST,
            payload: {
              message:
                "Kunne ikke få forbindelse til serveren. Kontakt TjenerTeamet, hvis fejlen gentager sig",
              type: "error",
              autoDismiss: true
            }
          });
        } else {
          dispatch({
            type: ADD_TOAST,
            payload: {
              message:
                "Noget gik desværre galt, da vi prøvede at logge dig ind. Kontakt TjenerTeamet, hvis fejlen gentager sig",
              type: "error",
              autoDismiss: true
            }
          });
        }

        dispatch({ type: SIGN_IN_EMPLOYEE_FAIL });
        // End the spinner
        dispatch({
          type: END_SPINNER
        });
      });
  };
}

export function introFormUpdateUser(credentials) {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      req()
        .post("employees/intro-form", credentials)
        .then(({ data }) => {
          dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: {
              user: data.user
            }
          });
          // Success
          dispatch({
            type: ADD_TOAST,
            payload: {
              message: "Tak fordi du gav tid til at udfylde information",
              type: "success",
              autoDismiss: true
            }
          });
        })
        .catch(() => {
          dispatch({
            type: UPDATE_USER_FAIL
          });
          dispatch({
            type: ADD_TOAST,
            payload: {
              message:
                "Noget gik galt, da vi prøvede at oprette dit nye profil. Kontakt TjenerTeamet, hvis fejlen gentager sig",
              type: "error",
              autoDismiss: true
            }
          });
        });
    });
  };
}

export function updateUser(user) {
  return dispatch => {
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: {
        user
      }
    });
  };
}

export function updateWorkLocations(workLocations) {
  return dispatch => {
    dispatch({
      type: UPDATE_WORK_LOCATIONS_SUCCESS,
      payload: {
        workLocations
      }
    });
  };
}

export function updateWorkSkill(workSkill, afterAction = null) {
  return async dispatch => {
    dispatch({
      type: UPDATE_WORK_SKILLS
    });

    req()
      .put("employees/work-skills", workSkill)
      .then(({ data: user }) => {
        if (afterAction) afterAction();

        dispatch({
          type: UPDATE_WORK_SKILLS_SUCCESS,
          payload: {
            user
          }
        });
        // Success
        dispatch({
          type: ADD_TOAST,
          payload: {
            message: "Dine kompetencer er blevet opdateret!",
            type: "success",
            autoDismiss: true
          }
        });
      })
      .catch(() => {
        dispatch({
          type: UPDATE_WORK_SKILLS_FAIL
        });
        dispatch({
          type: ADD_TOAST,
          payload: {
            message:
              "Noget gik galt, da vi prøvede at updater din kompetencer. Kontakt TjenerTeamet, hvis fejlen gentager sig",
            type: "error",
            autoDismiss: true
          }
        });
      });
  };
}

export function deleteWorkSkill(jobTitleId, afterAction = null) {
  return async dispatch => {
    dispatch({
      type: UPDATE_WORK_SKILLS
    });

    req()
      .delete(`employees/work-skills/${jobTitleId}`)
      .then(({ data: employee }) => {
        if (afterAction) afterAction();

        dispatch({
          type: DELETE_WORK_SKILL_SUCCESS,
          payload: {
            user: employee
          }
        });

        // Success
        dispatch({
          type: ADD_TOAST,
          payload: {
            message: "Dine kompetencer er blevet opdateret!",
            type: "success",
            autoDismiss: true
          }
        });
      })
      .catch(() => {
        dispatch({
          type: ADD_TOAST,
          payload: {
            message:
              "Noget gik galt, da vi prøvede at updater din kompetencer. Kontakt TjenerTeamet, hvis fejlen gentager sig",
            type: "error",
            autoDismiss: true
          }
        });
        dispatch({
          type: DELETE_WORK_SKILL_FAIL,
          payload: {
            error: true
          }
        });
      });
  };
}

// Auto sign in, when page is reloaded
export function autoSignIn() {
  return dispatch => {
    const corp_token = localStorage.getItem("corporation_jwt_access_token");
    const emp_token = localStorage.getItem("employee_jwt_access_token");

    const taken_over_corp_token = localStorage.getItem(
      "taken_over_corporation_token"
    );
    const taken_over_emp_token = localStorage.getItem(
      "taken_over_employee_token"
    );

    const state = store.getState();

    if (taken_over_corp_token) {
      autoLoginTakenOverCorporation(taken_over_corp_token, dispatch, state);
    } else if (taken_over_emp_token) {
      autoLoginTakenOverEmployee(taken_over_emp_token, dispatch, state);
    } else if (corp_token) {
      autoLoginCorporation(corp_token, dispatch, state);
    } else if (emp_token) {
      autoLoginEmployee(emp_token, dispatch, state);
    } else {
      dispatch({
        type: REMOVE_LOADING_SCREEN
      });
    }
  };
}

function autoLoginEmployee(emp_token, dispatch, state) {
  axios({
    url: `${state.appConfig.baseUrl}employees/me`,
    method: "get",
    headers: {
      authorization: "bearer " + emp_token
    }
  })
    .then(({ data }) => {
      localStorage.setItem("employee_jwt_access_token", data.token);
      dispatch({
        type: AUTO_SIGN_IN_EMPLOYEE_SUCCESS,
        payload: {
          user: data.employee,
          token: data.token
        }
      });
      dispatch({
        type: REMOVE_LOADING_SCREEN
      });
    })
    .catch(() => {
      dispatch({
        type: AUTO_SIGN_IN_EMPLOYEE_FAIL
      });
      dispatch({
        type: REMOVE_LOADING_SCREEN
      });
    });
}

function autoLoginCorporation(corp_token, dispatch, state) {
  axios({
    url: `${state.appConfig.baseUrl}corporations/me`,
    method: "get",
    headers: {
      authorization: "bearer " + corp_token
    }
  })
    .then(({ data }) => {
      localStorage.setItem("corporation_jwt_access_token", data.token);
      dispatch({
        type: AUTO_SIGN_IN_CORPORATION_SUCCESS,
        payload: {
          user: data.corporation,
          token: data.token
        }
      });
      dispatch({
        type: REMOVE_LOADING_SCREEN
      });
    })
    .catch(() => {
      dispatch({
        type: AUTO_SIGN_IN_CORPORATION_FAIL
      });
      dispatch({
        type: REMOVE_LOADING_SCREEN
      });
    });
}

function autoLoginTakenOverCorporation(taken_over_corp_token, dispatch, state) {
  axios({
    url: `${state.appConfig.baseUrl}corporations/me`,
    method: "get",
    headers: {
      authorization: "bearer " + taken_over_corp_token
    }
  })
    .then(({ data }) => {
      localStorage.setItem("taken_over_corporation_token", data.token);
      dispatch({
        type: AUTO_SIGN_IN_TAKEN_OVER_CORPORATION_SUCCESS,
        payload: {
          user: data.corporation,
          token: data.token
        }
      });
      dispatch({
        type: REMOVE_LOADING_SCREEN
      });
    })
    .catch(() => {
      dispatch({
        type: AUTO_SIGN_IN_TAKEN_OVER_CORPORATION_FAIL
      });
      dispatch({
        type: REMOVE_LOADING_SCREEN
      });
    });
}

function autoLoginTakenOverEmployee(taken_over_emp_token, dispatch, state) {
  axios({
    url: `${state.appConfig.baseUrl}employees/me`,
    method: "get",
    headers: {
      authorization: "bearer " + taken_over_emp_token
    }
  })
    .then(({ data }) => {
      localStorage.setItem("taken_over_employee_token", data.token);
      dispatch({
        type: AUTO_SIGN_IN_TAKEN_OVER_EMPLOYEE_SUCCESS,
        payload: {
          user: data.employee,
          token: data.token
        }
      });
      dispatch({
        type: REMOVE_LOADING_SCREEN
      });
    })
    .catch(() => {
      dispatch({
        type: AUTO_SIGN_IN_TAKEN_OVER_EMPLOYEE_FAIL
      });
      dispatch({
        type: REMOVE_LOADING_SCREEN
      });
    });
}

export function takeOverEmployee(employeeId) {
  return async dispatch => {
    req()
      .get(`admin/employees/${employeeId}/take-over`)
      .then(({ data }) => {
        // Set the employee token to the localStorage
        localStorage.setItem("taken_over_employee_token", data.token);
        dispatch({
          type: TAKEN_OVER_EMPLOYEE_SUCCESS,
          payload: {
            token: data.token,
            user: data.employee
          }
        });
      })
      .catch(() => {
        dispatch({
          type: TAKEN_OVER_EMPLOYEE_FAIL
        });
      });
  };
}

export function takeOverCorporation(corporationId, RedirectUrl = '/') {
  const state = store.getState();
  const { id } = state.auth.user;

  if (id === corporationId) {
    return addToast({
      message: "Kan ikke overtage din egen profil",
      type: "warning",
      autoDismiss: true
    });
  }

  return async dispatch => {
    req()
      .get(`admin/corporations/${corporationId}/take-over`)
      .then(({ data }) => {
        localStorage.setItem("taken_over_corporation_token", data.token);
        dispatch({
          type: TAKEN_OVER_CORPORATION_SUCCESS,
          payload: {
            token: data.token,
            user: data.corporation
          }
        });
        window.location.href = RedirectUrl;
      })
      .catch(() => {
        dispatch({
          type: TAKEN_OVER_CORPORATION_FAIL
        });
      });
  };
}

export function disableTakeOverProfile() {
  return async dispatch => {
    const taken_over_corp_token = localStorage.getItem(
      "taken_over_corporation_token"
    );
    const taken_over_emp_token = localStorage.getItem(
      "taken_over_employee_token"
    );

    const state = store.getState();

    if (taken_over_corp_token) {
      localStorage.removeItem("taken_over_corporation_token");
      window.location.reload();
      const corp_token = localStorage.getItem("corporation_jwt_access_token");
      axios({
        url: `${state.appConfig.baseUrl}corporations/me`,
        method: "get",
        headers: {
          authorization: "bearer " + corp_token
        }
      })
        .then(({ data }) => {
          dispatch({
            type: DISABLE_TAKE_OVER_CORPORATION_SUCCESS,
            payload: {
              token: data.token,
              user: data.corporation
            }
          });
        })
        .catch(() => {
          dispatch({
            type: DISABLE_TAKE_OVER_CORPORATION_FAIL
          });
        });
    }

    if (taken_over_emp_token) {
      localStorage.removeItem("taken_over_employee_token");
      const corp_token = localStorage.getItem("corporation_jwt_access_token");
      axios({
        url: `${state.appConfig.baseUrl}corporations/me`,
        method: "get",
        headers: {
          authorization: "bearer " + corp_token
        }
      })
        .then(({ data }) => {
          dispatch({
            type: DISABLE_TAKE_OVER_EMPLOYEE_SUCCESS,
            payload: {
              token: data.token,
              user: data.corporation
            }
          });
        })
        .catch(() => {
          dispatch({
            type: DISABLE_TAKE_OVER_EMPLOYEE_FAIL
          });
        });
    }
  };
}
