// Libs
import React, { useState } from "react";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

// Components
import WorkSkillsOverview from "./WorkSkillsOverview";
import CvOverview from "./CVOverview";

// Style
import CloseCircleIcon from "mdi-react/CloseCircleIcon";

import colors from "../../../styles/colors";
import SelectStaffModal from "./SelectStaffModal";

// Actions
import {
  hideModal,
  showImageContainer,
  showModal
} from "../../../actions/uiActions";
import RemoveStaffModal from "./RemoveStaffModal";
import { breakPoint } from "../../../styles/breakPoints";

const StaffCard = props => {
  const {
    shift,
    setShift,
    type,
    showRemove,
    showAddToApplied,
    staffType,
    isAdmin,
    displayFbBtn
  } = props;
  const [staffTab, setStaffTab] = useState(0);

  return (
    <div className={componentStyle(colors)}>
      <span className="applyDate">{type === "applied" && shift.appliedDuration ? `Ansøgt: ${shift.appliedDuration}` : ''}</span>
      <div
        className={
          `outer-component` +
          `${type === "double" ? " type-double " : " "}` +
          `${type === "gray" ? " type-gray " : " "}`
        }
      >
        {shift.employee.baseURL_image && shift.employee.uniform_image && (
          <div className="image-container">
            <img
              onClick={() =>
                props.showImageContainer(
                  `${shift.employee.baseURL_image}w_350/${shift.employee.uniform_image}`
                )
              }
              className="image-left"
              src={`${shift.employee.baseURL_image}b_rgb:ffffff,c_pad,h_160,w_160/dpr_2.0,f_auto/${shift.employee.uniform_image}`}
              alt={"uniform"}
            />
          </div>
        )}
        {shift.employee.baseURL_image && shift.employee.profile_image && (
          <div className="image-container">
            <img
              onClick={() =>
                props.showImageContainer(
                  `${shift.employee.baseURL_image}b_rgb:ffffff,c_pad,h_500,w_500/dpr_2.0,f_auto/${shift.employee.profile_image}`
                )
              }
              className="image-right"
              src={`${shift.employee.baseURL_image}b_rgb:ffffff,c_pad,h_160,w_160/dpr_2.0,f_auto/${shift.employee.profile_image}`}
              alt={"profile"}
            />
          </div>
        )}

        <div className="top-container">
          {type === "applied" ? (
            <button
              onClick={() =>
                props.showModal({
                  hideTop: true,
                  content: (
                    <SelectStaffModal
                      employee={shift.employee}
                      setShift={() => setShift(shift.id, "selected")}
                    />
                  )
                })
              }
              className="select-button"
            >
              Book nu
            </button>
          )
          : type === "selected" ? (
            <button
              onClick={() =>
                props.showModal({
                  content: (
                    <RemoveStaffModal setShift={setShift} shift={shift} />
                  )
                })
              }
              className="unselect-button"
            >
              Fjern
            </button>
          )
        : <div></div>}
          <div className="right-btns">
            {
              displayFbBtn && shift.employee.facebook_link && (
                <a href={shift.employee.facebook_link} target="_blank" className="fb-profile-btn">FB</a>
              )
            }
            {showRemove && isAdmin && (
              <CloseCircleIcon onClick={() => setShift(shift.id, "unwanted")} />
            )}
          </div>
          {showAddToApplied && (
            <button
              onClick={() => setShift(shift.id, "applied")}
              className="add-button"
            >
              Fjern
            </button>
          )}
        </div>

        <div className="full-name-container">
          <div className="name-container">
            <p>
              {`${
                shift.employee.first_name
              } ${shift.employee.last_name.substring(0, 1).toUpperCase()}`}
            </p>
            <div />
            <p>{`${shift.employee.age} år`}</p>
          </div>
        </div>
        <p className="address">{shift.employee.city}</p>

        <div className="stepper-container">
          <div
            className={
              staffTab === 0 ? "selected-step-contain" : "step-contain"
            }
            onClick={() => setStaffTab(0)}
          >
            <p>Kompetencer</p>
          </div>
          <div
            className={
              staffTab === 1 ? "selected-step-contain" : "step-contain"
            }
            onClick={() => setStaffTab(1)}
          >
            <p>C/V</p>
          </div>
        </div>
        {staffTab === 0 && (
          <WorkSkillsOverview shift={shift} staffType={staffType} />
        )}
        {staffTab === 1 && <CvOverview employee={shift.employee} />}
      </div>
    </div>
  );
};

const componentStyle = colors => css`
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  .applyDate {
    font-size: 0.8rem;
    margin: 0 0 0.25rem 0.5rem;
    color: #98989D;
  }
  @media screen and (max-width: ${breakPoint.sm}px) {
    align-items: center;
  }

  .outer-component {
    min-height: 15rem;
    max-width: 24rem;
    //max-height: 19rem;
    border-radius: 5px;
    position: relative;
    overflow: visible;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0.5rem;

    .image-container {
      display: flex;
      align-items: center;

      .image-left,
      .image-right {
        object-fit: cover;
        top: -30px;
        left: 130px;
        position: absolute;
        width: 4.4rem;
        height: 4.4rem;
        border-radius: 9999px;
        margin-right: 1rem;
        cursor: pointer;

        &:hover {
          border: 1px solid ${colors.primeBlue};
        }
      }

      .image-right {
        left: 205px;
      }
    }
  }

  .type-double {
    background-color: ${colors.mediumGrey};
  }

  .type-gray {
    background-color: ${colors.grey};
  }

  svg {
    position: relative;
    top: 0px;
    right: -4px;
    color: ${colors.darkGrey};
  }
  svg:hover {
    cursor: pointer;
    fill: ${colors.darkerGrey};
  }

  .top-container {
    display: flex;
    justify-content: space-between;
    .right-btns{
      display: flex;
      .fb-profile-btn {
        background-color: #4A90E2;
        color: #fff;
        padding: 0px 0.25rem;
        border-radius: 5px;
      }
    }
    .select-button,
    .add-button,
    .unselect-button {
      background-color: ${colors.green};
      padding: 0.25rem 1.5rem;
      font-size: 0.75rem;
      font-weight: 600;
      color: ${colors.white};
      border-radius: 0.25rem;
      outline: none;
    }

    .select-button:hover {
      background-color: ${colors.darkerGreen};
    }

    .unselect-button {
      background-color: ${colors.darkerRed};
    }

    .unselect-button:hover {
      background-color: ${colors.red};
    }

    .add-button {
      background-color: ${colors.red};
    }

    .add-button:hover {
      background-color: ${colors.darkerRed};
    }
  }

  .full-name-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;

    .name-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p:first-child {
        font-size: 0.75rem;
        font-weight: bold;
      }

      div {
        width: 0.4rem;
      }

      p:last-child {
        font-size: 0.7rem;
        color: ${colors.darkerGrey};
      }
    }
  }

  .address {
    font-size: 0.65rem;
    margin-bottom: 0.25rem;
  }

  .stepper-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    .step-contain,
    .selected-step-contain {
      cursor: default;
      background-color: ${colors.darkerGrey};
      border-radius: 5px;
      color: ${colors.white};
      font-weight: 600;
      width: 50%;
      display: flex;
      justify-content: center;
      margin: 0 0.15rem;
      p {
        padding: 0.25rem 1.5rem;
        color: ${colors.white};
        font-weight: 600;
        font-size: 0.55rem;
      }
    }

    .step-contain {
      background-color: ${colors.grey};
      color: ${colors.black};

      p {
        color: ${colors.black};
      }
    }

    .step-contain:hover {
      background-color: ${colors.darkGrey};
      cursor: pointer;
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  showImageContainer: bindActionCreators(showImageContainer, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch)
});

export default connect(null, mapDispatchToProps)(StaffCard);
