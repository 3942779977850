// Libs
import React from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { css } from "emotion";
import CloseIcon from "mdi-react/CloseIcon";

// Actions
import { hideModal } from "../../actions/uiActions";

// Styles
import colors from "../../styles/colors";
import colors_V1 from "../../styles/colors-V-1.0";
import { breakPoint } from "../../styles/breakPoints";

const ModalPage = props => {
  const {
    content,
    active,
    title,
    hideTop,
    disableBackgroundCancel
  } = useSelector(state => state.ui.modal);

  return (
    <>
      {active && (
        <div className={componentStyle(active, colors)}>
          <div
            className="modal"
            onClick={disableBackgroundCancel ? null : () => props.hideModal()}
          />
          <div className="modal-content">
            {!hideTop && (
              <div className="top-section">
                <p className="title">{title || ""}</p>
                <CloseIcon onClick={() => props.hideModal()} />
              </div>
            )}
            {content}
          </div>
        </div>
      )}
    </>
  );
};

const componentStyle = (active, colors) => css`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${breakPoint.sm}px) {
    position: fixed;
    z-index: 20;
  }

  .modal {
    position: fixed;
    display: ${active ? "flex" : "none"};
    z-index: 20; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal-content {
    position: fixed;
    border: 1px solid ${colors_V1.grey_50};
    max-height: 100%;
    background-color: ${colors.greyWhite};
    display: inline-block;
    border: 1px solid ${colors.grey};
    border-radius: 3px;
    z-index: 40;
    overflow: auto;
    max-width: 40rem;
    
    @media screen and (max-width: ${breakPoint.sm}px) {
      position: sticky;
    }

    div.top-section {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;

      p.title {
        font-size: 1rem;
        font-weight: 500;
        margin: 0 2.5rem;
      }

      svg {
        position: absolute;
        right: 6px;
        cursor: pointer;
        height: 1.85rem;
        width: 1.85rem;
        top: 14px;
        
        @media screen and (max-width: ${breakPoint.sm}px) {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  hideModal: bindActionCreators(hideModal, dispatch)
});

export default connect(null, mapDispatchToProps)(ModalPage);
