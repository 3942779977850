// Libs
import React from "react";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Styles
import colors from "../../../styles/colors";

// Actions
import { hideModal } from "../../../actions/uiActions";

const SimpleCancelShiftsModal = props => {
  return (
    <div className={componentStyle()}>
      <p className="title">Er du sikker må, at du vil fjerne din ansøgning?</p>
      <div className="btn-container">
        <button className="cancel-btn" onClick={() => props.hideModal()}>
          ANNULLER
        </button>
        <button
          className="submit-btn"
          onClick={() => {
            props.cancelShift();
            props.hideModal();
          }}
        >
          FJERN ANSØGNING
        </button>
      </div>
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  p.title {
    margin-top: 0.5rem;
    color: ${colors.darkestGrey};
    font-weight: 500;
  }

  div.btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;

    button.cancel-btn,
    button.submit-btn {
      padding: 0.4rem 0.8rem;
      font-weight: bold;
      font-size: 0.85rem;
      border-radius: 5px;
      outline: none;

      &:hover {
        border: 1px solid ${colors.primeBlue};
      }
      &:active {
        border: 1px solid ${colors.primeBlue};
      }
    }

    button.cancel-btn {
      background-color: ${colors.white};
      color: ${colors.primeBlue};
      margin-right: 0.5rem;
      border: 1px solid ${colors.white};
    }

    button.submit-btn {
      background-color: ${colors.red};
      color: ${colors.white};
      border: 1px solid ${colors.red};
    }

    button.submit-btn:hover {
      background-color: ${colors.darkerRed};
    }
    button.submit-btn:active {
      background-color: ${colors.darkerRed};
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  hideModal: bindActionCreators(hideModal, dispatch)
});

export default connect(null, mapDispatchToProps)(SimpleCancelShiftsModal);
