// Libs
import React, { useState } from "react";
import { useHistory } from "react-router";
import { css } from "emotion";

// Components
import Account from "./Account";
import Zones from "./Zones";
import Skills from "./Skills";
import Experience from "./Experience";
import PageContainer from "../../ui/PageContainer";
import NavigateBackArrow from "../../ui/NavigateBackArrow";

// Styles
import colors from "../../../styles/colors";
import { breakPoint } from "../../../styles/breakPoints";

const EditEmployee = props => {
  const history = useHistory();

  const [tab, setTab] = useState(0);

  return (
    <PageContainer showNavigation={true}>
      <NavigateBackArrow navigateURL={() => history.push("/")} />
      <div className={componentStyle()}>
        <div className="edit-nav-wrapper">
          <button
            onClick={() => setTab(0)}
            className={tab === 0 ? "selected" : "not-selected"}
          >
            Konto
          </button>

          <button
            onClick={() => setTab(1)}
            className={tab === 1 ? "selected" : "not-selected"}
          >
            Erfaringer/Udd
          </button>

          <button
            onClick={() => setTab(2)}
            className={tab === 2 ? "selected" : "not-selected"}
          >
            Kompetencer
          </button>
          <button
            onClick={() => setTab(3)}
            className={tab === 3 ? "selected" : "not-selected"}
          >
            Zoner
          </button>
        </div>
      </div>
      {tab === 0 && <Account />}
      {tab === 1 && <Experience />}
      {tab === 2 && <Skills />}
      {tab === 3 && <Zones />}
    </PageContainer>
  );
};

const componentStyle = () => css`
  width: 100%;
  display: flex;
  justify-content: center;

  .edit-nav-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    button {
      font-size: 0.85rem;
      padding: 0.2rem 1rem;
      border-radius: 8px;
      min-width: 6rem;
      font-weight: 500;
      margin: 0.25rem 0.5rem;
      outline: none;

      &.selected {
        background-color: ${colors.white};
        color: ${colors.darkGrey};
        border: 1px solid ${colors.darkGrey};
      }

      &.not-selected {
        background-color: ${colors.darkerGrey};
        color: ${colors.white};
      }
    }
  }

  @media screen and (max-width: ${breakPoint.sm}px) {
    .edit-nav-wrapper {
      justify-content: center;

      button {
        font-size: 0.5rem;
        min-width: 5rem;
      }
    }
  }
`;

export default EditEmployee;
