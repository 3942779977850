// Corporation sign in
export const SIGN_IN_CORPORATION_SUCCESS = "SIGN_IN_CORPORATION_SUCCESS";
export const SIGN_IN_CORPORATION_FAIL = "SIGN_IN_CORPORATION_FAIL";

export const AUTO_SIGN_IN_CORPORATION_SUCCESS =
  "AUTO_SIGN_IN_CORPORATION_SUCCESS";
export const AUTO_SIGN_IN_CORPORATION_FAIL = "AUTO_SIGN_IN_CORPORATION_FAIL";

export const TEMPORARY_SAVE_BOOKING = "TEMPORARY_SAVE_BOOKING";
export const REMOVE_TEMPORARY_SAVED_BOOKING = "REMOVE_TEMPORARY_SAVED_BOOKING";

export const CREATE_CORPORATION_STARTED = "CREATE_CORPORATION_STARTED";
export const CREATE_CORPORATION_SUCCESS = "CREATE_CORPORATION_SUCCESS";
export const CREATE_CORPORATION_FAIL = "CREATE_CORPORATION_FAIL";
export const CORPORATION_ALREADY_EXISTS = "CORPORATION_ALREADY_EXISTS";

export const CREATE_CORPORATION_AND_BOOKING_SUCCESS =
  "CREATE_CORPORATION_AND_BOOKING_SUCCESS";
export const CREATE_CORPORATION_AND_BOOKING_FAIL =
  "CREATE_CORPORATION_AND_BOOKING_FAIL";

// Employee sign in
export const SIGN_IN_EMPLOYEE_SUCCESS = "SIGN_IN_EMPLOYEE_SUCCESS";
export const SIGN_IN_EMPLOYEE_FAIL = "SIGN_IN_EMPLOYEE_FAIL";

export const AUTO_SIGN_IN_EMPLOYEE_SUCCESS = "AUTO_SIGN_IN_EMPLOYEE_SUCCESS";
export const AUTO_SIGN_IN_EMPLOYEE_FAIL = "AUTO_SIGN_IN_EMPLOYEE_FAIL";

export const UPDATE_WORK_LOCATIONS_SUCCESS = "UPDATE_WORK_LOCATIONS_SUCCESS";

export const UPDATE_WORK_SKILLS = "UPDATE_WORK_SKILLS";
export const UPDATE_WORK_SKILLS_SUCCESS = "UPDATE_WORK_SKILLS_SUCCESS";
export const UPDATE_WORK_SKILLS_FAIL = "UPDATE_WORK_SKILLS_FAIL";
export const DELETE_WORK_SKILL_SUCCESS = "DELETE_WORK_SKILL_SUCCESS";
export const DELETE_WORK_SKILL_FAIL = "DELETE_WORK_SKILL_FAIL";

// Admin
export const TAKEN_OVER_EMPLOYEE_SUCCESS = "TAKEN_OVER_EMPLOYEE_SUCCESS";
export const TAKEN_OVER_EMPLOYEE_FAIL = "TAKEN_OVER_EMPLOYEE_FAIL";

export const TAKEN_OVER_CORPORATION_SUCCESS = "TAKEN_OVER_CORPORATION_SUCCESS";
export const TAKEN_OVER_CORPORATION_FAIL = "TAKEN_OVER_CORPORATION_FAIL";

export const AUTO_SIGN_IN_TAKEN_OVER_EMPLOYEE_SUCCESS =
  "AUTO_SIGN_IN_TAKEN_OVER_EMPLOYEE_SUCCESS";
export const AUTO_SIGN_IN_TAKEN_OVER_EMPLOYEE_FAIL =
  "AUTO_SIGN_IN_TAKEN_OVER_EMPLOYEE_FAIL";

export const AUTO_SIGN_IN_TAKEN_OVER_CORPORATION_SUCCESS =
  "AUTO_SIGN_IN_TAKEN_OVER_CORPORATION_SUCCESS";
export const AUTO_SIGN_IN_TAKEN_OVER_CORPORATION_FAIL =
  "AUTO_SIGN_IN_TAKEN_OVER_CORPORATION_FAIL";

export const DISABLE_TAKE_OVER_EMPLOYEE_SUCCESS =
  "DISABLE_TAKE_OVER_EMPLOYEE_SUCCESS";
export const DISABLE_TAKE_OVER_EMPLOYEE_FAIL =
  "DISABLE_TAKE_OVER_EMPLOYEE_FAIL";

export const DISABLE_TAKE_OVER_CORPORATION_SUCCESS =
  "DISABLE_TAKE_OVER_CORPORATION_SUCCESS";
export const DISABLE_TAKE_OVER_CORPORATION_FAIL =
  "DISABLE_TAKE_OVER_CORPORATION_FAIL";

// General
export const REMOVE_LOADING_SCREEN = "REMOVE_LOADING_SCREEN";
export const ADD_LOADING_SCREEN = "ADD_LOADING_SCREEN";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const START_SPINNER = "START_SPINNER";
export const END_SPINNER = "END_SPINNER";

export const SIGN_OUT = "SIGN_OUT";

// UI
export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const SHOW_IMAGE_CONTAINER = "SHOW_IMAGE_CONTAINER";
export const HIDE_IMAGE_CONTAINER = "HIDE_IMAGE_CONTAINER";

// Bookings
export const RESET_BOOKINGS = "RESET_BOOKINGS";
export const CHANGE_WEEK_NUMBER = "CHANGE_WEEK_NUMBER";
export const CHANGE_TAB = "CHANGE_TAB";

export const GET_BOOKINGS = "GET_BOOKING";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";
export const GET_BOOKINGS_WITH_SAME_SEARCH_TERM_SUCCESS = "GET_BOOKINGS_WITH_SAME_SEARCH_TERM_SUCCESS";
export const GET_BOOKINGS_WITH_NEW_SEARCH_TERM_SUCCESS = "GET_BOOKINGS_WITH_NEW_SEARCH_TERM_SUCCESS";
export const END_OF_BOOKINGS = "END_OF_BOOKINGS";
export const GET_BOOKINGS_FAILURE = "GET_BOOKINGS_FAILURE";

export const GET_ADMIN_BOOKINGS = "GET_ADMIN_BOOKINGS";
export const GET_ADMIN_BOOKINGS_SUCCESS = "GET_ADMIN_BOOKINGS_SUCCESS";
export const GET_ADMIN_BOOKINGS_WITH_SAME_SEARCH_TERM_SUCCESS = "GET_ADMIN_BOOKINGS_WITH_SAME_SEARCH_TERM_SUCCESS";
export const GET_ADMIN_BOOKINGS_WITH_NEW_SEARCH_TERM_SUCCESS = "GET_ADMIN_BOOKINGS_WITH_NEW_SEARCH_TERM_SUCCESS";
export const GET_ADMIN_BOOKINGS_FAILURE = "GET_ADMIN_BOOKINGS_FAILURE";
export const END_OF_ADMIN_BOOKINGS = "END_OF_ADMIN_BOOKINGS";
export const CHANGE_ALL_GRAVY_STATUS = "CHANGE_ALL_GRAVY_STATUS";

// Employees
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_WITH_SAME_SEARCH_TERM_SUCCESS = "GET_EMPLOYEES_WITH_SAME_SEARCH_TERM_SUCCESS";
export const GET_EMPLOYEES_WITH_NEW_SEARCH_TERM_SUCCESS = "GET_EMPLOYEES_WITH_NEW_SEARCH_TERM_SUCCESS";
export const GET_EMPLOYEES_FAILURE = "GET_EMPLOYEES_FAILURE";
export const END_OF_EMPLOYEES = "END_OF_EMPLOYEES";

export const RESET_EMPLOYEES = "RESET_EMPLOYEES";

// Corporations
export const GET_CORPORATIONS = "GET_CORPORATIONS";
export const GET_CORPORATIONS_SUCCESS = "GET_CORPORATIONS_SUCCESS";
export const GET_CORPORATIONS_WITH_NEW_SEARCH_TERM_SUCCESS = "GET_CORPORATIONS_WITH_NEW_SEARCH_TERM_SUCCESS";
export const GET_CORPORATIONS_WITH_SAME_SEARCH_TERM_SUCCESS = "GET_CORPORATIONS_WITH_SAME_SEARCH_TERM_SUCCESS";
export const GET_CORPORATIONS_FAILURE = "GET_CORPORATIONS_FAILURE";
export const END_OF_CORPORATIONS = "END_OF_CORPORATIONS";

export const RESET_CORPORATIONS = "RESET_CORPORATIONS";
