// Libs
import React from "react";

// Components
import ReactStars from "react-stars/dist/react-stars";
import { css } from "emotion";

const Rating = props => {
  return (
    <div className={`${componentStyle(props)} ${props.className}`}>
      <ReactStars
        half={props.half || false}
        onChange={props.onChange}
        count={props.count || 5}
        edit={props.edit || false}
        value={props.value || 0}
        size={props.size || 10}
      />
      <p className="title">{props.title || ""}</p>
    </div>
  );
};

const componentStyle = props => css`
  display: flex;
  align-items: center;

  .title {
    margin-left: 0.35rem;
    font-size: 0.65rem;
    font-weight: 500;
  }
`;

export default Rating;
