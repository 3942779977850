// Modules
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { css } from "emotion";

// Components
import MapMarkerIcon from "mdi-react/MapMarkerCircleIcon";
import WalkIcon from "mdi-react/WalkIcon";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import ClipboardTextOutlineIcon from "mdi-react/ClipboardTextOutlineIcon";
import DateViewer from "../ui/DateViewer";

// Utilities and config
import staff_type_converter from "../../utilities/staff_type_converter";
import { workHours } from "../../utilities/get-work-hours-total";

// Styles
import colors from "../../styles/colors";
import colorsNew from "../../styles/colors-V-1.0";
import bookingTypes from "../../config/bookingTypes";
import ViewDeprecatedBooking from "./viewDeprecatedBooking/ViewDeprecatedBooking";
import { bindActionCreators } from "redux";
import { showModal } from "../../actions/uiActions";
import { connect, useSelector } from "react-redux";
import { breakPoint } from "../../styles/breakPoints";

const Booking = props => {
  const history = useHistory();

  const { booking, selectedShifts, readOnly } = props;
  const onSubmitHandler = () => {
    // if checkout approved by admin lock the booking
    if (booking.status === bookingTypes.deprecated) {
      props.showModal({
        content: <ViewDeprecatedBooking bookingId={booking.id} />
      });
    } else if (booking.status === bookingTypes.finished) {
      history.push(`/checkout-employees/${booking.id}`);
    } else {
      history.push("/viewBooking");
    }
  };

  const { takenOverCorporation } = useSelector(state => state.auth);
  const { is_admin } = useSelector(state => state.auth.user);

    const totalShifts = selectedShifts?.length || 0;

    let checkoutLabels = {statusLabelText: '', statusLabelColor: '', employeeCheckedOutCount: 0, corporationCheckedOutCount: 0, ratingCount: 0, invoiceStatusText: '', invoiceStatusColor: ''};
    let checkoutRejectedCountEmployee = 0
    let checkoutRejectedCountCorporation = 0
    for(let s of selectedShifts || []){
      const employeeRatings = s.employee.employeeRatings || s.employeeRatings;
      if(employeeRatings.some(rating => (rating.score > 0 && rating.booking_id === booking.id))){
        checkoutLabels = {...checkoutLabels, ratingCount: (checkoutLabels.ratingCount + 1)};
      }
      const employeeCheckedOut = s.employee_checkin_time && s.employee_checkout_time;
      const corporationCheckedOut = s.corporation_checkin_time && s.corporation_checkout_time;

      const checkoutRejected = employeeCheckedOut && corporationCheckedOut && (s.employee_checkin_time != s.corporation_checkin_time || s.employee_checkout_time != s.corporation_checkout_time);
      const checkoutRejectedByCorporation = checkoutRejected && s.last_checkout_by == "corporation";
      const checkoutRejectedByEmployee = checkoutRejected && s.last_checkout_by == "employee";
      if(corporationCheckedOut && !checkoutRejectedByEmployee){
        checkoutLabels = {...checkoutLabels, corporationCheckedOutCount: (checkoutLabels.corporationCheckedOutCount + 1) }
      }
      if(employeeCheckedOut && !checkoutRejectedByCorporation){
        checkoutLabels = {...checkoutLabels, employeeCheckedOutCount: (checkoutLabels.employeeCheckedOutCount + 1) }
      }

      if(checkoutRejected){
        checkoutLabels = {...checkoutLabels, checkoutRejectedCount: (checkoutLabels.checkoutRejectedCount + 1), statusLabelText: 'Afvist', statusLabelColor: colors.custardYellow }
        if(checkoutRejectedByEmployee){
          checkoutRejectedCountEmployee++
          checkoutLabels = {...checkoutLabels, statusLabelText: `Afvist ${checkoutRejectedCountEmployee}`  }
        }
        else if(checkoutRejectedByCorporation){
          checkoutRejectedCountCorporation++
          checkoutLabels = {...checkoutLabels, statusLabelText: `Afvist ${checkoutRejectedCountCorporation}` }
        }
      }
    }
    if(!checkoutLabels.statusLabelText){
      if(totalShifts == checkoutLabels.corporationCheckedOutCount && totalShifts == checkoutLabels.employeeCheckedOutCount){
        checkoutLabels = {...checkoutLabels, statusLabelText: 'Godkendt', statusLabelColor: colorsNew.green_50, invoiceStatusColor: colors.darkerGrey, invoiceStatusText: 'FAKTURA KLAR'};
      }
      else if(totalShifts == checkoutLabels.corporationCheckedOutCount){
        checkoutLabels = {...checkoutLabels, statusLabelText: 'Stemplet ud', statusLabelColor: colorsNew.green_50};
      }
      else{
        checkoutLabels = {...checkoutLabels, statusLabelText: 'Stempl ud', statusLabelColor: colorsNew.red_20};
      }
    }
    if(booking.all_check_times_approved){
      checkoutLabels = {...checkoutLabels, statusLabelText: 'Lukket', statusLabelColor: colorsNew.green_50, invoiceStatusColor: colorsNew.green_50, invoiceStatusText: 'FAKTURA SENDT'};
    }
    const {statusLabelColor, statusLabelText, employeeCheckedOutCount, corporationCheckedOutCount, ratingCount, invoiceStatusColor, invoiceStatusText} = checkoutLabels;
  return (
    <div
      onClick={() => !readOnly && onSubmitHandler()}
      className={`${componentStyle(colors, readOnly)} ${props.className || ""}`}
      style={props.style}
    >
      <div className="inside-component">
        {props.waitingApproval && (
          <div
            style={{ position: "absolute", top: "-28px", fontWeight: "bold" }}
          >
            <p>{booking.corporation.name}</p>
          </div>
        )}

        <div className="date-container">
          <DateViewer date={booking.date} className="date-style" size={23} showYear={true} />
        </div>

        <div className="content-box">
          <div className="container-a">
            <p className="container-a-title"><ClipboardTextOutlineIcon size={20} />{booking.arrangement_type ? booking.arrangement_type : booking.arrangement_type_other }</p>
            <div className="container-a-b">
              <div className="time-container">
              <ClockOutlineIcon size={20} />
                <p> {`${
                  booking.start_time
                } - ${booking.end_time} [${workHours({
                  start_time: booking.start_time,
                  end_time: booking.end_time
                })}/t]`}</p>
              </div>
              <p className="staff-type-container"><WalkIcon size={20} /> {`${staff_type_converter(
                  booking.staff_type
                ).toUpperCase()}`}</p>
              <div className="address-container">
                <MapMarkerIcon size={20} />
                <p className="text-xs text-gray-600">
                  {`${booking.address}, ${booking.zip_code} ${booking.city}`}
                </p>
              </div>
            </div>
          </div>

          <div className="container-b">
            <div className="container-a-b">

                  <div className="label-wrapper">
                    {invoiceStatusText && (<p className="label" style={{backgroundColor: invoiceStatusColor}}>{invoiceStatusText}</p>) }
                    <p className={`label ml-2`} style={{backgroundColor: statusLabelColor, color: statusLabelColor == "#ecc94b" ? colors.darkerGrey : '#fff'}}>{statusLabelText}</p>
                  </div>
                  <div className="label-wrapper">
                    <span>Stemplet ud</span>
                    <p className={`label ml-2`} style={{backgroundColor: totalShifts == corporationCheckedOutCount ? colorsNew.green_50 : colorsNew.red_20}}>{corporationCheckedOutCount} af {totalShifts}</p>
                  </div>
                  <div className="label-wrapper">
                    <span>Rating</span>
                    <p className={`label ml-2`} style={{backgroundColor: totalShifts == ratingCount ? colorsNew.green_50 : colors.custardYellow, color: totalShifts == ratingCount ? '#fff' : colors.darkerGrey}}>{ratingCount} af {totalShifts}</p>
                  </div>
                  <div className="label-wrapper">
                    <span>Tjenere Stemplet ud</span>
                    <p className={`label ml-2`} style={{backgroundColor: totalShifts == employeeCheckedOutCount ? colorsNew.green_50 : colors.darkerGrey}}>{employeeCheckedOutCount} af {totalShifts}</p>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const componentStyle = (colors, readOnly) => css`
  position: relative;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0rem;
  margin: ${readOnly? '1.25rem .75rem' : '1.25rem 0'};

  @media screen and (max-width: ${breakPoint.sm}px) {
    margin-top: 1rem;
  }

  &:hover {
    // background-color: ${colors.mediumGrey};
    background-color: transparent;
  }

  p.date-detail {
    font-size: 1.7rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 1rem;
    }
  }

  .label-wrapper {
    display: flex;
    align-items: baseline;
    margin-bottom:5px;
    span{
        margin-right:8px;
        display:inline-block;
        font-size: 15px;
        line-height: 18px;
    }
    .on-label {
      margin-right: 0.25rem;
    }
  }

  .inside-component {
    display: flex;

    .date-container {
      display: flex;
      flex: 0 0 auto;
      max-width: 8%;
      justify-content: center;
      align-items: center;
      margin: 0 0.5rem;

      .date-style {
        .day,
        .month {
            font-size: 20px;
            line-height: 25px;
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 12px;
          }
        }

        .year {
          left: 20px;
          bottom: 4px;
          position:initial;  
          font-size: 20px;
          line-height: 25px;
          @media screen and (max-width: ${breakPoint.sm}px) {
            bottom: 6px;
            font-size: 12px;
          }
        }

        .date {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 15px;
          }
        }
      }
    }

    .content-box {
      display: flex;
      flex: 0 0 auto;
      max-width: 92%;
      justify-content: space-between;
      width: 100%;
      padding: 1rem;
      border: 1px solid ${colors.grey};
      background-color: ${colors.white};
      border-radius:5px;
      .mdi-icon {
        color: #4A4A4A;
      }
      .container-a {
          overflow: hidden;
          max-width: 60%;
        .container-a-title {
          display: -webkit-box;
          -webkit-box-align: center;
          align-items: center;
          font-size: 1.25rem;
          margin-bottom: 0.25rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          flex: 0 0 auto;
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 0.55rem;
          }
        }

        .container-a-b {
          .time-container {
            display: flex;
            align-items: center;
            margin-bottom: 0.25rem;

            p {
              font-size: 1rem;
              margin-right: 0.5rem;

              @media screen and (max-width: ${breakPoint.sm}px) {
                font-size: 0.55rem;
              }
            }
          }

          .wage-container {
            margin-bottom: 0.25rem;

            p {
              font-size: 0.75rem;
              color: ${colors.darkerGrey};
            }
          }

          .address-container, .staff-type-container {
            display: flex;
            align-items: center;

            p {
              font-size: 0.65rem;
              color: ${colors.darkerGrey};

              @media screen and (max-width: ${breakPoint.sm}px) {
                font-size: 0.55rem;
              }
            }

            svg {
              height: 1.25rem;
              width: 1.25rem;
              margin-right: 0.12rem;
            }

            @media screen and (max-width: ${breakPoint.sm}px) {
              svg {
                height: 0.85rem;
                width: 0.85rem;
              }
            }
          }
        }
      }

      .container-b {
        display: flex;
        align-items: center;

        @media screen and (max-width: ${breakPoint.sm}px) {
          padding-top: 0;
        }

        .container-a-b {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;

          .booked-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @media screen and (max-width: ${breakPoint.sm}px) {
              font-size: 0.55rem;
            }

            .staffs {
              margin-bottom: 0.25rem;
              text-align: end;
            }

            .selected-staff-container {
              display: flex;
              justify-content: space-between;
              width: 7rem;

              @media screen and (max-width: ${breakPoint.sm}px) {
                width: 4rem;
              }

              p {
                font-size: 0.875rem;

                @media screen and (max-width: ${breakPoint.sm}px) {
                  font-size: 0.55rem;
                }
              }
            }

            .applied-staff-container {
              display: flex;
              justify-content: space-between;
              width: 7rem;

              @media screen and (max-width: ${breakPoint.sm}px) {
                width: 4rem;
              }

              p {
                font-size: 0.875rem;
                margin-bottom: 0.5rem;
                color: ${colors.darkGrey};

                @media screen and (max-width: ${breakPoint.sm}px) {
                  font-size: 0.55rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .countdown-container {
    margin-top: 0.25rem;

    p {
      background-color: ${colors.darkGrey};
      color: ${colors.white};
      border-radius: 0.25rem;
      text-align: center;
      font-size: 0.65rem;
      font-weight: 700;
      padding: 0.25rem 0.5rem;
    }
  }

  .label-container,
  .light-grey-label,
  .grey-label,
  .green-label,
  .yellow-label,
  .red-label,
  .label {
    text-align: center;
    padding: 0.25rem;
    min-width: 4rem;
    border-radius: 0.25rem;
    align-items: center;
    color: ${colors.white};
    font-weight: 600;
    font-size: 0.65rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.4rem;
      width: 4rem;
      min-width: 0;
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  showModal: bindActionCreators(showModal, dispatch)
});

export default connect(null, mapDispatchToProps)(Booking);
