export default {
  open: "open",
  closed: "closed",
  expired: "expired",
  cancelled: "cancelled",
  finished: "finished",
  // These are not status, but are used for Redux
  waitingForApproval: "waitingForApproval",
  history: "history",
  deprecated: "deprecated"
};
