// Libs
import React, { useEffect, useState } from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { css } from "emotion";

// Components
import PageContainer from "../ui/PageContainer";
import NavigateBackArrow from "../ui/NavigateBackArrow";
import Spinner from "../ui/Spinner";
import ErrorBox from "../ui/ErrorBox";
import InfiniteScroll from "react-infinite-scroller";

// Styles
import AccountIcon from "mdi-react/AccountIcon";
import colors from "../../styles/colors";

// Actions
import { takeOverCorporation } from "../../actions/authActions";
import {
  getCorporations,
  resetCorporations
} from "../../actions/corporationActions";
import { showImageContainer } from "../../actions/uiActions";
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";

// Utilities
import { useAsyncDebounce } from "../../utilities/useAsyncDebounce";
import req from "../../utilities/request-utility";

const ShowCorporations = props => {
  const history = useHistory();

  // Redux state
  const {
    loading,
    error,
    endOfFeed,
    corporations,
    searchTerm: reduxSearchTerm
  } = useSelector(state => state.corporation);

  // Local state
  const [searchTerm, setSearchTerm] = useState(null);
  const [totalCorporations, setTotalCorporations] = useState("");

  const debouncedGetCorporations = useAsyncDebounce(props.getCorporations, 250);

  useEffect(() => {
    return () => {
      props.resetCorporations();
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getTotalCountCorporations();
    if (searchTerm !== reduxSearchTerm) {
      props.resetCorporations();
    }
  }, [searchTerm]);

  const getTotalCountCorporations = async () => {
    try {
      const { data: count } = await req()(`admin/corporations/count`);
      setTotalCorporations(count);
    } catch (error) {}
  };

  return (
    <PageContainer showNavigation={true} showFullScreen={true}>
      <NavigateBackArrow navigateURL={() => history.push("/")} />
      <div className={componentStyle(colors)}>
        <div className="stats-container">
          <div>
            <p>
              Virksomheder i systemet:{" "}
              <span style={{ fontWeight: "bold" }}>{totalCorporations}</span>
            </p>
          </div>
        </div>
        <div className="search-container">
          <label>Søg</label>
          <input
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>

        <InfiniteScroll
          loadMore={() => debouncedGetCorporations({ searchTerm })}
          useWindow={true}
          hasMore={true}
        >
          {endOfFeed && corporations.length === 0 && (
            <div className="no-users-container">
              <p>Der er ikke nogen brugere i systemet</p>
            </div>
          )}
          {corporations.length > 0 && (
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th />
                    <th>Id</th>
                    <th>Firma navn</th>
                    <th>Email</th>
                    <th>Telefon</th>
                    <th>Oprettet</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {corporations.map((corporation, index) => (
                    <tr key={index}>
                      <td>
                        <div className="image-container">
                          {corporation.profile_image && (
                            <img
                              onClick={() =>
                                props.showImageContainer(
                                  `${corporation.baseURL_image}h_500,w_500/${corporation.profile_image}`
                                )
                              }
                              src={`${corporation.baseURL_image}c_thumb,g_face,h_160,w_160/dpr_2.0,f_auto/${corporation.profile_image}`}
                              alt="corporation-profile"
                            />
                          )}
                          {!corporation.baseURL && <AccountIcon />}
                        </div>
                      </td>
                      <td>{corporation.id}</td>
                      <td>{corporation.name}</td>
                      <td>{corporation.email}</td>
                      <td>{corporation.phone}</td>
                      <td>
                        {moment(corporation.terms_accepted_at).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        <div className="btn-container">
                          <button
                            onClick={() =>
                              props.takeOverCorporation(corporation.id)
                            }
                          >
                            Overtag
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </InfiniteScroll>

        {!loading && error && <ErrorBox />}
        {loading && (
          <Spinner
            title={"Henter virksomheder..."}
            style={{ margin: "2rem 0" }}
          />
        )}
      </div>
    </PageContainer>
  );
};

const componentStyle = colors => css`
  position: absolute;
  width: 100%;
  background-color: ${colors.superLightGrey};

  div.stats-container {
    display: flex;
    justify-content: center;
    margin-right: 2rem;

    div {
      padding: 0.5rem 1rem;
      border: 1px solid ${colors.darkGrey};
      border-radius: 5px;
    }
  }

  .search-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
    align-items: center;

    label {
      margin-right: 0.5rem;
    }

    input {
      border: 1px solid ${colors.primeBlue};
      border-radius: 5px;
      padding: 0.25rem;
      width: 20rem;
    }
  }

  .no-users-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-weight: 500;
    }
  }

  .table-container {
    padding: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    table {
      table-layout: auto;
      width: 100%;
      background-color: ${colors.superLightGrey};

      th {
        padding: 0.5rem 0.5rem;
        font-size: 0.85rem;
        text-align: start;
      }

      tbody {
        td {
          border-width: 1px;
          padding: 0.5rem;
          font-size: 0.85rem;
          background-color: ${colors};
        }

        .image-container {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          height: 3.2rem;
          justify-content: center;
          align-items: center;
          width: 100%;

          img {
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 9999px;
            border-color: ${colors.lightGrey};
            border-width: 1px;
          }
          img:hover {
            border-color: ${colors.darkGrey};
          }
        }
      }
    }

    .btn-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      button {
        background-color: ${colors.darkBlue};
        padding: 0.25rem 1rem;
        border-radius: 0.25rem;
        color: ${colors.white};
        font-size: 0.75rem;
        font-weight: 500;
      }

      button:hover {
        background-color: ${colors.blue};
      }
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  showImageContainer: bindActionCreators(showImageContainer, dispatch),
  getCorporations: bindActionCreators(getCorporations, dispatch),
  resetCorporations: bindActionCreators(resetCorporations, dispatch),
  takeOverCorporation: bindActionCreators(takeOverCorporation, dispatch)
});

export default connect(null, mapDispatchToProps)(ShowCorporations);
