// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { withRouter } from "react-router";

// Components
import UpcomingBookings from "./UpcomingBookings";
import FinishedBookings from "../checkout/FinishedBookings";

// Styles
import colors from "../../../styles/colors";

// Utilities
import req from "../../../utilities/request-utility";
import { breakPoint } from "../../../styles/breakPoints";

const MyBookingsOverview = props => {
  const [step, setStep] = useState(0);
  const [checkoutNotifications, setCheckoutNotifications] = useState(null);

  useEffect(() => {
    req()(`notifications/corporation/checkout`).then(({ data: shifts }) => {
      setCheckoutNotifications(shifts);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(props.location.state?.step){
      setStep(1);
    }
    window.history.replaceState({}, document.title)
  }, [props.location.state]);

  return (
    <div className={componentStyle(colors)}>
      <ul>
        <li>
          <button
            onClick={() => setStep(0)}
            className={step === 0 ? "selected" : "header-option"}
          >
            KOMMENDE BOOKINGER
          </button>
        </li>

        <li>
          <button
            onClick={() => setStep(1)}
            className={step === 1 ? "selected" : "header-option"}
          >
            AFSLUTTEDE BOOKINGER
            {checkoutNotifications > 0 && (
              <span className="badge">{checkoutNotifications}</span>
            )}
          </button>
        </li>
      </ul>

      <hr />

      {step === 0 && (
        <div className="title-container">
          <UpcomingBookings />
        </div>
      )}
      {step === 1 && (
        <div className="title-container">
          <FinishedBookings />
        </div>
      )}
    </div>
  );
};

const componentStyle = colors => css`
  ul {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0.25rem;
    justify-content: center;
  }

  li {
    margin-right: 2.5rem;
    padding: 0 0.5rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      margin-right: 0.5rem;
    }
  }

  .badge {
    position: absolute;
    top: -10px;
    right: -24px;
    padding: 2px 9px;
    border-radius: 50%;
    background: red;
    color: white;
    font-weight: bold;

    @media screen and (max-width: ${breakPoint.sm}px) {
      padding: 2px 7px;
      right: -17px;
    }
  }

  .header-option {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.85rem;
    color: ${colors.darkGrey};
    outline: none;
    position: relative;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.65rem;
    }
  }

  .selected {
    text-transform: uppercase;
    color: ${colors.primeBlue};
    font-size: 0.85rem;
    font-weight: bold;
    outline: none;
    position: relative;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.65rem;
    }
  }

  .selected,
  .header-option:hover {
    color: ${colors.darkerPrimeBlue};
  }

  .title-container {
    padding: 1rem 0;
  }

  .noti-icon {
    position: relative;
    top: -7px;
    right: 1px;
    background-color: ${colors.darkRed};
    color: ${colors.white};
    font-weight: 900;
    padding: 0.12rem 0.4rem;
    border-radius: 50%;
    font-size: 0.8rem;
  }

  hr {
    border: 1px solid ${colors.mediumGrey};
  }
`;

export default withRouter(MyBookingsOverview);
