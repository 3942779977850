// Libs
import React, { useEffect, useState } from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { css } from "emotion";

// Actions
import { takeOverEmployee } from "../../actions/authActions";
import { getEmployees, resetEmployees } from "../../actions/employeeActions";

// Styles
import AccountIcon from "mdi-react/AccountIcon";
import colors from "../../styles/colors";

// Utilities
import req from "../../utilities/request-utility";

// Components
import PageContainer from "../ui/PageContainer";
import Spinner from "../ui/Spinner";
import ErrorBox from "../ui/ErrorBox";
import InfiniteScroll from "react-infinite-scroller";
import NavigateBackArrow from "../ui/NavigateBackArrow";
import { addToast, showImageContainer } from "../../actions/uiActions";

// Utilities
import { useAsyncDebounce } from "../../utilities/useAsyncDebounce";

const ShowEmployees = (props) => {
  const history = useHistory();

  // Redux state
  const {
    employees,
    loading,
    error,
    endOfFeed,
    searchTerm: reduxSearchTerm,
  } = useSelector((state) => state.employee);

  // Local state
  const [searchTerm, setSearchTerm] = useState("");
  const [totalEmployees, setTotalEmployees] = useState("");

  const debouncedGetEmployees = useAsyncDebounce(props.getEmployees, 250);

  const getTotalCountEmployees = async () => {
    try {
      const { data: count } = await req()(`admin/employees/count`);
      setTotalEmployees(count);
    } catch (error) {}
  };
  const inviteToAllGravy = async (employeeId) => {
    try {
      await req().post(`admin/employees/${employeeId}/invite-all-gravy`);
      props.addToast({
        message: "Medarbejder inviteret..",
        type: "success",
        autoDismiss: true,
      });
    } catch (error) {
      props.addToast({
        message: "Noget gik galt. Kunne ikke gemme ændringerne..",
        type: "error",
        autoDismiss: true,
      });
    }
  };

  useEffect(() => {
    getTotalCountEmployees();
    if (searchTerm !== reduxSearchTerm) {
      props.resetEmployees();
    }
  }, [searchTerm]);

  return (
    <PageContainer showNavigation={true} showFullScreen={true}>
      <NavigateBackArrow navigateURL={() => history.push("/")} />
      <div className={componentStyle(colors)}>
        <div className="stats-container">
          <div>
            <p>
              Medarbejder i systemet:{" "}
              <span style={{ fontWeight: "bold" }}>{totalEmployees}</span>
            </p>
          </div>
        </div>
        <div className="search-container">
          <label>Søg</label>
          <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <InfiniteScroll
          loadMore={() => debouncedGetEmployees({ searchTerm })}
          useWindow={true}
          hasMore={true}
        >
          {endOfFeed && employees.length === 0 && (
            <div className="no-users-container">
              <p>Der er ikke nogen brugere i systemet</p>
            </div>
          )}
          <div>
            {employees.length > 0 && (
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th>Id</th>
                      <th>Fornavn</th>
                      <th>Efternavn</th>
                      <th>CPR</th>
                      <th>Email</th>
                      <th>Tlf.</th>
                      <th>Alder</th>
                      <th>By</th>
                      <th>Ansøgt jobs</th>
                      <th>Fået jobs</th>
                      <th>Oprettet</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {employees.map((employee, index) => (
                      <tr key={index}>
                        <td>
                          <div className="image-container">
                            {employee.baseURL_image && (
                              <img
                                onClick={() =>
                                  props.showImageContainer(
                                    `${employee.baseURL_image}w_350/${employee.profile_image}`
                                  )
                                }
                                src={`${employee.baseURL_image}c_thumb,g_face,h_160,w_160/dpr_2.0,f_auto/${employee.profile_image}`}
                                alt="employee-profile"
                              />
                            )}
                            {!employee.baseURL_image && <AccountIcon />}
                          </div>
                        </td>
                        <td>{employee.id}</td>
                        <td>{employee.first_name}</td>
                        <td>{employee.last_name}</td>
                        <td>{employee.cpr_nr}</td>
                        <td>
                          <span
                            style={{
                              fontWeight: "bold",
                              marginRight: "1rem",
                            }}
                          >
                            FB:
                          </span>
                          {"  "}
                          {employee.login_email}
                          <br />
                          <span
                            style={{
                              fontWeight: "bold",
                              marginRight: "0.25rem",
                            }}
                          >
                            Mails:
                          </span>{" "}
                          {employee.email}
                        </td>
                        <td>{employee.phone}</td>
                        <td>{employee.age}</td>
                        <td>{employee.city}</td>
                        <td>{employee.nrOfAppliedShifts}</td>
                        <td>{employee.nrOfShifts}</td>
                        <td>
                          {employee.terms_accepted_at
                            ? moment(employee.terms_accepted_at).format("llll")
                            : ""}
                        </td>
                        <td>
                          <div className="btn-container">
                            <button
                              onClick={() =>
                                props.takeOverEmployee(employee.id)
                              }
                            >
                              Overtag
                            </button>
                            <button
                              onClick={() => inviteToAllGravy(employee.id)}
                            >
                              All Gravy
                            </button>
                            {employee.facebook_link && (
                              <a href={employee.facebook_link} target="_blank">
                                Facebook
                              </a>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {loading && !error && (
            <Spinner title={"Henter ansatte..."} style={{ margin: "1rem 0" }} />
          )}
          {!loading && error && <ErrorBox />}
        </InfiniteScroll>
      </div>
    </PageContainer>
  );
};

const componentStyle = () => css`
  position: absolute;
  width: 100%;
  background-color: ${colors.superLightGrey};

  div.stats-container {
    display: flex;
    justify-content: center;
    margin-right: 2rem;

    div {
      padding: 0.5rem 1rem;
      border: 1px solid ${colors.darkGrey};
      border-radius: 5px;
    }
  }

  .search-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
    align-items: center;

    label {
      margin-right: 0.5rem;
    }

    input {
      border: 1px solid ${colors.primeBlue};
      border-radius: 5px;
      padding: 0.25rem;
      width: 20rem;
    }
  }

  .no-users-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-weight: 500;
    }
  }

  .table-container {
    padding: 0.5rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    table {
      table-layout: auto;
      background-color: ${colors.superLightGrey};
      th {
        padding: 0.5rem 0.5rem;
        font-size: 0.65rem;
        text-align: start;
      }

      tbody {
        td {
          border-width: 1px;
          padding: 0.5rem;
          font-size: 0.65rem;
        }

        .image-container {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          height: 3.2rem;
          justify-content: center;
          align-items: center;
          width: 100%;

          img {
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 9999px;
            border-color: ${colors.lightGrey};
            border-width: 1px;
          }
          img:hover {
            border-color: ${colors.darkGrey};
          }
        }
      }
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: auto;
    max-width: fit-content;

    button,
    a {
      background-color: ${colors.darkBlue};
      padding: 0.25rem 1rem;
      border-radius: 0.25rem;
      color: ${colors.white};
      font-size: 0.75rem;
      font-weight: 500;
      margin: 0.25rem;
      width: 100%;
    }

    button:hover {
      background-color: ${colors.blue};
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showImageContainer: bindActionCreators(showImageContainer, dispatch),
  getEmployees: bindActionCreators(getEmployees, dispatch),
  resetEmployees: bindActionCreators(resetEmployees, dispatch),
  takeOverEmployee: bindActionCreators(takeOverEmployee, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(ShowEmployees);
