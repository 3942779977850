// Libs
import React from "react";
import { css } from "emotion";

// Styles
import waiter from "../../static/JobbutlerHome.jpg";
import colors from "../../styles/colors";

// Components
import NavigationBar from "../NavigationBar";
import ModalPage from "./ModalPage";
import { breakPoint } from "../../styles/breakPoints";
import ViewImageContainer from "./ViewImageContainer";

const PageContainer = props => {
  return (
    <div className={componentStyle(colors)} style={props.style}>
      <div
          style={{backgroundColor: props.backgroundColor || ""}}
        className={props.showFullScreen ? "full-screen-container" : "container"}
      >
        <ViewImageContainer />
        <ModalPage />
        {props.showNavigation && (
          <NavigationBar
            showContract={props.showContract && props.showContract}
            userType={props.navigationBarType}
          />
        )}
        {props.children}
      </div>
      {!props.showFullScreen && <div className="images-box-div" />}
    </div>
  );
};

const componentStyle = colors => css`
  position: absolute;
  width: 100%;
  height: 100%;

  .container {
    position: fixed;
    height: 100vh;
    background-color: ${colors.white};
    overflow: auto;
    background-color: ${colors.superLightGrey};
    width: 48rem;
  }

  .full-screen-container {
    overflow: auto;
    background-color: ${colors.superLightGrey};
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
  }

  div.images-box-div {
    width: 60%;
    height: 100vh;
    background-size: cover;
    position: absolute;
    z-index: -1;
    right: 0;
    background-image: url(${waiter});
  }

  @media screen and (max-width: ${breakPoint.sm}px) {
    div.images-box-div {
      display: none !important;
    }

    div.container {
      overflow: auto;
      background-color: ${colors.superLightGrey};
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 1900px) {
    div.images-box-div {
      width: 65%;
    }
  }

  @media (min-width: 2300px) {
    div.images-box-div {
      width: 70%;
    }
  }

  @media (min-width: 2600px) {
    div.images-box-div {
      width: 100%;
    }
  }
`;

export default PageContainer;
