import {
  ADD_TOAST,
  END_SPINNER,
  HIDE_IMAGE_CONTAINER,
  HIDE_MODAL,
  REMOVE_TOAST,
  SHOW_IMAGE_CONTAINER,
  SHOW_MODAL,
  START_SPINNER
} from "../actions/actionTypes";

const initialState = {
  toast: false,
  spinner: false,
  modal: {
    disableBackgroundCancel: false,
    hideTop: false,
    active: false,
    content: null,
    title: ""
  },
  imageContainer: {
    active: false,
    image: null
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_SPINNER:
      return {
        ...state,
        spinner: true
      };

    case END_SPINNER:
      return {
        ...state,
        spinner: false
      };

    case ADD_TOAST:
      return {
        ...state,
        toast: action.payload
      };

    case REMOVE_TOAST:
      return {
        ...state,
        toast: false
      };

    case SHOW_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          active: true,
          disableBackgroundCancel:
            action.payload.disableBackgroundCancel || false,
          hideTop: action.payload.hideTop || false,
          content: action.payload.content,
          title: action.payload.title || ""
        }
      };

    case HIDE_MODAL: {
      return {
        ...state,
        modal: {
          ...state.modal,
          active: false,
          content: null
        }
      };
    }

    case SHOW_IMAGE_CONTAINER:
      return {
        ...state,
        imageContainer: {
          ...state.imageContainer,
          active: true,
          image: action.payload
        }
      };

    case HIDE_IMAGE_CONTAINER:
      return {
        ...state,
        imageContainer: {
          ...state.imageContainer,
          active: false,
          image: null
        }
      };

    default:
      return state;
  }
}
