// Libs
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { css } from "emotion";

// Components
import ExperienceModal from "./ExperienceModal";
import ErrorBox from "../../ui/ErrorBox";
import Spinner from "../../ui/Spinner";

// Utilities and config
import req from "../../../utilities/request-utility";

// Actions
import { addToast, hideModal, showModal } from "../../../actions/uiActions";

// Styles
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import CancelIcon from "mdi-react/CancelIcon";
import colors from "../../../styles/colors";
import { breakPoint } from "../../../styles/breakPoints";

const Experience = props => {
  // Loading and errors
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Experiences
  const [experiences, setExperiences] = useState([]);

  const [selectedExperience, setSelectedExperience] = useState(null);

  // Selector
  const [showMore, setShowMore] = useState(false);

  const addExperience = experience => {
    props.hideModal();
    setLoading(true);
    req()
      .post("employees/work-experience", experience)
      .then(({ data: experiences }) => {
        setExperiences(experiences);
        setLoading(false);
        props.addToast({
          message: "Erfaring er blevet oprettet!",
          type: "success",
          autoDismiss: true
        });
      })
      .catch(err => {
        setError(true);
        setLoading(false);
        props.addToast({
          message:
            "Kunne ikke tilføje erfaring. Hvis fejlen fortsætter, venligst kontakt JobButler",
          type: "warning",
          autoDismiss: true
        });
      });
  };

  const deleteExperience = experienceId => {
    setLoading(true);
    req()
      .delete(`employees/work-experience/${experienceId}`)
      .then(({ data: experiences }) => {
        setExperiences(experiences);
        setLoading(false);
        props.addToast({
          message: "Erfaring er blevet slettet!",
          type: "success",
          autoDismiss: true
        });
      })
      .catch(err => {
        setLoading(false);
        setError(true);
        props.addToast({
          message:
            "Kunne ikke slette erfaring. Hvis fejlen fortsætter, venligst kontakt JobButler",
          type: "warning",
          autoDismiss: true
        });
      });
  };

  const updateExperience = experience => {
    props.hideModal();
    setLoading(true);
    req()
      .put(`employees/work-experience/${experience.id}`, experience)
      .then(({ data: experiences }) => {
        setExperiences(experiences);
        setLoading(false);
        props.addToast({
          message: "Erfaring er blevet opdateret!",
          type: "success",
          autoDismiss: true
        });
      })
      .catch(err => {
        setError(true);
        setLoading(false);
        props.addToast({
          message:
            "Kunne ikke opdater erfaring. Hvis fejlen fortsætter, venligst kontakt JobButler",
          type: "warning",
          autoDismiss: true
        });
      });
  };

  useEffect(() => {
    req()
      .get("employees/work-experience")
      .then(({ data: experiences }) => {
        setExperiences(experiences);
        setLoading(false);
      })
      .catch(err => {
        setError(true);
        setLoading(false);
        props.addToast({
          message:
            "Kunne ikke hente erfaringer. Hvis fejlen fortsætter, venligst kontakt JobButler",
          type: "warning",
          autoDismiss: true
        });
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={componentStyle()}>
      {loading && !error && <Spinner title={"Indlæser..."} />}
      {!loading && error && <ErrorBox />}
      {!loading && !error && (
        <Fragment>
          <div className="top-section-cont">
            <p className="header-title">Erfaringer</p>
            <button
              onClick={() =>
                props.showModal({
                  title: "TILFØJ ERFARNGER",
                  content: (
                    <ExperienceModal onSubmit={addExperience} type={"new"} />
                  )
                })
              }
              className="new-experience"
            >
              Tilføj erfaring
            </button>
          </div>

          <table>
            <thead>
              <tr>
                <th>Arbejdsplads</th>
                <th>Stilling</th>
                <th>Type</th>
                <th>Start. år</th>
                <th>Start. mdr.</th>
                <th>Slut. år</th>
                <th>Slut. mdr.</th>
                <th>Antal vagter</th>
                <th>Stadig ansat</th>
                <th></th>
              </tr>
            </thead>
            {experiences.length > 0 && (
              <tbody>
                {experiences.map(exp => (
                  <tr>
                    <td>
                      <p>{exp.employer_text}</p>
                    </td>
                    <td>
                      <p>{exp.title}</p>
                    </td>
                    <td>
                      <p>{exp.exp_type}</p>
                    </td>
                    <td>
                      <p>{exp.start_year}</p>
                    </td>
                    <td>
                      <p>{exp.start_month}</p>
                    </td>
                    <td>
                      <p>{exp.end_year}</p>
                    </td>
                    <td>
                      <p>{exp.end_month}</p>
                    </td>
                    <td>
                      <p>{exp.shift_count}</p>
                    </td>
                    <td>
                      {exp.still_employed ? (
                        <p className="icon-center">
                          <CheckCircleIcon color={colors.green} size={18} />
                        </p>
                      ) : (
                        <p className="icon-center">
                          <CancelIcon color={colors.red} size={18} />
                        </p>
                      )}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          setSelectedExperience(exp);
                          setShowMore(true);
                        }}
                        className="options-btn"
                      >
                        Mere
                      </button>
                      {showMore && exp.id === selectedExperience.id && (
                        <button
                          onClick={() => {
                            setShowMore(false);
                          }}
                          className="invisible-btn"
                        />
                      )}
                      {showMore && exp.id === selectedExperience.id && (
                        <div className="options-container">
                          <p
                            onClick={() =>
                              props.showModal({
                                title: "TILFØJ ERFARNGER",
                                content: (
                                  <ExperienceModal
                                    experience={exp}
                                    onSubmit={updateExperience}
                                    type={"update"}
                                  />
                                )
                              })
                            }
                            className="option"
                          >
                            Rediger
                          </p>

                          <div className="divider" />
                          <p
                            onClick={() => {
                              deleteExperience(exp.id);
                              setShowMore(false);
                              setSelectedExperience(null);
                            }}
                            className="option"
                          >
                            Slet
                          </p>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {experiences.length < 1 && (
            <p className="no-experience-container">
              Ingen erfaringer er tilføjet endnu
            </p>
          )}
        </Fragment>
      )}
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;

  p.no-experience-container {
    margin-top: 1rem;
    text-align: center;
  }

  div.top-section-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;

    p.header-title {
      font-size: 1.25rem;
      text-transform: uppercase;
      padding-left: 1rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.85rem;
      }
    }

    button.new-experience {
      margin-right: 1.25rem;
      outline: none;
      padding: 0.5rem 1.25rem;
      background-color: ${colors.darkerGrey};
      border-radius: 5px;
      color: ${colors.white};
      font-weight: bold;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.7rem;
        padding: 0.25rem 0.85rem;
      }
    }

    button.new-experience:hover {
      background-color: ${colors.black};
    }
  }

  table {
    table-layout: auto;

    th {
      padding: 0.5rem 0.25rem;
      font-size: 0.65rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.2rem;
      }
    }

    td {
      border-width: 1px;
      padding: 0.5rem 0.5rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        padding: 0;
      }
      p {
        text-align: center;
        font-size: 0.65rem;

        @media screen and (max-width: ${breakPoint.sm}px) {
          font-size: 0.2rem;
        }
      }

      .icon-center {
        text-align: -webkit-center;

        @media screen and (max-width: ${breakPoint.sm}px) {
          svg {
            width: 0.75rem;
          }
        }
      }
    }

    button.show-message-btn,
    button.options-btn {
      outline: none;
      color: ${colors.white};
      padding: 0.25rem 1.25rem;
      border-radius: 5px;
      background-color: ${colors.lightBlue};
      font-size: 0.65rem;
      font-weight: bold;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.3rem;
        padding: 0.12rem 0.5rem;
      }
    }

    button.show-message-btn:hover {
      background-color: ${colors.darkBlue};
    }

    button.options-btn {
      background-color: ${colors.darkGrey};
    }

    button.options-btn:hover {
      background-color: ${colors.darkerGrey};
    }

    button.invisible-btn {
      z-index: 10;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: default;
    }

    div.options-container {
      position: absolute;
      z-index: 10;
      background-color: ${colors.grey};
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: 4.8rem;
      border-radius: 0.5rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);

      @media screen and (max-width: ${breakPoint.sm}px) {
        right: 3px;
        p {
          font-size: 0.75rem;
          padding: 0.12rem 0.5rem;
        }
      }

      div.divider {
        border-color: ${colors.grey};
        margin-bottom: 0.5rem;
      }

      p.option {
        cursor: pointer;
        display: block;
        padding: 0.25rem 1rem;
        color: ${colors.darkerGrey};
        font-weight: bold;
        font-size: 0.65rem;
      }

      p.option:hover {
        color: ${colors.white};
        background-color: ${colors.darkerGrey};
      }
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch)
});

export default connect(null, mapDispatchToProps)(Experience);
