// LIbs
import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { css } from "emotion";
import { useHistory } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

// Actions
import { signInEmployee } from "../../actions/authActions";
import { showModal } from "../../actions/uiActions";

// Components
import { ClipLoader } from "react-spinners";
import PageContainer from "../ui/PageContainer";

// Styles
import colors from "../../styles/colors";
import { breakPoint } from "../../styles/breakPoints";

// Facebook Pixel
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const EmployeeLogin = (props) => {
  useEffect(() => {
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, options);
    ReactPixel.revokeConsent();
  }, []);

  const { spinner } = useSelector((state) => state.ui);
  const history = useHistory();
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  // Sent token to API
  const responseFacebook = (response) => {
    ReactPixel.grantConsent();
    props.signIn({ fbToken: response.accessToken });
  };

  return (
    <PageContainer showNavigation={true}>
      <div className={componentStyle(props)}>
        <p className="title">Log ind med facebook</p>

        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          fields="name,link,email,picture.width(500)"
          scope="public_profile,email,user_link"
          isMobile={false}
          callback={responseFacebook}
          render={(renderProps) =>
            !spinner ? (
              <button
                disabled={!acceptedTerms}
                onClick={acceptedTerms && renderProps.onClick}
                className={acceptedTerms ? "facebook" : "facebook disabled"}
              >
                Fortsæt med facebook
              </button>
            ) : (
              <ClipLoader size={38} color={"#123abc"} loading={spinner} />
            )
          }
        />

        <div className="checkbox-wrapper">
          <div className="check-box-box">
            <input
              checked={acceptedTerms}
              type="checkbox"
              onChange={() => setAcceptedTerms(!acceptedTerms)}
            />
          </div>
          <p>
            Jeg har læst og accepteret{" "}
            <a
              target="_blank"
              href={"https://www.tjenerteamet.dk/cookiepolicy.html"}
            >
              Politikken for beskyttelse af personlige oplysninger
            </a>
          </p>
        </div>
        <button
          onClick={() => history.push("/corporationLogin")}
          className="navigate-to-corporation"
        >
          Søger du personale til event? - klik her!
        </button>
      </div>
    </PageContainer>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  p.title {
    color: ${colors.medDarkGrey};
    margin-top: 1rem;
    font-size: 2rem;
  }

  button.facebook {
    cursor: pointer;
    margin-top: 1rem;
    color: ${colors.white};
    font-size: 1.25rem;
    padding: 0.35rem 0.75rem;
    border-radius: 5px;
    background-color: ${colors.blue};
    margin-right: 0.5rem;

    &.disabled {
      background-color: ${colors.darkGrey};
      cursor: not-allowed;
    }
  }

  div.checkbox-wrapper {
    display: flex;
    margin-top: 1.5rem;
    align-items: center;

    .check-box-box {
      display: flex;
      margin-right: 0.5rem;
      border-radius: 5px;
      background-color: ${colors.blue};
      padding: 0.2rem;

      input {
        height: 1rem;
        width: 1rem;
      }
    }

    a {
      color: ${colors.blue};
    }
  }

  button.navigate-to-corporation {
    margin-top: 1rem;
    color: ${colors.blue};
    font-weight: 500;
  }

  @media screen and (max-width: ${breakPoint.sm}px) {
    padding: 1rem;

    p.title {
      font-size: 1.5rem;
    }

    button.facebook {
      font-size: 1.25rem;
    }

    div.checkbox-wrapper {
      p {
        font-size: 0.65rem;
      }
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
  signIn: bindActionCreators(signInEmployee, dispatch),
});

export default connect(null, mapDispatchToProps)(EmployeeLogin);
