import {addCommas} from "./add_commas";
import {remove_commas} from "./remove_commas";
import {workHours} from "./get-work-hours-total";
import React from "react";

export default function getInvoiceTotalForCorporation(shifts, booking , totalRecordsData = false) {
    let total = 0;

    shifts.map(s => {
        const { corporation_checkin_time, corporation_checkout_time, employee_checkin_time, employee_checkout_time, checkout_rejected_count_employee, checkout_rejected_count_corporation, last_checkout_by } = s;

        const employeeCheckedOut = employee_checkin_time && employee_checkout_time;
        const corporationCheckedOut = corporation_checkin_time && corporation_checkout_time;
    
        const checkoutApproved = employeeCheckedOut && corporationCheckedOut && (corporation_checkin_time == employee_checkin_time && corporation_checkout_time == employee_checkout_time);
    
        const checkoutApprovedByCompany = checkoutApproved && last_checkout_by == "corporation"
        const checkoutApprovedByEmployee = checkoutApproved && last_checkout_by == "employee"
    
        const adminCompanyCheckoutTimes = checkoutApprovedByCompany ? {start_time: s.corporation_checkin_time, end_time : s.corporation_checkout_time } : checkoutApprovedByEmployee ? {start_time: s.employee_checkin_time, end_time : s.employee_checkout_time } : s.admin_checkin_time && s.admin_checkout_time ? {start_time: s.admin_checkin_time, end_time : s.admin_checkout_time } : {start_time: booking.start_time, end_time : booking.end_time }; 

        if (
            s.admin_approve_invoice &&
            adminCompanyCheckoutTimes.start_time &&
            adminCompanyCheckoutTimes.end_time
        ) {

            let hours = parseFloat(workHours({start_time: adminCompanyCheckoutTimes.start_time, end_time: adminCompanyCheckoutTimes.end_time}));
            let hourlyWage = parseFloat(remove_commas(booking.hourly_wage));
            let fee = remove_commas(booking.fee);
            let transport = parseFloat(booking.transport_wage);

            total +=
                1.125 *
                (
                    (hourlyWage *
                        (
                            hours +
                            transport
                        )
                    )
                )
                +
                (
                    fee *
                    (
                        hours +
                        transport
                    )
                )
        }
    });

    return totalRecordsData ? total.toFixed(2) : addCommas(total.toFixed(2));
}
