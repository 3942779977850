// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

// Components
import staff_type_converter from "../../utilities/staff_type_converter";
import Spinner from "../ui/Spinner";
import ErrorBox from "../ui/ErrorBox";

// Utilities and config
import req from "../../utilities/request-utility";

// Actions
import { addToast } from "../../actions/uiActions";

// Styles
import colors from "../../styles/colors";
import { breakPoint } from "../../styles/breakPoints";

const CancelledEmployeeShifts = props => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [cancelledShifts, setCancelledShifts] = useState([]);

  useEffect(() => {
    req()(`shifts/corporation/cancelled-shifts/${props.employeeId}`)
      .then(({ data: cancelledShifts }) => {
        setCancelledShifts(cancelledShifts);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        props.addToast({
          message: "Noget gik galt...",
          type: "error",
          autoDismiss: true
        });
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={componentStyle()}>
      {loading && !error && <Spinner title={"Indlæser aflysninger..."} />}
      {!loading && error && <ErrorBox />}
      {!loading && !error && (
        <div>
          <p style={{ paddingLeft: "1rem", fontSize: "1.25rem" }}>
            Aflysninger
          </p>
          <table>
            <tbody>
              {cancelledShifts.map(
                ({
                  corporation,
                  cancellation_reason,
                  cancelled_at,
                  booking,
                  hours_before_booking_date
                }) => (
                  <tr>
                    <td>
                      <p style={{ whiteSpace: "noWrap" }}>
                        {moment(booking.date).format("ll")}
                      </p>
                    </td>
                    <td>
                      <p
                        className="cancelled-title"
                        style={{ whiteSpace: "noWrap" }}
                      >
                        {hours_before_booking_date &&
                          `${hours_before_booking_date} t`}
                      </p>
                      <p style={{ whiteSpace: "noWrap", fontStyle: "italic" }}>
                        ({moment(cancelled_at).format("ll")})
                      </p>
                    </td>
                    <td>
                      <div className="container-cancelled">
                        <p
                          style={{ fontWeight: "bold" }}
                        >{`${corporation.name}`}</p>
                        <p style={{ marginBottom: "0.125rem" }}>{`${
                          booking.arrangementType
                        } - ${staff_type_converter(
                          booking.staff_type
                        ).toLowerCase()}`}</p>
                        <p style={{ maxWidth: "20rem" }}>
                          {cancellation_reason}
                        </p>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem;
  max-height: 50rem;

  div.image-container {
    img {
      width: 3rem;
      height: 3rem;
    }
  }

  .container-cancelled {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: ${breakPoint.sm}px) {
    div.image-container {
      img {
        width: 4rem;
        height: 2rem;
      }
    }
  }

  p.cancelled-title {
    font-weight: bold;
    color: ${colors.red};
  }

  table {
    border-spacing: 1rem 0.5rem;
    border-collapse: separate;

    tr {
      vertical-align: top;
    }

    th {
      font-size: 0.75rem;
      text-align: start;
    }

    td {
      text-align: start;
      font-size: 0.75rem;
    }
    p {
      word-break: break-word;
    }

    @media screen and (max-width: ${breakPoint.sm}px) {
      p,
      th,
      td {
        font-size: 0.55rem;
      }

      & {
        border-spacing: 0.5rem 0.5rem;
      }
    }
  }

  div.reason-for-cancellation {
    max-width: 20rem;
  }

  .stars-container {
    display: flex;
  }
`;

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch)
});

export default connect(null, mapDispatchToProps)(CancelledEmployeeShifts);
