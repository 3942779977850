import {ADD_LOADING_SCREEN, REMOVE_LOADING_SCREEN} from "../actions/actionTypes";

const initialState = {
    loadingScreen: true,
    baseUrl: process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : "http://localhost:3030/api/",
    spinner: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REMOVE_LOADING_SCREEN:
            return {
                ...state,
                loadingScreen: false
            };

        case ADD_LOADING_SCREEN:
            return {
                ...state,
                loadingScreen: true
            };

        default:
            return state;
    }
}