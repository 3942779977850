// Modules
import React from "react";

// Utilities
import { css } from "emotion";
import colors from "../../../styles/colors";

const PriceSection = props => {
  const { action } = props;

  return (
    <div className={componentStyle(colors)}>
      {props.isSignedIn ? (
        <div className="price-container">
          <button onClick={() => action()}>{props.buttonLabel}</button>
        </div>
      ) : (
        <div className="price-container">
          <button onClick={() => action()}>Fortsæt</button>
        </div>
      )}
    </div>
  );
};

const componentStyle = colors => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  .price-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
      outline: none;
      background-color: ${colors.mediumBlue};
      color: ${colors.white};
      font-weight: 700;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      text-align: center;
    }

    button:hover {
      background-color: ${colors.blue};
    }
  }
`;

export default PriceSection;
