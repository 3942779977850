// Modules
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { css } from "emotion";

// Components
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import DateViewer from "../ui/DateViewer";

// Utilities and config
import staff_type_converter from "../../utilities/staff_type_converter";
import { workHours } from "../../utilities/get-work-hours-total";

// Styles
import colors from "../../styles/colors";
import bookingTypes from "../../config/bookingTypes";
import ViewDeprecatedBooking from "./viewDeprecatedBooking/ViewDeprecatedBooking";
import { bindActionCreators } from "redux";
import { showModal } from "../../actions/uiActions";
import { connect, useSelector } from "react-redux";
import { breakPoint } from "../../styles/breakPoints";

const Booking = (props) => {
  const history = useHistory();

  const { booking } = props;

  const onSubmitHandler = () => {
    // if checkout approved by admin lock the booking
    if (booking.status === bookingTypes.deprecated) {
      props.showModal({
        content: <ViewDeprecatedBooking bookingId={booking.id} />,
      });
    } else if (booking.status === bookingTypes.finished) {
      history.push(`/checkout-employees/${booking.id}`);
    } else {
      history.push("/viewBooking");
    }
  };

  const { takenOverCorporation } = useSelector((state) => state.auth);
  const { is_admin } = useSelector((state) => state.auth.user);

  return (
    <div
      onClick={() => onSubmitHandler()}
      className={`${componentStyle(colors)} ${props.className || ""}`}
      style={props.style}
    >
      <div className="inside-component">
        {props.waitingApproval && (
          <div
            style={{ position: "absolute", top: "-28px", fontWeight: "bold" }}
          >
            <p>{booking.corporation.name}</p>
          </div>
        )}

        <div className="date-container">
          <DateViewer date={booking.date} className="date-style" size={23} />
        </div>

        <div className="content-box">
          <div className="container-a">
            <p className="container-a-title">{booking.arrangement_type}</p>
            <div className="container-a-b">
              <div className="time-container">
                <p style={{ fontWeight: "bold" }}>{`Kl. ${
                  booking.start_time
                } - ${booking.end_time} (${workHours({
                  start_time: booking.start_time,
                  end_time: booking.end_time,
                })} timer)`}</p>
              </div>
              <div className="address-container">
                <MapMarkerIcon size={20} />
                <p className="text-xs text-gray-600">
                  {`${booking.address}, ${booking.zip_code} ${booking.city}`}
                </p>
              </div>
            </div>
          </div>

          <div className="container-b">
            <div className="container-a-b">
              <div className="booked-container">
                <div className="selected-staff-container">
                    <p style={{ fontWeight: "500", display: 'flex' }}>
                    { booking.language ? booking.language === "Engelsk" ? `🇬🇧 ` : booking.language === "Dansk" ? `🇩🇰 ` : `🇩🇰 🇬🇧` : ``}
                      {`${staff_type_converter(
                      booking.staff_type
                    ).toUpperCase()}`}</p>
                  <p
                    style={{ fontWeight: "500" }}
                  >{`${booking.number_of_staff}`}</p>
                </div>
                {booking.approved &&
                  booking.status !== bookingTypes.deprecated && (
                    <Fragment>
                      <div className="selected-staff-container">
                        <p>BOOKET</p>
                        <p>
                          {booking.selectedShifts ? booking.selectedShifts : 0}
                        </p>
                      </div>

                      {booking.status === bookingTypes.open && (
                        <div className="applied-staff-container">
                          <p>ANSØGERE</p>
                          <p>
                            {booking.appliedShifts ? booking.appliedShifts : 0}
                          </p>
                        </div>
                      )}
                    </Fragment>
                  )}
              </div>

              {!booking.approved && (
                <>
                  <p className="red-label">AFVENTER GODKENDELSE AF ADMIN</p>
                </>
              )}

              {booking.approved &&
                booking.selectedShifts !== booking.number_of_staff &&
                booking.status !== bookingTypes.finished &&
                booking.status !== "deprecated" && (
                  <div className="label-wrapper">
                    {(takenOverCorporation || is_admin) &&
                      booking.status === bookingTypes.open && (
                        <p
                          style={{ marginRight: "0.25rem" }}
                          className="light-grey-label"
                        >
                          ON
                        </p>
                      )}
                    <p className="yellow-label">GODKENDT - SØGER</p>
                  </div>
                )}

              {booking.approved &&
                booking.selectedShifts !== booking.number_of_staff &&
                booking.status !== bookingTypes.finished &&
                booking.status === "deprecated" && (
                  <>
                    <p className="green-label">Færdig</p>
                  </>
                )}

              {booking.approved &&
                booking.selectedShifts === booking.number_of_staff &&
                booking.status !== bookingTypes.finished && (
                  <div className="label-wrapper">
                    {(takenOverCorporation || is_admin) &&
                      booking.status === bookingTypes.open && (
                        <p
                          style={{ marginRight: "0.25rem" }}
                          className="light-grey-label"
                        >
                          ON
                        </p>
                      )}
                    <p className="green-label">BESAT</p>
                  </div>
                )}

              {/** ENDED BOOKINGS **/}
              {booking.approved &&
                booking.status === bookingTypes.finished &&
                booking.missing_check_out &&
                !booking.all_check_times_approved && (
                  <>
                    <p className="red-label">STEMPEL UD</p>
                  </>
                )}

              {(booking.approved &&
                booking.status === bookingTypes.finished &&
                !booking.missing_check_out &&
                !booking.all_check_times_approved &&
                !booking.missing_rating) ||
                (booking.all_check_times_approved && (
                  <>
                    <p className="green-label">FÆRDIG</p>
                  </>
                ))}

              {booking.approved &&
                booking.status === bookingTypes.finished &&
                !booking.missing_check_out &&
                !booking.all_check_times_approved &&
                booking.missing_rating && (
                  <>
                    <p className="yellow-label">ANGIV RATINGS</p>
                  </>
                )}

              {booking.all_check_times_approved && (
                <>
                  <p className="grey-label mt-2">FAKTURA SENDT</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const componentStyle = (colors) => css`
  position: relative;
  cursor: pointer;
  border-radius: 0.25rem;
  border: 1px solid ${colors.grey};
  background-color: ${colors.white};
  padding: 0.5rem;
  margin-top: 1.5rem;

  @media screen and (max-width: ${breakPoint.sm}px) {
    margin-top: 1rem;
  }

  &:hover {
    background-color: ${colors.mediumGrey};
  }

  p.date-detail {
    font-size: 1.7rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 1rem;
    }
  }

  .label-wrapper {
    display: flex;
    align-items: baseline;

    .on-label {
      margin-right: 0.25rem;
    }
  }

  .inside-component {
    display: flex;

    .date-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0.5rem;

      .date-style {
        .day,
        .month {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 12px;
          }
        }

        .year {
          left: 20px;
          bottom: 4px;

          @media screen and (max-width: ${breakPoint.sm}px) {
            bottom: 6px;
            font-size: 12px;
          }
        }

        .date {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 15px;
          }
        }
      }
    }

    .content-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-right: 1rem;

      .container-a {
        padding-top: 1rem;
        margin-left: 0.75rem;

        .container-a-title {
          font-size: 1.25rem;
          margin-bottom: 0.25rem;

          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 0.55rem;
          }
        }

        .container-a-b {
          .time-container {
            display: flex;
            align-items: baseline;
            margin-bottom: 0.25rem;

            p {
              font-size: 1rem;
              margin-right: 0.5rem;

              @media screen and (max-width: ${breakPoint.sm}px) {
                font-size: 0.55rem;
              }
            }
          }

          .wage-container {
            margin-bottom: 0.25rem;

            p {
              font-size: 0.75rem;
              color: ${colors.darkerGrey};
            }
          }

          .address-container {
            display: flex;
            align-items: center;

            p {
              font-size: 0.65rem;
              color: ${colors.darkerGrey};

              @media screen and (max-width: ${breakPoint.sm}px) {
                font-size: 0.55rem;
              }
            }

            svg {
              height: 1.25rem;
              width: 1.25rem;
              margin-right: 0.12rem;
            }

            @media screen and (max-width: ${breakPoint.sm}px) {
              svg {
                height: 0.85rem;
                width: 0.85rem;
              }
            }
          }
        }
      }

      .container-b {
        display: flex;
        padding-top: 1rem;
        align-items: center;

        @media screen and (max-width: ${breakPoint.sm}px) {
          padding-top: 0;
        }

        .container-a-b {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;

          .booked-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @media screen and (max-width: ${breakPoint.sm}px) {
              font-size: 0.55rem;
            }

            .staffs {
              margin-bottom: 0.25rem;
              text-align: end;
            }

            .selected-staff-container {
              display: flex;
              justify-content: space-between;
              width: 7rem;

              @media screen and (max-width: ${breakPoint.sm}px) {
                width: 4rem;
              }

              p {
                font-size: 0.875rem;

                @media screen and (max-width: ${breakPoint.sm}px) {
                  font-size: 0.55rem;
                }
              }
            }

            .applied-staff-container {
              display: flex;
              justify-content: space-between;
              width: 7rem;

              @media screen and (max-width: ${breakPoint.sm}px) {
                width: 4rem;
              }

              p {
                font-size: 0.875rem;
                margin-bottom: 0.5rem;
                color: ${colors.darkGrey};

                @media screen and (max-width: ${breakPoint.sm}px) {
                  font-size: 0.55rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .countdown-container {
    margin-top: 0.25rem;

    p {
      background-color: ${colors.darkGrey};
      color: ${colors.white};
      border-radius: 0.25rem;
      text-align: center;
      font-size: 0.65rem;
      font-weight: 700;
      padding: 0.25rem 0.5rem;
    }
  }

  .label-container,
  .light-grey-label,
  .grey-label,
  .green-label,
  .yellow-label,
  .red-label {
    text-align: center;
    padding: 0.25rem 1rem;
    min-width: 8rem;
    border-radius: 0.25rem;
    align-items: center;
    color: ${colors.white};
    font-weight: 600;
    font-size: 0.65rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.4rem;
      width: 4rem;
      min-width: 0;
    }
  }

  .green-label {
    background-color: ${colors.green};
  }

  .light-grey-label {
    background-color: ${colors.grey};
    min-width: 2rem;
  }

  .red-label {
    background-color: ${colors.red};
  }

  .grey-label {
    background-color: ${colors.darkerGrey};
  }

  .yellow-label {
    background-color: ${colors.custardYellow};
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(null, mapDispatchToProps)(Booking);
