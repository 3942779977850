// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Styles
import colors from "../../../styles/colors";

// Components
import RecordCircleOutlineIcon from "mdi-react/RecordCircleOutlineIcon";
import Spinner from "../../ui/Spinner";

const SkillsModal = props => {
  const [workSkills, setWorkSkills] = useState([
    { value: 0, skill_type: null },
    { value: 0, skill_type: null },
    { value: 0, skill_type: null },
    { value: 0, skill_type: null },
    { value: 0, skill_type: null }
  ]);
  const [step, setStep] = useState(0);
  const { loadingWorkSkill: loading } = useSelector(state => state.auth);

  const previousQuestion = () => {
    setStep(step - 1);
  };

  const nextQuestion = () => {
    if (step < 4) return setStep(step + 1);
    props.update(workSkills);
  };

  useEffect(() => {
    if (props.workSkills) setWorkSkills(props.workSkills);
  }, []);

  return (
    <div className={componentStyle()}>
      {loading && <Spinner title={"Gemmer..."} />}
      {!loading && (
        <>
          <div className="header-component">
            <p className="header-title">KOMPETENCER</p>
            <p className="sub-title">{props.workSkillQuestions.job}</p>
          </div>

          <p className="description-text">
            For at vi kan matche dig bedst muligt med vores restauranter, skal
            vi vide, hvad du kan <br />
            Svær ærligt - der er jobs til alle. Du kan altid ændre dit niveau i
            takt med at du udvikler dig
          </p>
          <hr className="divider" />
          <p className="question-title">
            {props.workSkillQuestions.title[step]}
          </p>
          <p className="question">
            {props.workSkillQuestions.questions[step].question}
          </p>

          <div
            onClick={() =>
              setWorkSkills(
                workSkills.map((w, i) =>
                  i !== step
                    ? w
                    : {
                        value: 1,
                        skill_type: props.workSkillQuestions.values[step]
                      }
                )
              )
            }
            className={
              workSkills[step].value === 1
                ? "selected-answer-container"
                : "not-selected-answer-container"
            }
          >
            <p className="answer-title">
              {props.workSkillQuestions.questions[step].answers[0]}
            </p>
            <div className="points-container">
              <div className="point-selected" />
              <div className="point-not-selected" />
              <div className="point-not-selected" />
              <div className="point-not-selected" />
              <div className="point-not-selected" />
            </div>
          </div>

          <div
            onClick={() =>
              setWorkSkills(
                workSkills.map((w, i) =>
                  i !== step
                    ? w
                    : {
                        value: 2,
                        skill_type: props.workSkillQuestions.values[step]
                      }
                )
              )
            }
            className={
              workSkills[step].value === 2
                ? "selected-answer-container"
                : "not-selected-answer-container"
            }
          >
            <p className="answer-title">
              {props.workSkillQuestions.questions[step].answers[1]}
            </p>
            <div className="points-container">
              <div className="point-selected" />
              <div className="point-selected" />
              <div className="point-not-selected" />
              <div className="point-not-selected" />
              <div className="point-not-selected" />
            </div>
          </div>

          <div
            onClick={() =>
              setWorkSkills(
                workSkills.map((w, i) =>
                  i !== step
                    ? w
                    : {
                        value: 3,
                        skill_type: props.workSkillQuestions.values[step]
                      }
                )
              )
            }
            className={
              workSkills[step].value === 3
                ? "selected-answer-container"
                : "not-selected-answer-container"
            }
          >
            <p className="answer-title">
              {props.workSkillQuestions.questions[step].answers[2]}
            </p>
            <div className="points-container">
              <div className="point-selected" />
              <div className="point-selected" />
              <div className="point-selected" />
              <div className="point-not-selected" />
              <div className="point-not-selected" />
            </div>
          </div>

          <div
            onClick={() =>
              setWorkSkills(
                workSkills.map((w, i) =>
                  i !== step
                    ? w
                    : {
                        value: 4,
                        skill_type: props.workSkillQuestions.values[step]
                      }
                )
              )
            }
            className={
              workSkills[step].value === 4
                ? "selected-answer-container"
                : "not-selected-answer-container"
            }
          >
            <p className="answer-title">
              {props.workSkillQuestions.questions[step].answers[3]}
            </p>
            <div className="points-container">
              <div className="point-selected" />
              <div className="point-selected" />
              <div className="point-selected" />
              <div className="point-selected" />
              <div className="point-not-selected" />
            </div>
          </div>

          <div
            onClick={() =>
              setWorkSkills(
                workSkills.map((w, i) =>
                  i !== step
                    ? w
                    : {
                        value: 5,
                        skill_type: props.workSkillQuestions.values[step]
                      }
                )
              )
            }
            className={
              workSkills[step].value === 5
                ? "selected-answer-container"
                : "not-selected-answer-container"
            }
          >
            <p className="answer-title">
              {props.workSkillQuestions.questions[step].answers[4]}
            </p>
            <div className="points-container">
              <div className="point-selected" />
              <div className="point-selected" />
              <div className="point-selected" />
              <div className="point-selected" />
              <div className="point-selected" />
            </div>
          </div>

          <hr className="divider" />

          <div className="process-container">
            <div className="inside-container">
              {step === 0 && (
                <>
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.grey} />
                  <RecordCircleOutlineIcon size={25} color={colors.grey} />
                  <RecordCircleOutlineIcon size={25} color={colors.grey} />
                  <RecordCircleOutlineIcon size={25} color={colors.grey} />
                </>
              )}
              {step === 1 && (
                <>
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.grey} />
                  <RecordCircleOutlineIcon size={25} color={colors.grey} />
                  <RecordCircleOutlineIcon size={25} color={colors.grey} />
                </>
              )}
              {step === 2 && (
                <>
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.grey} />
                  <RecordCircleOutlineIcon size={25} color={colors.grey} />
                </>
              )}
              {step === 3 && (
                <>
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.grey} />
                </>
              )}
              {step === 4 && (
                <>
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                  <RecordCircleOutlineIcon size={25} color={colors.blue} />
                </>
              )}
            </div>

            <div className="button-container">
              {step !== 0 && (
                <button className="back" onClick={() => previousQuestion()}>
                  TILBAGE
                </button>
              )}
              <button
                disabled={workSkills[step].value === 0}
                onClick={() => nextQuestion()}
                className={
                  workSkills[step].value === 0 ? "not-submit" : "submit"
                }
              >
                {step < 4 ? "VIDERE" : "BEKRÆFT"}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  min-width: 18rem;
  min-height: 4rem;

  div.header-component {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    p.header-title {
      margin-right: 0.5rem;
    }

    p.sub-title {
      font-size: 0.65rem;
      padding: 0.25rem;
      border-radius: 0.25rem;
      color: ${colors.white};
      background-color: ${colors.lightBlue};
      font-weight: bold;
    }
  }

  p.description-text {
    font-size: 0.65rem;
    margin-bottom: 0.75rem;
  }

  hr.divider {
    border-width: 1px;
    border-top-width: 0;
    margin-bottom: 1.25rem;
    border-color: ${colors.grey};
  }

  p.question-title {
    font-size: 0.75rem;
  }

  p.question {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  div.selected-answer-container,
  div.not-selected-answer-container {
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    background-color: ${colors.lightBlue};
    color: ${colors.white};
    border-radius: 5px;
    cursor: pointer;

    p.answer-title {
      font-size: 0.875rem;
      margin-bottom: 0.25rem;
      font-weight: bold;
    }

    div.points-container {
      display: flex;
      margin-left: 0.25rem;

      div.point-selected,
      div.point-not-selected {
        width: 0.75rem;
        height: 0.75rem;
        background-color: ${colors.black};
        margin-right: 0.5rem;
      }

      div.point-not-selected {
        background-color: ${colors.grey};
      }
    }
  }

  div.selected-answer-container:hover {
    background-color: ${colors.blue};
  }

  div.not-selected-answer-container {
    background-color: ${colors.mediumGrey};
    color: ${colors.black};
  }

  div.not-selected-answer-container:hover {
    background-color: ${colors.darkGrey};
  }

  div.process-container {
    display: flex;
    justify-content: space-between;

    div.inside-container {
      display: flex;
      align-items: center;
    }
  }

  div.button-container {
    button.back,
    button.not-submit,
    button.submit {
      padding: 0.5rem 0.75rem;
      border-radius: 5px;
      color: ${colors.white};
      background-color: ${colors.grey};
      cursor: no-drop;

      &.back {
        margin-right: 0.25rem;
        background-color: ${colors.medDarkGrey};
      }
    }

    button.submit {
      background-color: ${colors.lightBlue};
      cursor: pointer;
    }
    button.submit:hover {
      background-color: ${colors.blue};
    }
  }
`;

export default SkillsModal;
