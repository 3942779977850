// Libs
import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

// Utilities
import req from "../../../utilities/request-utility";

// Components
import Spinner from "../../ui/Spinner";
import ErrorBox from "../../ui/ErrorBox";

// Actions
import { updateWorkLocations } from "../../../actions/authActions";
import { addToast } from "../../../actions/uiActions";

// Styles
import colors from "../../../styles/colors";

const Zones = props => {
  const { user } = useSelector(state => state.auth);

  // State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [updatedWorkLocations, setUpdatedWorkLocations] = useState(
    user.workLocations
  );

  // Debounce function
  const [debounceUpdateZones] = useDebouncedCallback(async () => {
    setLoading(true);
    await req()
      .put("employees/work-locations", updatedWorkLocations)
      .then(({ data: workLocations }) => {
        setLoading(false);
        props.updateWorkLocations(workLocations);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
        props.addToast({
          message:
            "Kunne ikke gemme ændringerne. Hvis fejlen fortsætter, venligst kontakt JobButler",
          type: "warning",
          autoDismiss: true
        });
      });
  }, 100);

  // Update workLocations and debounce
  const addWorkLocation = loc => {
    if (
      updatedWorkLocations.some(
        location =>
          location.country === loc.country &&
          location.area_code === loc.area_code
      )
    ) {
      const tempWorkLocations = updatedWorkLocations.filter(
        location => location.area_code !== loc.area_code
      );
      setUpdatedWorkLocations(tempWorkLocations);
      debounceUpdateZones();
    } else {
      setUpdatedWorkLocations([...updatedWorkLocations, loc]);
      debounceUpdateZones();
    }
  };

  return (
    <div className={componentStyle()}>
      {!loading && error && <ErrorBox />}
      {loading && !error && <Spinner title={"opdater ændringer..."} />}
      {!loading && !error && (
        <div className="zones-container">
          <button
            onClick={() =>
              addWorkLocation({ country: "denmark", area_code: "northZealand" })
            }
            className={
              updatedWorkLocations.some(
                location =>
                  location.country === "denmark" &&
                  location.area_code === "northZealand"
              )
                ? "selected"
                : "not-selected"
            }
          >
            Nordsjælland
          </button>
          <div className="box-divider">
            <button
              onClick={() =>
                addWorkLocation({
                  country: "denmark",
                  area_code: "westZealand"
                })
              }
              className={
                updatedWorkLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "westZealand"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Vestsjælland
            </button>
            <button
              onClick={() =>
                addWorkLocation({ country: "denmark", area_code: "copenhagen" })
              }
              className={
                updatedWorkLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "copenhagen"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              København
            </button>
          </div>
          <button
            onClick={() =>
              addWorkLocation({ country: "denmark", area_code: "southZealand" })
            }
            className={
              updatedWorkLocations.some(
                location =>
                  location.country === "denmark" &&
                  location.area_code === "southZealand"
              )
                ? "selected"
                : "not-selected"
            }
          >
            Sydsjælland
          </button>
          <div className="box-divider">
            <button
              onClick={() =>
                addWorkLocation({
                  country: "denmark",
                  area_code: "northernJutland"
                })
              }
              className={
                updatedWorkLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "northernJutland"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Nordjylland
            </button>
            <button
              onClick={() =>
                addWorkLocation({
                  country: "denmark",
                  area_code: "eastJutland"
                })
              }
              className={
                updatedWorkLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "eastJutland"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Østjylland
            </button>
          </div>
          <div className="box-divider">
            <button
              onClick={() =>
                addWorkLocation({
                  country: "denmark",
                  area_code: "westJutland"
                })
              }
              className={
                updatedWorkLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "westJutland"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Vestjylland
            </button>
            <button
              onClick={() =>
                addWorkLocation({
                  country: "denmark",
                  area_code: "southernJutland"
                })
              }
              className={
                updatedWorkLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "southernJutland"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Sønderjylland
            </button>
          </div>
          <div className="box-divider">
            <button
              onClick={() =>
                addWorkLocation({ country: "denmark", area_code: "fyn" })
              }
              className={
                updatedWorkLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "fyn"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Fyn og øerne
            </button>
            <button
              onClick={() =>
                addWorkLocation({ country: "denmark", area_code: "bornholm" })
              }
              className={
                updatedWorkLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "bornholm"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Bornholm
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const componentStyle = () => css`
  border-radius: 0.25rem;
  overflow: hidden;
  margin: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  div.zones-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    width: 100%;
  }

  button.selected,
  button.not-selected {
    outline: none;
    color: ${colors.black};
    border: 1px solid ${colors.darkerGrey};
    border-radius: 5px;
    font-size: 0.75rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 1.25rem 0;
    margin-bottom: 1.25rem;
    font-weight: bold;
    flex: 1;
  }

  button.not-selected:hover {
    background-color: ${colors.mediumGrey};
  }

  button.selected {
    color: ${colors.white};
    background-color: ${colors.primeBlue};
  }

  button.selected:hover {
    background-color: ${colors.darkerPrimeBlue};
  }

  div.box-divider {
    display: flex;
    justify-content: space-between;
  }
`;

const mapDispatchToProps = dispatch => ({
  updateWorkLocations: bindActionCreators(updateWorkLocations, dispatch),
  addToast: bindActionCreators(addToast, dispatch)
});

export default connect(null, mapDispatchToProps)(Zones);
