// Action types
import {
  END_OF_CORPORATIONS,
  GET_CORPORATIONS,
  GET_CORPORATIONS_FAILURE,
  GET_CORPORATIONS_SUCCESS,
  GET_CORPORATIONS_WITH_NEW_SEARCH_TERM_SUCCESS,
  GET_CORPORATIONS_WITH_SAME_SEARCH_TERM_SUCCESS,
  GET_EMPLOYEES_WITH_NEW_SEARCH_TERM_SUCCESS,
  GET_EMPLOYEES_WITH_SAME_SEARCH_TERM_SUCCESS,
  RESET_CORPORATIONS
} from "../actions/actionTypes";

const initialState = {
  corporations: [],
  loading: false,
  error: false,
  endOfFeed: false,
  searchTerm: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CORPORATIONS:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_CORPORATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        corporations: [...state.corporations, ...action.payload.corporations],
      };

    case GET_CORPORATIONS_WITH_SAME_SEARCH_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        corporations: [...state.corporations, ...action.payload.corporations],
        searchTerm: action.payload.searchTerm,
      };
    case GET_CORPORATIONS_WITH_NEW_SEARCH_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        corporations: [...action.payload.corporations],
        searchTerm: action.payload.searchTerm,
        endOfFeed: false,
      };

    case GET_CORPORATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case END_OF_CORPORATIONS:
      return {
        ...state,
        loading: false,
        error: false,
        endOfFeed: true
      };
    case RESET_CORPORATIONS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
