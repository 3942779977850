import {REMOVE_TEMPORARY_SAVED_BOOKING, TEMPORARY_SAVE_BOOKING} from "./actionTypes";

export function temporarySaveBooking(booking) {
    return dispatch => {
        dispatch({
            type: TEMPORARY_SAVE_BOOKING,
            payload: booking
        })
    }
}

export function removeTemporaryBooking() {
    return dispatch => {
        dispatch({
            type: REMOVE_TEMPORARY_SAVED_BOOKING
        })
    }
}