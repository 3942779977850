// Libs
import React from "react";
import moment from "moment";
import { css } from "emotion";

// Style
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import CardBulletedSettingsIcon from "mdi-react/CardBulletedSettingsIcon";
import CarHatchbackIcon from "mdi-react/CarHatchbackIcon";
import colors from "../../../styles/colors";

// Components
// import DatePicker from "react-modern-calendar-datepicker";
import DatePickerUI from "../../ui/DatePickerUI";

// Utilities

const InformationAboutYou = props => {
  const { userInformation, setUserInformation, validation } = props;
  return (
    <div className={componentStyle()}>
      <p className="title">Færdiggør din profil</p>

      <div className="info-about-you-container">
        <p className="title-header">Fortæl os lidt om dig selv</p>

        <div className="city-and-zip">
          <div>
            <label>Hvilken by bor du i?</label>
            <input
              name="city"
              value={userInformation.updatedUser.city}
              onChange={e =>
                setUserInformation({
                  ...userInformation,
                  updatedUser: {
                    ...userInformation.updatedUser,
                    city: e.target.value
                  }
                })
              }
            />
            <p className="invalid">{validation.city.message}</p>
          </div>
          <div>
            <label>Telefon nr.</label>
            <input
              name="phone"
              value={userInformation.updatedUser.phone}
              onChange={e =>
                setUserInformation({
                  ...userInformation,
                  updatedUser: {
                    ...userInformation.updatedUser,
                    phone: e.target.value
                  }
                })
              }
            />
            <p className="invalid">{validation.phone.message}</p>
          </div>
          <div>
            <label>E-mail</label>
            <input
              name="email"
              value={userInformation.updatedUser.email}
              onChange={e =>
                setUserInformation({
                  ...userInformation,
                  updatedUser: {
                    ...userInformation.updatedUser,
                    email: e.target.value
                  }
                })
              }
            />
            <p className="invalid">{validation.email.message}</p>
          </div>
        </div>

        <div className="date-container">
          <label>Fødselsdag</label>
          <DatePickerUI
            defaultValue={moment(userInformation.birth_date).format(
              "YYYY-MM-DD"
            )}
            onDateChange={date => {
              setUserInformation({
                ...userInformation,
                updatedUser: {
                  ...userInformation.updatedUser,
                  birth_date: moment(date, "YYYY-MM-DD")
                }
              });
            }}
          />
          <p className="invalid">{validation.birth_date.message}</p>
        </div>

        <div className="about-you">
          <div>
            <label>Derfor skal i hyre mig </label>

            <textarea
              name="description"
              style={{ backgroundColor: colors.white }}
              value={userInformation.updatedUser.description}
              onChange={e =>
                setUserInformation({
                  ...userInformation,
                  updatedUser: {
                    ...userInformation.updatedUser,
                    description: e.target.value
                  }
                })
              }
              maxLength="255"
              rows="5"
              placeholder="Fortæl kort om, hvorfor vi skal hyre dig?"
            />
            <p className="invalid">{validation.description.message}</p>
          </div>
        </div>

        <div className="driver-license">
          <label>Marker følgende, hvis du er i besiddelse af: </label>
          <div className="card-container">
            <div className="card">
              <button
                onClick={() =>
                  setUserInformation({
                    ...userInformation,
                    updatedUser: {
                      ...userInformation.updatedUser,
                      has_driver_license: !userInformation.updatedUser
                        .has_driver_license
                    }
                  })
                }
                className={
                  userInformation.updatedUser.has_driver_license
                    ? "selected"
                    : "not-selected"
                }
              >
                JEG HAR KØREKORT
              </button>
              <div className="icon">
                <CardBulletedSettingsIcon size={27} />
              </div>
            </div>

            <div className="card">
              <button
                onClick={() =>
                  setUserInformation({
                    ...userInformation,
                    updatedUser: {
                      ...userInformation.updatedUser,
                      owns_car: !userInformation.updatedUser.owns_car
                    }
                  })
                }
                className={
                  userInformation.updatedUser.owns_car
                    ? "selected"
                    : "not-selected"
                }
              >
                JEG HAR BIL
              </button>
              <div className="icon">
                <CarHatchbackIcon size={30} />
              </div>
            </div>
          </div>
        </div>

        <div className="btn-container">
          <button onClick={() => props.moveBackward()} className="back">
            TILBAGE
          </button>
          <button onClick={() => props.submitHandler()} className="submit">
            VIDERE
          </button>
        </div>
      </div>
    </div>
  );
};

const componentStyle = () => css`
  .date-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }

  p.title-header {
    text-align: start;
    width: 100%;
    margin-bottom: 0.5rem;
    font-size: 1.11rem;
    font-weight: 500;
  }

  p.title {
    font-size: 1.25rem;
    margin: 1rem 0 0.5rem;
    text-align: center;
  }

  p.sub-title {
    font-size: 0.75rem;
    text-align: start;
    margin-bottom: 0.5rem;
  }

  p.invalid {
    font-size: 0.75rem;
    color: ${colors.red};
    margin-left: 0.5rem;
  }

  div.info-about-you-container {
    border-radius: 0.25rem;
    overflow: visible;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem;
    margin: 1rem auto;
    width: 20rem;

    div.address {
      display: flex;
      width: 100%;
      margin-bottom: 0.75rem;

      div.box {
        width: 100%;
      }
    }

    div.city-and-zip {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 0.75rem;
      flex-direction: column;

      div:first-child {
        flex: 1;
      }

      div:last-of-type {
        flex: 1;
      }
    }

    div.birthday-and-phone {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 0.75rem;

      div:first-child {
        flex: 2;
        margin-right: 1rem;
      }

      div:last-of-type {
        flex: 1;
      }
    }

    div.about-you {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 0.75rem;

      div {
        width: 100%;
      }
    }

    div.driver-license {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;

      div.card-container {
        display: flex;
        width: 100%;
        flex-direction: column;

        div.card {
          display: flex;
          position: relative;
          width: 100%;

          div.icon {
            pointer-events: none;
            position: absolute;
            top: 0;
            bottom: 0;
            margin-right: 1.5rem;
            right: 0;
            padding-bottom: 0.25rem;
            display: flex;
            align-items: center;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }

          button.selected,
          button.not-selected {
            font-size: 0.65rem;
            height: 3.2rem;
            width: 100%;
            background-color: ${colors.primeBlue};
            color: ${colors.white};
            margin: 0.5rem;
            border: 1px solid ${colors.grey};
            outline: none;
            padding-right: 2rem;
            font-weight: bold;
            border-radius: 5px;
          }

          button.selected:hover {
            background-color: ${colors.darkerPrimeBlue};
          }

          button.not-selected {
            color: ${colors.black};
            font-weight: 400;
            background-color: ${colors.lightGrey};
          }

          button.not-selected:hover {
            background-color: ${colors.grey};
          }
        }
      }
    }
  }

  label {
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: ${colors.black};
    font-size: 0.6rem;
    margin-bottom: 0.25rem;
    margin-left: 0.25rem;
  }

  input,
  textarea {
    cursor: text;
    display: block;
    font-size: 0.75rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
  }

  input:hover,
  textarea:hover {
    border: 1px solid ${colors.black};
  }

  input::placeholder,
  textarea::placeholder {
    color: ${colors.darkerGrey};
  }

  div.btn-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    button.submit,
    button.back,
    button.disabled {
      padding: 0.5rem 2rem;
      color: ${colors.white};
      border-radius: 5px;
      outline: none;
      background-color: ${colors.lightBlue};
      margin: 0.5rem;
    }

    button.submit:hover {
      background-color: ${colors.blue};
    }
    button.disabled {
      cursor: no-drop;
    }

    button.disabled,
    button.back {
      background-color: ${colors.grey};
    }

    button.back:hover {
      background-color: ${colors.darkGrey};
    }
  }
`;

export default InformationAboutYou;
