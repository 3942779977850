// Libs
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { css } from "emotion";

// Actions
import { addToast } from "../../../actions/uiActions";

// Utilities
import req from "../../../utilities/request-utility";

// Components
import BookingDescription from "../../corporation/viewBooking/BookingDescription";
import PageContainer from "../../ui/PageContainer";
import NavigateBackArrow from "../../ui/NavigateBackArrow";

// styles
import colors from "../../../styles/colors";
import Spinner from "../../ui/Spinner";

const ReviewBooking = props => {
  const history = useHistory();

  // Params
  const { bookingId } = props.match.params;
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    req()(`corporations/bookings/${bookingId}`)
      .then(({ data: booking }) => {
        setBooking(booking);
        setLoading(false);
      })
      .catch(() => {
        props.addToast({
          message: "Kunne ikke hente oplysningerne..",
          type: "error"
        });
      });
    // eslint-disable-next-line
  }, []);

  const approveBooking = () => {
    req()
      .put(`admin/bookings/${bookingId}/approve-booking/`)
      .then(() => {
        history.push("/admin/approve-booking-overview");
      })
      .catch(err => {
        props.addToast({
          message: "Noget gik galt..",
          type: "error"
        });
      });
  };

  const dontApproveBooking = () => {
    req()
      .put(`admin/bookings/${bookingId}/not-approve-booking`)
      .then(() => {
        history.push("/admin/approve-booking-overview");
      })
      .catch(err => {
        props.addToast({
          message: "Noget gik galt..",
          type: "error"
        });
      });
  };

  return (
    <PageContainer showNavigation={true}>
      <NavigateBackArrow
        navigateURL={() => history.push("/admin/approve-booking-overview")}
      />
      {!loading && (
        <div className={componentStyle(colors)}>
          <BookingDescription booking={booking} />
          <div className="second-div">
            <div
              onClick={() => history.push(`/editbooking/${bookingId}?review=1`)}
              className="box"
            >
              <p>REDIGER BOOKING</p>
            </div>
          </div>

          <div className="btn-container">
            <button onClick={() => dontApproveBooking()} className="cancel-btn">
              AFVIS/SLET
            </button>
            <button onClick={() => approveBooking()} className="accept-btn">
              GODKEND
            </button>
          </div>
        </div>
      )}
      {loading && <Spinner title={"Henter booking..."} />}
    </PageContainer>
  );
};

const componentStyle = colors => css`
  .btn-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 1.25rem;

    .cancel-btn {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      background-color: ${colors.darkRed};

      width: 16rem;
      border-radius: 0.25rem;
      color: ${colors.white};
      font-weight: 700;
    }
    .cancel-btn:hover {
      background-color: ${colors.red};
    }

    .accept-btn {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      background-color: ${colors.green};
      width: 16rem;
      color: ${colors.white};
      font-weight: 700;
    }
    .accept-btn:hover {
      background-color: ${colors.darkerGreen};
    }
  }

  .second-div {
    display: flex;
    width: 100%;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    justify-content: flex-end;
  }

  .box {
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: ${colors.white};

    p {
      padding: 0.25rem 1rem;
      font-size: 0.75rem;
      font-weight: bold;
      border: 1px solid ${colors.darkerGrey};
      border-radius: 3px;
      text-decoration: underline;
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch)
});

export default connect(null, mapDispatchToProps)(ReviewBooking);
