// Libs
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { css } from "emotion";

// Components
import BookingDescription from "./BookingDescription";
import StaffSelection from "./StaffSelection";
import CancelBookingModal from "../CancelBookingModal";
import PageContainer from "../../ui/PageContainer";
import Spinner from "../../ui/Spinner";
import NavigateBackArrow from "../../ui/NavigateBackArrow";

// Utilities
import req from "../../../utilities/request-utility";

// Styles
import colors from "../../../styles/colors";

// Actions
import { addToast, showModal } from "../../../actions/uiActions";
import ErrorBox from "../../ui/ErrorBox";
import { breakPoint } from "../../../styles/breakPoints";

const ViewBooking = (props) => {
  const history = useHistory();

  // Params
  const { bookingId } = props.match.params;

  const [loading, setLoading] = useState(true);
  const [booking, setBooking] = useState({});
  const [error, setError] = useState(false);

  const [shifts, setShifts] = useState({
    appliedShifts: [],
    selectedShifts: [],
    unwantedShifts: [],
    doubleBookedShifts: [],
    grayShifts: [],
    sickShifts: [],
    removedShifts: [],
    bookedStaff: 0,
    loading: true,
  });

  // Redux state
  const { is_admin } = useSelector((state) => state.auth.user);
  const { takenOverCorporation } = useSelector((state) => state.auth);

  useEffect(() => {
    (async function getContent() {
      getBooking();
      getShifts();
    })();
    // eslint-disable-next-line
  }, []);

  const getBooking = () => {
    req()(`corporations/bookings/${bookingId}`)
      .then(({ data: booking }) => {
        setBooking(booking);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
        props.addToast({
          message: "Kunne ikke hente oplysningerne...",
          type: "error",
          autoDismiss: true,
        });
      });
  };

  const getShifts = () => {
    try {
      // Gets the shifts shifts
      req()
        .get(`shifts/corporation/${bookingId}`)
        .then(({ data: shiftContent }) => {
          setShifts({
            ...shifts,
            appliedShifts: shiftContent.applied || [],
            selectedShifts: shiftContent.selected || [],
            unwantedShifts: shiftContent.unwanted || [],
            doubleBookedShifts: shiftContent.double || [],
            grayShifts: shiftContent.gray || [],
            sickShifts: shiftContent.sick || [],
            removedShifts: shiftContent.removed || [],
            bookedStaff: shiftContent.bookedStaff,
            loading: false,
          });
        });
    } catch (err) {
      props.addToast({
        message: "Kunne ikke hente vagterne",
        type: "error",
      });
    }
  };

  const changeBookingStatus = (status) => {
    setLoading(true);
    req()
      .put(`corporations/bookings/${booking.id}/change-status`, status)
      .then(() => {
        (async function getContent() {
          getBooking();
          getShifts();
        })();
        setLoading(false);
        props.addToast({
          message:
            status.status === "open"
              ? "Bookingen er åben!"
              : "Bookingen er lukket!",
          type: "success",
          autoDismiss: true,
        });
      })
      .catch(() => {
        setLoading(false);
        props.addToast({
          message: "Kunne ikke ændre bookingens status..",
          type: "error",
          autoDismiss: true,
      });
      });
  };
  const sendBookingList = () => {
    setLoading(true);
    req()
      .post(`corporations/bookings/${booking.id}/send-booking-list`)
      .then(() => {
        setLoading(false);
        props.addToast({
          message: "Bookingliste sendt!",
          type: "success",
          autoDismiss: true,
        });
      })
      .catch(() => {
        setLoading(false);
        props.addToast({
          message: "Kunne ikke ændre bookingens status..",
          type: "error",
          autoDismiss: true,
        });
      });
  };

  const deleteBooking = () => {
    if (shifts.loading || shifts.selectedShifts.length > 0) {
      props.addToast({
        message:
          "Det er ikke muligt at slette en booking med valgte medarbejder til jobbet. Fjern alle medarbejder for at slette bookingen",
        type: "error",
        autoDismiss: true,
      });
      return;
    }

    props.showModal({
      content: <CancelBookingModal bookingId={bookingId} />,
    });
  };

  const updateShiftStatus = (shiftId, status, reason = null) => {
    if (
      shifts.bookedStaff === booking.number_of_staff &&
      status === "selected"
    ) {
      props.addToast({
        message:
          "Du kan ikke tilføje flere til ansatte. Har du brug for flere ansatte, så kan du redigere bookingen under 'Rediger booking'",
        type: "warning",
        autoDismiss: true,
      });
    } else {
      setShifts({...shifts, loading: true});

      req()
        .put(`corporations/shifts/${shiftId}?shiftType=${status}`, { reason })
        .then(() => {
          getShifts();
        })
        .catch(() => {
          props.addToast({
            message: "Kunne ikke gemme dit valg",
            type: "error",
          });
        });
    }
  };
  return (
    <PageContainer showNavigation={true}>
      <NavigateBackArrow navigateURL={() => history.push("/")} />
      <div className={componentStyle(colors)}>
        {loading && !error && <Spinner size={45} title={"Indlæser..."} />}
        {!loading && error && <ErrorBox />}
        {!loading && !error && (
          <>
            <BookingDescription booking={booking} />
            <div className="options-container">
              <div className="first-div" />
              <div className="second-div">
                <div
                  onClick={() => history.push(`/editbooking/${bookingId}`)}
                  className="box"
                >
                  <p>REDIGER BOOKING</p>
                </div>
                <div className="box" onClick={deleteBooking}>
                  <p>SLET BOOKING</p>
                </div>
                {booking.status === "open" &&
                  booking.approved &&
                  (is_admin || takenOverCorporation) && (
                    <div
                      onClick={() => changeBookingStatus({ status: "closed" })}
                      className="box open-booking "
                    >
                      <p>LUK BOOKING</p>
                    </div>
                  )}
                {booking.status === "closed" &&
                  booking.approved &&
                  (is_admin || takenOverCorporation) && (
                    <div
                      onClick={() => changeBookingStatus({ status: "open" })}
                      className="box closed-booking"
                    >
                      <p>ÅBEN BOOKING</p>
                    </div>
                  )}
              </div>
            </div>

            {booking.approved && (
              <StaffSelection
                updateShiftStatus={updateShiftStatus}
                shifts={shifts}
                booking={booking}
                sendBookingList={sendBookingList}
              />
            )}
          </>
        )}
      </div>
    </PageContainer>
  );
};

const componentStyle = (colors) => css`
  height: 100%;
  width: 100%;

  .options-container {
    display: flex;

    .first-div {
      width: 6.4rem;
      height: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 0;
        height: 0;
      }
    }

    .second-div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-right: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        padding: 0.5rem 1rem;
      }
    }

    .box {
      cursor: pointer;
      border-radius: 0.25rem;
      background-color: ${colors.white};

      p {
        padding: 0.25rem 1rem;
        font-size: 0.75rem;
        font-weight: bold;
        border: 1px solid ${colors.darkerGrey};
        border-radius: 3px;
        text-decoration: underline;

        @media screen and (max-width: ${breakPoint.sm}px) {
          font-size: 0.5rem;
        }
      }
    }

    .box:hover {
      background-color: ${colors.lightGrey};
    }
  }

  .open-booking,
  .closed-booking {
    background-color: ${colors.primeBlue} !important;

    p {
      color: ${colors.white};
      text-decoration: none !important;
    }
  }
  .open-booking:hover {
    background-color: ${colors.darkerPrimeBlue};
  }

  .closed-booking {
    background-color: ${colors.darkRed} !important;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(null, mapDispatchToProps)(ViewBooking);
