export default staffType => {
  const type = staffType;

  switch (type) {
    case "waiter":
      return "Tjener";
    case "bartender":
      return "Bartender";
    default:
      return staffType;
  }
};
