// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import ReactStars from "react-stars";

// Styles
import colors from "../../../styles/colors";
import noProfilePicture from "../../../static/noProfilePicture.png";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";

// Utilities and config
import { hours, minutes } from "../../employee/shiftsOverview/config";
import { workHours } from "../../../utilities/get-work-hours-total";

// Components
import Button_DEPRECATED from "../../ui/Button_DEPRECATED";
import SingleImageContainer from "../../ui/SingleImageContainer";
import { bindActionCreators } from "redux";
import { showImageContainer } from "../../../actions/uiActions";
import { connect } from "react-redux";

const CheckOutEmployeeBox = props => {
  const {
    id: employee_id,
    first_name,
    baseURL_image,
    profile_image,
    last_name,
    city,
    age
  } = props.shift.employee;

  const {
    id,
    corporation_checkin_time,
    corporation_checkout_time,
    employee_checkin_time,
    employee_checkout_time,
    comment,
    rating,
    time_comment_by_corporation,
    time_comment_by_employee,
    last_checkout_by,
    first_checkout_by,
    checkout_rejected_count_corporation,
    checkout_rejected_count_employee
  } = props.shift;

  const { isHistory = false } = props;
  // Component state
  const checkout_time = last_checkout_by == "employee" ? employee_checkout_time || corporation_checkout_time || '' : corporation_checkout_time || employee_checkout_time || ''
  const checkin_time = last_checkout_by == "employee" ? employee_checkin_time || corporation_checkin_time || props.booking.start_time : corporation_checkin_time || employee_checkin_time || props.booking.start_time;

  const [checkout, setCheckout] = useState({
    rating: rating,
    corporation_checkout_time: checkout_time,
    corporation_checkin_time: checkin_time,
    comment: comment,
    time_comment_by_corporation: time_comment_by_corporation || "",
    first_checkout_by
  });

  useEffect(() => {
    checkout.corporation_checkin_time && checkout.corporation_checkout_time && props.setShiftsChanges({id, employee_id , ...checkout});
  }, [checkout])
  const checkout_time_updated = (checkin_time != checkout.corporation_checkin_time || checkout_time != checkout.corporation_checkout_time);
  useEffect(() => {
    setCheckout({
      ...checkout,
      checkout_rejected_count_corporation: checkout_time_updated && employee_checkin_time ? checkout_rejected_count_corporation + 1 : checkout_rejected_count_corporation,
      send_text: checkout_time_updated && employee_checkin_time ? true : false
    })
  }, [checkout_time_updated])
  const {checkout_to, checkout_from, comment_rating, comment_time, rating: autofill_rating} = props.autoFillData || {};

  useEffect(() => {

    setCheckout({
      ...checkout,
      rating: autofill_rating || checkout.rating,
      corporation_checkout_time: checkout_to || checkout.corporation_checkout_time,
      corporation_checkin_time: checkout_from || checkout.corporation_checkin_time,
      comment: comment_rating || checkout.comment,
      time_comment_by_corporation: comment_time || checkout.time_comment_by_corporation,
    })
  }, [checkout_to, checkout_from, comment_rating, comment_time, autofill_rating])
  const checkInTime = checkout.corporation_checkin_time.split(':');
  const checkOutTime = checkout.corporation_checkout_time.split(':');
  const checkInTimeHour = checkInTime[0] || '';
  const checkInTimeMinute = checkInTime[1] || '';
  const checkOutTimeHour = checkOutTime[0] || '';
  const checkOutTimeMinute = checkOutTime[1] || '';
  const corporationCheckedOut = corporation_checkin_time && corporation_checkout_time;
  const employeeCheckedOut = employee_checkin_time && employee_checkout_time;
  
  // no one checked out
  let checkoutBoxParams = { statusBarColor: "#4A4A4A", statusBarBGColor: "#F6E61C", statusbarText: "Stempel ud", buttonColor: "#4A4A4A", buttonBGColor: "#F6E61C", buttonText: `Stempel ${first_name} ud`, checkedOut: false, displayCommentField: false, headerText: "har stemplet ud som vist herunder:", timeCommentLabel: "Forklar kort, hvorfor du ikke godkender tiden?", earlyCheckoutBtns: false, disableDropdowns: false }
  if (checkout_time && checkout_time_updated) {
    // checkout updated
    if (checkout_rejected_count_employee) {
      checkoutBoxParams = { ...checkoutBoxParams, statusBarColor: "#4A4A4A", statusBarBGColor: "#F6E61C", statusbarText: "AFVIST / STEMPEL UD", buttonColor: "#4A4A4A", buttonBGColor: "#F5717F", buttonText: `STEMPEL ${first_name} UD`, checkedOut: false, displayCommentField: true }
    } else {
      checkoutBoxParams = { ...checkoutBoxParams, statusBarColor: "#fff", statusBarBGColor: "#F5717F", statusbarText: "STEMPLE UD", buttonColor: "#fff", buttonBGColor: "#F5717F", buttonText: "AFVIS TID - STEMP UD", checkedOut: false, displayCommentField: true }
    }
  }
  else if (corporationCheckedOut && employeeCheckedOut && (corporation_checkin_time != employee_checkin_time || corporation_checkout_time != employee_checkout_time)) {
    // checkout rejected
    if (checkout_rejected_count_corporation) {
      checkoutBoxParams = { ...checkoutBoxParams, statusBarColor: "#fff", statusBarBGColor: "#417505", statusbarText: "AFVIST NY TID", buttonColor: "#fff", buttonBGColor: "#979797", buttonText: "Opdater", checkedOut: true, headerText: "har afvist og stemplet ny tid:" }
    }
    else {
      checkoutBoxParams = { ...checkoutBoxParams, statusBarColor: "#fff", statusBarBGColor: "#F5717F", statusbarText: "GODKEND El. AFVIS NY TID", buttonColor: "#fff", buttonBGColor: "#979797", buttonText: "Opdater", checkedOut: false, displayCommentField: true, timeCommentLabel: "Knyt en kommentar til check-ud, hvis nødvendigt:", earlyCheckoutBtns: true }
    }
  }
  else if (corporationCheckedOut && employeeCheckedOut) {
    // checkout approved
    checkoutBoxParams = { ...checkoutBoxParams, statusBarColor: "#fff", statusBarBGColor: "#417505", statusbarText: checkout_rejected_count_corporation ? "AFVIST / GODKENDT" : checkout_rejected_count_employee ? "GODKEND NY TID" : "GODKENDT", buttonColor: "#fff", buttonBGColor: "#979797", buttonText: "Opdater", checkedOut: true }
  
  }
  else if (employeeCheckedOut) {
    // only employee checkout
    checkoutBoxParams = { ...checkoutBoxParams, statusBarColor: "#fff", statusBarBGColor: "#F5717F", statusbarText: "Godkend", buttonColor: "#fff", buttonBGColor: "#417505", buttonText: `Godkend ${first_name} Tider`, checkedOut: false }
  }
  else if (corporationCheckedOut) {
    // only company checkout
    checkoutBoxParams = { ...checkoutBoxParams, statusBarColor: "#4A4A4A", statusBarBGColor: "#F6E61C", statusbarText: "Stempelt ud", buttonColor: "#fff", buttonBGColor: "#979797", buttonText: "Opdater", checkedOut: true }
  }
  if (props.booking.all_check_times_approved) {
    checkoutBoxParams = { ...checkoutBoxParams, disableDropdowns: true}
  }
  const { statusBarBGColor, statusBarColor, statusbarText, buttonText, buttonBGColor, buttonColor, checkedOut, displayCommentField, headerText, timeCommentLabel, earlyCheckoutBtns, disableDropdowns } = checkoutBoxParams;
  const workingHours = workHours({start_time: checkout.corporation_checkin_time, end_time: checkout.corporation_checkout_time, changeTimeFormat: true});
  const employeeCheckoutHours = workHours({start_time: employee_checkin_time, end_time: employee_checkout_time, changeTimeFormat: true});

  const transportWageConverter = {"1.5": "+1,5 time", "0.5": "+30 min.", "1" : "+1 time", "2": "+2 time"}

  let transport_wage = transportWageConverter[props.booking.transport_wage];


  return (
    <div className={`${componentStyle(colors)}`}>
      {/** 1. Section **/}
      <div style={{ display: "flex", padding: '0.5rem' }}>
        {baseURL_image && profile_image ? (
          <div style={{ display: "flex", justifyContent: "items-center" }}>
            <SingleImageContainer
              onClick={() =>
                props.showImageContainer(
                  `${baseURL_image}h_500,w_500/${profile_image}`
                )
              }
              baseURL={baseURL_image}
              image={profile_image}
              cloudinaryString={"c_thumb,g_face,h_160,w_160/dpr_2.0,f_auto/"}
              imageStyle={{
                cursor: "pointer",
                top: "-30px",
                left: "100px",
                height: "unset"
              }}
            />
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "items-center" }}>
            <SingleImageContainer
              image={noProfilePicture}
              cloudinaryString={"c_thumb,g_face,h_160,w_160/dpr_2.0,f_auto/"}
              imageStyle={{
                cursor: "pointer",
                top: "-30px",
                left: "100px",
                height: "unset"
              }}
            />
          </div>
        )}

        <div className="employee-information-container">
          <div className="first">
            <div className="inner-first">
              <p>
                {first_name} {last_name.substring(0, 1).toUpperCase()}
              </p>
              <p>{`${age} år`}</p>
            </div>
          </div>
          <p>{city}</p>
          {employee_checkin_time && employee_checkout_time ?
            <>
              <p>{first_name} {headerText}</p>
              <p>{employee_checkin_time} - {employee_checkout_time} ({employeeCheckoutHours} /t)</p>
            </>
            :
            <p>{first_name} har ikke stemplet ud endnu!</p>
          }
          <div className="card-label" style={{ backgroundColor: statusBarBGColor, color: statusBarColor }}>
            {statusbarText}
          </div>
        </div>
      </div>

      {
        time_comment_by_employee && <div className="time-comment-employee">
          “{time_comment_by_employee}”
        </div>
      }
      {/** 2. Section **/}

      <div className="checkout-container">
        <div className="checkout-time-pickers">
          <>
            <div className="check">
              <p>Fra</p>
              <div className="time-boxes">
                <select
                  disabled={disableDropdowns}
                  className={checkedOut ? "disabled" : ""}
                  name="corporation_checkin_time"
                  onChange={e => {
                    const checkInTime = `${e.target.value}:${checkInTimeMinute || '00'}`
                    setCheckout({ ...checkout, [e.target.name]: checkInTime });
                  }}
                  value={checkInTimeHour}
                >
                  <option value='' disabled>
                    --
                  </option>

                  {hours.map((value, index) => (
                    <option key={`checkin-time-hour-${index}`} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <span>:</span>
                <select
                  disabled={disableDropdowns}
                  className={checkedOut ? "disabled" : ""}
                  name="corporation_checkin_time"
                  onChange={e => {
                    const checkInTime = `${checkInTimeHour || '00'}:${e.target.value}`
                    setCheckout({ ...checkout, [e.target.name]: checkInTime });
                  }}
                  value={checkInTimeMinute}
                >
                  <option value='' disabled>
                    --
                  </option>
                  {minutes.map((value, index) => (
                    <option key={`checkin-time-minutes-${index}`} value={value}>
                      {value}
                    </option>
                  ))}
                </select>

              </div>
            </div>

            <span className="time-icon"><ClockOutlineIcon size={25} /></span>

            <div className="check">
              <p>Til</p>
              <div className="time-boxes">
                <select
                  disabled={disableDropdowns}
                  className={checkedOut ? "disabled" : ""}
                  name="corporation_checkout_time"
                  onChange={e => {
                    const checkOutTime = `${e.target.value}:${checkOutTimeMinute || '00'}`
                    setCheckout({ ...checkout, [e.target.name]: checkOutTime });
                  }}
                  value={checkOutTimeHour}
                >
                  <option value='' disabled>
                    --
                  </option>
                  {hours.map((value, index) => (
                    <option key={`checkout-time-hours${index}`} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <span>:</span>
                <select
                  disabled={disableDropdowns}
                  className={checkedOut ? "disabled" : ""}
                  name="corporation_checkout_time"
                  onChange={e => {
                    const checkOutTime = `${checkOutTimeHour || '00'}:${e.target.value}`
                    setCheckout({ ...checkout, [e.target.name]: checkOutTime });
                  }}
                  value={checkOutTimeMinute}
                >
                  <option value='' disabled>
                    --
                  </option>
                  {minutes.map((value, index) => (
                    <option key={`checkout-time-minutes${index}`} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        </div>
        <div className="working-hours" style={{color: checkedOut? '#417505' : '#4A4A4A'}}>
            {workingHours} /t
        </div>
        {transport_wage &&
        <div className="working-hours" style={{color: checkedOut? '#417505' : '#4A4A4A'}}>{transport_wage} transporttillæg</div>
        }
        {
          earlyCheckoutBtns ?
            <div className="early-checkout-btns">
              <Button_DEPRECATED
                onClick={() =>
                  props.submitCheckout(props.shift.employee.id, checkout)
                }
                style={{
                  backgroundColor: "#417505"
                }}
                title={"GODKEND NY TID"}
              />
              <Button_DEPRECATED
                onClick={() =>{
                  const rejectedTime = {
                    ...checkout,
                    corporation_checkin_time,
                    corporation_checkout_time,
                    checkout_rejected_count_corporation: checkout_rejected_count_corporation + 1,
                    send_text: true
                  }
                  props.submitCheckout(props.shift.employee.id, rejectedTime)
                }
                }
                style={{
                  backgroundColor: "#F5717F"
                }}
                title={"AFVIS NY TID"}
              />

            </div>
            :
            <></>
        }

        {
          displayCommentField ?
            <div className="checkout-comment-box">
              <p className="time-comment-title">{timeCommentLabel}</p>
              <textarea
                placeholder={`“Forklaring vises til  ${first_name} under check-ud”`}
                rows="2"
                value={checkout.time_comment_by_corporation}
                name="time_comment_by_corporation"
                onChange={e => {
                  setCheckout({ ...checkout, [e.target.name]: e.target.value });
                }}
                maxLength="140"
              />
            </div>
            : time_comment_by_corporation && <div className="time-comment-company">
              “{time_comment_by_corporation}”
            </div>
        }
      </div>

      <div className="rating-container">
        <>
          {!isHistory && (
            <p style={{ marginBottom: "0.25rem" }}>
              Hjælp os med at blive bedre og giv vores medarbejder en rating fra
              1-5 for deres indsats på dette job
            </p>
          )}
          <ReactStars
            count={5}
            value={parseFloat(checkout.rating) || 0}
            half={false}
            size={25}
            onChange={newRating => {
              setCheckout({ ...checkout, rating: newRating });
            }}
          />
          {!isHistory && (
            <p className="comment-title">{`Knyt én opmuntrende kommentar til ${first_name} indsats:`}</p>
          )}
        </>
        <textarea
          rows="2"
          value={checkout.comment}
          name="comment"
          onChange={e => {
            setCheckout({ ...checkout, [e.target.name]: e.target.value });
          }}
        />
      </div>

      <div className="submit-container">
        <Button_DEPRECATED
          onClick={() =>
            props.submitCheckout(props.shift.employee.id, checkout)
          }
          style={{
            padding: "0.4rem", textTransform: 'uppercase', backgroundColor: buttonBGColor
            , color: buttonColor
          }}
          title={buttonText}
        />
      </div>
    </div>
  );
};

const componentStyle = colors => css`
  display: block;
  background-color: ${colors.white};
  border-radius: 0.25rem;
  border: 1px solid ${colors.grey};
  padding: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 0.5rem;
  cursor: default;

  .title {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: 500;
  }

  textarea {
    border: 2px solid ${colors.grey};
    width: 100%;
    border-radius: 10px;
    font-size: 11px;
    padding: 0.25rem;
    outline: none;
    resize: none;
  }

  .employee-information-container {
    display: flex;
    flex-direction: column;
    vertical-align: bottom;
    width: 100%;
    padding: 0.5rem;
    position: relative;

    .card-label {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #F5717F;
      padding: 0.3rem 0.7rem;
      font-size: 0.75rem;
      text-transform: uppercase;
      border-radius: 0.2rem;
      font-weight: 500;    
    }
    p {
      font-size: 0.65rem;
      color: ${colors.black};
    }

    .first {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .inner-first {
        display: flex;
        align-items: center;

        p:first-child {
          font-size: 0.75rem;
          font-weight: 500;
          margin-right: 0.25rem;
        }
        p:last-child {
          font-size: 0.65rem;
          color: ${colors.darkGrey};
        }
      }

      .success-text {
        padding: 0.25rem 0.5rem;
        background-color: ${colors.green};
        font-size: 0.65rem;
        border-radius: 0.25rem;
        color: ${colors.white};
        font-weight: 500;
        text-transform: uppercase;
      }

      .fail-text {
        padding: 0.25rem 0.5rem;
        background-color: ${colors.red};
        font-size: 0.65rem;
        border-radius: 0.25rem;
        color: ${colors.white};
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }

  .information-container {
    padding: 0.5rem 0.8rem;
    display: flex;
    background-color: ${colors.mediumGrey};

    .wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .job-information-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      p {
        font-size: 0.9rem;
        font-weight: 500;
      }

      .label {
        display: flex;
        background-color: ${colors.mediumBlue};
        width: 100%;
        border-radius: 5px;

        p {
          margin: auto;
          font-size: 11px;
          color: ${colors.white};
          text-transform: uppercase;
        }
      }
    }
  }
  .time-comment-employee {
    margin: 0.5rem 0 1rem;
    background: #eee;
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
    line-height: 1.1rem;
    font-weight: 500;
    font-style: italic;
    overflow-wrap: break-word;
  }
  .checkout-container {
    width: 100%;
    padding: 0.5rem 0.5rem 1.5rem;
    margin-bottom: 1rem;
    background-color: #eee;
    .time-comment-company {
      margin: 2rem 0.5rem 0;
      padding: 0.5rem 1.5rem;
      background: #fff;
      font-size: 0.85rem;
      line-height: 1.1rem;
      color: #417505;
      font-weight: 500;
      font-style: italic;
      overflow-wrap: break-word;
    }
    .checkout-time-pickers {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
    .working-hours {
      margin: 1rem 0.5rem;
      padding: 0 0.5rem;
      background: #ddd;
      text-align: center;
      border-radius: 3px;
      font-weight: 500;
    }
    .early-checkout-btns{
      margin: 1rem 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        padding: 0.5rem 0;
        text-transform: uppercase;
        font-size: .8rem;
        width: 48%;
        border-radius: 0.15rem;
      }
    }
    .checkout-comment-box {
      margin: 2rem 0 0;
      padding: 0 0.5rem;
      .time-comment-title {
        font-size: 11px;
        color: #4A4A4A;
      }
      textarea {
        font-size: 11px;
        margin-top: 0.3rem;
      }
      textarea::placeholder {
        color: #98989D;
        opacity: 1;
      }
    }
    span.time-icon {
      margin: 1.6rem 0.5rem 0;
    }

    .check {
      width: 50%;
      padding: 0 0.5rem;
      .time-boxes{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        span {
          font-weight: bold
        }
      }
      p {
        font-weight: 500;
        font-size: 0.85rem;
        letter-spacing: 0.05em;
        padding: 0 0 0.1rem;
      }

      select, button {
        display: block;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        line-height: 1.25;
        text-align-last: center;
        background-color: #4A4A4A;
        font-size: 1rem;
        font-weight: bold;
        padding: 0.5rem 0.75rem;
        border-radius: 3px;
        letter-spacing: 1px;
        color: ${colors.white};

        select:hover {
          border: 1px solid ${colors.darkGrey};
        }

        select:focus {
          outline: none;
        }

        &.disabled {
          cursor: default;
          background-color: ${colors.disabledColor};
          color: #417505;
          select:hover {
            border: none;
          }
        }
      }
    }
  }

  .rating-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.25rem;
    padding: 0.5rem 1rem;

    p {
      font-size: 0.85rem;
      line-height: 1.1rem;
    }
  }

  .comment-title {
    margin-bottom: 0.25rem;
    margin-top: 0.5rem;
  }

  .submit-container {
    padding: 0.5rem 1rem;
  }
  @media screen and (max-width: 767px){
    .checkout-container span.time-icon{
      margin: 1.6rem 0 0;
    }
    .checkout-container .check .time-boxes span{
      display: inline-block;
      margin: 0 0.2rem;
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  showImageContainer: bindActionCreators(showImageContainer, dispatch)
});

export default connect(null, mapDispatchToProps)(CheckOutEmployeeBox);
