export function workHours({ start_time, end_time, changeTimeFormat = false }, extended = false) {
  start_time = start_time ?? "00:00";
  end_time = end_time ?? "00:00";
  // Convert to decimal number
  let startTime = start_time.replace(":", ".");
  let endTime = end_time.replace(":", ".");
  let time;

  // Replace half hours
  if (startTime.charAt(3) === "1" && startTime.charAt(4) === "5") {
    startTime = startTime.substring(0, 3) + "25";
  }

  if (startTime.charAt(3) === "3") {
    startTime = startTime.substring(0, 3) + "5";
  }

  if (startTime.charAt(3) === "4" && startTime.charAt(4) === "5") {
    startTime = startTime.substring(0, 3) + "75";
  }

  if (endTime.charAt(3) === "1" && endTime.charAt(4) === "5") {
    endTime = endTime.substring(0, 3) + "25";
  }

  if (endTime.charAt(3) === "3") {
    endTime = endTime.substring(0, 3) + "5";
  }

  if (endTime.charAt(3) === "4" && endTime.charAt(4) === "5") {
    endTime = endTime.substring(0, 3) + "75";
  }

  startTime = parseFloat(startTime);
  endTime = parseFloat(endTime);

  // If endTime is less than startTime + 24 hours, and plus the rest with Math.abs
  if (endTime <= startTime) {
    time = Math.abs(24.0 - startTime) + endTime;
    time = time.toFixed(2);
  }
  // If endTime is bigger than starTime, then just do Math.abs
  else if (endTime > startTime) {
    time = Math.abs(startTime - endTime).toFixed(2);
  } else {
    time = 0
  }
  
  if(changeTimeFormat) time = time.toString().replace('.', ',')
  if (!extended) return time;

  // Convert back to hour format
  time = time.toString();

  var n = new Date(0, 0);
  n.setSeconds(+time * 60 * 60);
  time = n.toTimeString().slice(0, 5);

  return time;
}
