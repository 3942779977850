// Libs
import React from "react";
import { css } from "emotion";

// Components
import DateViewer from "../../ui/DateViewer";
import CorporationBookingOverview from "../../ui/CorporationBookingOverview";

// Utilities
import { addCommas } from "../../../utilities/add_commas";
import { remove_commas } from "../../../utilities/remove_commas";
import { workHours } from "../../../utilities/get-work-hours-total";

// Styles
import moneyBagSVG from "../../../styles/icons/money-bag.svg";
import colors from "../../../styles/colors-V-1.0";
import { breakPoint } from "../../../styles/breakPoints";

const DeprecatedShift = props => {
  const shift = props.shift;

  return (
    <div
      style={props.style && props.style}
      className={`${deprecatedShiftStyle(props)} ${
        props.className ? props.className : ""
      }`}
    >
      {/** 2. section **/}
      <div className="second-wrapper">
        <div className="date-wrapper">
          <DateViewer
            showYear={true}
            className="date-style"
            size={22}
            date={shift.booking.date}
          />
        </div>
        <div className="info-wrapper">
          <CorporationBookingOverview
            arrangementType={shift.booking.arrangement_type}
            address={`${shift.booking.address}, ${shift.booking.zip_code} ${shift.booking.city} `}
            time={`Kl. ${shift.booking.start_time} - ${shift.booking.end_time}`}
            price={`DKK  ${addCommas(
              remove_commas(shift.booking.hourly_wage) *
                workHours({
                  start_time: shift.booking.start_time,
                  end_time: shift.booking.end_time
                })
            )}  (${shift.booking.hourly_wage} /t)`}
            staffType={shift.booking.staff_type}
            priceIcon={moneyBagSVG}
          />
        </div>
      </div>
    </div>
  );
};

const deprecatedShiftStyle = props => css`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: default;
  border-radius: 3px;
  margin-bottom: 4rem;

  &:first-of-type {
    margin-top: 2rem;
  }

  div.first-wrapper {
    display: flex;

    .logo-wrapper {
      width: 4.5rem;
      display: flex;
      justify-content: start;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2.25rem;
      }

      .logo {
        @media screen and (max-width: ${breakPoint.sm}px) {
          height: ${props.size - 15 || 30}px;
          width: ${props.size - 15 || 30}px;
        }
      }
    }

    .corporation-name-and-rating {
      display: flex;
      justify-content: center;
    }
  }

  div.second-wrapper {
    display: flex;

    .date-wrapper {
      padding-top: 0.5rem;
      display: flex;
      align-items: center;
      width: 4.5rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2.25rem;
      }

      .date-style {
        .year {
          bottom: -29px;
          left: -2px;
        }

        .day,
        .month {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: ${props.size - 20 || 12}px;
          }
        }

        .date {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: ${props.size - 17 || 25}px;
          }
        }

        .year {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 10px;
            bottom: -10px;
            left: 3px;
          }
        }
      }
    }

    .info-wrapper {
      flex: 1;
      background-color: ${colors.white};
      border-radius: 3px;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
    }
  }

  div.third-wrapper {
    display: flex;
    margin-top: 0.25rem;

    .space-filler {
      width: 4.5rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2.25rem;
        min-width: 2.25rem;
      }
    }

    .label {
      font-weight: bold;
      padding: 0.25rem 1.25rem;
      font-size: 0.65rem;
      border-radius: 3px;
      border: 1px solid ${colors.green_50};
      display: flex;
      align-items: center;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.4rem;
      }
    }

    .waiting {
      background-color: ${colors.white};
      border-radius: 3px;
      color: ${colors.green_50};
    }
  }
`;

export default DeprecatedShift;
