export const remove_commas = total => {
    if(!total) return "";

    if (total.toString().includes(",")) {
        let parts = total.toString().split(",");

        if (parts[0].includes(".")) {
            let tempParts = parts[0].split(".");
            let tempPartsRes = tempParts[0] + tempParts[1];
            return tempPartsRes + "." + parts[1];
        }

        return parts[0] + "." + parts[1];
    } else if (total.toString().includes(".")) {
        let parts = total.toString().split(".");
        return parts[0] + parts[1];
    } else {
        return total;
    }
};
