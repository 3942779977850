import {
  REMOVE_TEMPORARY_SAVED_BOOKING,
  TEMPORARY_SAVE_BOOKING
} from "../actions/actionTypes";

const initialState = {
  booking: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TEMPORARY_SAVE_BOOKING:
      return {
        ...state,
        booking: action.payload
      };
    case REMOVE_TEMPORARY_SAVED_BOOKING:
      return {
        ...state,
        booking: false
      };
    default:
      return state;
  }
}
