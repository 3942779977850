import {
  END_OF_ADMIN_BOOKINGS,
  END_OF_BOOKINGS,
  GET_ADMIN_BOOKINGS,
  GET_ADMIN_BOOKINGS_FAILURE,
  GET_ADMIN_BOOKINGS_SUCCESS,
  GET_BOOKINGS,
  GET_BOOKINGS_FAILURE,
  GET_BOOKINGS_SUCCESS,
  RESET_BOOKINGS,
  GET_BOOKINGS_WITH_SAME_SEARCH_TERM_SUCCESS,
  GET_BOOKINGS_WITH_NEW_SEARCH_TERM_SUCCESS,
  GET_ADMIN_BOOKINGS_WITH_SAME_SEARCH_TERM_SUCCESS,
  GET_ADMIN_BOOKINGS_WITH_NEW_SEARCH_TERM_SUCCESS,
  CHANGE_TAB,
  CHANGE_WEEK_NUMBER,
  CHANGE_ALL_GRAVY_STATUS
} from "../actions/actionTypes";
import { upcoming } from "../config/bookingStatus";
import moment from "moment";

const initialState = {
  upcoming: {
    bookings: [],
    loading: false,
    endOfFeed: false,
    error: false,
    tab: 0,
    weekNumber: moment().week(),
    weekNumberToDisplay: moment().week(),
    totalBookings: 0,
  },
  finished: {
    bookings: [],
    loading: false,
    endOfFeed: false,
    error: false,
  },
  admin_upcoming_bookings: {
    bookings: [],
    loading: false,
    endOfFeed: false,
    error: false,
    searchTerm: ''
  },
  admin_old_bookings: {
    bookings: [],
    loading: false,
    endOfFeed: false,
    error: false,
    searchTerm: ''
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_BOOKINGS:
    case GET_BOOKINGS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: true,
          error: false,
        },
      };
    case GET_ADMIN_BOOKINGS_SUCCESS:
    case GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: false,
          error: false,
          totalBookings: action.payload.totalBookings ?? state.upcoming.totalBookings,
          bookings: action.payload.type == upcoming ? [
            ...action.payload.bookings
          ] : [
            ...state[action.payload.type].bookings,
            ...action.payload.bookings
          ]
        }
      };

    case GET_ADMIN_BOOKINGS_WITH_SAME_SEARCH_TERM_SUCCESS:
    case GET_BOOKINGS_WITH_SAME_SEARCH_TERM_SUCCESS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: false,
          error: false,
          bookings: [
            ...state[action.payload.type].bookings,
            ...action.payload.bookings,
          ],
          searchTerm: action.payload.searchTerm,
          endOfFeed: false
        },
      }

    case GET_ADMIN_BOOKINGS_WITH_NEW_SEARCH_TERM_SUCCESS:
    case GET_BOOKINGS_WITH_NEW_SEARCH_TERM_SUCCESS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: false,
          error: false,
          bookings: [
            ...action.payload.bookings,
          ],
          searchTerm: action.payload.searchTerm,
          endOfFeed: false
        },
      }

    case GET_ADMIN_BOOKINGS_FAILURE:
    case GET_BOOKINGS_FAILURE:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: false,
          error: true,
        },
      };

    case END_OF_ADMIN_BOOKINGS:
    case END_OF_BOOKINGS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: false,
          error: false,
          endOfFeed: true,
        },
      };

    case CHANGE_TAB:
      return {
        ...state,
        upcoming: {
          ...state.upcoming,
          tab: action.payload
        },
      };
    case CHANGE_WEEK_NUMBER:
      return {
        ...state,
        upcoming: {
          ...state.upcoming,
          ...action.payload
        },
      };

    case CHANGE_ALL_GRAVY_STATUS:
      return {
        ...state,
        admin_upcoming_bookings: {
          ...state.admin_upcoming_bookings,
          bookings: state.admin_upcoming_bookings.bookings.map(booking => {
            if(booking.id === action.payload.id){
              return {...booking, all_gravy_status: 'SCHEDULED'}
            }
            return booking
          })
        },
      };

    case RESET_BOOKINGS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
