// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";

// Styles
import colors from "../../../styles/colors";

// Utilities
import reformatExperience from "../../../utilities/reformat-experience-shift-count";
import req from "../../../utilities/request-utility";
import Spinner from "../../ui/Spinner";

const CvOverview = props => {
  const [workExperiences, setWorkExperiences] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getWorkExperiences = async () => {
      try {
        const { data: workExperiences } = await req()(
          `corporations/employees/${props.employee.id}/work-experiences`
        );
        setWorkExperiences(workExperiences);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    getWorkExperiences();
  }, []);

  return (
    <div className={componentStyle(colors)}>
      {loading && <Spinner style={{ marginTop: "1rem" }} />}
      {!loading &&
        workExperiences
          .sort((a, b) => parseInt(b.start_year) - parseInt(a.start_year))
          .map(
            ({
              title,
              exp_type,
              employer_text,
              start_year,
              start_month,
              end_year,
              end_month,
              shift_count,
              still_employed
            }) => (
              <>
                <hr />
                <div className="cv-container">
                  <div className="cv-wrapper">
                    <p className="staff-type">{title}</p>
                    <p className="experience-type">
                      {exp_type && ` - ${exp_type}`}
                    </p>
                  </div>
                  <p className="employer">{employer_text}</p>
                  <div className="cv-wrapper">
                    {start_month && (
                      <p className="month">{`${start_month.substring(
                        0,
                        3
                      )}.`}</p>
                    )}
                    <p className="year">{start_year && start_year}</p>
                    <p className="space">
                      {(start_month || start_year) &&
                        (end_month || end_year || still_employed) &&
                        " - "}
                    </p>
                    {end_month && !still_employed && (
                      <p className="month">{`${end_month.substring(0, 3)}.`}</p>
                    )}
                    {end_year && !still_employed && (
                      <p className="year">{end_year}</p>
                    )}
                    {still_employed && (
                      <p className="still-employed">stadig ansat</p>
                    )}
                  </div>
                  <p className="shift-count">
                    {shift_count && reformatExperience(shift_count)}
                  </p>
                </div>
              </>
            )
          )}
    </div>
  );
};

const componentStyle = () => css`
  hr {
    &:first-of-type {
      display: none;
    }
  }

  div.cv-container {
    padding: 0.25rem 0.5rem;

    div.cv-wrapper {
      display: flex;

      &:first-of-type {
        margin-top: 0.5rem;
      }
    }
  }

  p {
    &.head-title {
      font-size: 1rem;
      text-align: center;
      padding: 0.5rem 0 0;
    }

    &.experience-type,
    &.staff-type {
      font-size: 0.85rem;
      font-weight: 500;
    }

    &.employer {
      font-size: 0.75rem;
    }

    &.still-employed,
    &.shift-count,
    &.month,
    &.year {
      font-size: 0.75rem;
      color: ${colors.medDarkGrey};

      &.month {
        margin-right: 0.2rem;
      }
    }

    &.space {
      font-size: 0.75rem;
      margin: 0 0.2rem;
    }
  }
`;

export default CvOverview;
