import React from "react";
import { ClipLoader } from "react-spinners";
import { css } from "emotion";

const Spinner = props => {
  return (
    <div
      className={`${componentStyle(props.titleSize)} ${props.className || ""}`}
      style={props.style}
    >
      <ClipLoader color={props.color} size={props.size} />
      {props.title && <p>{props.title}</p>}
    </div>
  );
};

const componentStyle = titleSize => css`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: ${titleSize || 0.85}rem;
    margin-top: 0.25rem;
  }
`;

export default Spinner;
