export default {
  // WHITE
  white: "#ffffff",

  // BLACK

  // EGG SHELL
  egg_shell_20: "#f5f5f5",

  // GREY
  grey_5: "#dddddd",
  grey_10: "#D3D3D3",
  grey_15: "#9b9b9b",
  grey_20: "#6f7069",
  grey_50: "#4a4a4a",

  // RED
  red_20: "#f5717f",
  red_30: "#d0021b",

  // GREEN
  green_50: "#417505",

  // ORANGE
  orange_50: "#f5a623",

  // YELLOW
  // BLUE
  // GREEN

  // BLACK

  // VIOLET
  violet_10: "#3d3546"
};
