// Libs
import React from "react";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Styles
import colors from "../../../styles/colors";

// Components
import Button_DEPRECATED from "../../ui/Button_DEPRECATED";

// Actions
import { hideModal } from "../../../actions/uiActions";

const SelectStaffModal = props => {
  const setShift = props.setShift;
  const employee = props.employee;

  return (
    <div className={componentStyle(colors)}>
      <p className="title">{`Book personale`}</p>

      <hr />

      <p className="title-header">Hvad sker der, når du booker personale?</p>

      <ol>
        <li>{`1. ${employee.first_name} vil få direkte besked`}</li>

        <li>{`2. Personalet møder op som beskrevet i bookingen`}</li>
      </ol>

      <hr />
      <div className="btn-container">
        <Button_DEPRECATED
          className="cancel-btn"
          title={"Annullér"}
          onClick={() => props.hideModal()}
        />
        <Button_DEPRECATED
          className="submit-btn"
          onClick={() => {
            props.hideModal();
            setShift(employee.shift_id, "selected");
          }}
        >
          {`Book ${employee.first_name}!`}
        </Button_DEPRECATED>
      </div>
    </div>
  );
};

const componentStyle = colors => css`
  margin: 0.5rem;
  width: 40rem;

  .title {
    font-size: 1.5rem;
    color: #414142;
    font-weight: 500;
  }

  .title-header {
    font-size: 1.25rem;
    font-weight: 500;
    color: #414142;
    margin-bottom: 0.5rem;
  }

  ol {
    li {
      padding: 0 1rem;
      font-size: 1rem;
      color: #414142;
      font-weight: 500;
    }
  }

  hr {
    margin: 0.5rem;
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
  }

  .cancel-btn {
    width: auto;
    padding: 0.25rem 1rem;
    margin: 0 0.5rem;
    background-color: ${colors.white} !important;
    border: 1px solid ${colors.grey};
    color: ${colors.darkerGrey};
    font-size: 0.85rem;
  }
  .cancel-btn:hover {
    cursor: pointer;
    background-color: ${colors.lightGrey} !important;
  }

  .submit-btn {
    width: auto;
    padding: 0.25rem 1rem;
    margin: 0 0.5rem;
    font-size: 0.85rem;
    background-color: ${colors.mediumBlue};
  }

  .submit-btn:hover {
    cursor: pointer;
    background-color: ${colors.darkBlue};
  }

  svg {
    height: 1.25rem;
    margin-right: 0.5rem;
  }
`;

const mapDispatchToProps = dispatch => ({
  hideModal: bindActionCreators(hideModal, dispatch)
});

export default connect(null, mapDispatchToProps)(SelectStaffModal);
