// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import ReactStars from "react-stars";
import moment from "moment";
import "moment/locale/da";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";
import FileSaver from "file-saver";
import { parse } from "json2csv";
import tinyColor from "tinycolor2";

// Components
import PageContainer from "../ui/PageContainer";
import Button_DEPRECATED from "../ui/Button_DEPRECATED";
import Spinner from "../ui/Spinner";
import NavigateBackArrow from "../ui/NavigateBackArrow";
import InfiniteScroll from "react-infinite-scroller";
import ErrorBox from "../ui/ErrorBox";

// Styles
import colors from "../../styles/colors";
import CheckIcon from "mdi-react/CheckIcon";

// Utilities and config
import bookingTypes from "../../config/bookingTypes";
import req from "../../utilities/request-utility";

// Actions
import { takeOverCorporation } from "../../actions/authActions";
import { addToast } from "../../actions/uiActions";
import { getAdminBookings, resetBookings } from "../../actions/bookingActions";

// Utilities
import { workHours } from "../../utilities/get-work-hours-total";
import { addCommas } from "../../utilities/add_commas";
import { remove_commas } from "../../utilities/remove_commas";
import DatePickerUI from "../ui/DatePickerUI";
import { useAsyncDebounce } from "../../utilities/useAsyncDebounce";

const OldBookings = (props) => {
  const history = useHistory();

  //Redux State
  const {
    bookings,
    loading,
    error,
    endOfFeed,
    searchTerm: reduxSearchTerm,
  } = useSelector((state) => state.booking.admin_old_bookings);

  //Local state
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loadingDownloadEmployeeCSV, setLoadingDownloadEmployeeCSV] =
    useState(false);

  const debouncedGetOldBookings = useAsyncDebounce(props.getAdminBookings, 250);

  useEffect(() => {
    props.getAdminBookings({ type: "admin_old_bookings" });

    return () => {
      props.resetBookings();
    };
  }, []);

  useEffect(() => {
    if (searchTerm !== reduxSearchTerm) {
      props.resetBookings();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (startDate && endDate) {
      props.resetBookings();
    }
  }, [startDate, endDate]);

  const determineBackgroundColor = (bookingStatus, zenegy, billingApproved) => {
    if (zenegy) {
      return "approved-zenegy";
    } else if (billingApproved) {
      return "approved-billing";
    } else if (bookingStatus === bookingTypes.cancelled) return "cancelled";
    else if (bookingStatus === bookingTypes.expired) return "expired";
    else if (bookingStatus === bookingTypes.finished) return "finished";
  };

  const downloadEmployeeCVR = async () => {
    try {
      setLoadingDownloadEmployeeCSV(true);

      const { data: dataBookings } = await req()(
        `admin/bookings/employees-csv?startDate=${startDate}&endDate=${endDate}`
      );

      const employeeShifts = [];

      dataBookings.forEach((b) => {
        b.selectedStaff.forEach((s) => {
          if (s.admin_approve_checkout) {
            employeeShifts.push({
              id: s.employee.id,
              Virksomhed: b.corporation.name,
              Medarbejder: `${s.employee.first_name} ${s.employee.last_name}`,
              Dato: moment(b.date).format("DD.MM.YYYY"),
              Timer:
                s.admin_employee_checkin_time && s.admin_employee_checkout_time
                  ? addCommas(
                      parseFloat(
                        workHours({
                          start_time: s.admin_employee_checkin_time,
                          end_time: s.admin_employee_checkout_time,
                        })
                      ) + parseFloat(b.transport_wage)
                    )
                  : "",
              Sats: b.hourly_wage,
              Godkendt:
                s.admin_employee_checkin_time && s.admin_employee_checkout_time
                  ? addCommas(
                      1.125 *
                        remove_commas(b.hourly_wage) *
                        workHours({
                          start_time: s.admin_employee_checkin_time,
                          end_time: s.admin_employee_checkout_time,
                        })
                    )
                  : "",
              Zenegy:
                s.admin_employee_checkin_time &&
                s.admin_employee_checkout_time &&
                s.is_approved_for_payroll
                  ? addCommas(
                      1.125 *
                        remove_commas(b.hourly_wage) *
                        workHours({
                          start_time: s.admin_employee_checkin_time,
                          end_time: s.admin_employee_checkout_time,
                        })
                    )
                  : "",
            });
          }
        });
      });

      if (employeeShifts.length === 0) {
        setLoadingDownloadEmployeeCSV(false);
        props.addToast({
          message:
            "Kunne ikke finde nogen ansatte indenfor angivet tidsperiode",
          type: "error",
          autoDismiss: true,
        });
        return;
      }

      const options = { delimiter: ";" };

      let csv = parse(employeeShifts, options);
      let BOM = "\uFEFF";
      csv = BOM + csv;
      const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      FileSaver.saveAs(csvData, "data.csv");
      setLoadingDownloadEmployeeCSV(false);
    } catch (error) {
      setLoadingDownloadEmployeeCSV(false);
      props.addToast({
        message: "Noget gik galt",
        type: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <PageContainer showNavigation={true} showFullScreen={true}>
      <NavigateBackArrow navigateURL={() => history.push("/")} />
      <div className={componentStyle()}>
        <div className="header-container">
          <div style={{ display: "flex", alignItems: "center" }}>
            <p>Admin</p>
            <p>Historiske Bookinger</p>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div style={{ minWidth: "6rem", marginRight: "0.5rem" }}>
              <label>Søg</label>
              <div>
                <input
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div style={{ minWidth: "6rem", marginRight: "0.5rem" }}>
              <label>Start dato</label>
              <DatePickerUI onDateChange={(date) => setStartDate(date)} />
            </div>
            <div style={{ minWidth: "6rem", marginRight: "0.5rem" }}>
              <label>Slut dato</label>
              <DatePickerUI onDateChange={(date) => setEndDate(date)} />
            </div>
            <button
              className={!startDate || !endDate ? "disabled" : ""}
              disabled={!startDate || !endDate}
              onClick={downloadEmployeeCVR}
            >
              {loadingDownloadEmployeeCSV ? (
                <Spinner color={colors.white} />
              ) : (
                `Hent ansatte oversigt`
              )}
            </button>
          </div>
        </div>
        <InfiniteScroll
          loadMore={() =>
            debouncedGetOldBookings({
              type: "admin_old_bookings",
              searchTerm,
              startDate,
              endDate,
            })
          }
          useWindow={true}
          hasMore={true}
        >
          {endOfFeed && bookings.length === 0 && (
            <div className="no-users-container">
              <p>Der er ikke nogen brugere i systemet</p>
            </div>
          )}
          {bookings.length > 0 && (
            <div className="content">
              <table>
                <thead>
                  <tr>
                    <th>Virksomhed</th>
                    <th>Søges</th>
                    <th>Ansat</th>
                    <th>Ansøgt</th>
                    <th>Hvornår</th>
                    <th />
                    <th>Status</th>
                    <th />
                  </tr>
                </thead>
                {bookings.map(
                  (
                    {
                      id,
                      corporation,
                      arrangement_type,
                      status,
                      selectedStaff,
                      hourly_wage,
                      hourly_wage_with_extra,
                      number_of_staff,
                      totalNrOfShifts,
                      date,
                      start_time,
                      end_time,
                      created_at,
                      checkoutCompleted,
                      paid_at,
                      is_sent_to_zenegy,
                      city,
                      all_check_times_approved,
                      urgentPeriod,
                      fee,
                    },
                    index
                  ) => (
                    <tbody key={`old-booking-key-${index}`}>
                      <tr
                        className={determineBackgroundColor(
                          status,
                          is_sent_to_zenegy,
                          all_check_times_approved
                        )}
                      >
                        <td>
                          <div className="corporation-name-container">
                            <p>{`${corporation.name},`}</p>
                            <div className="event-container">
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "1rem",
                                }}
                              >
                                {city}
                              </p>
                              {status === "cancelled" && (
                                <label className="cancelled-label">
                                  Annulleret
                                </label>
                              )}
                              {status === bookingTypes.expired && (
                                <label className="expired-label">Udløbet</label>
                              )}
                              {status === bookingTypes.finished && (
                                <label className="finished-label">
                                  Afsluttet
                                </label>
                              )}
                              {status === bookingTypes.open && (
                                <label className="open-label">Åben</label>
                              )}
                              {status === bookingTypes.closed && (
                                <label className="closed-label">Lukket</label>
                              )}
                              {urgentPeriod && (
                                <label className="cancelled-label">
                                  {urgentPeriod}
                                </label>
                              )}
                            </div>
                          </div>
                          <table className="table-first-column">
                            <thead>
                              <tr
                                style={
                                  status === bookingTypes.cancelled
                                    ? { backgroundColor: colors.tonedGrey }
                                    : status === bookingTypes.expired
                                    ? { backgroundColor: colors.tonedGrey }
                                    : status === bookingTypes.finished &&
                                      checkoutCompleted
                                    ? {
                                        backgroundColor: colors.lightYellow,
                                      }
                                    : null
                                }
                              >
                                <th>ID</th>
                                <th>Navn</th>
                                <th>Timeløn</th>
                                <th />
                                <th>Rating</th>
                                <th>Total</th>
                              </tr>
                            </thead>

                            {selectedStaff.map(
                              (
                                {
                                  employee,
                                  rating,
                                  admin_employee_checkin_time,
                                  admin_employee_checkout_time,
                                },
                                index
                              ) => (
                                <tbody key={`staff-index-${index}`}>
                                  <tr
                                    style={
                                      status === bookingTypes.cancelled
                                        ? {
                                            backgroundColor: colors.tonedGrey,
                                          }
                                        : status === bookingTypes.expired
                                        ? {
                                            backgroundColor: colors.tonedGrey,
                                          }
                                        : status === bookingTypes.finished &&
                                          checkoutCompleted
                                        ? {
                                            backgroundColor: colors.lightYellow,
                                          }
                                        : null
                                    }
                                  >
                                    <td>{`ID#: ${employee.id}`}</td>
                                    <td>{`${employee.first_name} ${employee.last_name}`}</td>
                                    <td>{`DKK ${hourly_wage}`}</td>
                                    <td style={{ display: "inline-block" }}>
                                      {(!employee.cpr_nr ||
                                        !employee.bank_account_nr ||
                                        !employee.bank_reg_nr) && (
                                        <label className="data-label">
                                          DATA
                                        </label>
                                      )}
                                    </td>
                                    <td>
                                      <ReactStars
                                        count={5}
                                        edit={false}
                                        value={rating.rating}
                                        // onChange={ratingChanged}
                                        size={12}
                                        color2={"#ffd700"}
                                      />
                                    </td>
                                    <td>
                                      {admin_employee_checkin_time &&
                                      admin_employee_checkout_time
                                        ? `DKK ` +
                                          addCommas(
                                            1.125 *
                                              remove_commas(hourly_wage) *
                                              workHours({
                                                start_time:
                                                  admin_employee_checkin_time,
                                                end_time:
                                                  admin_employee_checkout_time,
                                              })
                                          )
                                        : ""}
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            )}
                          </table>
                        </td>
                        <td>{number_of_staff}</td>
                        <td>{selectedStaff.length}</td>
                        <td>{totalNrOfShifts}</td>
                        <td>
                          <div className="date-container">
                            <div>
                              <p className="date">{`${moment(date).format(
                                "dddd. DD/MM"
                              )} ${start_time} - ${end_time}`}</p>
                              <p>{`Oprettet: ${moment(created_at).format(
                                `llll`
                              )}`}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <Button_DEPRECATED
                            onClick={() =>
                              props.takeOverCorporation(corporation.id)
                            }
                            title={"Overtag"}
                          />
                        </td>
                        <td>
                          <Button_DEPRECATED
                            onClick={() =>
                              window.open(
                                `/admin/bookingInvoice/${id}`,
                                "_blank"
                              )
                            }
                            className={"invoice-btn"}
                            title={"Faktura"}
                          />
                        </td>
                        <td>
                          {status !== bookingTypes.cancelled &&
                            status !== bookingTypes.expired && (
                              <div className="stats-container">
                                {all_check_times_approved ? (
                                  <label className="finished">
                                    <CheckIcon />
                                    FAK.
                                  </label>
                                ) : (
                                  <label className="not-finished">FAK.</label>
                                )}
                                {paid_at ? (
                                  <label className="finished">
                                    <CheckIcon />
                                    BET.
                                  </label>
                                ) : (
                                  <label className="not-finished">BET.</label>
                                )}
                                {is_sent_to_zenegy ? (
                                  <label className="finished">
                                    <CheckIcon />
                                    ZEN.
                                  </label>
                                ) : (
                                  <label className="not-finished">ZEN.</label>
                                )}
                              </div>
                            )}
                        </td>
                      </tr>
                    </tbody>
                  )
                )}
              </table>
            </div>
          )}
          {!loading && error && <ErrorBox />}
          {loading && (
            <Spinner
              title={"Indlæser bookinger..."}
              style={{ margin: "2rem 0" }}
            />
          )}
        </InfiniteScroll>
      </div>
    </PageContainer>
  );
};

const componentStyle = () => css`
  padding: 2rem;
  width: 100%;
  position: absolute;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    input {
      width: 100%;
      cursor: pointer;
      display: block;
      font-size: 0.75rem;
      outline: none;
      background-color: ${colors.white};
      border: 1px solid ${colors.darkGrey};
      padding: 0.4rem 1rem;
      border-radius: 0.25rem;
      line-height: 1.25;
    }

    p {
      font-size: 2rem;
      margin-right: 0.25rem;
      color: ${colors.darkerRed};
      font-weight: 600;
    }

    p:last-child {
      font-size: 1.5rem;
      font-weight: 500;
      color: ${colors.darkerGrey};
      text-decoration: underline;
    }

    button {
      font-size: 1rem;
      padding: 0.25rem 2rem;
      background-color: ${colors.primeBlue};
      color: ${colors.white};
      border-radius: 3px;
      outline: none;

      &:hover {
        background-color: ${tinyColor(colors.primeBlue).darken(3).toString()};
      }

      &:active {
        background-color: ${tinyColor(colors.primeBlue).darken(5).toString()};
      }

      &.disabled {
        background-color: ${colors.grey};
        cursor: no-drop;
      }
    }
  }

  table {
    width: 100%;

    tr {
      background-color: ${colors.white};

      &.approved-zenegy {
        background-color: ${tinyColor(colors.green).lighten(40).toString()};
      }

      &.approved-billing {
        background-color: ${tinyColor(colors.lightYellow).toString()};
      }

      &.cancelled {
        background-color: ${colors.tonedGrey};
      }

      &.expired {
        background-color: ${colors.tonedGrey};
      }
    }

    th {
      text-align: start;
    }
    td {
      border-top: 1px solid ${colors.darkerGrey};
      padding: 1rem 0.5rem;
      font-size: 0.85rem;
      vertical-align: baseline;
      font-weight: 500;
      color: ${colors.darkerGrey};
    }
  }

  .content {
    background-color: ${colors.white};
    width: 100%;
    padding: 1rem;
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .table-first-column {
    border: 1px solid ${colors.darkGrey};
    border-radius: 25px;

    th {
      padding: 0.25rem;
      font-size: 0.7rem;
      text-align: start;
      font-weight: 600;
    }
    td {
      padding: 0.25rem;
      border: none;
      font-size: 0.65rem;
      vertical-align: middle;
    }
  }

  .date-container {
    display: flex;
    align-items: baseline;

    .date {
      font-weight: 700;
    }

    div {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;

      p {
        font-size: 0.85rem;
      }

      p:last-child {
        font-size: 0.75rem;

        font-style: italic;
      }
    }
  }

  button {
    width: auto;
    padding: 0.3rem 0.5rem;
    font-size: 0.75rem;
  }

  .corporation-name-container {
    display: flex;
    align-items: baseline;
    p {
      color: ${colors.black};
      font-size: 1rem;
      font-weight: 500;
      margin-right: 0.25rem;
    }

    .event-container {
      display: flex;

      .cancelled-label,
      .expired-label,
      .finished-label,
      .closed-label,
      .open-label {
        background-color: ${colors.darkRed};
        color: ${colors.white};
        font-size: 0.7rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        margin: 0.125rem;
        border-radius: 5px;
      }

      .expired-label {
        background-color: ${colors.red};
      }

      .finished-label {
        background-color: ${colors.blue};
      }

      .closed-label {
        background-color: ${colors.custardYellow};
      }

      .open-label {
        background-color: ${colors.green};
      }

      p {
        color: ${colors.darkerGrey};
        font-size: 0.85rem;
        font-weight: 500;
      }
    }
  }

  .data-label {
    background-color: ${colors.darkerRed};
    color: ${colors.white};
    font-size: 0.6rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    border-radius: 5px;
  }

  .invoice-btn {
    background-color: ${colors.darkerBlue} !important;
    width: auto;
    padding: 0.3rem 0.5rem;
    font-size: 0.75rem;
  }

  .stats-container {
    display: inline-flex;
    flex-direction: column;
    align-items: end;

    .not-finished,
    .finished {
      background-color: ${colors.darkGrey};
      display: flex;
      color: ${colors.white};
      font-size: 0.7rem;
      font-weight: bold;
      align-items: center;
      padding: 0 0.5rem;
      border-radius: 5px;
      margin: 0.1rem;
    }

    .finished {
      background-color: ${colors.green};
      display: flex;

      svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.2rem;
      }
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  takeOverCorporation: bindActionCreators(takeOverCorporation, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  getAdminBookings: bindActionCreators(getAdminBookings, dispatch),
  resetBookings: bindActionCreators(resetBookings, dispatch),
});

export default connect(null, mapDispatchToProps)(OldBookings);
