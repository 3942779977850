import React, { Fragment } from "react";
import { css } from "emotion";
import colors from "../../../styles/colors";
import StepBar from "../../ui/StepBar";
import { breakPoint } from "../../../styles/breakPoints";

const ChooseWorkLocations = props => {
  const { userInformation, setUserInformation } = props;

  const addWorkLocation = loc => {
    if (
      userInformation.workLocations.some(
        location =>
          location.country === loc.country &&
          location.area_code === loc.area_code
      )
    ) {
      const tempWorkLocations = userInformation.workLocations.filter(
        location => location.area_code !== loc.area_code
      );
      setUserInformation({
        ...userInformation,
        workLocations: [...tempWorkLocations]
      });
    } else {
      setUserInformation({
        ...userInformation,
        workLocations: [...userInformation.workLocations, loc]
      });
    }
  };

  return (
    <Fragment>
      <div className={componentStyle()}>
        <p className="title">Hvor kan du arbejde henne?</p>
        {/*<StepBar*/}
        {/*    currentStep={2}*/}
        {/*    steps={4}*/}
        {/*    titles={["VÆLG JOBS", "VÆLG OMRÅDER", "VEJLEDNING", "OM DIG"]}*/}
        {/*/>*/}

        <div className="zones-container">
          <button
            onClick={() =>
              addWorkLocation({
                country: "denmark",
                area_code: "northZealand"
              })
            }
            className={
              props.userInformation.workLocations.some(
                location =>
                  location.country === "denmark" &&
                  location.area_code === "northZealand"
              )
                ? "selected"
                : "not-selected"
            }
          >
            Nordsjælland
          </button>
          <div className="box-divider">
            <button
              onClick={() =>
                addWorkLocation({
                  country: "denmark",
                  area_code: "westZealand"
                })
              }
              className={
                props.userInformation.workLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "westZealand"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Vestsjælland
            </button>
            <button
              onClick={() =>
                addWorkLocation({
                  country: "denmark",
                  area_code: "copenhagen"
                })
              }
              className={
                props.userInformation.workLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "copenhagen"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              København
            </button>
          </div>
          <button
            onClick={() =>
              addWorkLocation({
                country: "denmark",
                area_code: "southZealand"
              })
            }
            className={
              props.userInformation.workLocations.some(
                location =>
                  location.country === "denmark" &&
                  location.area_code === "southZealand"
              )
                ? "selected"
                : "not-selected"
            }
          >
            Sydsjælland
          </button>
          <div className="box-divider">
            <button
              onClick={() =>
                addWorkLocation({
                  country: "denmark",
                  area_code: "northernJutland"
                })
              }
              className={
                props.userInformation.workLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "northernJutland"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Nordjylland
            </button>
            <button
              onClick={() =>
                addWorkLocation({
                  country: "denmark",
                  area_code: "eastJutland"
                })
              }
              className={
                props.userInformation.workLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "eastJutland"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Østjylland
            </button>
          </div>
          <div className="box-divider">
            <button
              onClick={() =>
                addWorkLocation({
                  country: "denmark",
                  area_code: "westJutland"
                })
              }
              className={
                props.userInformation.workLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "westJutland"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Vestjylland
            </button>
            <button
              onClick={() =>
                addWorkLocation({
                  country: "denmark",
                  area_code: "southernJutland"
                })
              }
              className={
                props.userInformation.workLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "southernJutland"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Sønderjylland
            </button>
          </div>
          <div className="box-divider">
            <button
              onClick={() =>
                addWorkLocation({ country: "denmark", area_code: "fyn" })
              }
              className={
                props.userInformation.workLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "fyn"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Fyn og øerne
            </button>
            <button
              onClick={() =>
                addWorkLocation({ country: "denmark", area_code: "bornholm" })
              }
              className={
                props.userInformation.workLocations.some(
                  location =>
                    location.country === "denmark" &&
                    location.area_code === "bornholm"
                )
                  ? "selected"
                  : "not-selected"
              }
            >
              Bornholm
            </button>
          </div>
          <div className="btn-container">
            <button onClick={() => props.moveBackward()} className="back">
              TILBAGE
            </button>
            <button
              disabled={0 < !props.userInformation.workLocations.length}
              onClick={() => props.moveForward()}
              className={
                0 < !props.userInformation.workLocations.length
                  ? "disabled"
                  : "submit"
              }
            >
              VIDERE
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const componentStyle = () => css`
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5rem;

  @media screen and (max-width: ${breakPoint.sm}px) {
    padding: 0;
  }

  p.title {
    font-size: 1.25rem;
    text-align: center;
    margin: 1rem 0;
  }

  div.zones-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: ${colors.white};
    padding: 1rem;
  }

  button.selected,
  button.not-selected {
    outline: none;
    color: ${colors.black};
    border: 1px solid ${colors.grey};
    background-color: ${colors.lightGrey};
    border-radius: 5px;
    font-size: 0.75rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 1.25rem 0;
    margin-bottom: 1.25rem;
    font-weight: bold;
    flex: 1;

    @media screen and (max-width: ${breakPoint.sm}px) {
      padding: 0.65rem 0;
      font-size: 0.6rem;
    }
  }

  button.not-selected:hover {
    background-color: ${colors.mediumGrey};
  }

  button.selected {
    color: ${colors.white};
    background-color: ${colors.primeBlue};
  }

  button.selected:hover {
    background-color: ${colors.darkerPrimeBlue};
  }

  div.box-divider {
    display: flex;
    justify-content: space-between;
  }

  div.btn-container {
    display: flex;
    justify-content: center;

    button.submit,
    button.back,
    button.disabled {
      padding: 0.5rem 3rem;
      color: ${colors.white};
      border-radius: 5px;
      outline: none;
      background-color: ${colors.lightBlue};
      margin: 0 2rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.8rem;
        margin: 0.25rem;
      }
    }

    button.submit:hover {
      background-color: ${colors.blue};
    }
    button.disabled {
      cursor: no-drop;
    }

    button.disabled,
    button.back {
      background-color: ${colors.grey};
    }

    button.back:hover {
      background-color: ${colors.darkGrey};
    }
  }
`;

export default ChooseWorkLocations;
