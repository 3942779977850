export function staffTypeLanguage(staffType, language) {
    if (language === "dk") {
        if (staffType === "waiter") {
            return `Tjener`;
        }
        if (staffType === "bartender") {
            return `Bartender`;
        }
    }
}
