import { combineReducers } from "redux";

// Reducers
import authReducer from "./authReducer";
import appConfigReducer from "./appConfigReducer";
import uiReducer from "./uiReducer";
import storageReducer from "./storageReducer";
import bookingReducer from "./bookingReducer";
import employeeReducer from "./employeeReducer";
import corporationReducer from "./corporationReducer";

export default combineReducers({
  appConfig: appConfigReducer,
  auth: authReducer,
  ui: uiReducer,
  storage: storageReducer,
  booking: bookingReducer,
  employee: employeeReducer,
  corporation: corporationReducer
});
