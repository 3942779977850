// Utilities
import req from "../utilities/request-utility";

// Action types
import {
  ADD_TOAST,
  END_OF_CORPORATIONS,
  GET_CORPORATIONS,
  GET_CORPORATIONS_FAILURE,
  GET_CORPORATIONS_SUCCESS,
  GET_CORPORATIONS_WITH_NEW_SEARCH_TERM_SUCCESS,
  GET_CORPORATIONS_WITH_SAME_SEARCH_TERM_SUCCESS,
  RESET_CORPORATIONS
} from "./actionTypes";

export function getCorporations({
  limit = 20,
  offset = null,
  searchTerm = null
} = {}) {
  return async function(dispatch, getState) {
    try {
      let state = getState().corporation;

      let corporations = [];
      let totalCorporations = 0;

      if (offset === null) offset = state.corporations.length;

      // Safe-guard to prevent duplicate post-fetchings!
      if (state.loading || state.error || state.endOfFeed) return;

      dispatch({ type: GET_CORPORATIONS });

      if (searchTerm === state.searchTerm) {
        const { data: data } = await req()(
          `admin/corporations?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`
        );
        corporations = data.corporations;
        totalCorporations = data.totalCorporations;

        if (corporations.length) {
          dispatch({
            type: GET_CORPORATIONS_WITH_SAME_SEARCH_TERM_SUCCESS,
            payload: { corporations, searchTerm, totalCorporations }
          });
        } else {
          dispatch({
            type: END_OF_CORPORATIONS
          });
        }
      } else if (searchTerm) {
        const { data: data } = await req()(
          `admin/corporations?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`
        );
        corporations = data.corporations;
        totalCorporations = data.totalCorporations;

        if (corporations.length) {
          dispatch({
            type: GET_CORPORATIONS_WITH_NEW_SEARCH_TERM_SUCCESS,
            payload: { corporations, searchTerm, totalCorporations }
          });
        } else {
          dispatch({
            type: END_OF_CORPORATIONS
          });
        }
      } else {
        const { data: data } = await req()(
          `admin/corporations?limit=${limit}&offset=${offset}`
        );
        corporations = data.corporations;
        totalCorporations = data.totalCorporations;

        if (corporations.length) {
          dispatch({
            type: GET_CORPORATIONS_SUCCESS,
            payload: { corporations, totalCorporations }
          });
        } else {
          dispatch({
            type: END_OF_CORPORATIONS
          });
        }
      }
    } catch (err) {
      dispatch({ type: GET_CORPORATIONS_FAILURE });
      dispatch({
        type: ADD_TOAST,
        payload: {
          message:
            "Noget gik galt, da vi prøvede at hente virksomhederne. Hvis fejlen gentager sig, bedes de kontakte tjenerteamet",
          type: "error",
          autoDismiss: true
        }
      });
    }
  };
}

export function resetCorporations() {
  return dispatch => {
    dispatch({ type: RESET_CORPORATIONS });
  };
}
