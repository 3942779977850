import React from "react";
import { css } from "emotion";
import colors from "../../styles/colors";

const InputField = props => {
  return (
    <input
      disabled={props.disabled}
      ref={props.ref}
      readOnly={props.readOnly}
      value={props.value}
      name={props.name}
      onChange={props.onChange}
      placeholder={props.placeholder}
      style={props.style}
      className={`${componentStyle()} ${props.className} ${props.disabled &&
        "disabled"}`}
    />
  );
};

const componentStyle = () => css`
  & {
    cursor: text;
    display: block;
    font-size: 0.75rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
  }
  &:hover {
    border: 1px solid ${colors.black};
  }

  &::placeholder {
    color: ${colors.placeholder};
  }

  .disabled {
    background-color: ${colors.grey};
    border: 1px solid ${colors.grey};
    cursor: no-drop;
  }
  .disabled:hover {
    border: 1px solid ${colors.grey};
  }
`;

export default InputField;
