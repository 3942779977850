import React, { Component } from "react";
import Spinner from "./Spinner";
import { css } from "emotion";

class LoadingScreen extends Component {
  render() {
    return (
      <div className={componentStyle()}>
        <Spinner size={50} />
      </div>
    );
  }
}

const componentStyle = () => css`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default LoadingScreen;
