// Libs
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { css } from "emotion";
import { useHistory } from "react-router";

// Utilities
import req from "../../../utilities/request-utility";

// Components
import Spinner from "../../ui/Spinner";
import WaitingOverviewEmployee from "./WaitingOverviewEmployee";

// Styles
import { breakPoint } from "../../../styles/breakPoints";
import colors from "../../../styles/colors";

const WaitingShifts = () => {
  const history = useHistory();

  const [shifts, setShifts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    req()
      .get("shifts/employee/applied-shifts")
      .then(({ data: shifts }) => {
        setShifts(shifts);
        setLoading(false);
      })
      .catch(err => {});
    // eslint-disable-next-line
  }, []);

  const showBooking = ({ bookingId }) => {
    history.push(`/bookinginformation/${bookingId}`);
  };

  return (
    <div className={componentStyle()}>
      {loading && <Spinner style={{ marginTop: "2rem" }} />}
      {!loading && shifts.length > 0 && (
        <Fragment>
          {shifts.map(shift => (
            <WaitingOverviewEmployee
              showBooking={showBooking}
              shift={shift}
              className="waiting-overview"
            />
          ))}
        </Fragment>
      )}
      {!loading && shifts.length === 0 && (
        <div className="local-container">
          <p>Her kan du se de jobs, som du har ansøgt!</p>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({});

const componentStyle = () => css`
  div.waiting-overview {
    margin: 1rem 0;
  }

  div.local-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    p {
      text-align: center;
      font-size: 1rem;
      color: ${colors.darkerGrey};

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.65rem;
      }
    }
  }
`;

export default connect(null, mapDispatchToProps)(WaitingShifts);
