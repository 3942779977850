// Utilities
import req from "../utilities/request-utility";
import {
  ADD_TOAST,
  END_OF_EMPLOYEES,
  GET_EMPLOYEES,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_WITH_NEW_SEARCH_TERM_SUCCESS,
  GET_EMPLOYEES_WITH_SAME_SEARCH_TERM_SUCCESS,
  RESET_EMPLOYEES
} from "./actionTypes";

export function getEmployees({
  limit = 15,
  offset = null,
  searchTerm = null
} = {}) {

  return async function(dispatch, getState) {
    try {
      let state = getState().employee;

      let employees = [];
      let totalEmployees = 0;

      if (offset === null) offset = state.employees.length;

      // Safe-guard to prevent duplicate post-fetchings!
      if (state.loading || state.error || state.endOfFeed) return;

      dispatch({ type: GET_EMPLOYEES });

      // If searchTerm is the same
      if (searchTerm === state.searchTerm && searchTerm) {
        const { data: data } = await req()(
          `admin/employees?offset=${offset}&limit=${limit}&searchTerm=${searchTerm}`
        );
        employees = data.employees;
        totalEmployees = data.totalEmployees;
        if (employees.length) {
          dispatch({
            type: GET_EMPLOYEES_WITH_SAME_SEARCH_TERM_SUCCESS,
            payload: { employees, searchTerm, totalEmployees }
          });
        } else {
          dispatch({
            type: END_OF_EMPLOYEES
          });
        }
      } else if (searchTerm) {
        const { data: data } = await req()(
          `admin/employees?offset=${offset}&limit=${limit}&searchTerm=${searchTerm}`
        );
        employees = data.employees;
        totalEmployees = data.totalEmployees;
        if (employees.length) {
          dispatch({
            type: GET_EMPLOYEES_WITH_NEW_SEARCH_TERM_SUCCESS,
            payload: { employees, searchTerm, totalEmployees }
          });
        } else {
          dispatch({
            type: END_OF_EMPLOYEES
          });
        }
      } else {
        const { data: data } = await req()(
          `admin/employees?offset=${offset}&limit=${limit}`
        );
        employees = data.employees;
        totalEmployees = data.totalEmployees;

        if (employees.length) {
          dispatch({
            type: GET_EMPLOYEES_SUCCESS,
            payload: { employees, searchTerm, totalEmployees }
          });
        } else {
          dispatch({
            type: END_OF_EMPLOYEES
          });
        }
      }
    } catch (err) {
      dispatch({ type: GET_EMPLOYEES_FAILURE });
      dispatch({
        type: ADD_TOAST,
        payload: {
          message:
            "Noget gik galt, da vi prøvede at hente ansatte. Hvis fejlen gentager sig, bedes do kontakte TjenerTeamet",
          type: "error",
          autoDismiss: true
        }
      });
    }
  };
}

export function resetEmployees() {
  return dispatch => {
    dispatch({ type: RESET_EMPLOYEES });
  };
}
