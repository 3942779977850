// Libs
import React, { useEffect, useState, Fragment } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Utilities
import req from "../../../utilities/request-utility";

// Styles
import colors from "../../../styles/colors";

// Actions
import { addToast, hideModal } from "../../../actions/uiActions";

// Components
import Spinner from "../../ui/Spinner";
import { breakPoint } from "../../../styles/breakPoints";

const CancelShiftModal = props => {
  const { bookingId } = props;

  const [cancellation_reason, setCancellation_reason] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const deleteShift = () => {
    setLoading(true);
    req()
      .put(`shifts/employee/cancel/${bookingId}`, {
        cancellation_reason
      })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        props.addToast({
          message: "Noget gik galt",
          type: "error",
          autoDismiss: true
        });
      });
  };

  useEffect(() => {
    if (cancellation_reason.length > 0) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [cancellation_reason]);

  return (
    <div className={componentStyle()}>
      {loading && <Spinner title={"Indlæser..."} />}
      {!loading && (
        <Fragment>
          <p className="title">
            Er du helt sikker på, at du vil aflyse vagten?
          </p>
          <hr />
          <div className="importing-for-you-box">
            <p className="sub-title">DETTE BETYDER FOR DIG</p>
            <p>
              Når du aflyser en vagt, så får du et gult kort. Aflyser du en vagt
              2 gange inden for 10 vagter, så får du karantæne i 3 mdr. Vores
              kunder forventer, at vi overholder vores aftaler. Det er derfor,
              at vi gør dette.
            </p>
          </div>
          <hr style={{ marginBottom: "1rem" }} />

          <div className="ordered-list-box">
            <p className="list-number">1.</p>
            <p>
              Det vil fremgå på din personlige profil, at du har meldt afbud,
              samt tidspunkt. Det kan kunden se når du søger jobs.
            </p>
          </div>
          <div className="ordered-list-box">
            <p className="list-number">2.</p>
            <p>Melder du afbud første gang, vil du få et gult kort.</p>
          </div>
          <div className="ordered-list-box">
            <p className="list-number">3.</p>
            <p>
              Melder du afbud 2 gang inden for 10 vagter, vil du få rødkort og
              få frosset din profil i 3 måneder.
            </p>
          </div>

          <div className="ordered-list-box">
            <p>
              § Melder du afbud 48 timer inden jobbet, vil du blive tildelt en
              rating på 1 stjerne, og få frosset din profil i 15 dage. Sker
              dette igen, vil du få rødt kort og få frosset din profil.
            </p>
          </div>

          <hr style={{ margin: "1rem 0" }} />

          <p className="sub-title">FINDE AFLØSER:</p>

          <div className="ordered-list-box">
            <p>
              Er du blevet syg/melder afbud, og du kan finde en afløser kan vi
              fjerne dit afbud som fremgår af din profil.
            </p>
          </div>

          <div className="ordered-list-box">
            <p>
              Hvis du skal melde dig syg, skal dette gøres fra morgenstunden af,
              kl. 08.00 - Så har vi mulighed for at finde en afløser. Dvs. du
              skal mærke efter, om du er for syg mellem kl. 08.00-09.00, ellers
              er det ikke muligt at melde sig syg.
            </p>
          </div>

          <div className="ordered-list-box">
            <p>
              § Møder du ikke op uden at melde afbud, vil du få frosset din
              profil. Rødt kort.
            </p>
          </div>

          <div className="ordered-list-box">
            <textarea
              name="cancellation_reason"
              onChange={e => setCancellation_reason(e.target.value)}
              rows="5"
              maxLength={300}
              placeholder={
                "Giv en forklaring, det gør det lettere for virksomheden at forstå aflysningen. (Max 300 karakter)"
              }
            />
          </div>

          <hr />

          <div className="btn-section">
            <button className="cancel-btn" onClick={() => props.hideModal()}>
              ANNULLÉR
            </button>
            <button
              disabled={btnDisabled}
              onClick={() => deleteShift()}
              className={btnDisabled ? "disabled-btn" : "delete-btn"}
            >
              MELD AFBUD
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  hideModal: bindActionCreators(hideModal, dispatch),
  addToast: bindActionCreators(addToast, dispatch)
});

const componentStyle = () => css`
  padding: 1rem;
  max-width: 42rem;

  .btn-section {
    display: flex;
    justify-content: flex-end;

    .cancel-btn,
    .delete-btn,
    .disabled-btn {
      padding: 0.25rem 0.85rem;
      margin: 0 0.5rem;
      border-radius: 5px;
      color: ${colors.blue};
      background-color: ${colors.white};
      font-weight: 500;
      outline: none;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.85rem;
      }
    }

    .disabled-btn {
      background-color: ${colors.grey};
      color: ${colors.white};
      cursor: no-drop;
    }

    .delete-btn {
      background-color: ${colors.darkerRed};
      color: ${colors.white};
      border: 1px solid ${colors.red};
    }

    .delete-btn:hover {
      background-color: ${colors.red};
    }

    .cancel-btn:hover {
      background-color: ${colors.lightGrey};
    }
  }

  input,
  textarea {
    cursor: text;
    display: block;
    font-size: 0.75rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.65rem;
    }
  }

  input:hover,
  textarea:hover {
    border: 1px solid ${colors.black};
  }

  input::placeholder,
  textarea::placeholder {
    color: ${colors.darkerGrey};
  }

  hr {
    margin-bottom: 0.5rem;
    border-color: ${colors.mediumGrey};
  }

  .title {
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color: ${colors.darkerGrey};

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.75rem;
    }
  }
  .sub-title {
    font-weight: bold;
    margin-bottom: 0.25rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.65rem;
    }
  }

  .importing-for-you-box {
    margin-bottom: 0.5rem;
  }

  .ordered-list-box {
    display: flex;
    margin-bottom: 0.75rem;

    p.list-number {
      min-width: 1.5rem;
    }
  }

  p {
    font-size: 0.95rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.65rem;
    }
  }
`;

export default connect(null, mapDispatchToProps)(CancelShiftModal);
