import React from "react";
import { css } from "emotion";
import colors from "../../styles/colors";
import AlertCircleOutlineIcon from "mdi-react/AlertCircleOutlineIcon";

const ErrorBox = () => {
  return (
    <div className={componentStyle(colors)}>
      <div className="error-box">
        <AlertCircleOutlineIcon />
        <p className="error-title">Noget gik galt</p>
        <p>
          Indlæs siden igen, eller gå tilbage. <br /> Hvis fejlen gentager sig
          kontakt kontakt@tjenerteamet.dk
        </p>
      </div>
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  width: 100%;
  justify-content: center;

  .error-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      width: 2.5rem;
      height: auto;
    }

    .error-title {
      text-align: center;
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.12rem;
    }

    p {
      text-align: center;

      font-size: 1rem;
      font-weight: 400;
    }
  }
`;

export default ErrorBox;
