// Libs
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { css } from "emotion";

// Utilities and config
import bookingStatus from "../../../config/bookingStatus";

// Components
import FinishedBooking from "../FinishedBooking";
import Spinner from "../../ui/Spinner";

// Actions
import { getBookings, resetBookings } from "../../../actions/bookingActions";

// Styles
import colors from "../../../styles/colors";
import ErrorBox from "../../ui/ErrorBox";
import { breakPoint } from "../../../styles/breakPoints";

const FinishedBookings = props => {
  const { bookings, loading, error } = useSelector(
    state => state.booking.finished
  );

  useEffect(() => {
    props.getBookings({ type: bookingStatus.finished });

    // ComponentWillUnmount
    return () => {
      props.resetBookings();
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className={componentStyle(colors)}>
      {loading && !error && <Spinner title={"Henter bookinger..."} />}
      {!loading && error && <ErrorBox />}

      {!loading && !error && bookings.length === 0 && (
        <div className="no-bookings-container">
          <p>Der er ikke nogen bookings som mangler at blive checked ud</p>
        </div>
      )}

      {!loading &&
        !error &&
        bookings.length > 0 &&
        bookings.map((booking, index) => (
          <div key={`booking-index-${index}`}>
            <FinishedBooking className="finished-booking" booking={booking} selectedShifts={booking.selectedShifts} />
          </div>
        ))}
    </div>
  );
};

const componentStyle = () => css`
  width: 100%;
  height: 100%;

  .finished-booking {
    min-height: 9rem;
    @media screen and (max-width: ${breakPoint.sm}px) {
      min-height: 5.5rem;
    }
  }

  .no-bookings-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-weight: 500;
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  getBookings: bindActionCreators(getBookings, dispatch),
  resetBookings: bindActionCreators(resetBookings, dispatch)
});

export default connect(null, mapDispatchToProps)(FinishedBookings);
