// Libs
import React from "react";
import { css } from "emotion";

// Components
import NoImage from "../../styles/NoImage.jpg";

const Logo = props => {

  return (
    <img
      src={props.src || NoImage}
      alt={props.alt}
      className={`${componentStyle(props)} ${
        props.className ? props.className : ""
      }`}
      style={props.style && props.style}
    />
  );
};

const componentStyle = props => css`
  height: ${props.size || 50}px;
  width: ${props.size || 50}px;
  border-radius: 50%;
  object-fit: cover;
`;

export default Logo;
