// Modules
import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";

// Actions
import { useToasts } from "react-toast-notifications";
import { removeToast } from "../../actions/uiActions";

const ToastHandler = props => {
  const { toast } = useSelector(state => state.ui);
  const { addToast } = useToasts();

  useEffect(() => {
    if (toast) {
      addToast(toast.message, {
        appearance: toast.type,
        autoDismiss: toast.autoDismiss
      });
      props.removeToast();
    }
    // eslint-disable-next-line
  }, [toast]);

  return <></>;
};

const mapDispatchToProps = dispatch => ({
  removeToast: bindActionCreators(removeToast, dispatch)
});

export default connect(null, mapDispatchToProps)(ToastHandler);
