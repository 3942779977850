// Libs
import React, { useEffect } from "react";
import { css } from "emotion";
import ReactStars from "react-stars";
import moment from "moment";
import "moment/locale/da";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";
import tinyColor from "tinycolor2";

// Components
import PageContainer from "../ui/PageContainer";
import Button_DEPRECATED from "../ui/Button_DEPRECATED";
import Spinner from "../ui/Spinner";

// Styles
import colors from "../../styles/colors";
import CheckIcon from "mdi-react/CheckIcon";

// Utilities and config
import bookingTypes from "../../config/bookingTypes";
import req from "../../utilities/request-utility";

// Actions
import { takeOverCorporation } from "../../actions/authActions";
import { addToast } from "../../actions/uiActions";
import NavigateBackArrow from "../ui/NavigateBackArrow";
import { getAdminBookings, resetBookings, changeAllGravyStatus } from "../../actions/bookingActions";
import InfiniteScroll from "react-infinite-scroller";
import ErrorBox from "../ui/ErrorBox";

const UpcomingBookings = props => {
  const history = useHistory();

  useEffect(() => {
    props.getAdminBookings({ type: "admin_upcoming_bookings" });
    // eslint-disable-next-line

    return () => {
      props.resetBookings();
    };
  }, []);

  const { bookings, loading, endOfFeed, error } = useSelector(
    state => state.booking.admin_upcoming_bookings
  );

  const determineBackgroundColor = (
    bookingStatus,
    shiftsCount,
    numberOfStaff,
    urgentPeriod
  ) => {
    if (
      bookingStatus === bookingTypes.finished ||
      bookingStatus === bookingTypes.cancelled
    ) {
      return "finished";
    } else if (bookingStatus === bookingTypes.closed) {
      return "full-closed";
    } else if (numberOfStaff === shiftsCount) {
      return "full";
    } else if (urgentPeriod === 24 && bookingStatus !== bookingTypes.finished) {
      return "urgent-24-hour";
    } else if (urgentPeriod === 48 && bookingStatus !== bookingTypes.finished) {
      return "urgent-48-hour";
    }
  };
  const sendToAllGravy = async(id) => {
    try {
      await req().put(`admin/bookings/${id}/change-all-gravy-status`);
      props.changeAllGravyStatus({id})
      props.addToast({
        message: "Planlagt til at sende til All Gravy..",
        type: "success",
        autoDismiss: true,
      });
    } catch (error) {
      props.addToast({
        message: "Noget gik galt. Kunne ikke gemme ændringerne..",
        type: "error",
        autoDismiss: true,
      });
    }
  }
  return (
    <PageContainer
      backgroundColor={"white"}
      showNavigation={true}
      showFullScreen={true}
    >
      <NavigateBackArrow navigateURL={() => history.push("/")} />
      <div className={componentStyle(colors)}>
        {endOfFeed && bookings.length === 0 && (
          <div className="no-users-container">
            <p>Der er ikke nogen brugere i systemet</p>
          </div>
        )}
        <div className="header-container">
          <p>Admin</p>
          <p>Kommende Bookinger</p>
        </div>
        {bookings.length > 0 && (
          <div className="content">
            <InfiniteScroll
              loadMore={() =>
                props.getAdminBookings({
                  type: "admin_upcoming_bookings"
                })
              }
              useWindow={true}
              hasMore={true}
            >
              <table>
                <thead>
                  <tr>
                    <th>Virksomhed</th>
                    <th>Søges</th>
                    <th>Ansat</th>
                    <th>Ansøgt</th>
                    <th>Hvornår</th>
                    <th />
                    <th>Status</th>
                    <th />
                  </tr>
                </thead>
                {bookings.map(
                  (
                    {
                      id,
                      corporation,
                      arrangement_type,
                      status,
                      selectedStaff,
                      hourly_wage,
                      number_of_staff,
                      totalNrOfShifts,
                      date,
                      start_time,
                      end_time,
                      created_at,
                      checkoutCompleted,
                      paid_at,
                      city,
                      approved,
                      all_check_times_approved,
                      urgentPeriod,
                      appliedStaff,
                      shifts,
                      all_gravy_status
                    },
                    index
                  ) => (
                    <tbody key={`old-booking-key-${index}`}>
                      <tr
                        className={determineBackgroundColor(
                          status,
                          number_of_staff,
                          selectedStaff.length,
                          urgentPeriod
                        )}
                      >
                        <td>
                          <div className="corporation-name-container">
                            <p
                              style={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: colors.darkestGrey
                              }}
                            >{`${corporation.name},`}</p>
                            <div className="event-container">
                              <p
                                style={{
                                  fontWeight: "500",
                                  fontSize: "12px",
                                  color: colors.darkGrey
                                }}
                              >
                                {`${arrangement_type},`}
                              </p>
                              <p
                                style={{
                                  fontWeight: "500",
                                  fontSize: "12px",
                                  color: colors.darkGrey
                                }}
                              >
                                {city}
                              </p>
                              {status === "cancelled" && (
                                <label
                                  className="cancelled-label"
                                  style={{
                                    backgroundColor: tinyColor(colors.darkGrey)
                                      .lighten(20)
                                      .toString()
                                  }}
                                >
                                  Annulleret
                                </label>
                              )}
                              {status === bookingTypes.expired && (
                                <label className="expired-label">Udløbet</label>
                              )}
                              {status === bookingTypes.finished && (
                                <label className="finished-label">
                                  Afsluttet
                                </label>
                              )}
                              {status === bookingTypes.open &&
                                approved &&
                                number_of_staff === selectedStaff.length && (
                                  <label className="open-label">Besat</label>
                                )}
                              {status === bookingTypes.open &&
                                approved &&
                                number_of_staff !== selectedStaff.length && (
                                  <label className="closed-label">Søger</label>
                                )}

                              {status === bookingTypes.closed && approved && (
                                <label className="open-label">
                                  Besat og lukket
                                </label>
                              )}

                              {!approved && (
                                <label className="expired-label">
                                  Mangler godkendelse
                                </label>
                              )}
                              {urgentPeriod && (
                                <label className="cancelled-label">
                                  {urgentPeriod}
                                </label>
                              )}
                              <Button_DEPRECATED
                                onClick={() =>
                                  sendToAllGravy(id)
                                }
                                disabled={all_gravy_status != "PENDING"}
                                className={"all-gravy-btn"}
                                title={all_gravy_status == "PENDING" ? "Send til All Gravy" : all_gravy_status == "SCHEDULED" ? "Planlagt for All Gravy" : "sendt til All Gravy"}
                              />
                            </div>
                          </div>
                          <table className="table-first-column">
                            <thead>
                              <tr
                                style={
                                  status === bookingTypes.cancelled
                                    ? { backgroundColor: colors.tonedGrey }
                                    : status === bookingTypes.expired
                                    ? { backgroundColor: colors.lightGreen }
                                    : status === bookingTypes.finished &&
                                      checkoutCompleted
                                    ? {
                                        backgroundColor: colors.lightYellow
                                      }
                                    : null
                                }
                              >
                                {/*<th>ID</th>*/}
                                {/*<th*/}
                                {/*  style={{*/}
                                {/*    paddingLeft: "1rem",*/}
                                {/*    width: "18rem"*/}
                                {/*  }}*/}
                                {/*></th>*/}
                                {/*<th></th>*/}
                                {/*<th />*/}
                                {/*/!*<th>Total</th>*!/*/}
                                {/*<th></th>*/}
                              </tr>
                            </thead>

                            {selectedStaff.map(
                              ({ nrOfShifts, employee, rating }, index) => (
                                <tbody key={`staff-index-${index}`}>
                                  <tr
                                    style={
                                      status === bookingTypes.cancelled
                                        ? {
                                            backgroundColor: colors.tonedGrey
                                          }
                                        : status === bookingTypes.expired
                                        ? {
                                            backgroundColor: colors.lightGreen
                                          }
                                        : status === bookingTypes.finished &&
                                          checkoutCompleted
                                        ? {
                                            backgroundColor: colors.lightYellow
                                          }
                                        : null
                                    }
                                  >
                                    {/*<td>{`ID#: ${employee.id}`}</td>*/}
                                    <td
                                      style={{
                                        padding: "0 0 0 1rem",
                                        width: "18rem",
                                        color: nrOfShifts !== 0 ? '#4A4A4A' : '#F5717F'
                                      }}
                                    >{`${employee.first_name} ${employee.last_name}`}</td>
                                    <td>{`DKK ${hourly_wage}`}</td>
                                    <td style={{ display: "inline-block" }}>
                                      {!employee.bank_account_nr ||
                                        (!employee.bank_reg_nr && (
                                          <label className="data-label">
                                            DATA
                                          </label>
                                        ))}
                                    </td>
                                    {/*<td>DKK 2439,00</td>*/}
                                    <td>
                                      {rating.rating && rating.rating > 0 ? (
                                        <ReactStars
                                          count={5}
                                          edit={false}
                                          value={rating.rating}
                                          // onChange={ratingChanged}
                                          size={12}
                                          color2={"#ffd700"}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            )}
                          </table>
                        </td>
                        <td>{number_of_staff}</td>
                        <td>{selectedStaff.length}</td>
                        <td>{appliedStaff.length}</td>
                        <td>
                          <div className="date-container">
                            <div>
                              <p className="date">{`${moment(date).format(
                                "dddd. DD/MM"
                              )} ${start_time} - ${end_time}`}</p>
                              <p><span style={{color: colors.darkGrey}}>Oprettet:</span>{` ${moment(created_at).format(
                                `llll`
                              )}`}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <Button_DEPRECATED
                            onClick={() =>
                              props.takeOverCorporation(corporation.id, `/viewBooking/${id}`)
                            }
                            className={"take-over-btn"}
                            title={"Overtag"}
                          />
                        </td>
                        <td>
                          <Button_DEPRECATED
                            onClick={() =>
                              window.open(
                                `/admin/bookingInvoice/${id}`,
                                "_blank"
                              )
                            }
                            className={"invoice-btn"}
                            title={"Faktura"}
                          />
                        </td>
                        <td>
                          {status !== bookingTypes.cancelled &&
                            status !== bookingTypes.expired && (
                              <div className="stats-container">
                                {all_check_times_approved ? (
                                  <label className="finished">
                                    <CheckIcon />
                                    FAK.
                                  </label>
                                ) : (
                                  <label className="not-finished">FAK.</label>
                                )}
                                {paid_at ? (
                                  <label className="finished">
                                    <CheckIcon />
                                    BET.
                                  </label>
                                ) : (
                                  <label className="not-finished">BET.</label>
                                )}

                                <label className="not-finished">ZEN.</label>
                              </div>
                            )}
                        </td>
                      </tr>
                    </tbody>
                  )
                )}
              </table>
            </InfiniteScroll>
          </div>
        )}
        {!loading && error && <ErrorBox />}
        {loading && (
          <Spinner
            title={"Indlæser bookinger..."}
            style={{ margin: "2rem 0" }}
          />
        )}
      </div>
    </PageContainer>
  );
};

const componentStyle = () => css`
  padding: 0 2rem;
  width: 100%;
  position: absolute;
  font-family: "Arial";

  .header-container {
    display: flex;
    align-items: baseline;
    margin-bottom: 0.5rem;

    p {
      font-size: 2rem;
      margin-right: 0.25rem;
      color: ${colors.darkerRed};
      font-weight: 600;
    }

    p:last-child {
      font-size: 1.5rem;
      font-weight: 500;
      color: ${colors.darkerGrey};
      text-decoration: underline;
    }
  }

  table {
    width: 100%;

    tr {
      border-style: hidden;

      &.finished {
        background-color: ${colors.tonedGrey};
      }

      &.urgent-24-hour {
        background-color: ${tinyColor(colors.lighterRed)
          .lighten(3)
          .toString()};
      }

      &.urgent-48-hour {
        background-color: ${tinyColor(colors.lightYellow).toString()};
      }

      &.full-closed {
        background-color: ${tinyColor(colors.green)
          .lighten(40)
          .toString()};
      }

      &.full {
        background-color: ${tinyColor(colors.green)
          .lighten(47)
          .toString()};
      }
    }

    th {
      text-align: start;
    }
    td {
      border-top: 1px solid ${colors.darkerGrey};
      padding: 0.125rem 0.5rem 0.45rem;
      font-size: 0.85rem;
      vertical-align: baseline;
      font-weight: 500;
      color: ${colors.darkerGrey};
    }
  }

  .content {
    width: 100%;
  }

  .table-first-column {
    border-radius: 25px;

    th {
      padding: 0.25rem 0;
      font-size: 0.7rem;
      text-align: start;
      font-weight: 600;
    }
    td {
      padding: 0;
      border: none;
      font-size: 0.65rem;
      vertical-align: middle;
    }
  }

  .date-container {
    display: flex;
    align-items: baseline;

    .date {
      font-weight: 700;
    }

    div {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;

      p {
        font-size: 0.85rem;
      }

      p:last-child {
        font-size: 0.75rem;

        font-style: italic;
      }
    }
  }

  button {
    width: auto;
    padding: 0.3rem 0.5rem;
    font-size: 0.75rem;
  }

  .corporation-name-container {
    display: flex;
    align-items: baseline;
    p {
      color: ${colors.black};
      font-size: 1rem;
      font-weight: 500;
      margin-right: 0.25rem;
    }

    .event-container {
      display: flex;

      .cancelled-label,
      .expired-label,
      .finished-label,
      .closed-label,
      .open-label {
        background-color: ${colors.darkRed};
        color: ${colors.white};
        font-size: 0.7rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        margin: 0.125rem;
        border-radius: 5px;
      }

      .expired-label {
        background-color: ${colors.red};
      }

      .finished-label {
        background-color: ${colors.blue};
      }

      .closed-label {
        background-color: ${colors.custardYellow};
      }

      .open-label {
        background-color: ${colors.green};
      }

      p {
        color: ${colors.darkerGrey};
        font-size: 0.85rem;
        font-weight: 500;
      }
    }
  }

  .data-label {
    background-color: ${colors.darkerRed};
    color: ${colors.white};
    font-size: 0.6rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    border-radius: 5px;
  }

  .take-over-btn,
  .invoice-btn {
    color: ${colors.darkestGrey};
    background-color: transparent !important;
    width: auto;
    padding: 0.3rem 0.5rem;
    font-size: 0.75rem;
  }

  .stats-container {
    display: inline-flex;
    flex-direction: column;
    align-items: end;

    .not-finished,
    .finished {
      background-color: ${colors.darkGrey};
      display: flex;
      color: ${colors.white};
      font-size: 0.7rem;
      font-weight: bold;
      align-items: center;
      padding: 0 0.5rem;
      border-radius: 5px;
      margin: 0.1rem;
    }

    .finished {
      background-color: ${colors.green};
      display: flex;

      svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.2rem;
      }
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  takeOverCorporation: bindActionCreators(takeOverCorporation, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  getAdminBookings: bindActionCreators(getAdminBookings, dispatch),
  changeAllGravyStatus: bindActionCreators(changeAllGravyStatus, dispatch),
  resetBookings: bindActionCreators(resetBookings, dispatch)
});

export default connect(null, mapDispatchToProps)(UpcomingBookings);
