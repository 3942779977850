// Libs
import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import moment from "moment";

// Components
import ChooseJobRoles from "./ChooseJobRoles";
import IntroductionVideo from "./IntroductionVideo";
import InformationAboutYou from "./InformationAboutYou";
import ChooseWorkLocations from "./ChooseWorkLocations";
import PageContainer from "../../ui/PageContainer";
import Spinner from "../../ui/Spinner";

// Validations
import FormValidator from "../../../validation/formValidator";
import { informationAboutYouValidation } from "../../../validation/informationAboutYouValidation";

// Utilities
import { introFormUpdateUser } from "../../../actions/authActions";
import { addToast, showModal } from "../../../actions/uiActions";
import EmployeeTransferOldAccount from "../EmployeeTransferOldAccount";

const NewUserForm = props => {
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);

  const { user } = useSelector(state => state.auth);
  const [loadUserCount, setLoadUserCount] = useState(0);

  // Validation form
  const validator = new FormValidator(informationAboutYouValidation);

  const [userInformation, setUserInformation] = useState({
    jobRoles: [],
    workLocations: [],
    updatedUser: {
      city: "",
      birth_date: moment(),
      description: "",
      phone: "",
      email: "",
      has_driver_license: false,
      owns_car: false,
      new_user: false,
      validation: validator.valid()
    }
  });

  useEffect(() => {
    if (loadUserCount < 3) {
      setUserInformation({
        ...userInformation,
        updatedUser: {
          ...userInformation.updatedUser,
          city: user.city ?? "",
          description: user.description ?? "",
          phone: user.phone ?? "",
          email: user.email ?? ""
        }
      });
    }
    setLoadUserCount(loadUserCount + 1);
  }, [user]);

  // loading
  const [loading, setLoading] = useState(false);

  // Validation submitted ( Validation)
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(
    userInformation.updatedUser.validation
  );

  // Validation
  useEffect(() => {
    if (submitted) {
      setValidation(validator.validate({ ...userInformation.updatedUser }));
    } else {
      setValidation(userInformation.updatedUser.validation);
    }
    // eslint-disable-next-line
  }, [userInformation.updatedUser]);

  const displayTransferAccountPopup = () => {
    dispatch(
      showModal({
        hideTop: true,
        disableBackgroundCancel: true,
        content: <EmployeeTransferOldAccount />
      })
    );
  };

  const submitHandler = () => {
    const tempUpdatedUser = { ...userInformation.updatedUser };
    const validation = validator.validate(tempUpdatedUser);
    tempUpdatedUser.validation = validation;
    setSubmitted(true);

    if (validation.isValid) {
      setLoading(true);
      const updatedUserInfo = {...userInformation, 
        updatedUser: {...userInformation.updatedUser, 
          login_email: userInformation.updatedUser.email
        }
      }
      props.introFormUpdateUser(updatedUserInfo);
    } else {
      // Force rerender component
      setUserInformation({ ...userInformation, updatedUser: tempUpdatedUser });
      // Show warning
      props.addToast({
        message: "Nogle felter er ikke udfyldt korrekt",
        type: "warning",
        autoDismiss: true
      });
    }
  };

  return (
    <PageContainer showNavigation={true} showContract={false}>
      <div className={componentStyle()}>
        {tabIndex === 0 && !loading && (
          <ChooseJobRoles
            userInformation={userInformation}
            setUserInformation={setUserInformation}
            moveForward={() => setTabIndex(1)}
            displayTransferAccountPopup={displayTransferAccountPopup}
          />
        )}
        {tabIndex === 1 && (
          <ChooseWorkLocations
            userInformation={userInformation}
            setUserInformation={setUserInformation}
            moveBackward={() => setTabIndex(0)}
            moveForward={() => setTabIndex(2)}
          />
        )}
        {tabIndex === 2 && !loading && (
          <IntroductionVideo
            moveBackward={() => setTabIndex(1)}
            moveForward={() => setTabIndex(3)}
          />
        )}
        {tabIndex === 3 && !loading && (
          <InformationAboutYou
            validation={validation}
            userInformation={userInformation}
            setUserInformation={setUserInformation}
            moveBackward={() => setTabIndex(2)}
            submitHandler={() => submitHandler()}
          />
        )}

        {loading && (
          <div className="loading-container">
            <Spinner size={50} titleSize={1} title={"Opretter ny profil..."} />
          </div>
        )}
      </div>
    </PageContainer>
  );
};

const componentStyle = () => css`
  width: 100%;
  height: 100%;

  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const mapDispatchToProps = dispatch => ({
  introFormUpdateUser: bindActionCreators(introFormUpdateUser, dispatch),
  addToast: bindActionCreators(addToast, dispatch)
});

export default connect(null, mapDispatchToProps)(NewUserForm);
