// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";

// Utilities
import req from "../../utilities/request-utility";

// Components
import Spinner from "../ui/Spinner";
import colors from "../../styles/colors";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import AlertCircleOutlineIcon from "mdi-react/AlertCircleOutlineIcon";
import PageContainer from "../ui/PageContainer";

const UnsubscribeEmail = props => {
  // State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const unsubscribe = async () => {
    try {
      await req()(
        `employees/emails/unsubscribe?token=${props.match.params.token}`
      );
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    unsubscribe();
  }, []);

  return (
    <PageContainer showNavigation={true}>
      <div className={componentStyle(props)}>
        {loading && !error && <Spinner />}
        {!loading && !error && (
          <div className="content">
            <CheckCircleIcon />
            <p>Du er nu blevet afmeldt fra TjenerTeamets nyhedsbrev</p>
          </div>
        )}
        {!loading && error && (
          <div className="content">
            <AlertCircleOutlineIcon />
            <p>Noget gik desværre galt, da vi prøvede at afmelde dig</p>
          </div>
        )}
      </div>
    </PageContainer>
  );
};

const componentStyle = () => css`
  display: flex;
  justify-content: center;

  .content {
    margin-top: 5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.black};
    border-radius: 5px;
    align-items: center;

    svg {
      height: 4rem;
      width: 4rem;
      fill: ${colors.primeBlue};
    }

    p {
      margin-top: 0.5rem;
      display: inline-block;
      color: ${colors.black};
      font-weight: 500;
    }
  }
`;

export default UnsubscribeEmail;
