import {
  AUTO_SIGN_IN_CORPORATION_FAIL,
  AUTO_SIGN_IN_CORPORATION_SUCCESS,
  AUTO_SIGN_IN_EMPLOYEE_FAIL,
  AUTO_SIGN_IN_EMPLOYEE_SUCCESS,
  AUTO_SIGN_IN_TAKEN_OVER_CORPORATION_FAIL,
  AUTO_SIGN_IN_TAKEN_OVER_CORPORATION_SUCCESS,
  AUTO_SIGN_IN_TAKEN_OVER_EMPLOYEE_FAIL,
  AUTO_SIGN_IN_TAKEN_OVER_EMPLOYEE_SUCCESS,
  CORPORATION_ALREADY_EXISTS,
  CREATE_CORPORATION_AND_BOOKING_FAIL,
  CREATE_CORPORATION_AND_BOOKING_SUCCESS,
  CREATE_CORPORATION_FAIL,
  CREATE_CORPORATION_STARTED,
  CREATE_CORPORATION_SUCCESS,
  DELETE_WORK_SKILL_FAIL,
  DELETE_WORK_SKILL_SUCCESS,
  DISABLE_TAKE_OVER_CORPORATION_FAIL,
  DISABLE_TAKE_OVER_CORPORATION_SUCCESS,
  DISABLE_TAKE_OVER_EMPLOYEE_FAIL,
  DISABLE_TAKE_OVER_EMPLOYEE_SUCCESS,
  SIGN_IN_CORPORATION_FAIL,
  SIGN_IN_CORPORATION_SUCCESS,
  SIGN_IN_EMPLOYEE_FAIL,
  SIGN_IN_EMPLOYEE_SUCCESS,
  SIGN_OUT,
  TAKEN_OVER_CORPORATION_FAIL,
  TAKEN_OVER_CORPORATION_SUCCESS,
  TAKEN_OVER_EMPLOYEE_FAIL,
  TAKEN_OVER_EMPLOYEE_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_WORK_LOCATIONS_SUCCESS,
  UPDATE_WORK_SKILLS,
  UPDATE_WORK_SKILLS_FAIL,
  UPDATE_WORK_SKILLS_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  user: {
    new_user: false,
    is_admin: false,
    workLocations: [],
    workSkills: []
  },
  loadingWorkSkill: false,
  loading: false,
  token: null,
  isAuthenticatedCorporation: false,
  isAuthenticatedEmployee: false,

  takenOverEmployee: false,
  takenOverCorporation: false,

  error: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case AUTO_SIGN_IN_CORPORATION_SUCCESS:
      return {
        ...state,
        isAuthenticatedCorporation: true,
        user: action.payload.user,
        token: action.payload.token
      };

    case AUTO_SIGN_IN_CORPORATION_FAIL:
      return {
        ...state,
        error: true
      };

    case AUTO_SIGN_IN_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isAuthenticatedEmployee: true,
        user: action.payload.user,
        token: action.payload.token
      };

    case AUTO_SIGN_IN_EMPLOYEE_FAIL:
      return {
        ...state,
        error: true
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: true
      };

    case UPDATE_WORK_LOCATIONS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          workLocations: action.payload.workLocations
        }
      };

    case UPDATE_WORK_SKILLS:
      return {
        ...state,
        loadingWorkSkill: true,
        user: {
          ...state.user
        }
      };

    case UPDATE_WORK_SKILLS_SUCCESS:
      return {
        ...state,
        loadingWorkSkill: false,
        user: {
          ...action.payload.user
        }
      };

    case UPDATE_WORK_SKILLS_FAIL:
      return {
        ...state,
        error: true,
        loadingWorkSkill: false,
        user: {
          ...state.user
        }
      };

    case DELETE_WORK_SKILL_SUCCESS:
      return {
        ...state,
        loadingWorkSkill: false,
        user: {
          ...action.payload.user
        }
      };

    case DELETE_WORK_SKILL_FAIL:
      return {
        ...state,
        loadingWorkSkill: false,
        user: {
          ...state.user
        },
        error: true
      };

    case SIGN_IN_CORPORATION_SUCCESS:
      return {
        ...state,
        isAuthenticatedCorporation: true,
        user: action.payload.user,
        token: action.payload.token
      };

    case SIGN_IN_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isAuthenticatedEmployee: true,
        user: action.payload.user,
        token: action.payload.token
      };

    case SIGN_IN_EMPLOYEE_FAIL:
      return {
        ...state,
        error: true
      };

    case SIGN_OUT:
      return {
        ...state,
        user: {},
        isAuthenticatedCorporation: false,
        isAuthenticatedEmployee: false
      };

    case SIGN_IN_CORPORATION_FAIL:
      return {
        ...state,
        error: true
      };

    case CREATE_CORPORATION_STARTED:
      return {
        ...state,
        loading: true
      };

    case CREATE_CORPORATION_SUCCESS:
      return {
        ...state,
        isAuthenticatedCorporation: true,
        user: action.payload.user,
        token: action.payload.token,
        loading: false
      };

    case CREATE_CORPORATION_FAIL:
      return {
        ...state,
        error: true,
        loading: false
      };

    case CORPORATION_ALREADY_EXISTS:
      return {
        ...state,
        loading: false
      };

    case CREATE_CORPORATION_AND_BOOKING_SUCCESS:
      return {
        ...state,
        isAuthenticatedCorporation: true,
        user: action.payload.user,
        token: action.payload.token
      };

    case CREATE_CORPORATION_AND_BOOKING_FAIL:
      return {
        ...state,
        error: true
      };

    case TAKEN_OVER_EMPLOYEE_SUCCESS:
      return {
        ...state,
        takenOverEmployee: true,
        isAuthenticatedCorporation: false,
        isAuthenticatedEmployee: true,
        user: action.payload.user,
        token: action.payload.token
      };

    case TAKEN_OVER_EMPLOYEE_FAIL:
      return {
        ...state,
        error: true
      };

    case TAKEN_OVER_CORPORATION_SUCCESS:
      return {
        ...state,
        takenOverCorporation: true,
        isAuthenticatedCorporation: true,
        isAuthenticatedEmployee: false,
        user: action.payload.user,
        token: action.payload.token
      };

    case TAKEN_OVER_CORPORATION_FAIL:
      return {
        ...state,
        error: true
      };

    case AUTO_SIGN_IN_TAKEN_OVER_EMPLOYEE_SUCCESS:
      return {
        ...state,
        takenOverEmployee: true,
        isAuthenticatedCorporation: false,
        isAuthenticatedEmployee: true,
        user: action.payload.user,
        token: action.payload.token
      };

    case AUTO_SIGN_IN_TAKEN_OVER_EMPLOYEE_FAIL: {
      return {
        ...state,
        error: true
      };
    }

    case AUTO_SIGN_IN_TAKEN_OVER_CORPORATION_SUCCESS:
      return {
        ...state,
        takenOverCorporation: true,
        isAuthenticatedCorporation: true,
        isAuthenticatedEmployee: false,
        user: action.payload.user,
        token: action.payload.token
      };

    case AUTO_SIGN_IN_TAKEN_OVER_CORPORATION_FAIL:
      return {
        ...state,
        error: true
      };

    case DISABLE_TAKE_OVER_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        takenOverEmployee: false,
        isAuthenticatedEmployee: false,
        isAuthenticatedCorporation: true,
        user: action.payload.user,
        token: action.payload.token
      };
    }

    case DISABLE_TAKE_OVER_EMPLOYEE_FAIL: {
      return {
        ...state,
        error: true
      };
    }

    case DISABLE_TAKE_OVER_CORPORATION_SUCCESS: {
      return {
        ...state,
        takenOverCorporation: false,
        isAuthenticatedCorporation: true,
        user: action.payload.user,
        token: action.payload.token
      };
    }

    case DISABLE_TAKE_OVER_CORPORATION_FAIL: {
      return {
        ...state,
        error: true
      };
    }

    default:
      return state;
  }
}
