// Libs
import React from "react";
import { css } from "emotion";

// Styles
import colors from "../../styles/colors";
import { useSelector } from "react-redux";

const CorporationContractModal = () => {
  const { name, cvr_nr } = useSelector(state => state.auth.user);

  return (
    <div className={componentStyle()}>
      <p className="header-title">{`KONTRAKT`}</p>
      <hr className="liner" />
      <p className="title">{`Handelsbetingelser`}</p>
      <p>
        {`
        Nærværende handelsbetingelser vedrører vikarieringsaftaler indgået
mellem PersonaleLand ApS, cvr. nr. 38718371 (herefter benævnt TjenerTeamet)
og ${name}, cvr. nr. ${cvr_nr} til TjenerTeamets ydelser (herefter er kunder benævnt
virksomhed). 
        `}
      </p>
      <p>
        {`TjenerTeamet indgår vikarieringsaftaler med det formål at servicere virksomhed med
midlertidigt personale efter behov. `}
      </p>
      <p>
        {`Nærværende handelsbetingelser er gældende for det til enhver tid gældende samarbejde
med TjenerTeamet med mindre andet særskilt er aftalt skriftligt. `}
      </p>
      <p className="pointer-header">{`§ 1 Gensidige forpligtelser`}</p>
      <div>
        <ol>
          <li>
            <p>
              {`TjenerTeamet administrerer bemanding, transport og aflønning af midlertidigt
personale.`}
            </p>
          </li>
          <li>
            <p>
              {`Ved booking af midlertidigt personale skal Virksomheden ved afgivelse af ordre
oplyse dato, sted, uniform, kontaktperson, mødested og tidsrum for booking af det
midlertidige personale. `}
            </p>
          </li>
          <li>
            <p>
              {`Virksomheder afregnes ikke for transport af midlertidigt personale inde for radios af
25 minutter kørsel af københavns hovedbanegård kun hvis det er anført ved
booking. `}
            </p>
          </li>
        </ol>
      </div>
      <p className="pointer-header">{`§  2 Almindelig Booking`}</p>
      <div>
        <ol>
          <li>
            <p>
              {`TjenerTeamet stiller midlertidigt personale til rådighed efter forudgående bestilling.
Bestilling af midlertidigt personale skal ske ved online bestilling.`}
            </p>
          </li>
          <li>
            <p>
              {`Bestilling af midlertidigt personale er accepteret af TjenerTeamet,
når Virksomheden modtager en bekræftende mail fra TjenerTeamet vedrørende
booking.`}
            </p>
          </li>
          <li>
            <p>
              {`TjenerTeamet fakturerer minimum for fem (5) timer pr. booket midlertidigt personale,
med mindre andet skriftligt er aftalt.`}
            </p>
          </li>
          <li>
            <p>
              Timeprisen for midlertidigt personale rekrutteret gennem
              TjenerTeamet følger den til enhver tid gældende{" "}
              <a href={"https://www.tjenerteamet.dk/priser.html"}>prisliste</a>
              {". "}
              Priserne inkluderer lovpligtig feriegodtgørelse og ATP.`
            </p>
          </li>
        </ol>
      </div>
      <p className="pointer-header">§ 3 Hastbooking</p>
      <div>
        <ol>
          <li>
            <p>
              Hvis Virksomheder har akut behov for midlertidigt personale inden
              for 2 dage (48 timer) eller 24 timer før start af den planlagte
              booking, kan midlertidigt personale bookes mod et gebyr jf. Priser{" "}
              <a href={"https://www.tjenerteamet.dk/priser.html"}>
                (link til Priser)
              </a>
            </p>
          </li>
          <li>
            <p>
              {`TjenerTeamet garanterer ikke midlertidigt personale ved hastbooking, men sørger i
videst muligt omfang for at opfylde virksomhedens behov. `}
            </p>
          </li>
        </ol>
      </div>
      <p className="pointer-header">{`§ 4 Overarbejde`}</p>
      <div>
        <ol>
          <li>
            <p>
              {`Hvis TjenerTeamet har stillet midlertidigt personale til rådighed til et arrangement, er
det op til det midlertidige personale selv at bestemme om vedkommende ønsker at
påtage sig overarbejde. `}
            </p>
          </li>
          <li>
            <p>{`Overarbejde faktureres for hver påbegyndte halve time.  `}</p>
          </li>
        </ol>
      </div>
      <p className="pointer-header">{`§ 5 Afbestilling`}</p>
      <div>
        <ol>
          <li>
            <p>
              {`TjenerTeamet stiller midlertidigt personale til rådighed efter forespørgsel
fra Virksomheder. Der kan være forhold hos Virksomheder, som betyder,
at Virksomhed skal foretage hel eller delvis afbestilling af det bookede midlertidige
personale.`}
            </p>
          </li>
          <li>
            <p>
              {`Hvis afbestilling sker mere end 4 dage før arrangementets start, opkræver
TjenerTeamet ikke gebyrer.`}
            </p>
          </li>
          <li>
            <p>
              {`Hvis afbestilling sker mindre end 4 dage før arrangementets start, opkræves
gebyr svarende til 2 timer af bestilling. `}
            </p>
          </li>
          <li>
            <p>
              {`Hvis afbestilling sker mindre end 24 timer før arrangementets start, opkræves
gebyr svarende til 3 timer hvis andet ikke er aftalt.`}
            </p>
          </li>
          <li>
            <p>
              Afbestilling skal ske online eller gennem fremsendelse af e-mail
              til{" "}
              <span style={{ color: "#bf8d05", fontWeight: "bold" }}>
                kontakt@tjenerteamet.dk
              </span>
              . Afbestillingen er først godkendt, når Virksomhed modtager en
              bekræftende mail fra TjenerTeamet vedrørende afbestilling af
              bookingen.
            </p>
          </li>
        </ol>
      </div>
      <p className="pointer-header">{`§ 6 Mødetider`}</p>
      <div>
        <ol>
          <li>
            <p>
              {`Det midlertidige personale forpligter sig til at møde jf. aftale, omklædt og klar til at
arbejde. Afregning sker jf. aftalt bookede tider. `}
            </p>
          </li>
        </ol>
      </div>
      <p className="pointer-header">{`§ 7 Det midlertidige personales vilkår`}</p>
      <div>
        <ol>
          <li>
            <p>
              {`Ved booking af midlertidigt personale hos TjenerTeamet medfølger disse krav for
det midlertidige personale, som anført i nedenstående: `}
            </p>
          </li>
          <li>
            <p>
              {`Jf. Vikarlovens § 3: ”Vikarbureauet skal sikre, at vikaren under dennes udsendelse
til en brugervirksomhed, for så vidt angår arbejdstidens længde, overarbejde,
pauser, hvileperioder, natarbejde, ferie, helligdage og aflønning, mindst har vilkår
svarende til, hvad der ville have været gældende i medfør af lovgivning, kollektive
overenskomster eller andre bindende generelle bestemmelser, hvis vikaren havde
været ansat direkte af brugervirksomheden til udførelse af samme arbejdsopgave.
Brugervirksomheden tilpligtes derfor loyalt at oplyse vikarbureauet om eventuelle
forpligtelser i henhold til ovenstående.” `}
            </p>
          </li>
          <li>
            <p>
              {`Pauser og bespisning: Det påligger Virksomheden at tilbyde midlertidigt
personale pauser af passende længde set i forhold til arrangementets varighed. På
vagter over 8 timer er samarbejdspartneren forpligtet til at give en 20 minutters
sammenhængende spisepause inkl. personalemad. Pausen betales
af Virksomhed. `}
            </p>
          </li>
        </ol>
      </div>
      <p className="pointer-header">{`§ 8 Sygdom`}</p>
      <div>
        <ol>
          <li>
            <p>
              {`I tilfælde af sygemeldinger, vil Virksomheden blive meddelt dette
straks. TjenerTeamet vil forsøge at finde andet midlertidigt personale, men kan ikke
garantere erstatning for sygemeldinger. Virksomheden bliver ikke faktureret
for sygemeldt midlertidigt personale. `}
            </p>
          </li>
        </ol>
      </div>
      <p className="pointer-header">{`§ 9 Fakturering`}</p>
      <div>
        <ol>
          <li>
            <p>
              {`TjenerTeamet fakturerer hver mandag for den forgående uge.
Betalingsbetingelserne er 4 dage netto til Danske Bank konto: 3409 12306326. `}
            </p>
          </li>
          <li>
            <p>
              {`Ved bestillinger på mere end 15 tjenere til samme arrangement, eller
arrangementer indenfor én uge, skal 75% af den estimerede pris indbetales senest
7 dage før arrangementet/arrangementerne på PersonaleLand's ApS konto. Ved
hastebestillinger på mere end 15 tjenere der indløber til TjenerTeamet umiddelbart
før arrangements-datoen skal 75% betales umiddelbart efter bookingen af
personale er bekræftet på platformen (senest 24 timer). `}
            </p>
          </li>
          <li>
            <p>
              {`Ved bestillinger på mere end 40 tjenere til samme arrangement, skal 25% af den
estimerede arrangementspris forudbetales 7 dage efter bestillingen er godkendt af
begge parter. Yderligere 50% af arrangementsprisen skal være betalt 7 dage før
arrangementsdatoen. `}
            </p>
          </li>
          <li>
            <p>
              {`For alle arrangementer over 15 tjenere betales de sidste 25% efter arrangementet
efter gældende faktureringsbetingelser. `}
            </p>
          </li>
          <li>
            <p>
              {`Overstiger TjenerTeamet's tilgodehavende hos samarbejdspartneren på mere end
kr. 12.000,- ex. moms skal denne konto udlignes, idet der ellers ikke er muligt at
sende mere personale ud til samarbejdspartneren. `}
            </p>
          </li>
        </ol>
      </div>
      <p className="pointer-header">{`§ 10 Arbejdsskadeforsikring`}</p>
      <div>
        <ol>
          <li>
            <p>
              {`Midlertidigt personale booket gennem TjenerTeamet er omfattet af samme
arbejdsskadeforsikring som Virksomheds andre medarbejdere. Har Virksomhed
ingen arbejdsskadedækning for TjenerTeamets personale, er de dækket af
TjenerTeamets arbejdsskadeforsikring. `}
            </p>
          </li>
        </ol>
      </div>
      <p className="pointer-header">
        {`§ 11 TjenerTeamets personale (rekruttering)`}
      </p>
      <div>
        <ol>
          <li>
            <p>
              {`Ved brug/leje/booking af TjenerTeamets personale accepterer Virksomhed
ovenstående aftalevilkår. Virksomheden hermed at have gjort sig bekendt med
indholdet heraf og accepteret aftalen i enhver henseende. TjenerTeamet og
Virksomheden accepterer, at disse handelsbetingelser indgår som en del af enhver
aftale mellem TjenerTeamet og Virksomhed med mindre andet er særskilt skriftligt
aftalt. `}
            </p>
          </li>
          <li>
            <p>
              {`Personale fra TjenerTeamet er forbeholdt TjenerTeamet og skal udelukkende
beskæftiges herigennem. `}
            </p>
          </li>
          <li>
            <p>
              {`Hvis Virksomhed ønsker at ansætte personale fra TjenerTeamet, bedes Virksomhed
sende navnet på hver enkelt medarbejder som ønskes rekrutteret samt dato for
overtagelse af omtalte. Hvis rekrutteringen godkendes vil der blive opkrævet et
rekrutteringshonorar på 4.900 DKK.`}
            </p>
          </li>
          <li>
            <p>
              {`Henvender Virksomhed sig direkte til TjenerTeamets personale angående
ansættelse af disse, betragtes dette som et ønske om rekruttering af
medarbejderen. `}
            </p>
          </li>
          <li>
            <p>
              {`Såfremt Virksomhed rekrutterer personale fra os og ikke kontakter TjenerTeamet i
den forbindelse, vil der blive opkrævet et yderligere tillæg på 2.500 DKK udover
rekrutteringshonoraret på 4.900 DKK.`}
            </p>
          </li>
          <li>
            <p>
              {`Opfordrer Virksomhed personale fra TjenerTeamet til at tage yderligere arbejde hos
Virksomhed, uden at det foregår igennem TjenerTeamet, vil det udløse en bødestraf
på 1.000 DKK. `}
            </p>
          </li>
          <li>
            <p>
              {`Modtager Virksomhed jobansøgninger fra vores medarbejdere, skal Virksomhed
uden ugrundet ophold kontakte TjenerTeamet herom. Dette gælder uden
undtagelser. `}
            </p>
          </li>
          <li>
            <p>
              {`Er du i tvivl om ovenstående, kontakt da TjenerTeamet for yderligere information.`}
            </p>
          </li>
        </ol>
      </div>
      <p style={{ fontWeight: "bold" }}>
        {`Ved brug/leje/booking af TjenerTeamets personale accepterer Virksomhed
ovenstående aftalevilkår. Virksomheden hermed at have gjort sig bekendt med
indholdet heraf og accepteret aftalen i enhver henseende. TjenerTeamet og
Virksomheden accepterer, at disse handelsbetingelser indgår som en del af enhver
aftale mellem TjenerTeamet og Virksomhed med mindre andet er særskilt skriftligt
aftalt..`}
      </p>
    </div>
  );
};

const componentStyle = () => css`
  max-width: 40rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  
  p {
    font-size: 0.85rem;
    margin: 0.85rem 0;
    color: ${colors.black};
  }

  p.header-title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }

  hr.liner {
    border-color: ${colors.grey};
    margin-bottom: 0.5rem;
  }

  p.title {
    font-size: 2rem;
    font-weight: bold;
  }

  p.pointer-header {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 0.85rem;
    font-family: "Calibri";
  }

  ol {
    list-style: decimal;
    margin-left: 2rem;
  }

  li {
    font-size: 0.85rem;
    margin: 0.5rem 0;

    p {
      padding: 0 0.5rem;
      font-size: 0.9rem;
      font-family: "Calibri";
      font-weight: 500;
    }
  }

  a {
    color: ${colors.blue};
  }
  a:hover {
    color: ${colors.darkBlue};
  }
`;

export default CorporationContractModal;
