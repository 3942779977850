import moment from "moment";

export const informationAboutYouValidation = [
  {
    field: "birth_date",
    method: date => {
      // It's it not selected
      if (date === "") {
        return true;
      }


      let selectedDate = moment(date);
      let newDate = moment();

      // Check if the user is 18 or older
      if (moment().diff(selectedDate, "years") < 18) {
        return true;
      }

      if (selectedDate.year() > newDate.year()) {
        return true;
      } else if (
        selectedDate.year() === newDate.year() &&
        selectedDate.month() > newDate.month()
      ) {
        return true;
      } else if (
        selectedDate.year() === newDate.year() &&
        selectedDate.month() === newDate.month() &&
        selectedDate.date() > newDate.date()
      ) {
        return true;
      } else if (
        selectedDate.year() === newDate.year() &&
        selectedDate.month() === newDate.month() &&
        selectedDate.date() === newDate.date()
      ) {
        return true;
      } else {
        return false;
      }
    },
    validWhen: false,
    message: "Vælg anden dato"
  },
  {
    field: "city",
    method: "isEmpty",
    validWhen: false,
    message: "Angiv by"
  },
  {
    field: "phone",
    method: "isEmpty",
    validWhen: false,
    message: "Angiv et telefon nr."
  },
  {
    field: "phone",
    method: "isByteLength",
    args: [{ min: 8, max: 8 }],
    validWhen: true,
    message: "Ikke gyldigt telefon nr."
  },
  {
    field: "phone",
    method: "isInt",
    args: [{ min: 0, max: 99999999 }],
    validWhen: true,
    message: "Ikke gyldigt telefon nr."
  },
  {
    field: "email",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler email"
  },
  {
    field: "email",
    method: "isEmail",
    validWhen: true,
    message: "Ikke gyldig email"
  },
  {
    field: "description",
    method: "isEmpty",
    validWhen: false,
    message: "Du mangler at fortælle om dig selv"
  }
];
