// Libs
import React, { Fragment, useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Utilities
import req from "../../../utilities/request-utility";

// Components
import Spinner from "../../ui/Spinner";

// Actions
import { showModal } from "../../../actions/uiActions";
import { css } from "emotion";
import { breakPoint } from "../../../styles/breakPoints";
import CheckOutOverviewEmployee from "./CheckOutOverviewEmployee";

// Styles
import colors from "../../../styles/colors";
import color_V1 from "../../../styles/colors-V-1.0";
import DeprecatedShift from "./DeprecatedShift";
import InfiniteScroll from "react-infinite-scroller";

const FinishedShifts = props => {
  // Component state
  const [shifts, setShifts] = useState([]);
  const [deprecatedShifts, setDeprecatedShifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPageNumber, setNextPageNumber] = useState(0);
  const [loadingShifts, setLoadingShifts] = useState(false);

  // Fetches the checkout shifts
  useEffect(() => {
    getShifts();
    getDepShifts();
    // eslint-disable-next-line
  }, []);

  const getShifts = useCallback(
    async () => {
      if (loadingShifts) {
        return;
      }

      setLoadingShifts(true);
      const offset = nextPageNumber ? (nextPageNumber*10) : 0;
      const limit = 10;
      try {
        const { data: newShifts } = await req()(`shifts/employee/finished-shifts?offset=${offset}&limit=${limit}`);

        setShifts([...shifts, ...newShifts]);

        if (newShifts.length < limit) {
          setNextPageNumber(false);
        } else {
          setNextPageNumber(nextPageNumber + 1);
        }
      } finally {
        setLoadingShifts(false);
      }
    },
    [shifts, loadingShifts, nextPageNumber]
  );

  const getDepShifts = async () => {
    try {
      setLoading(true);
      const { data: deprecatedShifts } = await req()(
        `employees/shifts/deprecated`
      );
      setDeprecatedShifts(deprecatedShifts);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className={componentStyle(props)}>
      {nextPageNumber === 0 || loading ? <Spinner style={{ marginTop: "2rem" }} /> : <></>}
      {(
        <Fragment>
          <InfiniteScroll
                    loadMore={getShifts}
                    hasMore={nextPageNumber}
                    loader={<Spinner style={{ marginTop: "2rem" }} />}
                    useWindow={false}
                >                
              {shifts.length > 0 &&
                shifts.map(shift => (
                      <div>
                        <CheckOutOverviewEmployee
                          className="check-out-overview"
                          shift={shift}
                          getFinishedShifts={getShifts}
                          setLoading={setLoading}
                        />
                        <div className="check-out-separate" />
                      </div>
                ))}
              </InfiniteScroll>
          {deprecatedShifts.length > 0 &&
            deprecatedShifts.map(shift => (
              <div>
                <DeprecatedShift shift={shift} />
              </div>
            ))}
        </Fragment>
      )}

      {!loading && !loadingShifts && shifts.length === 0 && deprecatedShifts.length === 0 && (
        <div className="no-jobs-wrapper">
          <p>Du har ingen jobs, hvor du mangler at stemple ud fra!</p>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  showModal: bindActionCreators(showModal, dispatch)
});

const componentStyle = () => css`
  div.check-out-overview {
    margin: 1rem 0;
    @media screen and (max-width:575px){
      .first-wrapper{
        .right-container{
          .corporation-name-and-rating{
            width:50%;
          }
          .space-container{
            display:none;
          }
          .label-container{
            width:50%;
            label{
              min-width:auto;
              font-size:10.4px;
            }
          }
        }
      }
      .second-wrapper{
        .right-container{
          .info-wrapper{
            .payment-date{
              font-size:8px;
            }
            .finished-booking{
              div{
                &:nth-child(1){
                  flex-wrap: wrap;
                }
              }
            }
          }
          .label-wrapper{
            .row{
              flex-wrap:wrap;
            }
            .label-group{
              label {
                padding:4px 8px;
                font-size:9px;
                min-width: max-content;
                margin-right:3px;
                &:last-child{
                  margin-right:0;
                }
              }
            } 
            label{
              font-size:9.5px;
              width: calc(50% - 10px);
              min-width: calc(50% - 10px);
              margin-bottom: 5px;
              margin-left:5px;
              &.rated , &.not-rated{
                min-width:auto;
                width:auto;
                padding: 3px 5px;
              }
            }
          }
        }
      }
    }
  }

  div.check-out-separate {
    border-top: 1px solid ${color_V1.grey_10};
  }

  div.no-jobs-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    p {
      text-align: center;
      font-size: 1rem;
      color: ${colors.darkerGrey};

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.65rem;
      }
    }
  }
`;

export default connect(null, mapDispatchToProps)(FinishedShifts);
