export const corparationFormValidator = [
  {
    field: "name",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler navn"
  },
  {
    field: "email",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler email"
  },
  {
    field: "email",
    method: "isEmail",
    validWhen: true,
    message: "Ikke gyldig email"
  },
  {
    field: "cvr_nr",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler cvr"
  },
  {
    field: "cvr_nr",
    method: "isLength",
    args: [{ min: 8, max: 8 }],
    validWhen: true,
    message: "Ikke gyldigt CVR"
  },
  {
    field: "address",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler addresse"
  },
  {
    field: "city_zip",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler post nr."
  },
  {
    field: "city",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler by"
  },
  {
    field: "contact_person",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler kontaktperson"
  },
  {
    field: "phone",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler telefon nr."
  },
  {
    field: "billing_email",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler email"
  },
  {
    field: "billing_email",
    method: "isEmail",
    validWhen: true,
    message: "Ikke gyldig email"
  },
  {
    field: "password",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler kodeord"
  },
  {
    field: "password",
    method: "isLength",
    args: [{ min: 8, max: 20 }],
    validWhen: true,
    message: "Kodeordet skal være længere"
  },
  {
    field: "confirmPassword",
    method: "isEmpty",
    validWhen: false,
    message: "Bekræft kodeord"
  },
  {
    field: "confirmPassword",
    method: "isLength",
    args: [{ min: 8, max: 20 }],
    validWhen: true,
    message: "Kodeordet skal være længere"
  },
  {
    field: "confirmPassword",
    method: (confirmation, state) => state.password === confirmation,
    validWhen: true,
    message: "Kodeordene stemmer ikke overens"
  },
  {
    field: "terms_accepted_at",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler at vælge"
  }
];
