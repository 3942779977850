// Libs
import React from "react";
import { css } from "emotion";

// Styles
import colors from "../../styles/colors";

const EmployeeContractModal = () => {
  return (
    <div className={componentStyle()}>
      <p className="header-title">{`KONTRAKT`}</p>
      <hr className="liner" />

      <p className="title">Tilknytningsaftale</p>
      <p>
        Nærværende tilknytningsaftale vedrører tilknytning af dig som
        arbejdstager til Personaleland ApS, cvr. nr. 38718371 (herefter benævnt
        TjenerTeamet). Du tilknyttes TjenerTeamet for den periode, som du har
        accepteret at påtage dig vagter jf. Særskilt beskrivelse, som du
        modtager i separat mail, sms og via profil.
      </p>

      <div>
        <p className="pointer-header">{`Tilknytning som vikar:`}</p>
        <ol>
          <li>
            <p>
              {`Du tilknyttes hermed TjenerTeamet som vikar med henblik på
              midlertidige ansættelser i TjenerTeamet, hvor din arbejdskraft
              stilles til rådighed for TjenerTeamets kunder.`}
            </p>
            <p>
              {`Du betragtes kun som ansat i TjenerTeamet i de perioder, de
              enkelte vikariater varer. Reglerne i denne aftale gælder for hvert
              enkelt vikariat, som du påtager dig.`}
            </p>
            <p>
              {`Såfremt du er omfattet af ansættelsesbevisloven, udgør nærværende
              tilknytningsaftale og selve aftalebekræftelsen tilsammen din
              ansættelseskontrakt.`}
            </p>
            <p>
              {`Du har ret til at afslå et tilbudt arbejde som vikar. I en
              eventuel opsigelsesperiode er du dog forpligtet til at påtage dig
              et andet passende vikariat med henblik på at begrænse
              TjenerTeamets tab mest muligt.`}
            </p>
          </li>
        </ol>
        <p className="pointer-header">Arbejdssted mv.</p>
        <ol start={2}>
          <li>
            <p>
              {`Arbejdsstedet er hos TjenerTeamets kunder. Begyndelsestidspunkt,
              varighed, løn og arbejdstid aftales særskilt i forbindelse med
              hvert enkelt vikariat.`}
            </p>
          </li>
        </ol>
        <p className="pointer-header">{`Ophør af vikariat`}</p>
        <ol start={3}>
          <li>
            <p>
              {`Hvis vikariatet er omfattet af en overenskomst, vil den fremgå af
              jobbeskrivelsen, og vikariatet kan bringes til ophør af begge
              parter med overenskomstens varsler.`}
            </p>
            <p>
              {`Hvis vikariatet ikke er omfattet af en overenskomst, kan
              vikariatet af begge parter bringes til ophr uden varsel.`}
            </p>
          </li>
        </ol>
        <p className="pointer-header">{`Lønudbetaling`}</p>
        <ol start={4}>
          <li>
            <p>
              {`Lønnen udbetales månedsvis bagud på baggrund af korrekt udfyldte
              timesedler, der er godkendt af den virksomhed, hvor arbejdet er
              udført samt TjenerTeamet. Lønnen er til disposition senest sidste
              bankdag i måneden efter endt lønperiode som er d. 16. til d. 15.`}
            </p>
            <p>
              {`Timerne skal være indskrevet hos TjenerTeamet senest 24 timer
              efter endt vikariat. Du er berettiget til den afviklede løn,
              uanset om der opstår uenighed mellem TjenerTeamet og kunden om
              kvaliteten af dit arbejde.`}
            </p>
          </li>
        </ol>
        <p className="pointer-header">{`Sygdom`}</p>
        <ol start={7}>
          <li>
            <p>
              Sygdom skal hurtigst muligt og senest kl. 08.00 på arbejdsdagen
              begyndelse meddeles til TjenerTeamet online via gældende job.
            </p>
            <p>
              Dokumentation for sygdommen i form af tro- og loveerklæring eller
              lægeerklæring skal kun fremsendes på foranledning af TjenerTeamet.
            </p>
          </li>
        </ol>
        <p className="pointer-header">{`Ferie`}</p>
        <ol start={8}>
          <li>
            <p>
              {`Du har ret til ferie med feriegodtgørelse i overensstemmelse med
              ferieloven.`}
            </p>
          </li>
        </ol>
        <p className="pointer-header">{`Persondata`}</p>
        <ol start={9}>
          <li>
            <p>
              {`Du giver hermed samtykke til, at oplysninger du har givet om dig
              selv kan registreres af TjenerTeamet, idet registreringen er
              nødvendig for vurdering af en ansøgning som vikar i TjenerTeamet.`}
            </p>
            <p>
              {`Du er bekendt med, at samtykket til enhver tid kan tilbagekaldes.
              Endvidere meddeler du samtykke til, at TjenerTeamet kan indhente
              referencer om tidligere ansttelsesforhold ifølge de oplysninger,
              du har givet herom, idet det er nødvendigt for vurderingen af en
              ansøgning som vikar i TjenerTeamet.`}
            </p>
            <p>
              {`Du meddeler tillige samtykke til, at TjenerTeamet kan foretage
              registrering af disse oplysninger. Du giver ved din
              underskrift/samtykke endvidere tilladelse til, at TjenerTeamet kan
              videregive de registrerede oplysninger om dig til brug for
              vurdering af et konkret vikariat.`}
            </p>
          </li>
        </ol>
        <p className="pointer-header">{`Jobtilbud og kontaktoplysninger`}</p>
        <ol start={10}>
          <li>
            <p>
              {`Du meddeler ved din underskrift samtykke til, at jobtilbud og
              andre meddelelser fra TjenerTeamet kan fremsendes pr. e-mail, sms
              og pushmeddelse, og forpligter dig til dagligt at kontrollere, om
              der er fremsendt e-mail fra TjenerTeamet`}
            </p>
          </li>
          <li>
            <p>
              {`Du er til enhver tid forpligtet til at holde TjenerTeamet
              underrettet om din bopæl, dit telefonnummer og din e-mailadresse.`}
            </p>
          </li>
        </ol>
        <p className="pointer-header">{`Instruktion mv.`}</p>
        <ol start={12}>
          <li>
            <p>
              {`Du er forpligtet til at følge kundens arbejdsreglement og
              arbejdstid samt arbejde efter kundens instruks og under kundens
              kontrol. Hvis der opstår problemer i løbet af vikariatet, bedes du
              kontakte os, så vi kan finde en løsning.`}
            </p>
          </li>
        </ol>
        <p className="pointer-header">{`Arbejdsskadeforsikring`}</p>
        <ol start={13}>
          <li>
            <p>
              {`Kunden sørger for, at du er forsikret med den lovpligtige
              arbejdsskadeforsikring. Kommer du til skade hos kunden i
              forbindelse med arbejdsudførsel, skal dette meddeles både kunden
              og TjenerTeamet.`}
            </p>
          </li>
        </ol>
        <p className="pointer-header">{`Arbejdsskadeforsikring`}</p>
        <ol start={14}>
          <li>
            <p>
              {`Du m ikke indgå aftaler om dine ansttelsesvilkår direkte med
              kunden. Eventuelle aftaler om ansttelsesvilkår, der er indgået
              uden TjenerTeamets medvirken, kan ikke gøres gældende mod
              TjenerTeamet.`}
            </p>
          </li>
        </ol>
        <p className="pointer-header">{`Tavshedspligt`}</p>
        <ol start={15}>
          <li>
            <p>
              {`Der påhviler dig tavshedspligt om såvel TjenerTeamets forhold og
              kundens forhold. Tavshedspligten gælder såvel under
              tilknytningen/vikariatet som indtil 3 år efter ophøret heraf, jf.
              markedsføringslovens §19.`}
            </p>
          </li>
        </ol>
        <p className="pointer-header">{`Gyldighed af nærværende aftale`}</p>
        <ol start={16}>
          <li>
            <p>
              {`Nærværende jobbekræftelse udgør sammen med
              tilknytningsaftalen/ansættelsesaftalen vikarens ansættelsesbevis,
              og er gyldig, når du godkender at påtage dig en vagt gennem
              TjenerTeamet. Du godkender dine vagter online gennem TjenerTeamets
              portaler.`}
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

const componentStyle = () => css`
  max-width: 40rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;

  p {
    font-size: 0.85rem;
    margin: 0.85rem 0;
    color: ${colors.black};
  }

  p.header-title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }

  hr.liner {
    border-color: ${colors.grey};
    margin-bottom: 0.5rem;
  }

  p.title {
    font-size: 2rem;
    font-weight: bold;
  }

  p.pointer-header {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 0.85rem;
    font-family: "Calibri";
  }

  ol {
    list-style: decimal;
    margin-left: 2rem;
  }

  li {
    font-size: 0.85rem;
    margin: 0.5rem 0;

    p {
      padding: 0 0.5rem;
      font-size: 0.9rem;
      font-family: "Calibri";
      font-weight: 500;
    }
  }

  a {
    color: ${colors.blue};
  }
  a:hover {
    color: ${colors.darkBlue};
  }
`;

export default EmployeeContractModal;
