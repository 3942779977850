// Libs
import React, { useState } from "react";
import { css } from "emotion";
import tinycolor from "tinycolor2";
import { useDispatch } from "react-redux";

// Styles
import colors from "../../styles/colors";

// Components
import Spinner from "../ui/Spinner";

// Utilities
import req from "../../utilities/request-utility";

// Actions
import { addToast, hideModal } from "../../actions/uiActions";
import { updateSignInEmployee } from "../../actions/authActions";

const EmployeeTransferOldAccount = props => {
  const dispatch = useDispatch();

  const [firstCpr, setFirstCpr] = useState("");
  const [lastCpr, setLastCpr] = useState("");

  const [loading, setLoading] = useState(false);

  const transferOldProfile = async () => {
    try {
      setLoading(true);
      const { data } = await req().put(`employees/transfer-old-profile`, {
        cpr: firstCpr + lastCpr
      });

      dispatch(
        addToast({
          message: "Din profil er blevet overført",
          type: "success",
          autoDismiss: true
        })
      );
      dispatch(hideModal());
      localStorage.setItem("employee_jwt_access_token", data.token);
      dispatch(
        updateSignInEmployee({ employee: data.employee, token: data.token })
      );
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch(
          addToast({
            message: "Vi kunne desværre ikke finde en bruger på dét cpr-nummer",
            type: "warning",
            autoDismiss: true
          })
        );
      }
      if (error.response && error.response.status === 401) {
          dispatch(
              addToast({
                  message: "Du har allerede overtaget din tidligere profil",
                  type: "warning",
                  autoDismiss: true
              })
          );
      }
      else {
        dispatch(
          addToast({
            message:
              "Noget gik galt, da vi prøvede at finde din gamle profil. Hvis problemet fortsætter, kontakt kontakt@TjenerTeamet.dk",
            type: "warning",
            autoDismiss: true
          })
        );
      }
      setLoading(false);
    }
  };

  return (
    <div className={componentStyle(props)}>
      <p className="title">Har du en gammel profil?</p>
      <p className="description">
        Ved at indtaste dit cpr-nummer forneden, vil systemet automatisk søge
        efter din tidligere profil og overføre alle dine informationer til det
        nye system
      </p>
      {!loading && (
        <>
          <label>Indtast dit cpr-nummer</label>
          <div className="cpr-container">
            <input
              maxLength={6}
              className="first"
              value={firstCpr}
              onChange={e => setFirstCpr(e.target.value)}
            />
            <span>-</span>
            <input
              maxLength={4}
              className="last"
              value={lastCpr}
              onChange={e => setLastCpr(e.target.value)}
            />
          </div>
        </>
      )}

      {loading && <Spinner className="spinner" />}
      {!loading && (
        <div className="btn-container">
          <button onClick={() => transferOldProfile()}>Jo, overfør nu!</button>
          <button className="cancel" onClick={() => dispatch(hideModal())}>
            Nej, ellers tak
          </button>
        </div>
      )}
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  max-width: 25rem;

  .spinner {
    margin-top: 1rem;
  }

  p.title {
    font-size: 1.25rem;
    font-weight: bold;
  }

  p.description {
    margin-top: 0.5rem;
  }

  label {
    font-size: 1rem;
    margin-top: 2rem;
  }

  .cpr-container {
    display: flex;
    align-items: center;
    width: 15rem;

    input {
      width: 100%;
      padding: 0.15rem 1rem;
      border-radius: 3px;
      border: 1px solid ${colors.darkGrey};
      letter-spacing: 5px;

      &.first {
        flex: 1;
      }
      &.last {
        width: 6rem;
      }
    }
    span {
      font-weight: bold;
      margin: 0 0.5rem;
    }
  }

  .btn-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    button {
      margin-top: 2rem;
      background-color: ${colors.primeBlue};
      padding: 0.5rem;
      border-radius: 5px;
      color: ${colors.white};
      font-weight: 500;
      outline: none;

      &:active {
        background-color: ${tinycolor(colors.primeBlue)
          .darken(10)
          .toString()};
        transition: background-color 120ms ease;
      }

      &:hover {
        background-color: ${tinycolor(colors.primeBlue)
          .darken(5)
          .toString()};
        transition: background-color 80ms ease;
      }

      &.cancel {
        margin-top: 0.5rem;
        background-color: ${colors.medDarkGrey};

        &:active {
          background-color: ${tinycolor(colors.medDarkGrey)
            .darken(10)
            .toString()};
          transition: background-color 120ms ease;
        }

        &:hover {
          background-color: ${tinycolor(colors.medDarkGrey)
            .darken(5)
            .toString()};
          transition: background-color 80ms ease;
        }
      }
    }
  }
`;

export default EmployeeTransferOldAccount;
