// Libs
import React, { Fragment } from "react";
import ReactStars from "react-stars";
import { css } from "emotion";

// Utilities and config
import { addCommas } from "../../utilities/add_commas";

// Styles
import colors from "../../styles/colors";

// Config
import userTypes from "../../config/userTypes";

const StatusBar = (props) => {

  // Data

  return (
    <div className={componentStyle()}>
      <div className="rating-container">
        <div>
          <p className="rating">{addCommas(props.meta.rating ? props.meta.rating : 0)}</p>
        </div>
        <div className="stars-container">
          <ReactStars
            count={5}
            edit={false}
            value={props.meta.rating ? props.meta.rating : 0}
            size={16}
          />
        </div>
        <Fragment>
          <div>
            <p className="rating-title">Mine ratings</p>
          </div>
        </Fragment>
      </div>

      {props.staffType === userTypes.employee && (
        <Fragment>
          <div className="jobs-container">
            <div style={{ paddingTop: "0.5rem" }}>
              <p className="jobs">{props.meta.shiftsCount ? props.meta.shiftsCount : 0}</p>
            </div>
            <div>
              <p className="jobs-title">Jobs taget</p>
            </div>
          </div>

          <div className="jobs-container">
            <div style={{ paddingTop: "0.5rem" }}>
              <p className="jobs" style={{ color: `${colors.red}` }}>
                {props.meta.cancelledShiftsCount ? props.meta.cancelledShiftsCount : 0}
              </p>
            </div>
            <div>
              <p className="jobs-title">Aflysninger</p>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const componentStyle = () => css`
  width: 100%;
  display: flex;
  align-items: baseline;

  .rating-container {
    display: flex;
    min-width: 7rem;
    flex-direction: column;
    justify-content: center;

    .rating {
      text-align: center;
      font-size: 1.5rem;
    }

    .rating-title {
      text-align: center;
      font-size: 13px;
      font-weight: 500;
    }

    .stars-container {
      display: flex;
      justify-content: center;
      margin-bottom: 0.25rem;
    }

    .ratings-sub-title {
      text-align: center;
      font-size: 0.75rem;
    }
  }

  .jobs-container {
    display: flex;
    flex-direction: column;
    min-width: 6rem;
    justify-content: center;

    .jobs {
      text-align: center;
      font-size: 1.5rem;
      color: ${colors.black};
    }

    .jobs-title {
      text-align: center;
      font-size: 13px;
      font-weight: 500;
    }
  }
`;

export default StatusBar;
