import React from "react";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import { css } from "emotion";
import colors from "../../styles/colors";
import { breakPoint } from "../../styles/breakPoints";

const NavigateBackArrow = props => {
  return (
    <div onClick={() => props.navigateURL()} className={`${componentStyle(colors)} ${props.className}`}>
      <ArrowLeftIcon size={24} color={colors.lightBlue} />
      <p>TILBAGE</p>
    </div>
  );
};

const componentStyle = colors => css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0.25rem;
  margin: 1rem;
  width: 8.8rem;
  border: 1px solid ${colors.lightBlue};

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  cursor: pointer;

  @media screen and (max-width: ${breakPoint.sm}px) {
    width: 7rem;
  }

  &:hover {
    border: 1px solid ${colors.darkBlue};
    svg {
      fill: ${colors.darkBlue};
    }

    p {
      color: ${colors.darkBlue};
    }
  }

  svg {
    margin-right: 0.5rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      height: 1rem;
    }
  }

  p {
    color: ${colors.lightBlue};

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.75rem;
    }
  }
`;

export default NavigateBackArrow;
