// Libs
import React, { useEffect, useState } from "react";

// Styles
import { css } from "emotion";
import colors from "../../styles/colors";

// Config
import { fifteenMinutes, hours } from "../corporation/newBooking/config";

// Components
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";

const TimeChanger = props => {
  const [time, setTime] = useState({
    start: {
      hours: "00",
      minutes: "00"
    },
    end: {
      hours: "00",
      minutes: "00"
    }
  });

  useEffect(() => {
    let obj = {
      start: {
        hours: "00",
        minutes: "00"
      },
      end: {
        hours: "00",
        minutes: "00"
      }
    };

    if (props.startHours) obj.start.hours = props.startHours;
    if (props.startMinutes) obj.start.minutes = props.startMinutes;
    if (props.endHours) obj.end.hours = props.endHours;
    if (props.endMinutes) obj.end.minutes = props.endMinutes;

    setTime(obj);
  }, []);

  return (
    <div className={componentStyle()}>
      {/* TID */}
      <div
        className="outer-container"
        style={{ justifyContent: "space-evenly" }}
      >
        {/* Timer */}
        <div className="time-container">
          <div style={{ position: "relative" }}>
            <select
              value={time.start.hours}
              onChange={e =>
                setTime({
                  ...time,
                  start: { ...time.start, hours: e.target.value }
                })
              }
            >
              {hours.map((hour, index) => (
                <option key={index} value={hour.value}>
                  {hour.value}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Minutter */}
        <div className="time-container">
          <select
            value={time.start.minutes}
            name="start_minutes"
            onChange={e =>
              setTime({
                ...time,
                start: { ...time.start, minutes: e.target.value }
              })
            }
          >
            {fifteenMinutes.map((minutes, index) => (
              <option key={index} value={minutes.value}>
                {minutes.value}
              </option>
            ))}
          </select>
        </div>
        {/* Klokke Ikon */}
        <div className="colon">
          <ClockOutlineIcon />
        </div>

        {/* Timer */}
        <div className="time-container">
          <div style={{ position: "relative" }}>
            <select
              value={time.end.hours}
              name="end_hours"
              onChange={e =>
                setTime({
                  ...time,
                  end: { ...time.end, hours: e.target.value }
                })
              }
            >
              {hours.map((hour, index) => (
                <option key={index} value={hour.value}>
                  {hour.value}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Minutter */}
        <div className="time-container">
          <select
            value={time.end.minutes}
            name="end_minutes"
            onChange={e =>
              setTime({
                ...time,
                end: { ...time.end, minutes: e.target.value }
              })
            }
          >
            {fifteenMinutes.map((minutes, index) => (
              <option key={index} value={minutes.value}>
                {minutes.value}
              </option>
            ))}
          </select>
        </div>
      </div>

      <button onClick={() => props.onSubmit(time)} className="submit-btn">
        {props.confirmText || "Bekræft"}
      </button>
    </div>
  );
};

const componentStyle = () => css`
  padding: 0 0.35rem 0.35rem;

  label {
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: ${colors.black};
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
    margin-left: 0.25rem;
  }

  select {
    cursor: pointer;
    font-size: 0.75rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 0.5rem;
    border-radius: 0.25rem;
    line-height: 1;
    outline: none;
  }
  select:hover {
    border: 1px solid ${colors.black};
  }

  select::placeholder {
    background-color: ${colors.grey};
  }

  textarea {
    cursor: text;
    display: block;
    font-size: 0.75rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.25rem;
    border-radius: 0.25rem;
    line-height: 1.25;
  }

  textarea:hover {
    border: 1px solid ${colors.black};
  }

  textarea::placeholder {
    color: ${colors.darkerGrey};
  }

  .outer-container {
    display: flex;
    justify-content: start;
    margin-bottom: 0.5rem;
    align-items: center;
    min-width: 17rem;

    .staff-container {
      margin-right: 0.5rem;
    }

    .date-container {
      margin-right: 0.5rem;
    }

    .amount-staff-container {
      width: 3.3rem;
    }
  }

  .colon {
  }

  div.rating-container {
    div.rating {
      display: flex;
      justify-content: center;
    }
  }

  div.comment-container {
    margin-bottom: 1rem;
  }

  button.submit-btn {
    text-align: center;
    width: 100%;
    font-size: 0.85rem;
    margin-top: 0.5rem;
    background-color: ${colors.lightBlue};
    color: ${colors.white};
    padding: 0.35rem 0rem;
    font-weight: bold;
    border-radius: 5px;
  }

  button.submit-btn:hover {
    background-color: ${colors.blue};
  }
`;

export default TimeChanger;
