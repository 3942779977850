// Libs
import React from "react";
import { css } from "emotion";

const Input = props => {
  return (
    <input
      type={props.type}
      name={props.name}
      maxLength={props.maxLength}
      placeholder={props.placeholder || ""}
      style={props.style && props.style}
      value={props.value}
      onChange={props.onChange}
      className={`${componentStyle(props)} ${
        props.className ? props.className : ""
      }`}
    />
  );
};

const componentStyle = props => css`
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default Input;
