// Libs
import React from "react";
import moment from "moment";
import { css } from "emotion";

// Styles
import colors from "../../styles/colors-V-1.0";
import { breakPoint } from "../../styles/breakPoints";

const DateViewer = props => {
  return (
    <div
      className={`${componentStyle(props)} ${props.className}`}
      style={props.style}
    >
      <p className={`day`}>
        {moment(props.date)
          .format("dddd")
          .slice(0, 3)
          .toUpperCase()}
      </p>
      <p className={`date`}>
        {moment(props.date)
          .format("ll")
          .slice(1, 2)
          .toUpperCase() === "."
          ? moment(props.date)
              .format("ll")
              .slice(0, 1)
              .toUpperCase()
          : moment(props.date)
              .format("ll")
              .slice(0, 2)
              .toUpperCase()}
      </p>
      <p className={`month`}>
        {moment(props.date)
          .format("ll")
          .slice(1, 2)
          .toUpperCase() === "."
          ? moment(props.date)
              .format("ll")
              .slice(3, 6)
              .toUpperCase()
          : moment(props.date)
              .format("ll")
              .slice(4, 7)
              .toUpperCase()}
      </p>
      {props.showYear && (
        <p className="year"> {moment(props.date).format("YYYY")}</p>
      )}
    </div>
  );
};

const componentStyle = props => css`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .date {
    color: ${colors.grey_15};
    font-size: ${props.size + 13 || 25}px;
    line-height: 0.9;
    font-weight: bold;
  }

  .day,
  .month {
    color: ${colors.grey_50};
    font-weight: 500;
    font-size: ${props.size || 12}px;
    letter-spacing: -1px;
  }

  .year {
    font-size: ${props.size || 12}px;
    position: absolute;
    bottom: -5px;
    left: 3px;
  }

  p {
    text-align: center;
  }
`;

export default DateViewer;
