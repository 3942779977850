// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import req from "../../utilities/request-utility";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Styles
import colors from "../../styles/colors";

// Components
import PageContainer from "../ui/PageContainer";
import Spinner from "../ui/Spinner";

// Actions
import { addToast } from "../../actions/uiActions";

// Validation
import FormValidator from "../../validation/formValidator";
import { resetPasswordEmailValidator } from "../../validation/resetPasswordEmailValidator";

const ResetPassword = props => {
  // Used to navigate from to pages
  const history = useHistory();

  // Validation form
  const validator = new FormValidator(resetPasswordEmailValidator);

  const [submitted, setSubmitted] = useState(false);

  const [data, setData] = useState({
    email: "",
    validation: validator.valid()
  });
  const [loading, setLoading] = useState(false);

  const [validation, setValidation] = useState(data.validation);

  const resetPassword = async () => {
    const validation = validator.validate(data);
    const tempData = { ...data };
    tempData.validation = validation;
    setSubmitted(true);

    if (validation.isValid) {
      setLoading(true);
      try {
        await req().post(`corporations/reset-password`, { email: data.email });
        props.addToast({
          message: "Der er blevet sendt et nyt kodeord til dig på mail",
          type: "success"
        });
        history.push("/corporationLogin");
      } catch (err) {
        setLoading(false);
        props.addToast({
          message: "Email eksisterer ikke i systemet",
          type: "error"
        });
      }
    } else {
      setData(tempData);
      props.addToast({
        message: "Feltet er ikke udfyldt korrekt",
        type: "warning",
        autoDismiss: true
      });
    }
  };

  useEffect(() => {
    if (submitted) {
      setValidation(validator.validate(data));
    } else {
      setValidation(data.validation);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <PageContainer showNavigation={true}>
      <div className={componentStyle()}>
        <div className="reset-password-container">
          <p className="header">Glemt dit kodeord?</p>
          <p className="sub-text">
            Vær helt rolig! Udfyld feltet forneden, og så skal vi nok sende et
            link, hvor du kan ændre dit kodeord
          </p>
          <label>Email</label>
          <input
            name="email"
            value={data.email}
            onChange={e =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
          />
          <p className="invalid-text">{validation.email.message}</p>

          {!loading && (
            <button
              type="email"
              disabled={data.email.length < 1}
              className={data.email.length > 0 ? "submit" : "disabled"}
              onClick={() => resetPassword()}
            >
              Nulstil kodeord
            </button>
          )}
          {loading && <Spinner style={{ margin: "1rem 0" }} />}
        </div>
      </div>
    </PageContainer>
  );
};

const componentStyle = () => css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .invalid-text {
    text-align: start;
    color: ${colors.red};
    font-size: 0.75rem;
    margin-left: 0.5rem;
    font-weight: 500;
  }

  div.reset-password-container {
    width: 20rem;
    margin-top: 7.5rem;

    p.header {
      text-align: center;
      font-size: 1.85rem;
      font-weight: 500;
    }

    p.sub-text {
      font-size: 0.75rem;
      text-align: center;
      margin-top: 0.5rem;
      letter-spacing: 1px;
      margin-bottom: 0.5rem;
    }
  }

  button.submit,
  button.disabled {
    padding: 0.5rem 0rem;
    background-color: ${colors.primeBlue};
    border-radius: 5px;
    width: 100%;
    margin: 1rem 0rem;
    color: ${colors.white};
    font-weight: 500;
    outline: none;
  }

  button.disabled {
    background-color: ${colors.grey};
    cursor: no-drop;
  }

  button.submit:hover {
    background-color: ${colors.darkerPrimeBlue};
  }

  input {
    cursor: text;
    display: block;
    font-size: 0.75rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
  }

  input:hover {
    border: 1px solid ${colors.black};
  }

  input::placeholder {
    color: ${colors.darkerGrey};
  }

  label {
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: ${colors.black};
    font-size: 0.6rem;
    margin-bottom: 0.25rem;
    margin-left: 0.25rem;
  }
`;

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch)
});

export default connect(null, mapDispatchToProps)(ResetPassword);
