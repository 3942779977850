export function transportWageTimeConverter(transportWage, language) {
  if (language === "dk") {
    if (transportWage === "0.5" || transportWage === 0.5) {
      return "30 minutters";
    }
    if (transportWage === "1" || transportWage === 1) {
      return "1 time";
    }
    if (transportWage === "1.5" || transportWage === 1.5) {
      return "halvanden time";
    }
    if (transportWage === "2" || transportWage === 2) {
      return "2 timers";
    } else return "";
  }
}
