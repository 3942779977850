// Libs
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { css } from "emotion";
import moment from "moment";
import { useDebouncedCallback } from "use-debounce";

// Components
import Booking from "../Booking";
import Spinner from "../../ui/Spinner";
import ErrorBox from "../../ui/ErrorBox";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { getBookings, resetBookings, changeWeekNumber, changeTab } from "../../../actions/bookingActions";
import bookingStatus from "../../../config/bookingStatus";
import { breakPoint } from "../../../styles/breakPoints";

// Styles
import colors from "../../../styles/colors";
import tinycolor from "tinycolor2";
import ArrowLeftCircleIcon from "mdi-react/ArrowLeftCircleIcon";
import ArrowRightCircleIcon from "mdi-react/ArrowRightCircleIcon";

//Actions
import { addToast } from "../../../actions/uiActions";

const UpcomingBookings = (props) => {
  const history = useHistory();

  
  const { bookings, loading, error, tab, weekNumber, weekNumberToDisplay, totalBookings } = useSelector(
    (state) => state.booking.upcoming
    );
    
  const startDate = moment(moment().week(weekNumber).startOf("week")).format("DD");
  const endDate = moment(moment().week(weekNumber).endOf("week")).format("DD");

  const {changeTab, changeWeekNumber} = props;

  useEffect(() => {
    getBookingDetails();
  }, [tab, weekNumber]);

  // Gets the information on the site
  const [getBookingDetails] = useDebouncedCallback(async () => {
    try {
      let currentWeek = tab == 0 ? 0 : weekNumber;
      props.getBookings({ type: bookingStatus.upcoming, weekNumber: currentWeek });
    } catch (error) {
      props.addToast({
        message: "Noget gik galt. Kunne ikke hente information..",
        type: "error",
        autoDismiss: true,
      });
    }
  }, 100);

  return (
    <div className={componentStyle()}>
      <div className="tab-navigator">
        <div className="wrapper">
          <button
            onClick={() => changeTab(0)}
            className={`${tab === 0 ? "active" : ""}`}
          >
            LISTE({totalBookings})
          </button>
          <button
            onClick={() => changeTab(1)}
            className={`${tab === 1 ? "active" : ""}`}
          >
            VIS UGE
          </button>
        </div>
        {tab === 1 && (
          <div className="week-wrapper">
            <ArrowLeftCircleIcon
              onClick={() => {
                  let visibleWeekNumber = weekNumberToDisplay == 1 ? 52 : weekNumberToDisplay - 1;
                  changeWeekNumber({
                    weekNumber: weekNumber - 1,
                    weekNumberToDisplay: visibleWeekNumber,
                  });
              }}
            />
            <p>{`UGE ${weekNumberToDisplay} (${startDate}-${endDate})`}</p>
            <ArrowRightCircleIcon
              onClick={() => {
                  let visibleWeekNumber = weekNumberToDisplay == 52 ? 1
                      : weekNumberToDisplay + 1;
                  changeWeekNumber({
                    weekNumber: weekNumber + 1,
                    weekNumberToDisplay: visibleWeekNumber,
                  });
              }}
            />
          </div>
        )}
      </div>
      {loading && !error && <Spinner title={"Henter bookinger..."} />}
      {!loading && error && <ErrorBox />}
      {!loading && !error && bookings.length === 0 && (
        <div className="no-bookings-container">
          <p>Du har ikke oprettet nogen bookings endnu</p>
        </div>
      )}


      {
        !loading &&
        !error &&
        bookings.length > 0 &&
        bookings.map((booking, index) => (
          <div
            key={`booking-index-${index}`}
            onClick={() => history.push(`/viewBooking/${booking.id}`)}
          >
            <Booking
              className="upcoming-booking"
              status={"open"}
              booking={booking}
            />
          </div>
        ))}
    </div>
  );
};

const componentStyle = () => css`
  width: 100%;
  height: 100%;

  .tab-navigator {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    .wrapper {
      position: relative;
      display: inline-block;
      border: 1px solid ${colors.primeBlue};
      border-radius: 3px;
      padding: 0.5rem;
      margin-bottom: 1rem;

      button {
        outline: none;
        padding: 0.35rem 0.75rem;
        font-weight: 500;
        border-radius: 3px;

        &.active {
          background-color: ${colors.primeBlue};
          color: ${colors.white};
          padding: 0.35rem 0.6rem;
        }
      }
    }

    .week-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        padding: 0 1rem;
        font-weight: bold;
        color: ${colors.primeBlue};
      }
      svg {
        height: 2rem;
        width: 2rem;
        fill: ${colors.primeBlue};
        cursor: pointer;

        &:active {
          fill: ${tinycolor(colors.primeBlue).darken(5).toString()};
        }
      }
    }
  }

  .upcoming-booking {
    min-height: 9.25rem;
    @media screen and (max-width: ${breakPoint.sm}px) {
      min-height: 5.5rem;
    }
  }

  .no-bookings-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-weight: 500;
    }
  }

  .noBooking {
    text-align: center;
    margin: 30px;
    font-weight: 500
  }
`;

const mapDispatchToProps = (dispatch) => ({
  resetBookings: bindActionCreators(resetBookings, dispatch),
  getBookings: bindActionCreators(getBookings, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  changeWeekNumber: bindActionCreators(changeWeekNumber, dispatch),
  changeTab: bindActionCreators(changeTab, dispatch),
});

export default connect(null, mapDispatchToProps)(UpcomingBookings);
