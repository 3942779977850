// Libs
import React from "react";
import { css } from "emotion";
import tinycolor from "tinycolor2";
import { useHistory } from "react-router";

// Components
import Logo from "../../ui/Logo";
import CorporationNameAndRating from "../../ui/CorporationNameAndRating";
import DateViewer from "../../ui/DateViewer";
import CorporationBookingOverview from "../../ui/CorporationBookingOverview";
import Label from "../../ui/Label";
import Rating from "../../ui/Rating";

// Styles
import colors from "../../../styles/colors-V-1.0";
import moneyBagSVG from "../../../styles/icons/money-bag.svg";
import { breakPoint } from "../../../styles/breakPoints";

// Utilities
import { staffTypeLanguage } from "../../../utilities/language-translator";
import { addCommas } from "../../../utilities/add_commas";
import { remove_commas } from "../../../utilities/remove_commas";
import { workHours } from "../../../utilities/get-work-hours-total";


const CheckOutOverviewEmployee = (props) => {
  const history = useHistory();
  const { shift } = props;

  const {corporation_checkin_time, corporation_checkout_time, employee_checkin_time, employee_checkout_time, checkout_rejected_count_employee, checkout_rejected_count_corporation} = props.shift;
  const employeeCheckedOut = employee_checkin_time && employee_checkout_time;
  const corporationCheckedOut = corporation_checkin_time && corporation_checkout_time;

  const checkoutApproved = employeeCheckedOut && corporationCheckedOut && (corporation_checkin_time == employee_checkin_time && corporation_checkout_time == employee_checkout_time);
  const checkoutRejected = employeeCheckedOut && corporationCheckedOut && (corporation_checkin_time != employee_checkin_time || corporation_checkout_time != employee_checkout_time);
  const checkoutRejectedByCompany = checkoutRejected && shift.last_checkout_by == "corporation"
  const checkoutApprovedByCompany = checkoutApproved && shift.last_checkout_by == "corporation"
  const checkoutApprovedByEmployee = checkoutApproved && shift.last_checkout_by == "employee"
  const checkoutRejectedByEmployee = checkoutRejected && shift.last_checkout_by == "employee"
  const transportWageConverter = {"1.5": "1,5 time", "0.5": "30 min.", "1" : "1 time", "2": "2 time"}

  let mainLabel = {
    text: "",
    class: ""
  }
  if(shift.admin_approve_checkout_shift){
    // admin approved invoice
    mainLabel = {...mainLabel, text: "STATUS:  Godkendt / Afsluttet", class: "success"};
  }
  else if(!employeeCheckedOut){
    // employee did not check out
    mainLabel = {...mainLabel, text: "Medarbejder skal stemple ud!", class: "not"};
  }
  else if(!corporationCheckedOut){
    // employee checked out but corporation did not
    mainLabel = {...mainLabel, text: "STATUS: Venter på virksomhed", class: "pending"};
  }
  else if(checkoutApproved){
    mainLabel = {...mainLabel, text: "STATUS: Godkendt"};
    if(checkout_rejected_count_employee > 0 && checkoutApprovedByCompany){
      mainLabel = {...mainLabel, text: "STATUS: Godkendt ny tid"};
    }
  }
  else if(checkoutRejected){
    mainLabel = {...mainLabel, text: "STATUS: Mangler godkendelse af admin", class: "not"};
    if(shift.first_checkout_by == "corporation" && checkoutRejectedByEmployee){
      mainLabel = {...mainLabel, text: "STATUS: Ny tid venter godkendels"};
    }
    if(shift.first_checkout_by == "employee" && checkoutRejectedByCompany){
      mainLabel = {...mainLabel, text: "STATUS: Afvist / Godkend ny tid"};
    }
  }
  const adminEmployeeCheckoutTimes = checkoutApprovedByCompany ? {start_time: shift.corporation_checkin_time, end_time : shift.corporation_checkout_time } : checkoutApprovedByEmployee ? {start_time: shift.employee_checkin_time, end_time : shift.employee_checkout_time } : {start_time: shift.admin_employee_checkin_time, end_time : shift.admin_employee_checkout_time }

  return (
    <div
      onClick={
        props.shift.admin_approve_checkout
          ? () => {}
          : () => history.push(`/checkout-employees/${shift.id}`)
      }
      className={`${componentStyle(props, corporationCheckedOut)} ${
        props.className ? props.className : ""
      }`}
      style={props.style && props.style}
    >
      {/** 1. section **/}
      <div className="first-wrapper">
        <div className="logo-wrapper">
          <Logo
            className="logo"
            size={50}
            src={
              shift.corporation.baseURL_image &&
              shift.corporation.icon_image &&
              `${shift.corporation.baseURL_image}h_100,w_100/dpr_2.0/r_max/${shift.corporation.icon_image}`
            }
          />
        </div>
        <div className="right-container">
          <CorporationNameAndRating
            className="corporation-name-and-rating"
            name={shift.corporation.name}
            numberOfRatings={shift.corporation.rating.nrOfRatings}
            value={shift.corporation.rating.rating}
          />
          <div className="space-container"></div>
          <div className="label-container">
            {
            mainLabel.text ?
            <Label
              className={`label checked-out ${mainLabel.class}`}
              value={`${mainLabel.text}`}
            />
            :
            <></>
          }
          </div>
        </div>
      </div>
      {/** 2. section **/}
      <div className="second-wrapper">
        <div className="date-wrapper">
          <DateViewer
            showYear={true}
            className="date-style"
            size={22}
            date={shift.booking.date}
          />
        </div>
        <div className="right-container">
          <div className="info-wrapper">
            <CorporationBookingOverview
              arrangementType={shift.booking.arrangement_type}
              address={`${shift.booking.zip_code} ${shift.booking.city} `}
              time={`${
                shift.admin_approve_checkout &&
                shift.admin_employee_checkin_time &&
                shift.admin_employee_checkout_time
                  ? `${shift.admin_employee_checkin_time} - ${shift.admin_employee_checkout_time}`
                  : `${shift.booking.start_time} - ${shift.booking.end_time}`
              }`}
              // price={`${shift.booking.hourly_wage} kr./t`}
              staffType={`${staffTypeLanguage(shift.booking.staff_type, "dk")}`}
              // priceIcon={moneyBagSVG}
              approveByAdmin={shift.admin_approve_checkout_shift}
              paymentDate={shift.paymentDate}
              className="finished-booking"
              rows={2}
            />
          </div>
          <div className="label-wrapper">
          <div className="row">
            {employeeCheckedOut ? (
              <Label
                className={`label checked-out ${checkoutRejectedByCompany || (checkoutRejected && checkout_rejected_count_corporation > 0) ? 'rejected'
                  : checkoutApprovedByCompany ? 'success' 
                  : 'pending'}`}
                value={`Stemplet ud: ${shift.employee_checkin_time}-${shift.employee_checkout_time} [${workHours({start_time: shift.employee_checkin_time, end_time: shift.employee_checkout_time, changeTimeFormat: true })}]`}
              />
            ) :
            (
              <Label
                notifications={!shift.admin_approve_checkout_shift && 1}
                className="label checked-out not"
                value={"Afslut job / Stempel ud"}
              />
            )}

            {
              corporationCheckedOut ? (
                <Label
                  className={`label checked-out ${checkoutRejectedByCompany && !checkout_rejected_count_employee ? 'rejected-outline'
                    : checkoutRejected ? 'rejected'
                    : checkoutApprovedByEmployee ? 'success'
                    : ''
                  }`}
                  notifications={checkoutRejectedByCompany && !shift.admin_approve_checkout_shift && !checkout_rejected_count_employee && 1}
                  value={`Virksomhed: ${shift.corporation_checkin_time}-${shift.corporation_checkout_time} [${workHours({start_time: shift.corporation_checkin_time, end_time: shift.corporation_checkout_time, changeTimeFormat: true })}]`}
                />
              ) : <div className="space-container"></div>
            }
            {shift.admin_approve_checkout_shift &&
                (
                <Label
                  className="label checked-out"
                  value={`Afsluttet: ${adminEmployeeCheckoutTimes.start_time}-${adminEmployeeCheckoutTimes.end_time} [${workHours({start_time: adminEmployeeCheckoutTimes.start_time, end_time: adminEmployeeCheckoutTimes.end_time, changeTimeFormat: true })}]`}
                />
              )}
          </div>
          <div className="row">
            {shift.employee_rated_corporation && (
              <Label
                className="label rated"
                value={
                  <Rating
                    color={colors.orange_50}
                    count={5}
                    value={shift.employee_rated_corporation}
                    size={10.4}
                    className={`rating`}
                  />
                }
              />
            )}
            {!shift.employee_rated_corporation && (
              <Label className="label not-rated" value={"Angiv rating"} />
            )}
              <>
                <Label
                  className={`label checked-out ${checkoutApproved && 'success'} ${shift.admin_approve_checkout_shift && shift.booking.transport_wage && shift.booking.transport_wage != 0 ? '' : 'hide'}`}
                  value={`kørselstillæg: ${transportWageConverter[shift.booking.transport_wage]}`}
                />
                {(shift.admin_approve_checkout_shift || checkoutApproved) && (
                  <div className="label-group">
                    <Label
                      className={`checked-out success`}
                      value={`[${parseFloat(
                          workHours({
                            start_time: adminEmployeeCheckoutTimes.start_time || shift.booking.start_time,
                            end_time: adminEmployeeCheckoutTimes.end_time || shift.booking.end_time
                          })
                        ) + parseFloat(shift.booking.transport_wage)
                        }]`.replace('.', ',')}
                    />
                    <Label
                      className={`checked-out success`}
                      value={`${shift.booking.hourly_wage} kr/t`}
                    />
                    <Label
                      className={`checked-out success`}
                      value={`${addCommas(
                          remove_commas(shift.booking.hourly_wage) *
                          (parseFloat(
                            workHours({
                              start_time: adminEmployeeCheckoutTimes.start_time || shift.booking.start_time,
                              end_time: adminEmployeeCheckoutTimes.end_time || shift.booking.end_time,
                            })
                          ) +
                            parseFloat(shift.booking.transport_wage))
                        )} kr`}
                    />
                  </div>
                )}
              </>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const componentStyle = (props, corporationCheckedOut) => css`
@media screen and (max-width: 575px) {
  .logo-wrapper{
    width: 2.75rem;
  }
  .date-wrapper{
    width: 2.75rem;
    .year{
      position: initial;
    }
  }
  .label-wrapper {
    display: flex;
    margin: 0 -10px;
    .row{
      flex-direction: column;
      flex: 1;
      padding: 0 10px;
      margin: 10px 0px 0 !important;
      .hide{
        ${(!corporationCheckedOut || !props.shift.admin_approve_checkout_shift) && 'display: none' }
      }
      .label-group{
        width: 100% !important;
        margin: 0 !important;
        label{
          font-size: 9.5px !important;
          text-align: center;
        }
      }
      label{
        width: 100% !important;
        margin-left: 0!important;
      }
    }
  }
}

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: ${props.shift.admin_approve_checkout ? "unset" : "pointer"};
  border-radius: 3px;
  color: #2e2e2e;

  &:hover {
    border: ${props.shift.admin_approve_checkout
      ? "none"
      : `1px solid ${colors.grey_10};`};
  }

  div.first-wrapper {
    display: flex;
    .right-container {
      display: flex;
      margin: 10px -5px 0;
      flex: 1;
      padding: 0 0 0.5rem 0;
      .corporation-name-and-rating, .space-container, .label-container  {
          width: 33%;
          margin: 0 5px;
      }
    }
    .label {
      font-weight: bold;
      padding: 0.25rem;
      font-size: 0.65rem;
      border-radius: 3px;
      background: ${tinycolor(colors.grey_5).darken(3).toString()};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: .4rem;
      min-width: 12rem;
      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.4rem;
      }
    }
    .checked-out {
      border-radius: 3px;

      &.not {
        color: ${colors.red_30};
      }

      &.success {
        color: ${colors.green_50};
      }
      &.pending {
        color: #2e2e2e;
      }

    }
    .logo-wrapper {
      width: 4.5rem;
      display: flex;
      justify-content: start;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2.25rem;
      }

      .logo {
        @media screen and (max-width: ${breakPoint.sm}px) {
          height: ${props.size - 15 || 30}px;
          width: ${props.size - 15 || 30}px;
        }
      }
    }

    .corporation-name-and-rating {
      display: flex;
      justify-content: center;
    }
  }

  div.second-wrapper {
    display: flex;

    .date-wrapper {
      display: flex;
      align-items: flex-start;
      width: 4.5rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2.25rem;
      }

      .date-style {
        .day,
        .month {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: ${props.size - 20 || 12}px;
          }
        }

        .date {
          font-size: 40px;
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: ${props.size - 17 || 25}px;
          }
        }

        .year {
          left: 0;
          font-size: 21px;
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 10px;
            bottom: 15px;
          }
        }
      }
    }
    .right-container{
      flex:1;

      .info-wrapper {
        flex: 1;
        background-color: ${colors.white};
        border-radius: 3px;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
          0 10px 10px -5px rgba(0, 0, 0, 0.04);

        .finished-booking{
          min-height: inherit;
          .approved {
            color: ${colors.green_50};
          }
          .staff-type {
            font-size: 13px;
            text-transform: uppercase;
            margin-right: 0.5rem;
          }
          .arrangement-type {
            font-size: 1rem;
          }
          .address {
            font-size: 0.6rem;
          }
          .time{
            font-size: 1rem;
          }
        }
      }
      .label-wrapper{
        .row{
          display: flex;
          margin: 10px -5px 0;
        }
        .label-group{
          display: flex;
          width: 33%;
          justify-content: space-between;
          margin: 0 5px;
          label {
            font-weight: bold;
            padding: 0.25rem 0.7rem;
            font-size: 0.65rem;
            border-radius: 3px;
            background: ${tinycolor(colors.grey_5).darken(3).toString()};
            @media screen and (max-width: ${breakPoint.sm}px) {
              font-size: 0.4rem;
            }
            }
        }
        .hide{
          visibility: hidden;
        }
        .space-container {
          width: 33%;
          margin: 0 5px;
        }

        .label {
          font-weight: bold;
          padding: 0.25rem 0;
          font-size: 0.65rem;
          border-radius: 3px;
          background: ${tinycolor(colors.grey_5).darken(3).toString()};
          text-align: center;
          width: 33%;
          margin: 0 5px;
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 0.4rem;
          }
        }
      }

  }


    .checked-out {
      border-radius: 3px;
      &.rejected {
        color: #a83b3b;
      }
      &.rejected-outline {
        border: 1px solid ${colors.red_30};
      }

      &.not {
        color: ${colors.red_30};
        border: 1px solid ${colors.red_30};
      }

      &.success {
        color: ${colors.green_50};
      }

      &.pending {
        color: #2e2e2e;
      }
    }

    .not-rated {
      color: #2e2e2e;
      min-width: 10rem;
      border: 1px solid ${colors.orange_50};
    }

    .rated {
      padding: 0 1.25rem;
      .rating {
        justify-content: center;
      }

    }
  }

`;


export default CheckOutOverviewEmployee;
