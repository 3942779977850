// Libs
import React, { useEffect, useState } from "react";

// Utilities
import { useHistory } from "react-router";
import req from "../../../utilities/request-utility";

// Components
import Booking from "../../corporation/Booking";
import PageContainer from "../../ui/PageContainer";
import NavigateBackArrow from "../../ui/NavigateBackArrow";
import Spinner from "../../ui/Spinner";
import colors from "../../../styles/colors";

// Styles
import { css } from "emotion";

const ApproveBookingOverview = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [notApprovedBookings, setNotApprovedBookings] = useState([]);

  useEffect(() => {
    req()("admin/bookings/waiting-approval").then(({ data: bookings }) => {
      setNotApprovedBookings(bookings);
      setLoading(false);
    });
  }, []);

  return (
    <PageContainer showNavigation={true}>
      <NavigateBackArrow navigateURL={() => history.push("/")} />

      <div className={componentStyle(colors)}>
        {!loading &&
          notApprovedBookings.length > 0 &&
          notApprovedBookings.map(booking => (
            <div
              onClick={() =>
                history.push(`/admin/review-booking/${booking.id}`)
              }
            >
              <Booking booking={booking} waitingApproval={true}/>
            </div>
          ))}

        {!loading && notApprovedBookings.length === 0 && (
          <div className="no-bookings-container">
            <p>Der er ikke flere bookinger som afventer godkendelse</p>
          </div>
        )}
        {loading && <Spinner title={"Henter bookinger..."} />}
      </div>
    </PageContainer>
  );
};

const componentStyle = colors => css`
  padding-top: 1.25rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  height: 100%;

  .no-bookings-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9.6rem;

    p {
      font-weight: 500;
      font-size: 1.125rem;
      color: ${colors.darkGrey};
    }
  }
`;

export default ApproveBookingOverview;
