// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router";

// Components
import PageContainer from "../ui/PageContainer";
import Spinner from "../ui/Spinner";

// Styles
import colors from "../../styles/colors";

// Validations
import FormValidator from "../../validation/formValidator";
import { newPasswordFormValidator } from "../../validation/newPasswordFormValidator";

// Actions
import { addToast } from "../../actions/uiActions";

// Utilities
import req from "../../utilities/request-utility";

const ConfirmResetPassword = props => {
  // Used to navigate from to pages
  const history = useHistory();

  // Params
  const { token } = props.match.params;

  // Validation form
  const validator = new FormValidator(newPasswordFormValidator);

  // Local State
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
    validation: validator.valid()
  });

  const [loading, setLoading] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const [validation, setValidation] = useState(password.validation);

  const submitHandler = async () => {
    const validation = validator.validate(password);
    const tempPassword = { ...password };
    tempPassword.validation = validation;
    setSubmitted(true);

    if (validation.isValid) {
      setLoading(true);
      try {
        await req().put(`corporations/confirm-reset-password/${token}`, {
          password: password.newPassword
        });
        props.addToast({
          message: "Kodeordet er blevet skiftet",
          type: "success",
          autoDismiss: true
        });
        history.push("/corporationLogin");
      } catch (err) {
        setLoading(false);
        props.addToast({
          message: "Noget gik galt - kunne ikke gemme ændringerne",
          type: "error"
        });
      }
    } else {
      setPassword(tempPassword);
      props.addToast({
        message: "Nogle felter er ikke udfyldt korrekt",
        type: "warning",
        autoDismiss: true
      });
    }
  };

  useEffect(() => {
    if (submitted) {
      setValidation(validator.validate(password));
    } else {
      setValidation(password.validation);
    }
    // eslint-disable-next-line
  }, [password]);

  return (
    <PageContainer showNavigation={true}>
      <div className={componentStyle()}>
        <div className="reset-password-container">
          <p className="header">Indtast nyt kodeord</p>
          <label>Kodeord</label>
          <input
            type="password"
            name="newPassword"
            value={password.newPassword}
            onChange={e =>
              setPassword({ ...password, [e.target.name]: e.target.value })
            }
          />
          <p className="invalid-text">{validation.newPassword.message}</p>
          <label>Bekræft kodeord</label>
          <input
            name="confirmPassword"
            type="password"
            value={password.confirmPassword}
            onChange={e =>
              setPassword({ ...password, [e.target.name]: e.target.value })
            }
          />
          <p className="invalid-text">{validation.confirmPassword.message}</p>
          {!loading && (
            <button
              disabled={
                password.newPassword.length < 1 &&
                password.confirmPassword.length < 1
              }
              className={
                password.newPassword.length > 0 &&
                password.confirmPassword.length > 0
                  ? "submit"
                  : "disabled"
              }
              onClick={() => submitHandler()}
            >
              Nulstil kodeord
            </button>
          )}
          {loading && <Spinner style={{ margin: "1rem 0" }} />}
        </div>
      </div>
    </PageContainer>
  );
};

const componentStyle = () => css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .invalid-text {
    text-align: start;
    color: ${colors.red};
    font-size: 0.75rem;
    margin-left: 0.5rem;
    font-weight: 500;
  }

  div.reset-password-container {
    width: 20rem;
    margin-top: 7.5rem;

    p.header {
      text-align: center;
      font-size: 1.85rem;
      font-weight: 500;
    }

    p.sub-text {
      font-size: 0.75rem;
      text-align: center;
      margin-top: 0.5rem;
      letter-spacing: 1px;
      margin-bottom: 0.5rem;
    }
  }

  button.submit,
  button.disabled {
    padding: 0.5rem 0rem;
    background-color: ${colors.primeBlue};
    border-radius: 5px;
    width: 100%;
    margin: 1rem 0rem;
    color: ${colors.white};
    font-weight: 500;
    outline: none;
  }

  button.disabled {
    background-color: ${colors.grey};
    cursor: no-drop;
  }

  button.submit:hover {
    background-color: ${colors.darkerPrimeBlue};
  }

  input {
    cursor: text;
    display: block;
    font-size: 0.75rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
  }

  input:hover {
    border: 1px solid ${colors.black};
  }

  input::placeholder {
    color: ${colors.darkerGrey};
  }

  label {
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: ${colors.black};
    font-size: 0.6rem;
    margin-bottom: 0.25rem;
    margin-left: 0.25rem;
  }
`;

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch)
});

export default connect(null, mapDispatchToProps)(ConfirmResetPassword);
