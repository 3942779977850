import React from "react";
import colors from "../../styles/colors";
import { css } from "emotion";
import { breakPoint } from "../../styles/breakPoints";

const DatePickerUI = props => {
  const {
    defaultValue,
    name = "",
    style,
    disabled = false,
    onDateChange,
    onChange
  } = props;

  return (
    <div className={`${componentStyle()} ${props.className}`} style={style}>
      <label>
        <input
          defaultValue={defaultValue}
          disabled={disabled}
          type="date"
          name={name}
          onChange={e => {
            if (onDateChange) onDateChange(e.target.value);
            if (onChange) onChange(e);
          }}
        />
      </label>
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  align-items: end;
  width: 100%;
  position: relative;

  label {
    display: flex;
    width: 100%;
    border-radius: 3px;
    outline: 0;
    cursor: pointer;
    margin: 0;
  }

  input {
    width: 100%;
    cursor: pointer;
    display: block;
    font-size: 0.75rem;
    outline: none;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.4rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
    -webkit-appearance: none;
    

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 4rem;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
      outline: none;
    }

    @media screen and (max-width: ${breakPoint.sm}px) {
      padding: 0.6rem 10px;
    }

    &:hover {
      border: 1px solid ${colors.black};
    }

    &::placeholder {
      color: ${colors.placeholder};
    }
  }
`;
export default DatePickerUI;
