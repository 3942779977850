// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";

// Styles
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import colors from "../../../styles/colors";

// Utilities and config
import {
  getJobTitle,
  getMonth,
  getNumberOfJobs,
  getYear
} from "./config";

const ExperienceModal = props => {
  // Configs
  const getJob = getJobTitle("dk");
  const month = getMonth("dk");
  const numberOfJobs = getNumberOfJobs("dk");
  const getYears = getYear();

  const { id } = useSelector(state => state.auth.user);
  const [experience, setExperience] = useState({
    employee_id: id,
    employer_text: "",
    start_year: "",
    end_year: "",
    exp_type: "",
    shift_count: "",
    title: getJob[0].value,
    description: "",
    start_month: "",
    end_month: "",
    still_employed: false
  });

  useEffect(() => {
    if (props.experience) {
      setExperience(props.experience);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={componentStyle()}>
      <p className="sub-title">Dette vil gøre din profil mere attraktiv</p>
      <div>
        <label>Arbejdsplads</label>
        <input
          value={experience.employer_text}
          onChange={e =>
            setExperience({ ...experience, employer_text: e.target.value })
          }
          name="employer_text"
        />
      </div>

      <div className="top-section-container-two">
        <div className="section-container choose-staff-box">
          <label>Vælg personal</label>
          <div className="choose-staff">
            <select
              onChange={e =>
                setExperience({ ...experience, title: e.target.value })
              }
              value={experience.title}
              name="title"
              id="grid-state"
            >
              {getJob.map((amount, index) => (
                <option key={index} value={amount.value}>
                  {amount.value}
                </option>
              ))}
            </select>
            <div className="png-div-right">
              <ChevronDownIcon />
            </div>
          </div>
        </div>
        <div className="section-container">
          <label />
          <div className="choose-work-type">
            <div className="work-option">
              <input
                onClick={() =>
                  setExperience({
                    ...experience,
                    exp_type: "Fuldtid"
                  })
                }
                checked={experience.exp_type === "Fuldtid" && true}
                type="radio"
                className="form-radio"
                name="exp_type"
              />
              <label>Fuldtid</label>
            </div>
            <div className="work-option">
              <input
                onClick={() =>
                  setExperience({ ...experience, exp_type: "Deltid" })
                }
                checked={experience.exp_type === "Deltid" && true}
                type="radio"
                className="form-radio"
                name="exp_type"
              />
              <label>Deltid</label>
            </div>
            <div className="work-option">
              <input
                onClick={() =>
                  setExperience({ ...experience, exp_type: "Vikar" })
                }
                checked={experience.exp_type === "Vikar" && true}
                type="radio"
                className="form-radio"
                name="exp_type"
              />
              <label>Vikar</label>
            </div>
          </div>
        </div>
      </div>

      <div className="top-section-container-two">
        <div className="section-container">
          <label>Fra</label>
          <div className="choose-staff">
            <select
              onChange={e =>
                setExperience({ ...experience, start_year: e.target.value })
              }
              value={experience.start_year}
              name="start_year"
            >
              <option value={""}>Angiv start år</option>
              {getYears.map((amount, index) => (
                <option key={index} value={amount.value}>
                  {amount.value}
                </option>
              ))}
            </select>
            <div className="png-div-right">
              <ChevronDownIcon />
            </div>
          </div>
        </div>
        <div className="section-container">
          <label />
          <div className="choose-staff">
            <select
              onChange={e =>
                setExperience({ ...experience, start_month: e.target.value })
              }
              value={experience.start_month}
              name="start_month"
            >
              <option value={""}>{"Angiv måned"}</option>
              {month.map((amount, index) => (
                <option key={index} value={amount.value}>
                  {amount.value}
                </option>
              ))}
            </select>
            <div className="png-div-right">
              <ChevronDownIcon />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "1rem 0 0 0"
        }}
      >
        <div className="still-employed-container">
          <input
            checked={experience.still_employed}
            name="still_employed"
            onClick={() => {
              setExperience({
                ...experience,
                still_employed: !experience.still_employed,
                end_year: experience.still_employed ? "" : experience.end_year,
                end_month: experience.still_employed ? "" : experience.end_month
              });
            }}
            type="radio"
            className="form-radio"
          />
          <p className="title">Stadig ansat?</p>
        </div>
      </div>

      {!experience.still_employed && (
        <div className="top-section-container-two">
          <div className="section-container">
            <label>Til</label>
            <div className="choose-staff">
              <select
                onChange={e =>
                  setExperience({ ...experience, end_year: e.target.value })
                }
                value={experience.end_year}
                name="end_year"
              >
                <option value={""}>Angiv slut år</option>
                {getYears.map((amount, index) => (
                  <option key={index} value={amount.value}>
                    {amount.value}
                  </option>
                ))}
              </select>
              <div className="png-div-right">
                <ChevronDownIcon />
              </div>
            </div>
          </div>
          <div className="section-container">
            <label />
            <div className="choose-staff">
              <select
                onChange={e =>
                  setExperience({ ...experience, end_month: e.target.value })
                }
                value={experience.end_month}
                name="start_month"
              >
                <option value={""}>{"Angiv år"}</option>
                {month.map((amount, index) => (
                  <option key={index} value={amount.value}>
                    {amount.value}
                  </option>
                ))}
              </select>
              <div className="png-div-right">
                <ChevronDownIcon />
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="top-section-container-two"
        style={{ marginBottom: "2rem" }}
      >
        {experience.exp_type === "Vikar" && (
          <div className="section-container">
            <label>Antal vagter</label>
            <div className="choose-staff">
              <select
                onChange={e =>
                  setExperience({ ...experience, shift_count: e.target.value })
                }
                value={experience.shift_count}
                name="shift_count"
              >
                <option value={""}>{"Antal vagter"}</option>
                {numberOfJobs.map((amount, index) => (
                  <option key={index} value={amount.value}>
                    {amount.value}
                  </option>
                ))}
              </select>
              <div className="png-div-right">
                <ChevronDownIcon />
              </div>
            </div>
          </div>
        )}
      </div>

      {/*<div*/}
      {/*  className="top-section-container-one"*/}
      {/*  style={{ marginBottom: "1rem" }}*/}
      {/*>*/}
      {/*  <label>Arbejdsbeskrivelse</label>*/}
      {/*  <textarea*/}
      {/*    onChange={e =>*/}
      {/*      setExperience({ ...experience, description: e.target.value })*/}
      {/*    }*/}
      {/*    value={experience.description}*/}
      {/*    maxLength="255"*/}
      {/*    name="description"*/}
      {/*    rows="4"*/}
      {/*    placeholder="Hvad har du foretaget dig ved denne arbejdsplads?"*/}
      {/*  />*/}
      {/*</div>*/}

      <button onClick={() => props.onSubmit(experience)} className="submit">
        {props.type === "new" ? "Opret" : "Opdater"}
      </button>
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  width: 21.2rem;
  padding: 0.5rem;

  div.still-employed-container {
    display: flex;
    width: 8rem;
    align-items: center;
    margin-right: 2.8rem;

    input {
      cursor: pointer;
    }

    p.title {
      white-space: nowrap;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      color: ${colors.black};
      font-size: 0.6rem;
    }
  }

  .png-div-right {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: ${colors.black};
  }

  label {
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: ${colors.black};
    font-size: 0.6rem;
    margin-bottom: 0.25rem;
    margin-left: 0.25rem;
  }

  select {
    cursor: pointer;
    display: block;
    font-size: 0.75rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
    outline: none;
  }
  select:hover {
    border: 1px solid ${colors.black};
  }

  select::placeholder {
    background-color: ${colors.grey};
  }

  input,
  textarea {
    cursor: text;
    display: block;
    font-size: 0.75rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
  }

  input:hover,
  textarea:hover {
    border: 1px solid ${colors.black};
  }

  input::placeholder,
  textarea::placeholder {
    color: ${colors.darkerGrey};
  }

  button.submit {
    outline: none;
    background-color: ${colors.lightBlue};
    border-radius: 5px;
    color: ${colors.white};
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
  }

  button.submit:hover {
    background-color: ${colors.blue};
  }

  div.choose-staff-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div.top-section-container-two {
    display: flex;
    margin-bottom: 0.25rem;

    div.section-container {
      flex: 1;

      div.choose-staff {
        position: relative;
      }
      div.choose-work-type {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        div.work-option {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 0.25rem;

          input {
            width: 2rem;
            cursor: pointer;
          }
        }
      }
    }

    div.section-container:first-child {
      margin-right: 0.5rem;
    }
  }
`;

export default ExperienceModal;
