// Libs
import moment from "moment";

export const standardFee = 49;
export const urgentBookingFee = 32;
export const emergencyBookingFee = 52;
// Update the total price by date
export const calculateFee = (date, state) => {
  state.date = date;
  state.fee = diffFee(diffDateCalculator(state));
  return state;
};

// Calculates the diff depending on how close to the date to booking is set
export const diffFee = diff => {
  let dateDiff = diff;
  if (dateDiff < 24) {
    return standardFee + emergencyBookingFee;
  } else if (dateDiff >= 24 && dateDiff < 48) {
    return standardFee + urgentBookingFee;
  } else {
    return standardFee;
  }
};

// Calculates the difference between current date and booking date
export const diffDateCalculator = state => {
  let currentDate = moment();
  let bookedDate = state["date"];
  let bookedTime = state["start_time"];

  // for handling when editing booking
  bookedDate = moment(bookedDate);

  let bookedDate_hour = parseInt(bookedTime.slice(0, 2));
  let bookedDate_min = parseInt(bookedTime.slice(3, 5));

  bookedDate.set("hours", bookedDate_hour).set("minute", bookedDate_min);

  return bookedDate.diff(currentDate, "hours");
};
