export const resetPasswordEmailValidator = [
  {
    field: "email",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler email"
  },
  {
    field: "email",
    method: "isEmail",
    validWhen: true,
    message: "Ikke gyldig email"
  }
];
