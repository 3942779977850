// Modules
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { css } from "emotion";

// Styles
import colors from "../../styles/colors";

// Actions
import { signInCorporation } from "../../actions/authActions";

// Components
import Spinner from "../ui/Spinner";

// Utilities and config
import userTypes from "../../config/userTypes";
import PageContainer from "../ui/PageContainer";
import { breakPoint } from "../../styles/breakPoints";

const CorporationLogin = props => {
  const { spinner } = useSelector(state => state.ui);
  const history = useHistory();

  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    if (user.email) history.push("/");
    // eslint-disable-next-line
  }, [user]);

  const [credentials, setCredentials] = useState({
    email: "",
    password: ""
  });

  return (
    <PageContainer
      showNavigation={true}
      navigationBarType={userTypes.corporation}
    >
      <div className={componentStyle()}>
        <div className="login-outer-container">
          <div className="login-container">
            <p className="title">Log ind som virksomhed</p>
            <input
              onChange={e =>
                setCredentials({
                  ...credentials,
                  [e.target.name]: e.target.value
                })
              }
              name="email"
              value={credentials.email}
              type="text"
              placeholder="Email"
            />
            <input
              style={{ marginBottom: "2rem" }}
              type="password"
              onChange={e =>
                setCredentials({
                  ...credentials,
                  [e.target.name]: e.target.value
                })
              }
              name="password"
              value={credentials.password}
              placeholder="Kodeord"
            />

            {!spinner && (
              <button
                onClick={() => {
                  props.signIn(credentials);
                }}
                className="submit-btn"
              >
                Login
              </button>
            )}
            {spinner && <Spinner style={{ marginBottom: "1rem" }} />}

            <div className="flex flex-col mb-3">
              <p className="text-xs text-center">Har du glemt kodeordet?</p>
              <p
                className="cursor-pointer text-xs text-center text-blue-600"
                onClick={() => history.push("/resetPassword")}
              >
                Nulstil adgangskode
              </p>
            </div>
            <div className="flex flex-col ">
              <p className="text-xs text-center">
                Mangler du en virksomhedsprofil?
              </p>
              <p
                className="cursor-pointer text-xs text-center text-blue-600"
                onClick={() => history.push("/newCorporation")}
              >
                Opret profil
              </p>
            </div>
            <div className="employee-searching-container">
              <p>
                <button onClick={() => history.push("/employeeLogin")}>
                  Søger du job? - Klik her!
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

const componentStyle = () => css`
  div.employee-searching-container {
    display: flex;
    margin-top: 1rem;

    button {
      color: ${colors.lightBlue};
      font-weight: 500;
      outline: none;
    }
    button:hover {
      color: ${colors.blue};
    }
  }

  .header {
    position: absolute;
    color: ${colors.white};
    font-weight: bold;
    font-size: 2.5rem;
    top: 5rem;
    left: 3rem;
  }

  .login-outer-container {
    display: flex;
    overflow: auto;
    width: 100%;
    right: 0;
    height: 100%;
    justify-content: center;

    .login-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 5rem 0;
      width: 30rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 100%;
      }

      .title {
        font-size: 2rem;
        margin-bottom: 1rem;

        @media screen and (max-width: ${breakPoint.sm}px) {
          font-size: 1.5rem;
        }
      }
    }
  }

  input {
    cursor: text;
    display: block;
    font-size: 1rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
    margin-bottom: 1rem;
    color: ${colors.black};

    @media screen and (max-width: ${breakPoint.sm}px) {
      width: 90%;
    }
  }

  input:hover {
    border: 1px solid ${colors.black};
  }

  input::placeholder {
    color: ${colors.placeholder};
  }

  .submit-btn {
    outline: none;
    background: ${colors.darkerGrey};
    color: ${colors.white};
    border-radius: 5px;
    font-weight: 600;
    width: 100%;
    padding: 0.5rem 0;
    margin-bottom: 2rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      width: 90%;
    }
  }

  .submit-btn:hover {
    background: ${colors.darkestGrey};
  }

  .submit-btn:focus {
    background: ${colors.black};
  }
`;

const mapDispatchToProps = dispatch => ({
  signIn: bindActionCreators(signInCorporation, dispatch)
});

export default connect(null, mapDispatchToProps)(CorporationLogin);
