// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import moment from "moment";
import { useDebouncedCallback } from "use-debounce";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import tinycolor from "tinycolor2";

// Styles
import colors from "../../styles/colors";
import "moment/locale/da";
import InvoiceIcon from "mdi-react/InvoiceIcon";

// Components
import PageContainer from "../ui/PageContainer";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import UserAddIcon from "mdi-react/UserAddIcon";
import AccountClockIcon from "mdi-react/AccountClockIcon";
import ArrowLeftCircleIcon from "mdi-react/ArrowLeftCircleIcon";
import ArrowRightCircleIcon from "mdi-react/ArrowRightCircleIcon";
import TimeChanger from "../ui/TimeChanger";
import UserPicker from "../ui/UserPicker";
import BookingInvoiceShift from "./BookingInvoiceShift";
import RemovedInvoiceShift from "./RemovedInvoiceShift";
import Button from "../ui/Button";

// Utilities and config
import req from "../../utilities/request-utility";
import userTypes from "../../config/userTypes";
import getInvoiceTotalForCorporation from "../../utilities/get-invoice-total-for-corporation";
import getInvoiceTotalFee from "../../utilities/get-invoice-total-fee";
import getInvoiceTotalHour from "../../utilities/get-invoice-total-hour";
import { workHours } from "../../utilities/get-work-hours-total";
import { remove_commas } from "../../utilities/remove_commas";
import { addCommas } from "../../utilities/add_commas";
import {
  standardFee,
  urgentBookingFee,
} from "../../utilities/calculate-booking-fee";
// Actions
import { addToast, hideModal, showModal } from "../../actions/uiActions";
import {
  takeOverCorporation,
  takeOverEmployee,
} from "../../actions/authActions";

const BookingInvoice = (props) => {
  // Params
  const { bookingId } = props.match.params;

  const [invoice, setInvoice] = useState({
    booking: null,
    corporation: null,
    bookingOptions: {},
    shifts: [],
    groupedShifts: [],
    removedShifts: [],
    // UI
    tab: 0,
    loading: true,
    error: false,
    weekNumber: moment().week(),
    weekNumberToDisplay: moment().week(),
    // Other
  });
  const {
    booking,
    corporation,
    bookingOptions,
    shifts,
    groupedShifts,
    removedShifts,
    loading,
    error,
    tab,
    weekNumber,
    weekNumberToDisplay,
  } = invoice;

  const [autoPrefill, setAutoPrefill] = useState(true);
  const [allEmployees, setAllEmployees] = useState([]);
  const numberOFInvoice = shifts.length;

  const getAllEmployees = async () => {
    try {
      const { data: employees } = await req()(
        `admin/employees/get-all-employees`
      );
      setAllEmployees(employees);
    } catch (error) {}
  };

  useEffect(() => {
    getAllEmployees();
  }, []);

  useEffect(() => {
    if (!autoPrefill) return;

    if (invoice.booking && invoice.booking.id && autoPrefill) {
      (async () => {
        setAutoPrefill(false);
        await autoPreFillTime();
      })();
    }
  }, [invoice]);

  useEffect(() => {
    getBookingDetails();
  }, [tab, weekNumber]);

  const autoPreFillTime = async () => {
    try {
      await req().put(`admin/shifts/auto-register-time`, {
        booking_id: booking.id,
        start_time: booking.start_time,
        end_time: booking.end_time,
      });
    } catch (err) {}
  };

  // Debounce function booking
  const [debouncedUpdateBooking] = useDebouncedCallback(async (params) => {
    const { booking_id, all_check_times_approved, paid_at } = params || {};
    let idOfBooking = booking_id ?? bookingId;
    let allCheckTimesApproved =
      all_check_times_approved ?? bookingOptions.all_check_times_approved;
    let paidAt = paid_at ?? bookingOptions.paid_at;
    try {
      await req().put(`admin/bookings/${idOfBooking}/options`, {
        all_check_times_approved: allCheckTimesApproved,
        paid_at: paidAt,
      });
      await getBookingDetails();
    } catch (error) {
      props.addToast({
        message: "Noget gik galt. Kunne ikke gemme ændringerne..",
        type: "error",
        autoDismiss: true,
      });
    }
  }, 100);

  const UpdateBookingAllWeeks = async (updateType) => {
    try {
      const bookingIds = groupedShifts?.map((booking) => booking.id);
      const allCheckTimesApproved = !groupedShifts.every(
        (booking) => booking.all_check_times_approved
      );
      const paidAt = !groupedShifts.every((booking) => booking.paid_at)
        ? moment()
        : null;
      // if no bookings then do nothing
      if (!bookingIds.length) return;
      await req().put(`admin/bookings/options/update-all-week-bookings`, {
        bookingIds,
        allCheckTimesApproved,
        paidAt,
        updateType,
      });
      await getBookingDetails();
    } catch (error) {
      props.addToast({
        message: "Noget gik galt...",
        type: "error",
        autoDismiss: true,
      });
    }
  };

  // Update the booking stats function - debounce
  const updateBookingOptions = (e, value) => {
    if (tab === 0) {
      setInvoice({
        ...invoice,
        bookingOptions: {
          ...invoice.bookingOptions,
          [e.target.name]: value,
        },
      });
      debouncedUpdateBooking();
    } else {
      UpdateBookingAllWeeks(e.target.name);
    }
  };

  const [debounceUpdateShift] = useDebouncedCallback(async (shift) => {
    try {
      await req().put(`shifts/admin/${shift.id}/approve`, {
        admin_approve_invoice: shift.admin_approve_invoice,
        admin_approve_checkout: !shift.admin_approve_checkout,
      });
      await getBookingDetails();
    } catch (error) {
      props.addToast({
        message: "Noget gik galt. Kunne ikke gemme ændringerne..",
        type: "error",
        autoDismiss: true,
      });
    }
  }, 100);

  // Update the shift approval by admin
  const updateShiftApproval = (shift) => {
    // setLoading(true);
    debounceUpdateShift(shift);
  };

  const [debounceUpdateInvoiceApproval] = useDebouncedCallback(
    async (shift) => {
      try {
        await req().put(`shifts/admin/${shift.id}/approve`, {
          admin_approve_invoice: !shift.admin_approve_invoice,
          admin_approve_checkout: shift.admin_approve_checkout,
        });
        await getBookingDetails();
      } catch (error) {
        props.addToast({
          message: "Noget gik galt. Kunne ikke gemme ændringerne..",
          type: "error",
          autoDismiss: true,
        });
      }
    },
    100
  );

  const updateInvoiceApproval = (shift) => {
    debounceUpdateInvoiceApproval(shift);
  };

  // Gets the information on the site
  const [getBookingDetails] = useDebouncedCallback(async () => {
    try {
      if (tab === 0) {
        const { data: details } = await req()(
          `admin/bookings/${bookingId}/details`
        );
        setInvoice({
          ...invoice,
          bookingOptions: details.bookingOptions,
          booking: details.booking,
          shifts: details.shifts,
          removedShifts: details.removedShifts,
          corporation: details.corporation,
          loading: false,
        });
      }
      if (tab === 1) {
        const { data: details } = await req()(
          `admin/corporations/${corporation.id}/bookings/details?weekNumber=${weekNumber}`
        );
        setInvoice({
          ...invoice,
          groupedShifts: details.shifts,
        });
      }
    } catch (error) {
      props.addToast({
        message: "Noget gik galt. Kunne ikke hente information..",
        type: "error",
        autoDismiss: true,
      });
    }
  }, 500);

  // Update the admin checkout time
  const onAdminCorporationCheckout = async (checkOut, shiftId) => {
    props.hideModal();
    await req()
      .put(`admin/shifts/${shiftId}/register-corporation-time`, {
        checkOut,
      })
      .then(() => {
        getBookingDetails();
        // Success message
        props.addToast({
          message: `Opdateret værdi!`,
          type: "success",
          autoDismiss: true,
        });
      })
      .catch(() => {
        props.addToast({
          message: "Noget gik galt...",
          type: "error",
          autoDismiss: true,
        });
      });
  };

  const onAdminEmployeeCheckout = async (checkOut, shiftId) => {
    props.hideModal();
    await req()
      .put(`admin/shifts/${shiftId}/register-employee-time`, {
        checkOut,
      })
      .then(() => {
        getBookingDetails();
        // Success message
        props.addToast({
          message: `Opdateret værdi!`,
          type: "success",
          autoDismiss: true,
        });
      })
      .catch(() => {
        props.addToast({
          message: "Noget gik galt...",
          type: "error",
          autoDismiss: true,
        });
      });
  };

  const updateTimeStatus = async (roleType, shift, status) => {
    try {
      if (roleType === userTypes.employee) {
        await req().put(`admin/shifts/${shift.id}/employee-time-status`, {
          status,
        });
        await getBookingDetails();
      }

      if (roleType === userTypes.corporation) {
        await req().put(`admin/shifts/${shift.id}/corporation-time-status`, {
          status,
        });
        await getBookingDetails();
      }
    } catch (error) {
      props.addToast({
        message: "Noget gik galt...",
        type: "error",
        autoDismiss: true,
      });
    }
  };

  const createInvoice = async () => {
    try {
      const invoiceShifts =
        tab == 0
          ? invoice.shifts
          : invoice.groupedShifts.flatMap((booking) => booking.shifts);
      let lineCount = 0;
      const invoiceData = {
        corporation: {
          name: invoice.corporation.name,
          cvr_nr: invoice.corporation.cvr_nr,
        },
        lines: invoiceShifts
          .filter((shift) => shift.admin_approve_invoice)
          .flatMap((s) => {
            const {
              corporation_checkin_time,
              corporation_checkout_time,
              employee_checkin_time,
              employee_checkout_time,
              last_checkout_by,
            } = s;
            const employeeCheckedOut =
              employee_checkin_time && employee_checkout_time;
            const corporationCheckedOut =
              corporation_checkin_time && corporation_checkout_time;

            const checkoutApproved =
              employeeCheckedOut &&
              corporationCheckedOut &&
              corporation_checkin_time == employee_checkin_time &&
              corporation_checkout_time == employee_checkout_time;

            const checkoutApprovedByCompany =
              checkoutApproved && last_checkout_by == "corporation";
            const checkoutApprovedByEmployee =
              checkoutApproved && last_checkout_by == "employee";

            const adminCompanyCheckoutTimes = checkoutApprovedByCompany
              ? {
                  start_time: s.corporation_checkin_time,
                  end_time: s.corporation_checkout_time,
                }
              : checkoutApprovedByEmployee
              ? {
                  start_time: s.employee_checkin_time,
                  end_time: s.employee_checkout_time,
                }
              : s.admin_checkin_time && s.admin_checkout_time
              ? {
                  start_time: s.admin_checkin_time,
                  end_time: s.admin_checkout_time,
                }
              : { start_time: s.booking.start_time, end_time: s.booking.end_time };

            const employeeName = `${
              s.employee.first_name
            } ${s.employee.last_name.substring(0, 1).toUpperCase()}.`;
            const description = `${
              s.booking && s.booking.date
                ? (() => {
                    let date = moment(s.booking.date).format("ddd DD/MM");
                    return (
                      date[0].toUpperCase() + date.substring(1, date.length)
                    );
                  })()
                : ""
            } ${
              adminCompanyCheckoutTimes.start_time +
              ` - ` +
              adminCompanyCheckoutTimes.end_time
            }  ${employeeName}`;
            const quantity =
              parseFloat(
                workHours({
                  start_time: adminCompanyCheckoutTimes.start_time,
                  end_time: adminCompanyCheckoutTimes.end_time,
                })
              ) + parseFloat(s.booking.transport_wage);
            let lateFee = 0;
            if (remove_commas(s.booking.fee) > standardFee) {
              lateFee = parseFloat(remove_commas(s.booking.fee)) - standardFee;
            }
            const unitPrice = (
              Math.abs(
                (1.125 *
                  remove_commas(s.booking.hourly_wage) *
                  (parseFloat(
                    workHours({
                      start_time: adminCompanyCheckoutTimes.start_time,
                      end_time: adminCompanyCheckoutTimes.end_time,
                    })
                  ) +
                    parseFloat(s.booking.transport_wage)) +
                  parseFloat(s.booking.transport_wage) +
                  remove_commas(s.booking.fee) *
                    (parseFloat(
                      workHours({
                        start_time: adminCompanyCheckoutTimes.start_time,
                        end_time: adminCompanyCheckoutTimes.end_time,
                      })
                    ) +
                      parseFloat(s.booking.transport_wage)) +
                  parseFloat(s.booking.transport_wage)) /
                  (parseFloat(
                    workHours({
                      start_time: adminCompanyCheckoutTimes.start_time,
                      end_time: adminCompanyCheckoutTimes.end_time,
                    })
                  ) +
                    parseFloat(s.booking.transport_wage))
              ) - lateFee
            ).toFixed(2);
            let productId = booking.staff_type == "bartender" ? '3KYzTNarTGKdXQKReO3AlA' : 'nCGFu3EMSjqsT3ErMoQ13Q'

            let line = [
              {
                priority: lineCount++,
                productId,
                unitPrice: unitPrice,
                quantity: quantity,
                description: description,
              },
            ];
            if (lateFee) {
              let lineObj = {
                productId: "ItF6xSkJR5KZKl8h2mnRPQ",
                description: `(<48 timer) + ${lateFee} /t ${employeeName}`,
              };
              if (
                remove_commas(s.booking.fee) >
                standardFee + urgentBookingFee
              ) {
                lineObj = {
                  productId: "Y2h6ptwyR7iD6kWXqFJk7w",
                  description: `(<24 timer) + ${lateFee} /t ${employeeName}`,
                };
              }
              line.push({
                priority: lineCount++,
                unitPrice: lateFee,
                quantity: quantity,
                ...lineObj,
              });
            }

            return line;
          }),
      };
      await req().put(`admin/billy/bookings/${booking.id}`, { invoiceData });
      // await getBookingDetails();
      props.addToast({
        message: `Medarbejder er blevet sendt til Billy!`,
        type: "success",
        autoDismiss: true,
      });
    } catch (error) {
      props.addToast({
        message: "Noget gik galt...",
        type: "error",
        autoDismiss: true,
      });
    }
  };
  const sendToZenegy = async (booking_id) => {
    let idOfBooking = booking_id ?? bookingId;
    try {
      await req().put(`admin/zenegy/bookings/${idOfBooking}`);
      // Success message
      await getBookingDetails();
      props.addToast({
        message: `Medarbejder er blevet sendt til Zenegy!`,
        type: "success",
        autoDismiss: true,
      });
    } catch (error) {
      props.addToast({
        message:
          error && error.response && error.response.data
            ? error.response.data
            : "Noget gik galt...",
        type: "error",
        autoDismiss: true,
      });
    }
  };

  const sendAllWeekShiftsToZenegy = async () => {
    const bookingIds = groupedShifts?.map((booking) => booking.id);
    try {
      await req().put(`admin/zenegy/bookings/send-all-week-shift-invoice`, {
        bookingIds,
      });
      // Success message
      await getBookingDetails();
      props.addToast({
        message: `Medarbejder er blevet sendt til Zenegy!`,
        type: "success",
        autoDismiss: true,
      });
    } catch (error) {
      props.addToast({
        message:
          error && error.response && error.response.data
            ? error.response.data
            : "Noget gik galt...",
        type: "error",
        autoDismiss: true,
      });
    }
  };

  const checkAllOut = async (booking_id) => {
    try {
      let bookingId = booking_id ?? booking.id;
      let shiftStatus = true;
      if (tab === 1) {
        const booking = groupedShifts.find(
          (booking) => booking.id == bookingId
        );
        const filterShift = booking?.shifts.filter((shift) => {
          if (shift.status === "selected") {
            return shift;
          }
        });
        shiftStatus = filterShift.some(
          (shift) =>
            shift.admin_approve_checkout === false ||
            shift.admin_approve_invoice === false
        );
      } else {
        const filterShift = shifts.filter((shift) => {
          if (shift.status === "selected") {
            return shift;
          }
        });
        shiftStatus = filterShift.some(
          (shift) =>
            shift.admin_approve_checkout === false ||
            shift.admin_approve_invoice === false
        );
      }
      await req().put(`admin/bookings/${bookingId}/shifts/approve-all`, {
        shiftStatus,
      });
      await getBookingDetails();
    } catch (error) {
      props.addToast({
        message: "Noget gik galt...",
        type: "error",
        autoDismiss: true,
      });
    }
  };

  const checkOutAllWeeks = async () => {
    try {
      let shiftAdminApproove = true;
      const bookingIds = groupedShifts?.map((booking) => booking.id);
      const filterShift = groupedShifts.map((groupedShift) => {
        return groupedShift.shifts.filter((shift) => {
          if (shift.status === "selected") {
            return groupedShift;
          }
        });
      });
      shiftAdminApproove = filterShift.some((booking) => {
        return booking.some(
          (shift) =>
            shift.admin_approve_checkout === false ||
            shift.booking.admin_approve_invoice === false
        );
      });
      // if no bookings then do nothing
      if (!bookingIds.length) return;
      await req().put(`admin/bookings/shifts/approve-all-week-bookings`, {
        bookingIds,
        shiftAdminApproove,
      });
      await getBookingDetails();
    } catch (error) {
      props.addToast({
        message: "Noget gik galt...",
        type: "error",
        autoDismiss: true,
      });
    }
  };

  const setTimeForAllShifts = async (time, booking_id) => {
    let bookingId = booking_id ?? booking.id;
    try {
      props.hideModal();
      await req().put(`admin/bookings/${bookingId}/shifts/set-time-for-all`, {
        time,
      });
      await getBookingDetails();
    } catch (error) {
      props.addToast({
        message: "Noget gik galt...",
        type: "error",
        autoDismiss: true,
      });
    }
  };
  const addWorkerToBooking = async (employee_id) => {
    try {
      props.hideModal();
      await req().post(`admin/bookings/${booking.id}/shifts/add-new-worker`, {
        employee_id,
      });
      await getBookingDetails();
    } catch (error) {
      props.addToast({
        message: "Noget gik galt...",
        type: "error",
        autoDismiss: true,
      });
    }
  };
  const setTimeForAllWeeksShifts = async (time) => {
    try {
      const bookingIds = groupedShifts?.map((booking) => booking.id);

      // if no bookings then do nothing
      if (!bookingIds.length) return;
      props.hideModal();
      await req().put(`admin/bookings/shifts/set-time-for-all-week-shifts`, {
        time,
        bookingIds,
      });
      await getBookingDetails();
    } catch (error) {
      props.addToast({
        message: "Noget gik galt...",
        type: "error",
        autoDismiss: true,
      });
    }
  };
  const invoiceSent =
    (bookingOptions.all_check_times_approved && tab === 0) ||
    (tab === 1 &&
      groupedShifts.every((booking) => booking.all_check_times_approved));
  const invoiceCreated =
    (bookingOptions.paid_at && tab === 0) ||
    (tab === 1 && groupedShifts.every((booking) => booking.paid_at));
  const sentToZenegy =
    (bookingOptions.is_sent_to_zenegy && tab === 0) ||
    (tab === 1 && groupedShifts.every((booking) => booking.is_sent_to_zenegy));
  
  let totalHoursForAll = 0,totalFeesForAll = 0, totalAmountForAll = 0;

  if (tab === 1) {
    groupedShifts.length > 0 && groupedShifts.map((booking) => {
      totalHoursForAll += parseFloat(getInvoiceTotalHour(booking.shifts, booking , true))
      totalFeesForAll += parseFloat(getInvoiceTotalFee(booking.shifts, booking , true))
      totalAmountForAll += parseFloat(getInvoiceTotalForCorporation(booking.shifts,booking, true))
    })
  }

  return (
    <PageContainer
      showFullScreen={true}
      showNavigation={true}
      backgroundColor={colors.white}
    >
      {!loading && !error && (
        <div className={componentStyle(props)}>
          <div className="invoice-container">
            <div className="top-navigation">
              <p className="admin">ADMIN</p>
              <p className="invoice">Faktura</p>
              <div className="btn-options-container">
                <button
                  className={invoiceSent && "checked"}
                  name="all_check_times_approved"
                  onClick={(e) =>
                    updateBookingOptions(
                      e,
                      !bookingOptions.all_check_times_approved
                    )
                  }
                >
                  SEND FAKTURA
                  {invoiceSent && <CheckCircleIcon />}
                </button>
                <button
                  className={invoiceCreated && "checked"}
                  name="paid_at"
                  onClick={(e) =>
                    updateBookingOptions(
                      e,
                      bookingOptions.paid_at ? null : moment()
                    )
                  }
                >
                  OPRET FAKTURA
                  {invoiceCreated && <CheckCircleIcon />}
                </button>
                <button
                  className={sentToZenegy && "checked"}
                  onClick={
                    tab === 0 ? () => sendToZenegy() : sendAllWeekShiftsToZenegy
                  }
                >
                  Send til Zenegy
                  {sentToZenegy && <CheckCircleIcon />}
                </button>

                <button onClick={() => createInvoice()}>Create Invoice</button>
              </div>
            </div>
            <hr className="top-navigation" />

            <div className="booking-information">
              <div style={{ display: "flex" }}>
                <p className="corporation-name" style={{ textAlign: "left" }}>
                  {corporation.name}
                </p>{" "}
                <Button
                  onClick={() => {
                    props.takeOverCorporation(corporation.id);
                  }}
                  title={"Overtag"}
                  style={{
                    backgroundColor: "#4299e1",
                    borderRadius: "3px",
                    padding: "0.3rem 0.5rem",
                    color: "white",
                    textAlign: "right",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <hr className="section-divider" />
              <table>
                <tbody>
                  <tr>
                    <th>
                      <p
                        style={{ marginTop: "1rem" }}
                        className="meta"
                      >{`Att.: ${corporation.contact_person}`}</p>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <p className="meta">{`${corporation.address}`}</p>
                      <p className="meta">{`${corporation.city_zip} ${corporation.city}`}</p>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <p
                        style={{ margin: "1rem 0" }}
                        className="meta"
                      >{`CVR-nr.: ${corporation.cvr_nr}`}</p>
                    </th>
                  </tr>
                </tbody>
              </table>

              <hr className="section-divider" />
              <table>
                <tbody>
                  <tr>
                    <th>
                      <p style={{ marginTop: "1rem" }} className="meta">
                        Kontakt:
                      </p>
                    </th>
                    <th>
                      <p style={{ marginTop: "1rem" }} className="meta">
                        {booking.contact_person}
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <p className="meta">Tlf.:</p>
                    </th>
                    <th>
                      <p className="meta">{booking.booking_person_nr}</p>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <p className="meta">Faktura email:</p>
                    </th>
                    <th>
                      <p className="meta">{corporation.billing_email}</p>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <p className="meta">Login-/Bruger email:</p>
                    </th>
                    <th>
                      <p className="meta">{corporation.email}</p>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <p  className="meta">
                        EAN:
                      </p>
                    </th>
                    <th>
                      <p  className="meta">
                        {corporation.ean}
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <p style={{ marginBottom: "2.5rem" }} className="meta">
                      Note til faktura:
                      </p>
                    </th>
                    <th>
                      <p style={{ marginBottom: "2.5rem" }} className="meta">
                        {booking.invoice_note}
                      </p>
                    </th>
                  </tr>
                </tbody>
              </table>
              {tab === 0 && (
                <>
                  <hr className="section-divider" />
                  <div
                    style={{
                      marginTop: "1rem",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    {(booking.arrangement_type ||
                      booking.arrangement_type_other) && (
                      <p
                        style={{ marginRight: "1rem" }}
                        className="booking-type"
                      >
                        {booking.arrangement_type
                          ? booking.arrangement_type
                          : booking.arrangement_type_other}
                      </p>
                    )}
                  </div>
                  <p className="meta">
                    {`
                    ${moment(booking.date).format("ddd D/M")} ${
                      booking.start_time
                    } - ${booking.end_time}
                    `}
                  </p>
                  <p className="meta" style={{ marginTop: "0.5rem" }}>
                    {`Godkendt: ${moment(booking.created_at).format("llll")}`}
                  </p>
                  <p className="meta" style={{ marginBottom: "0.5rem" }}>
                    {`Oprettet: ${
                      booking.original_created_at
                        ? moment(booking.original_created_at).format("llll")
                        : ""
                    }`}
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <th>
                          <p className="meta">Ansvarlig for booking:</p>
                        </th>
                        <th>
                          <p className="meta">{booking.booking_person}</p>
                        </th>
                      </tr>
                      <tr>
                        <th>
                          <p className="meta">Kontakt nr. til ansvarlig:</p>
                        </th>
                        <th>
                          <p className="meta">{booking.booking_person_nr}</p>
                        </th>
                      </tr>
                      <tr>
                        <th>
                          <p className="meta">Note til faktura:</p>
                        </th>
                        <th>
                          <p className="meta">{booking.invoice_note}</p>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}

              <div className="tab-navigator">
                <div className="wrapper">
                  <div className="color-info">
                    <p className="not-met">Ikke mødt</p>
                    <p className="removed">Fjernet</p>
                    <p className="sick">Sygemelding</p>
                  </div>
                  <button
                    onClick={() => setInvoice({ ...invoice, tab: 0 })}
                    className={`${tab === 0 ? "active" : ""}`}
                  >
                    Valgt faktura
                  </button>
                  <button
                    onClick={() => setInvoice({ ...invoice, tab: 1 })}
                    className={`${tab === 1 ? "active" : ""}`}
                  >
                    VIS UGE
                  </button>
                </div>
                {tab === 1 && (
                  <div className="week-wrapper">
                    <ArrowLeftCircleIcon
                      onClick={() => {
                        let weekNumberToDisplay =
                          invoice.weekNumberToDisplay == 1
                            ? 52
                            : invoice.weekNumberToDisplay - 1;
                        setInvoice({
                          ...invoice,
                          weekNumber: invoice.weekNumber - 1,
                          weekNumberToDisplay,
                        });
                      }}
                    />
                    <p>{`UGE ${weekNumberToDisplay}`}</p>
                    <ArrowRightCircleIcon
                      onClick={() => {
                        let weekNumberToDisplay =
                          invoice.weekNumberToDisplay == 52
                            ? 1
                            : invoice.weekNumberToDisplay + 1;
                        setInvoice({
                          ...invoice,
                          weekNumber: invoice.weekNumber + 1,
                          weekNumberToDisplay,
                        });
                      }}
                    />
                  </div>
                )}
              </div>

              {/** Shifts Table **/}
              <div className="shift-container">
                <div
                  style={{
                    height: "1.25rem",
                    position: "relative",
                    marginBottom: "0.25rem",
                  }}
                ></div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "25px" }} />
                      <th style={{ width: "50px" }}>
                        {tab === 0 && (
                          <button
                            onClick={() =>
                              props.showModal({
                                title: "Vælg Medarbejder",
                                content: (
                                  <UserPicker
                                    allEmployees={allEmployees}
                                    onSubmit={addWorkerToBooking}
                                  />
                                ),
                              })
                            }
                            style={{ top: "-37px", left: "20px" }}
                            className="check-out"
                          >
                            <UserAddIcon />
                          </button>
                        )}
                      </th>
                      <th style={{ width: "70px" }} />
                      <th style={{ width: "70px" }} />
                      <th style={{ width: "35px" }}>
                        <p
                          className="thead-title"
                          style={{ fontSize: "0.6rem" }}
                        >
                          Godkend
                        </p>
                        <button
                          style={{ top: "-37px", left: "7px" }}
                          className="check-out"
                          onClick={
                            tab === 0 ? () => checkAllOut() : checkOutAllWeeks
                          }
                        >
                          <CheckCircleIcon />
                        </button>
                      </th>
                      <th style={{ width: "150px" }}>
                        <>
                          <p
                            style={{ textAlign: "center" }}
                            className="thead-title"
                          >
                            Stempel ud:
                          </p>
                          <button
                            onClick={() =>
                              props.showModal({
                                title: "Opdater alle vagters tider",
                                content: (
                                  <TimeChanger
                                    startHours={
                                      booking.start_time
                                        ? booking.start_time.substring(0, 2)
                                        : null
                                    }
                                    startMinutes={
                                      booking.start_time
                                        ? booking.start_time.substring(3, 5)
                                        : null
                                    }
                                    endHours={
                                      booking.end_time
                                        ? booking.end_time.substring(0, 2)
                                        : null
                                    }
                                    endMinutes={
                                      booking.end_time
                                        ? booking.end_time.substring(3, 5)
                                        : null
                                    }
                                    onSubmit={(time) => {
                                      tab === 0
                                        ? setTimeForAllShifts({
                                            startTime: `${time.start.hours}:${time.start.minutes}`,
                                            endTime: `${time.end.hours}:${time.end.minutes}`,
                                          })
                                        : setTimeForAllWeeksShifts({
                                            startTime: `${time.start.hours}:${time.start.minutes}`,
                                            endTime: `${time.end.hours}:${time.end.minutes}`,
                                          });
                                    }}
                                    confirmText={"Opdater"}
                                  />
                                ),
                              })
                            }
                            style={{ top: "-37px", left: "90px" }}
                            className="check-out"
                          >
                            <AccountClockIcon />
                          </button>
                          <div className="check-out">
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <p style={{ marginRight: "1rem" }}>
                                Virksomhed{" "}
                                <span style={{ color: colors.lightBlue }}>
                                  🏢
                                </span>
                              </p>
                              <p>
                                Personale{" "}
                                <span style={{ color: colors.red }}>👤</span>
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <p style={{ marginRight: "1.6rem" }}>
                                Faktureret (F)
                              </p>
                              <p>Løn (L)</p>
                            </div>
                          </div>
                        </>
                      </th>
                      <th style={{ width: "40px" }}>
                        <p className="thead-title">Timer</p>
                      </th>
                      <th style={{ width: "40px" }}>
                        <p className="thead-title">Trans</p>
                      </th>
                      <th style={{ width: "40px" }}>
                        <p className="thead-title">I alt</p>
                      </th>
                      <th style={{ width: "60px" }}>
                        <p className="thead-title">Timeløn</p>
                      </th>
                      <th style={{ width: "40px" }}>
                        <p className="thead-title">Feriep</p>
                      </th>
                      <th style={{ width: "55px" }}>
                        <p className="thead-title">Tillæg</p>
                      </th>
                      <th style={{ width: "100px" }}>
                        <p className="thead-title">Total</p>
                      </th>
                      <th style={{ width: "50px" }}>
                        <p className="thead-title">Sendt</p>
                      </th>
                    </tr>
                  </thead>

                  {tab === 0
                    ? shifts.map((s, index) => (
                        <BookingInvoiceShift
                          getBookings={getBookingDetails}
                          updateTimeStatus={updateTimeStatus}
                          updateShiftApproval={updateShiftApproval}
                          updateInvoiceApproval={updateInvoiceApproval}
                          onAdminEmployeeCheckout={onAdminEmployeeCheckout}
                          onAdminCorporationCheckout={
                            onAdminCorporationCheckout
                          }
                          booking={s.booking}
                          shift={s}
                        />
                      ))
                    : groupedShifts.map((booking) => (
                        <>
                          <tr>
                            <td style={{ border: "none" }}>&nbsp;</td>
                            <td
                              style={{
                                border: "none",
                                color: "gray",
                                paddingLeft: "25px",
                              }}
                            >
                              <a
                                href={`/admin/bookingInvoice/${booking.id}`}
                                target="_blank"
                              >
                                <InvoiceIcon />
                              </a>
                            </td>
                            <td colSpan={2} style={{ border: "none" }}>
                              <div>Middag {booking.shifts.length} retter</div>
                              <div>{booking.arrangement_type}</div>
                              <div>
                                {`
                                  ${moment(booking.date).format("ddd D/M")} ${
                                    booking.start_time
                                  } - ${booking.end_time}
                                `}
                              </div>
                            </td>
                            <td style={{ border: "none" }}>
                              <button
                                className="check-out"
                                onClick={() => checkAllOut(booking.id)}
                                style={{
                                  backgroundColor: "#3d3546",
                                  borderRadius: "5px",
                                  padding: "0.25rem 0.5rem",
                                  color: "white",
                                }}
                              >
                                <CheckCircleIcon />
                              </button>
                            </td>
                            <td style={{ border: "none" }}>
                              <button
                                onClick={() =>
                                  props.showModal({
                                    title: "Opdater alle vagters tider",
                                    content: (
                                      <TimeChanger
                                        startHours={
                                          booking.start_time
                                            ? booking.start_time.substring(0, 2)
                                            : null
                                        }
                                        startMinutes={
                                          booking.start_time
                                            ? booking.start_time.substring(3, 5)
                                            : null
                                        }
                                        endHours={
                                          booking.end_time
                                            ? booking.end_time.substring(0, 2)
                                            : null
                                        }
                                        endMinutes={
                                          booking.end_time
                                            ? booking.end_time.substring(3, 5)
                                            : null
                                        }
                                        onSubmit={(time) =>
                                          setTimeForAllShifts(
                                            {
                                              startTime: `${time.start.hours}:${time.start.minutes}`,
                                              endTime: `${time.end.hours}:${time.end.minutes}`,
                                            },
                                            booking.id
                                          )
                                        }
                                        confirmText={"Opdater"}
                                      />
                                    ),
                                  })
                                }
                                className="check-out"
                                style={{
                                  backgroundColor: "#3d3546",
                                  borderRadius: "5px",
                                  padding: "0.25rem 0.5rem",
                                  color: "white",
                                  marginLeft: "90px",
                                }}
                              >
                                <AccountClockIcon />
                              </button>
                            </td>
                            <td
                              colSpan={8}
                              style={{ border: "none" }}
                              className="btn-options-container"
                            >
                              <button
                                className={
                                  booking.all_check_times_approved && "checked"
                                }
                                name="all_check_times_approved"
                                onClick={(e) =>
                                  debouncedUpdateBooking({
                                    booking_id: booking.id,
                                    all_check_times_approved:
                                      !booking.all_check_times_approved,
                                    paid_at: booking.paid_at,
                                  })
                                }
                              >
                                SEND FAKTURA
                                {booking.all_check_times_approved && (
                                  <CheckCircleIcon />
                                )}
                              </button>
                              <button
                                className={booking.paid_at && "checked"}
                                name="paid_at"
                                onClick={(e) =>
                                  debouncedUpdateBooking({
                                    booking_id: booking.id,
                                    all_check_times_approved:
                                      booking.all_check_times_approved,
                                    paid_at: booking.paid_at ? null : moment(),
                                  })
                                }
                              >
                                OPRET FAKTURA
                                {booking.paid_at && <CheckCircleIcon />}
                              </button>
                              <button
                                className={
                                  booking.is_sent_to_zenegy && "checked"
                                }
                                onClick={() => sendToZenegy(booking.id)}
                              >
                                Send til Zenegy
                                {booking.is_sent_to_zenegy && (
                                  <CheckCircleIcon />
                                )}
                              </button>
                            </td>
                          </tr>
                          {booking.shifts.map((s, index) => (
                            <BookingInvoiceShift
                              getBookings={getBookingDetails}
                              updateTimeStatus={updateTimeStatus}
                              updateShiftApproval={updateShiftApproval}
                              updateInvoiceApproval={updateInvoiceApproval}
                              onAdminEmployeeCheckout={onAdminEmployeeCheckout}
                              onAdminCorporationCheckout={
                                onAdminCorporationCheckout
                              }
                              booking={s.booking}
                              shift={s}
                            />
                          ))}
                          <tr className="table-body">
                            <th
                              colSpan={3}
                              style={{ width: "145px", paddingLeft: "1rem" }}
                            >
                              SUPER TOTAL
                            </th>
                            <th style={{ width: "70px" }}></th>
                            <th style={{ width: "35px" }}></th>
                            <th style={{ width: "150px" }}></th>
                            <th style={{ width: "40px" }}></th>
                            <th style={{ width: "40px" }}></th>
                            <th style={{ width: "40px" }}>
                              <div className="tb-container">
                                <p>-</p>
                                <p>
                                  {" "}
                                  {booking.shifts.length > 0 &&
                                    getInvoiceTotalHour(
                                      booking.shifts,
                                      booking
                                    )}
                                </p>
                                <p>-</p>
                                <p>-</p>
                              </div>
                            </th>
                            <th style={{ width: "60px" }}></th>
                            <th style={{ width: "40px" }}></th>
                            <th style={{ width: "55px" }}>
                              <div className="tb-container">
                                <p>-</p>
                                <p>
                                  {" "}
                                  {booking.shifts.length > 0 &&
                                    getInvoiceTotalFee(booking.shifts, booking)}
                                </p>
                              </div>
                              <hr />
                              <div className="tb-container">
                                <p>-</p>
                                <p>-</p>
                              </div>
                            </th>
                            <th style={{ width: "100px" }}>
                              <div className="tb-container">
                                <p>-</p>
                                <p>
                                  {" "}
                                  {booking.shifts.length > 0 &&
                                    getInvoiceTotalForCorporation(
                                      booking.shifts,
                                      booking
                                    )}
                                </p>
                              </div>
                              <hr />
                              <div className="tb-container">
                                <p>-</p>
                                <p>-</p>
                              </div>
                            </th>
                            <th style={{ width: "50px" }}></th>
                          </tr>
                        </>
                      ))}
                </table>
                {/* {tab === 0 && ( */}
                <table style={{ marginBottom: "2rem" }}>
                  <tbody>
                    <tr className="table-body">
                      <th style={{ width: "145px", paddingLeft: "1rem" }}>
                        SUPER TOTAL
                      </th>
                      <th style={{ width: "70px" }}></th>
                      <th style={{ width: "35px" }}></th>
                      <th style={{ width: "150px" }}></th>
                      <th style={{ width: "40px" }}></th>
                      <th style={{ width: "40px" }}></th>
                      <th style={{ width: "40px" }}>
                        <div className="tb-container">
                          <p>-</p>
                          {tab === 0 && (
                            <p>
                              {" "}
                              {shifts.length > 0 &&
                                getInvoiceTotalHour(shifts, booking)}
                            </p>
                          )}
                          {tab === 1 && (
                            <p>
                              {" "}
                              {addCommas(totalHoursForAll)}
                            </p>
                          )}
                          <div className="tb-container">
                            <p>-</p>
                            <p>-</p>
                          </div>
                        </div>
                      </th>
                      <th style={{ width: "60px" }}></th>
                      <th style={{ width: "40px" }}></th>
                      <th style={{ width: "55px" }}>
                        <div className="tb-container">
                          <p>-</p>
                          {tab === 0 && <p>
                            {" "}
                            {shifts.length > 0 &&
                              getInvoiceTotalFee(shifts, booking)}
                          </p>
                          }
                          {tab === 1 && 
                            <p>
                            {" "}
                            {addCommas(totalFeesForAll)}
                          </p>
                          }
                        </div>
                        <hr />
                        <div className="tb-container">
                          <p>-</p>
                          <p>-</p>
                        </div>
                      </th>
                      <th style={{ width: "100px" }}>
                        <div className="tb-container">
                          <p>-</p>
                          {tab === 0 && 
                            <p>
                              {" "}
                              {shifts.length > 0 &&
                              getInvoiceTotalForCorporation(shifts, booking)}
                          </p>
                          }
                          {tab === 1 && <p>
                            {" "}
                            {addCommas(totalAmountForAll)}
                            </p>
                            }
                        </div>
                        <hr />
                        <div className="tb-container">
                          <p>-</p>
                          <p>-</p>
                        </div>
                      </th>
                      <th style={{ width: "50px" }}></th>
                    </tr>
                  </tbody>
                </table>
                {/* )} */}
                {tab === 0 && (
                  <>
                    {/** REMOVED EMPLOYEES **/}
                    <p className="corporation-name">
                      Afmeldelser af virksomheden
                    </p>
                    <table style={{ marginBottom: "2rem" }}>
                      <thead>
                        <tr>
                          <th style={{ width: "50px" }} />
                          <th style={{ width: "70px" }} />
                          <th style={{ width: "150px" }}>
                            <p
                              style={{ textAlign: "center" }}
                              className="thead-title"
                            >
                              Begrundelse
                            </p>
                          </th>
                          <th style={{ width: "80px" }}>
                            <p
                              className="thead-title"
                              style={{ textAlign: "center" }}
                            >
                              Tidspunkt
                            </p>
                          </th>
                        </tr>
                      </thead>
                      {removedShifts.map((s, index) => (
                        <RemovedInvoiceShift shift={s} />
                      ))}
                    </table>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

const componentStyle = (props) => css`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-bottom: 5rem;
  .btn-options-container {
    display: flex;
    button {
      white-space: nowrap;
      min-width: fit-content;
      flex-direction: row;
      display: flex;
      align-items: center;
      padding: 0.25rem 0.75rem;
      background-color: ${colors.lightGrey};
      border: 1px solid ${colors.medDarkGrey};
      border-radius: 5px;
      color: ${colors.medDarkGrey};
      font-weight: 500;
      cursor: pointer;
      outline: none;
      font-size: 13px;
      margin: 0.3rem 0.5rem;
      width: 100%;

      svg {
        margin-left: 0.5rem;
      }

      &:hover {
        background-color: ${tinycolor(colors.lightGrey).darken(2).toString()};
      }

      &:active {
        background-color: ${tinycolor(colors.lightGrey).darken(5).toString()};
      }

      &.checked {
        background-color: ${colors.primeBlue};
        color: ${colors.white};

        svg {
          fill: ${colors.white};
        }

        &:hover {
          background-color: ${tinycolor(colors.primeBlue).darken(2).toString()};
        }

        &:active {
          background-color: ${tinycolor(colors.primeBlue).darken(5).toString()};
        }
      }

      &.checked {
      }
    }
  }

  .submit-btn {
    text-align: center;
    width: 100%;
    font-size: 0.85rem;
    margin-top: 0.5rem;
    background-color: #337ab7;
    color: #ffffff;
    padding: 0.35rem 0rem;
    font-weight: bold;
    border-radius: 5px;
  }
  .invoice-container {
    width: 100%;
    max-width: 1350px;
    background-color: ${colors.white};
    border-radius: 5px;
    padding: 2rem 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    .tab-navigator {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      flex-direction: column;
      align-items: center;

      .wrapper {
        position: relative;
        display: inline-block;
        border: 1px solid ${colors.primeBlue};
        border-radius: 3px;
        padding: 0.25rem;
        margin-bottom: 1rem;

        button {
          outline: none;
          padding: 0.35rem 0.75rem;
          font-weight: 500;
          border-radius: 3px;

          &.active {
            background-color: ${colors.primeBlue};
            color: ${colors.white};
            padding: 0.35rem 0.6rem;
          }
        }

        .color-info {
          display: flex;
          position: absolute;
          right: -330px;

          p {
            padding-left: 0.25rem;
            margin-right: 0.5rem;
            border-left: 10px solid;

            &.not-met {
              border-color: ${colors.lighterRed};
            }
            &.removed {
              border-color: ${colors.mediumGrey};
            }
            &.sick {
              border-color: ${colors.grey};
            }
          }
        }
      }

      .week-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          padding: 0 1rem;
          font-weight: bold;
          color: ${colors.primeBlue};
        }
        svg {
          height: 2rem;
          width: 2rem;
          fill: ${colors.primeBlue};
          cursor: pointer;

          &:active {
            fill: ${tinycolor(colors.primeBlue).darken(5).toString()};
          }
        }
      }
    }

    .top-navigation {
      position: relative;
      display: flex;
      width: 100%;
      max-width: 1000px;
      align-items: baseline;

      .btn-options-container {
        position: absolute;
        display: flex;
        right: 0;
        top: -2px;

        button {
          display: flex;
          align-items: center;
          padding: 0.25rem 0.75rem;
          background-color: ${colors.lightGrey};
          border: 1px solid ${colors.medDarkGrey};
          border-radius: 5px;
          color: ${colors.medDarkGrey};
          font-weight: 500;
          cursor: pointer;
          outline: none;
          margin-right: 0.5rem;

          svg {
            margin-left: 0.5rem;
          }

          &:hover {
            background-color: ${tinycolor(colors.lightGrey)
              .darken(2)
              .toString()};
          }

          &:active {
            background-color: ${tinycolor(colors.lightGrey)
              .darken(5)
              .toString()};
          }

          &.checked {
            background-color: ${colors.primeBlue};
            color: ${colors.white};

            svg {
              fill: ${colors.white};
            }

            &:hover {
              background-color: ${tinycolor(colors.primeBlue)
                .darken(2)
                .toString()};
            }

            &:active {
              background-color: ${tinycolor(colors.primeBlue)
                .darken(5)
                .toString()};
            }
          }

          &:last-of-type {
            // margin: 0;
          }

          &.checked {
          }
        }
      }
    }
  }

  .booking-information {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    table {
      th {
        position: relative;
        text-align: left;
        button {
          &.check-out {
            position: absolute;
            font-size: 0.55rem;
            padding: 0.25rem 0.5rem;
            border-radius: 5px;
            font-weight: 500;
            background-color: ${colors.primeBlue};
            color: ${colors.white};
            cursor: pointer;
          }
        }

        &:first-of-type {
          width: 200px;
        }
      }
    }
  }

  .shift-container {
    margin-top: 2rem;
    position: relative;

    .options-icon-container {
      position: relative;

      .options {
        z-index: 10;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: ${colors.lightGrey};
        width: 6rem;
        left: 6px;
        border: 1px solid ${colors.medDarkGrey};
        border-radius: 3px;

        p {
          margin: 0;
          padding: 0.125rem 0;
          font-weight: 500;
          font-size: 0.75rem;
          width: 100%;
          border-bottom: 1px solid ${colors.medDarkGrey};
          cursor: pointer;

          &:last-of-type {
            border-bottom: none;
          }

          &:hover {
            background-color: ${tinycolor(colors.lightGrey)
              .darken(2)
              .toString()};
          }

          &:active {
            background-color: ${tinycolor(colors.lightGrey)
              .darken(4)
              .toString()};
          }
        }
      }

      svg.down-icon {
        background-color: ${colors.lightGrey};
        border-radius: 50%;
        height: 2rem;
        width: 2rem;
        padding: 0.25rem;
        margin: 0 auto;
        cursor: pointer;
        transition: background-color 250ms;

        &:hover {
          background-color: ${tinycolor(colors.lightGrey).darken(2).toString()};
        }

        &:active {
          background-color: ${tinycolor(colors.lightGrey).darken(5).toString()};
        }
      }
    }

    table {
      table-layout: fixed;
      width: 100%;
    }

    table,
    th,
    td {
      border: 1px solid ${colors.darkGrey};
    }

    tr {
      .check-out {
        padding: 0 0.5rem;
        display: flex;
        align-items: end;
        flex-direction: column;

        p {
          font-weight: 500;
          font-size: 0.65rem;
        }
      }
    }

    tr.table-body {
      height: 0.85rem;
      width: 0.85rem;

      &.not-met-for-booking {
        background-color: ${colors.lighterRed};
      }

      &.not-selected {
        background-color: ${colors.grey};
      }

      &.removed-from-booking {
        background-color: ${colors.mediumGrey};
      }

      svg {
        &.success {
          height: 1.2rem;
          width: 1.2rem;
          fill: ${colors.blue};
          position: absolute;
          bottom: 3px;
          left: 19px;
        }
      }

      .tb-container {
        p {
          padding: 0 0.5rem;
          text-align: start;

          &.not-match {
            display: inline;
            background-color: ${colors.red};
            padding: 0.125rem;
            margin-left: 0.25rem;
            color: ${colors.white};
            border-radius: 3px;
            font-size: 0.55rem;
          }

          &:last-child {
            font-weight: bold;
          }

          button {
            &.success {
              color: ${colors.green};
              font-weight: bold;

              &.active {
                color: ${colors.white};
                background-color: ${colors.green};
                padding: 0 0.2rem;
              }
            }

            &.fail {
              color: ${colors.red};
              font-weight: bold;

              &.active {
                color: ${colors.white};
                background-color: ${colors.red};
                padding: 0 0.2rem;
              }
            }
          }
        }
      }

      button {
        outline: none;

        &.comment {
          display: flex;
          justify-content: center;
          font-size: 0.5rem;
          background-color: ${colors.mediumGrey};
          border: 1px solid ${colors.darkGrey};
          margin: 0.25rem 0.65rem 0.25rem;
          padding: 0.125rem 0.5rem;
          border-radius: 15px;
          transition: background-color 250ms;

          &:hover {
            background-color: ${tinycolor(colors.mediumGrey)
              .darken(4)
              .toString()};
          }
          &:active {
            background-color: ${tinycolor(colors.mediumGrey)
              .darken(7)
              .toString()};
          }
        }

        &.disabled {
          cursor: default;
          background-color: transparent;
          color: transparent;
          border: transparent;
          &:hover,
          &:active {
            background-color: transparent;
          }
        }
      }

      p {
        margin: 0.25rem 0;
        font-size: 0.65rem;
        text-align: center;

        &.label {
          background-color: ${colors.grey};
          margin: 0 0.25rem 0.25rem;
        }
      }

      .rating {
        display: flex;
        justify-content: center;
      }
    }
  }

  p {
    color: ${colors.darkerPrimeBlue};

    &.admin {
      font-weight: bold;
      font-size: 1.5rem;
      color: ${colors.red};
      margin-right: 0.5rem;
    }

    &.booking-type,
    &.invoice,
    &.corporation-name {
      font-size: 1.5rem;
      font-weight: 500;
    }

    &.meta {
      font-size: 0.8rem;
      color: ${colors.darkestGrey};
      font-weight: 500;
    }

    &.thead-title {
      font-size: 0.8rem;
      color: ${colors.darkestGrey};
      font-weight: 600;
      padding: 0.125rem;
    }
  }
  hr {
    width: 100%;
    border: 1px solid ${colors.mediumGrey};

    &.top-navigation {
      max-width: 980px;
    }

    &.section-divider {
      border-color: ${colors.grey};
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
  takeOverCorporation: bindActionCreators(takeOverCorporation, dispatch),
  takeOverEmployee: bindActionCreators(takeOverEmployee, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch),
});

export default connect(null, mapDispatchToProps)(BookingInvoice);
