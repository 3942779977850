// Libs
import React, { Fragment, useState } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";

// Utilities and config
import req from "../../utilities/request-utility";

// Styles
import colors from "../../styles/colors";

// Components
import Spinner from "../ui/Spinner";

// Actions
import { addToast, hideModal } from "../../actions/uiActions";
import ErrorBox from "../ui/ErrorBox";

const CancelBookingModal = props => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const cancelBooking = () => {
    setLoading(true);
    req()
      .put(`corporations/bookings/${props.bookingId}/cancel`)
      .then(() => {
        setLoading(false);
        props.hideModal();
        history.push("/");
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        props.addToast({
          message: "Kunne ikke slette bookingen...",
          type: "error",
          autoDismiss: true
        });
      });
  };

  return (
    <div className={componentStyle(colors)}>
      {loading && !error && <Spinner title={"Sletter booking..."} />}
      {!loading && error && <ErrorBox />}
      {!loading && !error && (
        <Fragment>
          <p className="title">
            Er du sikker på, at du ønsker at slette bookingen?
          </p>
          <button className="submit-btn" onClick={() => cancelBooking()}>
            Ja, slet bookingen!
          </button>
          <p className="sub-title">
            Dette vil slette bookingen permanent, og du vil ikke kunne fortryde
            efterfølgende.{" "}
          </p>
        </Fragment>
      )}
    </div>
  );
};

const componentStyle = colors => css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.25rem;

  p.title {
    font-weight: 500;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  p.sub-title {
    font-size: 0.75rem;
    margin-top: 0.5rem;
    text-align: center;
    font-style: italic;
  }

  button.submit-btn {
    background-color: ${colors.darkRed};
    border-radius: 0.25rem;
    color: ${colors.white};
    font-weight: 700;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    outline: none;
    font-size: 0.85rem;
  }

  button:hover {
    background-color: ${colors.red};
  }
`;

const mapDispatchToProps = dispatch => ({
  hideModal: bindActionCreators(hideModal, dispatch),
  addToast: bindActionCreators(addToast, dispatch)
});

export default connect(null, mapDispatchToProps)(CancelBookingModal);
