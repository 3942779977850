module.exports = (shiftCount, language = "dk") => {
  if (shiftCount.length < 1) return "";

  if (language === "dk") {
    switch (shiftCount) {
      case "1_2":
        return "0-1 vagter jobs";

      case "2_5":
        return "0-5 vagter jobs";

      case "6_19":
        return "6-19 vagter jobs";

      case "20_plus":
        return "20+ vagter jobs";

      case "60_plus":
        return "60+ vagter jobs (min. 2 års erfaring)";

      default:
        return `${shiftCount} vagter`;
    }
  }
};
