import React from "react";
import { css } from "emotion";
import noProfileImage from "../../static/noProfilePicture.png";
import colors from "../../styles/colors";

const SingleImageContainer = props => {
  const { baseURL, cloudinaryString, image } = props;

  const validateImage = () => {
    if (baseURL && cloudinaryString && image) {
      return `${baseURL}${cloudinaryString}/${image}`;
    } else if (baseURL && image) {
      return `${baseURL}/${image}`;
    } else if (!baseURL || !cloudinaryString || !image) {
      return noProfileImage;
    }
  };

  return (
    <div
      onClick={props.onClick}
      className={`${componentStyle()} ${props.className}`}
      style={props.style && props.style}
    >
      <img
        style={props.imageStyle && props.imageStyle}
        src={validateImage()}
        alt={props.alt}
      />
    </div>
  );
};

const componentStyle = () => css`
  img {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border-width: 1px;

    &:hover {
      border: 1px solid ${colors.primeBlue};
    }
  }
`;

export default SingleImageContainer;
