// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import ReactStars from "react-stars";

// Styles
import colors from "../../../styles/colors";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";

// Utilities and config
import { hours, minutes } from "../../employee/shiftsOverview/config";
import { workHours } from "../../../utilities/get-work-hours-total";

// Components
import Button_DEPRECATED from "../../ui/Button_DEPRECATED";

const CheckOutAutoFillBox = props => {
  const { date, start_time, end_time, isHistory = false } = props.booking;
  const { checkout_from, checkout_to, comment_rating, comment_time, rating } = props.autoFillData || {};

  // Component state
  const initialCheckout = {
    checkout_from: checkout_from || '',
    checkout_to: checkout_to || '',
    comment_rating: comment_rating || "",
    comment_time: comment_time || "",
    rating: rating || 0,
}
  const [checkout, setCheckout] = useState(initialCheckout);

  const checkInTime = checkout.checkout_from.split(':');
  const checkOutTime = checkout.checkout_to.split(':');
  const checkInTimeHour = checkInTime[0] || '';
  const checkInTimeMinute = checkInTime[1] || '';
  const checkOutTimeHour = checkOutTime[0] || '';
  const checkOutTimeMinute = checkOutTime[1] || '';

  // no one checked out
  let checkoutBoxParams = {statusBarColor: "#fff", statusBarBGColor: "#F5717F", statusbarText: "AUTO - UDFYLD", buttonColor: "#fff", buttonBGColor: "#979797", buttonText: `AUTOUDFYLD ALLE`, checkedOut: false}
  if(JSON.stringify(initialCheckout) !== JSON.stringify(checkout)){
    checkoutBoxParams = {statusBarColor: "#4A4A4A", statusBarBGColor: "#F6E61C", statusbarText: "UDFYLD ALLE", buttonColor: "#fff", buttonBGColor: "#417505", buttonText: `UDFYLD ALLE`, checkedOut: false}
  }
  else if(props.autoFillData){
    checkoutBoxParams = {statusBarColor: "#fff", statusBarBGColor: "#417505", statusbarText: "AUTOUDFYLDT", buttonColor: "#fff", buttonBGColor: "#979797", buttonText: `UDFYLD ALLE`, checkedOut: true}
  }
  const {statusBarBGColor, statusBarColor, statusbarText, buttonText, buttonBGColor, buttonColor, checkedOut} = checkoutBoxParams;

  const bookingDuration = workHours({start_time, end_time});
  return (
    <div className={`${componentStyle(colors)}`}>
      {/** 1. Section **/}
      <div style={{ display: "flex", padding: '0.5rem' }}>
        <div className="employee-information-container">
            <p>Oprindelig booking-tid: <br /> {start_time} - {end_time} [{bookingDuration} /t]</p>
          <div className="card-label" style={{ backgroundColor: statusBarBGColor, color: statusBarColor }}>
            {statusbarText}
          </div>
        </div>
      </div>

      {/** 2. Section **/}

      <div className="checkout-container">
        <div className="checkout-time-pickers">
          <>
            <div className="check">
              <p>Fra</p>
              <div className="time-boxes">
                <select
                  className={checkedOut ? "disabled" : ""}
                  name="checkout_from"
                  onChange={e => {
                    const checkInTime = `${e.target.value}:${checkInTimeMinute || '00'}`
                    setCheckout({ ...checkout, [e.target.name]: checkInTime });
                  }}
                  value={checkInTimeHour}
                >
                  <option value='' disabled>
                    --
                  </option>

                  {hours.map((value, index) => (
                    <option key={`checkin-time-hour-${index}`} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <span>:</span>
                <select
                  className={checkedOut ? "disabled" : ""}
                  name="checkout_from"
                  onChange={e => {
                    const checkInTime = `${checkInTimeHour || '00'}:${e.target.value}`
                    setCheckout({ ...checkout, [e.target.name]: checkInTime });
                  }}
                  value={checkInTimeMinute}
                >
                  <option value='' disabled>
                    --
                  </option>
                  {minutes.map((value, index) => (
                    <option key={`checkin-time-minutes-${index}`} value={value}>
                      {value}
                    </option>
                  ))}
                </select>

              </div>
            </div>

            <span className="time-icon"><ClockOutlineIcon size={25} /></span>

            <div className="check">
              <p>Til</p>
              <div className="time-boxes">
                <select
                  className={checkedOut ? "disabled" : ""}
                  name="checkout_to"
                  onChange={e => {
                    const checkOutTime = `${e.target.value}:${checkOutTimeMinute || '00'}`
                    setCheckout({ ...checkout, [e.target.name]: checkOutTime });
                  }}
                  value={checkOutTimeHour}
                >
                  <option value='' disabled>
                    --
                  </option>
                  {hours.map((value, index) => (
                    <option key={`checkout-time-hours${index}`} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <span>:</span>
                <select
                  className={checkedOut ? "disabled" : ""}
                  name="checkout_to"
                  onChange={e => {
                    const checkOutTime = `${checkOutTimeHour || '00'}:${e.target.value}`
                    setCheckout({ ...checkout, [e.target.name]: checkOutTime });
                  }}
                  value={checkOutTimeMinute}
                >
                  <option value='' disabled>
                    --
                  </option>
                  {minutes.map((value, index) => (
                    <option key={`checkout-time-minutes${index}`} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        </div>
              <div className="checkout-comment-box">
              <p className="time-comment-title">“Kan ses ved af mederbejder ved check-ud” (140 tegn)</p>
                <textarea
                  placeholder="“Forklaring vises til mederbejderen under check-ud” Maks 140 tegn."
                  rows="4"
                  value={checkout.comment_time}
                  name="comment_time"
                  onChange={e => {
                    setCheckout({ ...checkout, [e.target.name]: e.target.value });
                  }}
                  maxLength="140"
                />
              </div> 
      </div>

      <div className="rating-container">
        <>
          {!isHistory && (
            <p style={{ marginBottom: "0.25rem" }}>
              Hjælp os med at blive bedre og giv vores medarbejder en rating fra
              1-5 for deres indsats på dette job
            </p>
          )}
          <ReactStars
            count={5}
            value={parseFloat(checkout.rating) || 0}
            half={false}
            size={25}
            onChange={newRating => {
              setCheckout({ ...checkout, rating: newRating });
            }}
          />
        <p className="comment-title">{`Knyt én opmuntrende kommentar til indsats:`}</p>
        </>
        <textarea
            placeholder="“Kan ses af andre virksomheder ved booking” (140 tegn)"
          rows="4"
          value={checkout.comment_rating}
          name="comment_rating"
          onChange={e => {
            setCheckout({ ...checkout, [e.target.name]: e.target.value });
          }}
        />
      </div>

      <div className="submit-container">
        <Button_DEPRECATED
          onClick={() => props.submitCheckout(checkout) }
          style={{
            padding: "0.4rem", textTransform: 'uppercase', backgroundColor: buttonBGColor
            , color: buttonColor
          }}
          title={buttonText}
        />
      </div>
    </div>
  );
};

const componentStyle = colors => css`
  display: block;
  background-color: ${colors.white};
  border-radius: 0.25rem;
  border: 1px solid ${colors.grey};
  padding: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 0.5rem;
  cursor: default;

  .title {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: 500;
  }

  textarea {
    border: 2px solid ${colors.grey};
    width: 100%;
    border-radius: 10px;
    font-size: 0.85rem;
    padding: 0.25rem;
    outline: none;
    resize: none;
  }

  .employee-information-container {
    display: flex;
    flex-direction: column;
    vertical-align: bottom;
    width: 100%;
    padding: 0.5rem;
    position: relative;

    .card-label {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #F5717F;
      padding: 0.3rem 0.7rem;
      font-size: 0.75rem;
      text-transform: uppercase;
      border-radius: 0.2rem;
      font-weight: 500;    
    }
    p {
      font-size: 0.65rem;
      color: ${colors.black};
    }

    .first {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .inner-first {
        display: flex;
        align-items: center;

        p:first-child {
          font-size: 0.75rem;
          font-weight: 500;
          margin-right: 0.25rem;
        }
        p:last-child {
          font-size: 0.65rem;
          color: ${colors.darkGrey};
        }
      }

      .success-text {
        padding: 0.25rem 0.5rem;
        background-color: ${colors.green};
        font-size: 0.65rem;
        border-radius: 0.25rem;
        color: ${colors.white};
        font-weight: 500;
        text-transform: uppercase;
      }

      .fail-text {
        padding: 0.25rem 0.5rem;
        background-color: ${colors.red};
        font-size: 0.65rem;
        border-radius: 0.25rem;
        color: ${colors.white};
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }

  .information-container {
    padding: 0.5rem 0.8rem;
    display: flex;
    background-color: ${colors.mediumGrey};

    .wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .job-information-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      p {
        font-size: 0.9rem;
        font-weight: 500;
      }

      .label {
        display: flex;
        background-color: ${colors.mediumBlue};
        width: 100%;
        border-radius: 5px;

        p {
          margin: auto;
          font-size: 11px;
          color: ${colors.white};
          text-transform: uppercase;
        }
      }
    }
  }
  .time-comment-employee {
    margin: 0.5rem 0 1rem;
    background: #eee;
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
    line-height: 1.1rem;
    font-weight: 500;
  }
  .checkout-container {
    width: 100%;
    padding: 0.5rem 0.5rem 1.5rem;
    margin-bottom: 1rem;
    background-color: #eee;
    .time-comment-company {
      margin: 2rem 0.5rem 0;
      padding: 0.5rem 1.5rem;
      background: #fff;
      font-size: 0.85rem;
      line-height: 1.1rem;
      color: #417505;
      font-weight: 500;
    }
    .checkout-time-pickers {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
    .checkout-comment-box {
      margin: 2rem 0 0;
      padding: 0 0.5rem;
      .time-comment-title {
        font-size: .85rem;
        color: #4A4A4A;
      }
      textarea::placeholder {
        color: #98989D;
        opacity: 1;
      }
    }
    span.time-icon {
      margin: 1.6rem 0.5rem 0;
    }

    .check {
      width: 50%;
      padding: 0 0.5rem;
      .time-boxes{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        span {
          font-weight: bold
        }
      }
      p {
        font-weight: 500;
        font-size: 0.85rem;
        letter-spacing: 0.05em;
        padding: 0 0 0.1rem;
      }

      select, button {
        display: block;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        line-height: 1.25;
        text-align-last: center;
        background-color: #4A4A4A;
        font-size: 1rem;
        font-weight: bold;
        padding: 0.5rem 0.75rem;
        border-radius: 3px;
        letter-spacing: 1px;
        color: ${colors.white};

        select:hover {
          border: 1px solid ${colors.darkGrey};
        }

        select:focus {
          outline: none;
        }

        &.disabled {
          cursor: default;
          background-color: ${colors.disabledColor};
          color: #417505;
          select:hover {
            border: none;
          }
        }
      }
    }
  }

  .rating-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.25rem;
    padding: 0.5rem 1rem;

    p {
      font-size: 0.85rem;
      line-height: 1.1rem;
    }
  }

  .comment-title {
    margin-bottom: 0.25rem;
    margin-top: 0.5rem;
  }

  .submit-container {
    padding: 0.5rem 1rem;
  }
  @media screen and (max-width: 767px){
    .checkout-container span.time-icon{
      margin: 1.6rem 0 0;
    }
    .checkout-container .check .time-boxes span{
      display: inline-block;
      margin: 0 0.2rem;
    }
  }
`;


export default CheckOutAutoFillBox;
