// Libs
import React, { Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { css } from "emotion";

// Components
import SkillsModal from "./SkillsModal";

// Actions
import { deleteWorkSkill, updateWorkSkill } from "../../../actions/authActions";
import { hideModal, showModal } from "../../../actions/uiActions";

// Utilities and config
import { getWorkSkillQuestion } from "../../../utilities/get-workskill-question";

// Styles
import colors from "../../../styles/colors";
import WarningSkillsModal from "./WarningSkillsModal";
import Spinner from "../../ui/Spinner";

const Skills = props => {
  const {  jobRoles } = useSelector(state => state.auth.user);

  const { loadingWorkSkill: loading } = useSelector(state => state.auth.user);

  const update = skillNumber => {
    props.updateWorkSkill(skillNumber, () => {
      props.hideModal();
    });
  };

  const deleteSkill = skillNumber => {
    props.deleteWorkSkill(skillNumber, () => {
      props.hideModal();
    });
  };

  return (
    <div className={componentStyle()}>
      {loading && <Spinner title={"Indlæser ændringer..."} />}
      {!loading && (
        <Fragment>
          <p className="types-of-jobs-title">TYPER AF JOBS</p>
          <p className="header-title">Hvilke typer jobs søger du? </p>
          <p className="sub-header-title">
            Vi stiller dig 5 spørgsmål til hver stilling, som du vælger
          </p>

          <div className="staff-type-container">
            <div className="job-container">
              <label>
                <div className="last-container">
                  <input
                    checked={jobRoles.some(
                      j => j.employeeJobRole.jobRole_id === 1
                    )}
                    type="checkbox"
                    onClick={
                      jobRoles.some(j => j.employeeJobRole.jobRole_id === 1)
                        ? () =>
                            props.showModal({
                              hideTop: true,
                              content: (
                                <WarningSkillsModal
                                  jobType={1}
                                  onDelete={() => deleteSkill(1)}
                                />
                              )
                            })
                        : () => {
                            props.showModal({
                              content: (
                                <SkillsModal
                                  update={update}
                                  workSkillQuestions={getWorkSkillQuestion(
                                    "dk",
                                    1
                                  )}
                                />
                              )
                            });
                          }
                    }
                  />
                </div>
                <span>Tjener </span>
              </label>
            </div>

            <div className="job-container">
              <label>
                <div className="last-container">
                  <input
                    checked={jobRoles.some(
                      j => j.employeeJobRole.jobRole_id === 2
                    )}
                    type="checkbox"
                    onClick={
                      jobRoles.some(j => j.employeeJobRole.jobRole_id === 2)
                        ? () =>
                            props.showModal({
                              content: (
                                <WarningSkillsModal
                                  jobType={2}
                                  onDelete={() => deleteSkill(2)}
                                />
                              )
                            })
                        : () => {
                            props.showModal({
                              content: (
                                <SkillsModal
                                  update={update}
                                  workSkillQuestions={getWorkSkillQuestion(
                                    "dk",
                                    2
                                  )}
                                />
                              )
                            });
                          }
                    }
                  />
                </div>
                <span>Bartender</span>
              </label>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const componentStyle = () => css`
  padding: 0.5rem 1.5rem;

  p.types-of-jobs-title {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }

  p.header-title {
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
  }

  p.sub-header-title {
    font-size: 0.75rem;
  }

  div.staff-type-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    div.job-container {
      display: flex;
      margin-top: 1.5rem;

      label {
        display: flex;
        align-items: center;

        span {
          margin-left: 1rem;
          padding-top: 0.25rem;
          font-weight: bold;
          color: ${colors.darkestGrey};
        }

        div.last-container {
          padding: 0.5rem;
          display: flex;
          height: 2.5rem;
          align-items: center;
          width: 2.5rem;
          justify-content: center;
          background-color: ${colors.lightGrey};
          border: 1px solid ${colors.darkestGrey};
          cursor: pointer;

          input {
            cursor: pointer;
          }
        }

        div.last-container:hover {
          background-color: ${colors.grey};
        }
      }
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  showModal: bindActionCreators(showModal, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch),
  deleteWorkSkill: bindActionCreators(deleteWorkSkill, dispatch),
  updateWorkSkill: bindActionCreators(updateWorkSkill, dispatch)
});

export default connect(null, mapDispatchToProps)(Skills);
