// Libs
import React from "react";
import { css } from "emotion";

// Styles
import { breakPoint } from "../../styles/breakPoints";

const CorporationBookingOverview = props => {
  return (
    <div
      className={`${componentStyle(props)} ${props.className ? props.className : ""
        }`}
      style={props.style && props.style}
    >
      <div className="top-container">
        <div className="left-container">
          <p className="arrangement-type">{props.arrangementType || ""}</p>
          <p className="address">{props.address || ""}</p>
        </div>
        <div className="right-container">
          {props.rows == 2 && <p className="staff-type">{props.staffType || ""}</p>}
          {props.disabledBooking && <p className="badge" style={{ position: 'relative' }}></p>}
        </div>
      </div>
      <div className="bottom-container">
        <div className="left-container">
          <p className={props.approveByAdmin ? "approved time" : "time"}>
            {props.time || ""}
          </p>
          {props.priceIcon && (
            <img className="price-icon" src={props.priceIcon} alt="price-icon" />
          )}
          <p className={props.approveByAdmin ? "approved price" : "price"}>
            {props.price || ""}
          </p>
        </div>
        <div className="right-container">
          {props.paymentDate && (
            <p
              className={
                props.approveByAdmin ? "approved payment-date" : "payment-date"
              }
            >{`(Udbetaling d. ${props.paymentDate})`}</p>
          )}
        </div>
      </div>
      {
        props.rows != 2 && <div>
          <p className="staff-type">{props.staffType || ""}                    
          { props.language ? props.language === "Engelsk" ? ` 🇬🇧` : props.language === "Dansk" ? `🇩🇰 ` : ` 🇩🇰 🇬🇧` : ``}
          </p>
        </div>
      }
    </div>
  );
};

const componentStyle = props => css`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem 0 0.5rem;
  min-height: 6.5rem;
  justify-content: space-between;

  .top-container, .bottom-container{
    .right-container {
      justify-content: flex-end;
    }
  }
  @media screen and (max-width: ${breakPoint.sm}px) {
    min-height: 0;
    padding: 0.25rem 0.25rem 0 0.25rem;
  }

  .approved {
    color: green;
  }

  div:nth-child(1) {
    display: flex;
    align-items: baseline;

    .arrangement-type {
      font-size: 1.25rem;
      font-weight: 500;
      margin-right: 0.5rem;
      line-height: 0.8cm;
    }

    .address {
      font-size: 0.75rem;
      font-weight: 500;
    }

    @media screen and (max-width: ${breakPoint.sm}px) {
      .arrangement-type {
        font-size: 0.75rem;
        line-height: 0.6cm;
        margin-right: 0.25rem;
      }

      .address {
        font-size: 0.5rem;
        line-height: 0.6cm;
      }
    }
  }

  div:nth-child(2) {
    display: flex;
    align-items: center;

    .payment-date {
      font-size: 0.65rem;
      margin-left: 0.25rem;
      font-weight: 500;
    }

    .time {
      font-size: 1.15rem;
      font-weight: 500;
      margin-right: 0.5rem;
      letter-spacing: 1px;
      line-height: 0.8cm;
    }

    .price-icon {
      height: 1rem;
      width: 1rem;
    }

    .price {
      font-size: 0.65rem;
      font-weight: 500;
      margin-left: 0.25rem;
    }

    .approved-by-admin {
      margin-left: 0.25rem;
      font-size: 0.65rem;
      font-weight: 500;
    }

    @media screen and (max-width: ${breakPoint.sm}px) {
      .payment-date {
        font-size: 0.4rem;
      }

      .price-icon {
        height: 0.4rem;
        width: 0.65rem;
      }

      .time {
        font-size: 0.45rem;
        line-height: 0.6cm;
        letter-spacing: normal;
        margin-right: 0.25rem;
      }

      .price {
        font-size: 0.4rem;
        line-height: 0.6cm;
      }

      .approved-by-admin {
        font-size: 0.4rem;
        margin-left: 0.25rem;
      }
    }
  }

  div:nth-child(3) {
    display: flex;
    align-items: baseline;

    .staff-type {
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 0.8cm;
      display: inline-flex;
      align-items: center;
    }

    @media screen and (max-width: ${breakPoint.sm}px) {
      .staff-type {
        font-size: 0.65rem;
        line-height: 0.6cm;
      }
    }
  }
`;

export default CorporationBookingOverview;
