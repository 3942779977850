// Libs
import React from "react";
import moment from "moment";

// Components
import SingleImageContainer from "../ui/SingleImageContainer";

const RemovedInvoiceShift = props => {
  const s = props.shift;

  return (
    <tbody key={s.id}>
      <tr className={`table-body`}>
        <th style={{ width: "70px" }}>
          {" "}
          <SingleImageContainer
            style={{
              display: "flex",
              justifyContent: "center"
            }}
            imageStyle={{ height: "3rem", width: "3rem" }}
            cloudinaryString={"c_thumb,g_face,h_100,w_100/dpr_2.0,f_auto/"}
            baseURL={s.employee.baseURL_image}
            image={s.employee.profile_image}
          />
        </th>
        <td>
          <p>{`${s.employee.first_name} ${s.employee.last_name
            .substring(0, 1)
            .toUpperCase()}.`}</p>
          <p>{`ID: ${s.employee.id}`}</p>
          <p>{s.employee.phone}</p>
        </td>
        <td>
          <p>{s.removed_reason}</p>
        </td>
        <td>
          <p>{moment(s.removed_at).format("llll")}}</p>
        </td>
      </tr>
    </tbody>
  );
};

export default RemovedInvoiceShift;
