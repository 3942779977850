import React from "react";
import { bindActionCreators } from "redux";
import { hideImageContainer } from "../../actions/uiActions";
import { connect, useSelector } from "react-redux";
import { css } from "emotion";
import { breakPoint } from "../../styles/breakPoints";
import colors from "../../styles/colors";
import CloseIcon from "mdi-react/CloseIcon";

const ViewImageContainer = props => {
  const { image, active } = useSelector(state => state.ui.imageContainer);

  return (
    <>
      {active && (
        <div className={componentStyle(active, colors)}>
          <div
            id={"test"}
            className="modal"
            onClick={() => props.hideImageContainer()}
          />
          <div className="modal-content">
            <div className="top-section">
              <CloseIcon onClick={() => props.hideImageContainer()} />
            </div>
            <img src={image} alt={"viewImageContainer"} />
          </div>
        </div>
      )}
    </>
  );
};

const componentStyle = (active, colors) => css`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${breakPoint.sm}px) {
    position: fixed;
    z-index: 20;
  }

  .modal {
    position: fixed;
    display: ${active ? "flex" : "none"};
    z-index: 20; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal-content {
    position: fixed;
    max-height: 100%;
    background-color: ${colors.greyWhite};
    padding: 0.85rem;
    display: inline-block;
    border: 1px solid ${colors.grey};
    border-radius: 3px;
    z-index: 40;
    overflow: auto;

    @media screen and (max-width: ${breakPoint.sm}px) {
      position: sticky;
    }

    div.top-section {
      margin-bottom: 0.25rem;
      svg {
        float: right;
        cursor: pointer;
        margin-bottom: 0.5rem;
      }
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  hideImageContainer: bindActionCreators(hideImageContainer, dispatch)
});

export default connect(null, mapDispatchToProps)(ViewImageContainer);
