export const changePasswordValidation = [
    {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'Mangler kodeord'
    },
    {
        field: 'password',
        method: 'isLength',
        args: [{min: 8, max: 20}],
        validWhen: true,
        message: 'Kodeordet skal være længere'
    },
    {
        field: 'confirmPassword',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bekræft kodeord'
    },
    {
        field: 'confirmPassword',
        method: 'isLength',
        args: [{min: 8, max: 20}],
        validWhen: true,
        message: 'Kodeordet skal være længere'
    },
    {
        field: 'confirmPassword',
        method: (confirmation, state) => (state.password === confirmation),
        validWhen: true,
        message: 'Kodeordene stemmer ikke overens'
    },

];