import {
  ADD_TOAST,
  HIDE_IMAGE_CONTAINER,
  HIDE_MODAL,
  REMOVE_TOAST,
  SHOW_IMAGE_CONTAINER,
  SHOW_MODAL
} from "./actionTypes";

export function addToast(toast) {
  return dispatch => {
    dispatch({
      type: ADD_TOAST,
      payload: {
        message: toast.message,
        type: toast.type,
        autoDismiss: toast.autoDismiss ? toast.autoDismiss : ""
      }
    });
  };
}

export function removeToast() {
  return dispatch => {
    dispatch({
      type: REMOVE_TOAST
    });
  };
}

export function showModal(payload) {
  return dispatch => {
    dispatch({
      type: SHOW_MODAL,
      payload: payload
    });
  };
}

export function hideModal() {
  return dispatch => {
    dispatch({
      type: HIDE_MODAL
    });
  };
}

export function showImageContainer(payload) {
  return dispatch => {
    dispatch({
      type: SHOW_IMAGE_CONTAINER,
      payload: payload
    });
  };
}

export function hideImageContainer() {
  return dispatch => {
    dispatch({
      type: HIDE_IMAGE_CONTAINER
    });
  };
}
