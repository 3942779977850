import moment from "moment";

export function calculateTimeDifference(booking_date, checkin_time, checkout_time, difference_in = 'minutes') {
    let bookingStartTime = moment(booking_date);
    let bookingEndTime = moment(booking_date);
    const checkInTime = checkin_time.split(':');
    const checkOutTime = checkout_time.split(':');
    const checkInTimeHour = parseInt(checkInTime[0]);
    const checkInTimeMinute = parseInt(checkInTime[1]);
    const checkOutTimeHour = parseInt(checkOutTime[0]);
    const checkOutTimeMinute = parseInt(checkOutTime[1]);
  
    // Set the date
    bookingStartTime.set("hours", checkInTimeHour).set("minutes", checkInTimeMinute);
    bookingEndTime.set("hours", checkOutTimeHour).set("minutes", checkOutTimeMinute);

    const float_start_time = parseFloat(`${checkInTimeHour}.${checkInTimeMinute}`);
    const float_end_time = parseFloat(`${checkOutTimeHour}.${checkOutTimeMinute}`);

    // See if there is a negative imbalance between time
    if (float_start_time >= float_end_time) {
      bookingEndTime.add(1, "days");
    }
    const duration = moment.duration(bookingEndTime.diff(bookingStartTime));
    if(difference_in == "hours"){
      return parseFloat(duration.asHours().toFixed(0));
    }
      return parseFloat(duration.asMinutes().toFixed(0));
}
