// Modules
import React from "react";
import { css } from "emotion";

// Styles
import colors from "../../../styles/colors";
import { breakPoint } from "../../../styles/breakPoints";

const MiddleSection = (props) => {
    const { booking, setBooking } = props;

    return (
        <div className={componentStyle(colors)}>
            <div className="third-section">
                <div className="note-faktura">
                    <label>Note til faktura</label>
                    <input
                        placeholder="Tilføjes faktura"
                        name="invoice_note"
                        value={booking.invoice_note}
                        onChange={(e) =>
                            setBooking({
                                ...booking,
                                [e.target.name]: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
        </div>
    );
};

const componentStyle = (colors) => css`
.third-section {
    display: flex;
    position: relative;  
    flex-direction: column;

    .note-faktura {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 50%;
      margin: 0.6rem 0;
      align-self: flex-end;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 100%;
        margin: 0;
      }
  
      label {
        letter-spacing: 0.025em;
        color: ${colors.black};
        font-size: 12px;
        margin-bottom: 0.25rem;
        margin-left: 0.25rem;
      }
    }  
    input {
      cursor: text;
      display: block;
      font-size: 0.75rem;
      outline: none;
      width: 100%;
      background-color: ${colors.white};
      border: 1px solid ${colors.darkGrey};
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      line-height: 1.25;
    }
  
  
    input:hover {
      border: 1px solid ${colors.black};
    }
  
    input::placeholder {
      color: ${colors.placeholder};
    }
    }
`;

export default MiddleSection;

