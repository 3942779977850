// Libs
import React, { useState } from "react";
import { useHistory } from "react-router";

// Components
import Account from "./Account";
import PageContainer from "../../ui/PageContainer";
import NavigateBackArrow from "../../ui/NavigateBackArrow";

const EditCorporation = props => {
  const history = useHistory();

  const [tab] = useState(0);

  return (
    <PageContainer showNavigation={true}>
      <NavigateBackArrow navigateURL={() => history.push("/")} />
      {tab === 0 && <Account />}
    </PageContainer>
  );
};

export default EditCorporation;
