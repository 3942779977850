import React from "react";
import { css } from "emotion";
import colors from "../../styles/colors";

const Button_DEPRECATED = props => {
  return (
    <button
      className={`${style(colors)} ${props.buttonType || "neutral"} ${
        props.className
      }`}
      style={props.style}
      disabled={props.disabled ? props.disabled : false}
      onClick={!props.active ? props.onClick : null}
    >
      {props.title ? props.title : ""}
      {props.children}
    </button>
  );
};

const style = colors => css`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0.85rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 500;
  color: ${colors.white};
  letter-spacing: 0.05em;

  &:focus {
    outline: 0;
  }

  &.neutral {
    background-color: ${colors.mediumBlue};

    &:focus {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }
  }

  &.error {
    background-color: ${colors.darkRed};

    &:focus {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }
  }

  &:disabled {
    background-color: ${colors.grey};
    cursor: not-allowed;
  }
`;

export default Button_DEPRECATED;
