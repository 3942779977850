// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

// Components
import DateViewer from "../../ui/DateViewer";
import Logo from "../../ui/Logo";
import Label from "../../ui/Label";
import Rating from "../../ui/Rating";
import Input from "../../ui/Input";
import Selector from "../../ui/Selector";
import TextArea from "../../ui/TextArea";
import Button from "../../ui/Button";
import NavigateBackArrow from "../../ui/NavigateBackArrow";

// Styles
import colors from "../../../styles/colors-V-1.0";
import { breakPoint } from "../../../styles/breakPoints";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";

// Config
import { hours, minutes } from "./config";

// Utilities
import { staffTypeLanguage } from "../../../utilities/language-translator";
import { calculateTimeDifference } from "../../../utilities/calculate-time-difference";
import { workHours } from "../../../utilities/get-work-hours-total";

// Validation
import FormValidator from "../../../validation/formValidator";
import { checkOutEmployeeValidation } from "../../../validation/CheckOutEmployeeValidator";

// Actions
import { addToast } from "../../../actions/uiActions";

const CheckOutModal = props => {
  // Used to navigate from to pages
  const history = useHistory();

  const {
    // FUNCTIONS
    submitCheckout,
    // STATE
    booking,
    corporation,
    employee_checkin_time,
    employee_checkout_time,
    corporation_checkin_time,
    corporation_checkout_time,
    employee_rated_corporation,
    employee_comment_about_corporation,
    paymentDate,
    time_comment_by_employee,
    time_comment_by_corporation,
    last_checkout_by,
    first_checkout_by,
    checkout_rejected_count_employee,
    checkout_rejected_count_corporation
  } = props;

  // Validation
  const validator = new FormValidator(checkOutEmployeeValidation);
  const [submitted, setSubmitted] = useState(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);

  // Redux
  const dispatcher = useDispatch();
  // Redux state
  const {
    cpr_nr: userCpr,
    bank_account_nr: userBankAccountNr,
    bank_reg_nr: userBankRegNr,
    tax_card_type: userTaxCardType
  } = useSelector(state => state.auth.user);

  // Local state
  const checkout_time = last_checkout_by == "employee" ? employee_checkout_time || corporation_checkout_time || "" : corporation_checkout_time || employee_checkout_time || ""
  const checkin_time = last_checkout_by == "employee" ? employee_checkin_time || corporation_checkin_time || booking.start_time : corporation_checkin_time || employee_checkin_time || booking.start_time;

  const [checkout, setCheckout] = useState({
    booking_id: booking.id,
    employee_checkin_time: checkin_time,
    employee_checkout_time: checkout_time,
    cpr_nr: userCpr?.trim() || "",
    bank_account_nr: userBankAccountNr?.trim() || "",
    bank_reg_nr: userBankRegNr?.trim() || "",
    tax_card_type: userTaxCardType || "",
    rating: employee_rated_corporation || 0,
    employee_comment: employee_comment_about_corporation || "",
    time_comment_by_employee: time_comment_by_employee || "",
    validation: validator.valid(),
    first_checkout_by
  });
  const checkout_time_updated = (checkin_time != checkout.employee_checkin_time || checkout_time != checkout.employee_checkout_time);

  useEffect(() => {
    setCheckout({
      ...checkout,
      checkout_rejected_count_employee: checkout_time_updated && corporation_checkin_time ? checkout_rejected_count_employee + 1 : checkout_rejected_count_employee,
      send_mail: checkout_time_updated && corporation_checkin_time ? true : false
    })
  }, [checkout_time_updated])

  // Validation-extended
  const [validation, setValidation] = useState(checkout.validation);

  // Effects - Validation
  useEffect(() => {
    if (submitted) {
      setValidation(validator.validate(checkout));
    } else {
      setValidation(checkout.validation);
    }
  }, [checkout]);

  useEffect(() => {
    if (
      checkout.employee_checkin_time !== "" &&
      checkout.employee_checkout_time !== ""
    ) {
      setShowSubmitBtn(true);
    } else {
      setShowSubmitBtn(false);
    }
  }, [checkout]);

  // Submit Handler
  const submit = () => {
    const validation = validator.validate(checkout);
    const tempCheckout = { ...checkout };
    tempCheckout.validation = validation;
    setSubmitted(true);
    if (validation.isValid) {
      submitCheckout(checkout);
    } else {
      setCheckout(tempCheckout);
      dispatcher(
        addToast({
          message:
            "Nogle felter mangler at blive udfyldt eller er ukorrekt udfyldt",
          type: "warning",
          autoDismiss: true
        })
      );
    }
    setShowSubmitBtn(false);
  };

  const checkInTime = checkout.employee_checkin_time.split(':');
  const checkOutTime = checkout.employee_checkout_time.split(':');
  const checkInTimeHour = checkInTime[0] || '';
  const checkInTimeMinute = checkInTime[1] || '';
  const checkOutTimeHour = checkOutTime[0] || '';
  const checkOutTimeMinute = checkOutTime[1] || '';

  const corporationCheckedOut = corporation_checkin_time && corporation_checkout_time;
  const employeeCheckedOut = employee_checkin_time && employee_checkout_time;
  // no one checked out
  let checkoutBoxParams = { statusBarColor: "#fff", statusBarBGColor: "#F5717F", statusbarText: "Stempel ud", buttonColor: "#4A4A4A", buttonBGColor: "#F6E61C", buttonText: "Stempel ud", dropdownColor: "#fff", dropdownBGColor: "#4a4a4a", workingHoursColor: '#4A4A4A', noteText: "har ikke stemplet dig ud endnu: ", displayTimeComment: false, timeCommentLabel: "", checkoutDisabled: false }


  if (corporationCheckedOut && employeeCheckedOut && checkout_time_updated) {
    // checkout updated
    if (checkout_rejected_count_corporation) {
      checkoutBoxParams = { ...checkoutBoxParams, statusbarText: 'AFVIST / STEMPEL NY TID', statusBarBGColor: '#F6E61C', statusBarColor: "#4A4A4A", buttonText: "AFVIS TID / OPDATER", buttonBGColor: "#F5717F", buttonColor: "#fff" }
    }
    else {
      checkoutBoxParams = { ...checkoutBoxParams, statusbarText: 'Stempel ud', statusBarBGColor: '#F5717F', statusBarColor: "#fff", buttonText: "AFVIS TID - Stempel ud", buttonBGColor: "#F5717F", buttonColor: "#fff"}
    }
  }
  else if (corporationCheckedOut && employeeCheckedOut && (corporation_checkin_time != employee_checkin_time || corporation_checkout_time != employee_checkout_time)) {
    // checkout rejected
    if (!checkout_rejected_count_employee && checkout_rejected_count_corporation) {
      checkoutBoxParams = { ...checkoutBoxParams, statusbarText: 'AFVIST / GODKEND NY TID', statusBarBGColor: '#F5717F', statusBarColor: "#fff", buttonText: "Godkend Tid", buttonBGColor: "#417505", buttonColor: "#fff", noteText: "har afvist og stemplet dig ud: " }
    } else {
      checkoutBoxParams = { ...checkoutBoxParams, statusbarText: 'AFVIST/ STEMPEL NY TID', statusBarBGColor: '#F6E61C', statusBarColor: "#4a4a4a", buttonText: "Opdater", buttonBGColor: "#979797", buttonColor: "#fff", dropdownColor: "#417505", workingHoursColor: "#417505", dropdownBGColor: "#ddd" }
    }
  }
  else if (corporationCheckedOut && employeeCheckedOut) {
    // checkout approved
    checkoutBoxParams = { ...checkoutBoxParams, statusbarText: checkout_rejected_count_employee ? 'GODKENDT NY TID' : 'Godkendt', statusBarBGColor: '#417505', buttonText: "Opdater", buttonBGColor: "#979797", buttonColor: "#fff", dropdownColor: "#417505", workingHoursColor: "#417505", dropdownBGColor: "#ddd", noteText: "har stemplet dig ud: " }
  }
  else if (corporationCheckedOut) {
    // only company checkout
    if (checkout_time_updated) {
      checkoutBoxParams = { ...checkoutBoxParams, statusbarText: 'Stempel ud', statusBarBGColor: '#F5717F', statusBarColor: "#fff", buttonText: "AFVIS TID - Stempel ud", buttonBGColor: "#F5717F", buttonColor: "#fff" }
    } else {
      checkoutBoxParams = { ...checkoutBoxParams, statusbarText: 'Godkend', statusBarBGColor: '#F5717F', buttonText: "Godkend Tider", buttonBGColor: "#417505", buttonColor: "#fff", noteText: "har stemplet ud: " }
    }
  }
  else if (employeeCheckedOut) {
    // only employee checkout
    checkoutBoxParams = { ...checkoutBoxParams, statusbarText: 'Stempelt ud', statusBarBGColor: '#F6E61C', statusBarColor: "#4A4A4A", buttonText: "Opdater", buttonBGColor: "#979797", buttonColor: "#fff", dropdownColor: "#417505", workingHoursColor: "#417505", dropdownBGColor: "#ddd" }
  }

  if (checkout.employee_checkin_time && checkout.employee_checkout_time) {
    //get employee checkout duration
    let employeeCheckoutDuration = calculateTimeDifference(booking.date, checkout.employee_checkin_time, checkout.employee_checkout_time)

    if (checkout_time && checkin_time) {
      //get company checkout duration
      let companyCheckoutDuration = calculateTimeDifference(booking.date, checkin_time, checkout_time)
      if (employeeCheckoutDuration > companyCheckoutDuration) {
        checkoutBoxParams = { ...checkoutBoxParams, displayTimeComment: true, timeCommentLabel: `Hvorfor er tiden anderledes end ${corporation.name} har stemplet ud?`, checkoutDisabled: !checkout.time_comment_by_employee ? true : false }
      }
    }
    else {
      //get booking duration
      let bookingDuration = calculateTimeDifference(booking.date, booking.start_time, booking.end_time)
      if (employeeCheckoutDuration > bookingDuration) {
        checkoutBoxParams = { ...checkoutBoxParams, displayTimeComment: true, timeCommentLabel: `Hvorfor er tiden anderledes end ${booking.start_time}-${booking.end_time} ?`, checkoutDisabled: !checkout.time_comment_by_employee ? true : false }
      }
    }
  }
  const { statusBarBGColor, statusBarColor, statusbarText, noteText, buttonText, displayTimeComment, timeCommentLabel, checkoutDisabled } = checkoutBoxParams;
  const workingHours = workHours({start_time: checkout.employee_checkin_time, end_time: checkout.employee_checkout_time, changeTimeFormat: true});
  const companyCheckoutHours = workHours({start_time: corporation_checkin_time, end_time: corporation_checkout_time, changeTimeFormat: true});
  return (
    <div className={`${componentStyle(checkoutBoxParams)} ${props.className}`}>
      <div className="header">
        <NavigateBackArrow className="navBack" navigateURL={() => history.push({
          pathname: '/',
          state: { step: 3 }
        })} />
        <div className="label-container">
          <div className="card-label" style={{ backgroundColor: statusBarBGColor, color: statusBarColor }}>
            {statusbarText}
          </div>
        </div>
      </div>
      {/** First wrapper **/}
      <div className="first-wrapper">
        <div className="first-innerbox">
          <div className="left-data">
            <div className="date-wrapper">
              <DateViewer size={15} date={booking.date} />
            </div>
            <div className="info-wrapper">
              <p className="arrangement-type">{booking.arrangement_type}</p>
              <Label
                className="staff-type"
                value={`${staffTypeLanguage(booking.staff_type, "dk")}`}
              />
              <p className="time">{`${booking.start_time}-${booking.end_time}`}</p>
            </div>
          </div>
          <div className="right-data">
            <div className="icon-wrapper">
              <Logo
                className="logo"
                size={55}
                src={`${corporation.baseURL_image}h_100,w_100/dpr_2.0/r_max/${corporation.icon_image}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card-wrapper">
        {/** Third EXTRA wrapper **/}
        <div className="extra-third-wrapper">
          <p>
            {corporation.name} {noteText}
            {
              corporation_checkin_time && corporation_checkout_time ?
              <span>{`${corporation_checkin_time} - ${corporation_checkout_time}`} ({companyCheckoutHours} /t)</span>
              : <></>
            }

          </p>
        </div>

        {
          time_comment_by_corporation && <div className="time-comment-corporation">
            “{time_comment_by_corporation}”
          </div>
        }
        {/** Second wrapper **/}
        <div className="second-wrapper">
          <div className="checkout-time-pickers">
            <>
              <div className="time-wrapper">
                <Label className="time-label" value={"Fra"} />
                <div className="time-boxes">
                  <Selector
                    name="employee_checkin_time"
                    className="time-selector"
                    selected={checkInTimeHour}
                    values={hours}
                    onChange={e => {
                      const checkInTime = `${e.target.value}:${checkInTimeMinute || '00'}`
                      setCheckout({ ...checkout, [e.target.name]: checkInTime })
                    }}
                    defaultOption="--"
                  />
                  <span>:</span>
                  <Selector
                    name="employee_checkin_time"
                    className="time-selector"
                    selected={checkInTimeMinute}
                    values={minutes}
                    onChange={e => {
                      const checkInTime = `${checkInTimeHour || '00'}:${e.target.value}`
                      setCheckout({ ...checkout, [e.target.name]: checkInTime })
                    }}
                    defaultOption="--"
                  />
                </div>
                <p className="invalid-error">
                  {validation.employee_checkin_time.message}
                </p>

              </div>
              <span className="time-icon"><ClockOutlineIcon size={20} /></span>
              <div className="time-wrapper">
                <Label className={"time-label"} value={"Til"} />
                <div className="time-boxes">
                  <Selector
                    name="employee_checkout_time"
                    className="time-selector"
                    selected={checkOutTimeHour}
                    values={hours}
                    onChange={e => {
                      const checkOutTime = `${e.target.value}:${checkOutTimeMinute || '00'}`
                      setCheckout({ ...checkout, [e.target.name]: checkOutTime })
                    }}
                    defaultOption="--"
                  />
                  <span>:</span>
                  <Selector
                    name="employee_checkout_time"
                    className="time-selector"
                    selected={checkOutTimeMinute}
                    values={minutes}
                    onChange={e => {
                      const checkOutTime = `${checkOutTimeHour || '00'}:${e.target.value}`
                      setCheckout({ ...checkout, [e.target.name]: checkOutTime })
                    }}
                    defaultOption="--"
                  />
                </div>
                <p className="invalid-error">
                  {validation.employee_checkout_time.message}
                </p>
              </div>
            </>
          </div>
          <div className="working-hours">
              {workingHours} /t
          </div>
          <div className="time-title-container">
                <p className="time-title" style={{marginRight: '0.875rem'}}>
                  Angiv tidspunkt da du var omklædt og klar til at arbejde!
                </p>

                <p className="time-title" style={{marginLeft: '0.875rem', paddingLeft: '1rem'}}>
                  Angiv tidspunkt da du fik fri. Spørg den ansvarlige, hvis du er i tvivl!
                </p>
          </div>
          {
            displayTimeComment ?
              <div className="checkout-comment-box">
                <p className="time-comment-title">
                  {timeCommentLabel}
                </p>
                <TextArea
                  placeholder={`“Forklaring vises til ${corporation.name} under stempel-ud”`}
                  name="time_comment_by_employee"
                  className="feedback"
                  rows={2}
                  value={checkout.time_comment_by_employee}
                  onChange={e =>
                    setCheckout({ ...checkout, [e.target.name]: e.target.value })
                  }
                  maxLength="140"
                />
              </div>
              : time_comment_by_employee && <div className="time-comment-employee">
                “{time_comment_by_employee}”
              </div>

          }
        </div>

        {/** Fourth wrapper **/}
        {(!userCpr ||
          (userCpr && userCpr.length < 10) ||
          !userBankAccountNr ||
          (userBankAccountNr && userBankAccountNr.length < 10) ||
          !userBankRegNr ||
          userTaxCardType == 0) && (
            <div className="fourth-wrapper">
              <p className="title">
                Før at vi kan udbetale lønnen, skal vi bruge dit:
              </p>
              <div className="bank-wrapper">
                <div
                  className="bank-box"
                  style={{ marginRight: "1rem", flex: 1, minWidth: 0 }}
                >
                  <Label value={"Reg. nr:*"} />
                  <Input
                    type="number"
                    maxLength="4"
                    value={checkout.bank_reg_nr}
                    name="bank_reg_nr"
                    onChange={e =>
                      setCheckout({ ...checkout, [e.target.name]: e.target.value })
                    }
                  />
                  <p className="invalid-error">{validation.bank_reg_nr.message}</p>
                </div>
                <div className="bank-box" style={{ flex: 3, minWidth: 0 }}>
                  <Label value={'Konto nr. (10 cifre "eks. 0000223344")'} />
                  <Input
                    maxLength="10"
                    type="number"
                    value={checkout.bank_account_nr}
                    name="bank_account_nr"
                    onChange={e =>
                      setCheckout({ ...checkout, [e.target.name]: e.target.value })
                    }
                  />
                  <p className="invalid-error">
                    {validation.bank_account_nr.message}
                  </p>
                </div>
              </div>
              <div className="bank-wrapper" style={{ marginTop: "0.5rem" }}>
                <div className="bank-box" style={{ flex: 1 }}>
                  <Label value={"CPR. nr.**"} />
                  <Input
                    type="number"
                    onChange={e =>
                      setCheckout({ ...checkout, [e.target.name]: e.target.value })
                    }
                    maxLength="10"
                    value={checkout.cpr_nr}
                    name="cpr_nr"
                  />
                  <p className="invalid-error">{validation.cpr_nr.message}</p>
                </div>
              </div>
              <div className="bank-wrapper" style={{ marginTop: "0.5rem" }}>
                <div className="bank-box" style={{ flex: 1 }}>
                  <Label value={"SKATTEKORT*"} />
                  <select
                    name="tax_card_type"
                    onChange={e => {
                      setCheckout({ ...checkout, [e.target.name]: e.target.value });
                    }}
                    value={checkout.tax_card_type}
                  >
                    <option selected disabled key={`tax-card-0`} value={'0'}>Vælg</option>
                    <option key={`tax-card-2`} value={'2'}>Bikort</option>
                    <option key={`tax-card-1`} value={'1'}>Hovedkort (Frikort)</option>
                  </select>
                  <p className="invalid-error">
                    {validation.tax_card_type.message}
                  </p>
                </div>
              </div>
            </div>
          )}
        {/** Fifth wrapper **/}
        <div className="fifth-wrapper">
          <p className="ratting-title">Giv Feedback omkring dette job :)</p>
          <Rating
            onChange={rating => setCheckout({ ...checkout, rating })}
            size={30}
            edit={true}
            value={checkout.rating}
          />
        </div>
        {/** Sixth wrapper **/}
        <div className="sixth-wrapper">
          <p>
            Vi elsker at få feedback, hvis du har ros eller ros til jobbet! Skriv
            det her under - det er kun admin, som kan se det.
          </p>
          <TextArea
            name="employee_comment"
            className="feedback"
            rows={2}
            value={checkout.employee_comment}
            onChange={e =>
              setCheckout({ ...checkout, [e.target.name]: e.target.value })
            }
          />
        </div>
        {/** Third wrapper **/}
        <div className="third-wrapper">
          <p>
            Lønnen vil blive udbetalt: <span>{paymentDate}</span>
          </p>
        </div>
        {/** Seven wrapper **/}
        <div className="seven-wrapper">
          <Button
            disabled={!showSubmitBtn || checkoutDisabled}
            onClick={() => submit()}
            className="submit-btn"
            title={buttonText}
          />
        </div>
      </div>
    </div>
  );
};

const componentStyle = (checkoutBoxParams) => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  p {
    cursor: default;
  }
  .header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: .5rem 1rem;
    position: relative;
    .navBack {
      align-items: center;
      position: absolute;
      left: 0;
    }
    .label-container {
      width: calc(22rem - 0px);
      max-width: 22rem;
      margin: auto;
      padding: 0 1rem;
      .card-label {
        background-color: #F5717F;
        padding: 0.5rem;
        font-size: 0.6rem;
        text-transform: uppercase;
        border-radius: 0.2rem;
        font-weight: bold;  
        color: #fff;  
        height: max-content;
        width: max-content;
      }  
    }
  }
  .card-wrapper {
    padding: 0 1rem;
    max-width: 22rem;
    margin: auto;
  }
  div.first-wrapper {
    display: flex;
    background-color: #eee;
    align-items: center;
    padding: 0.5rem 1rem;
    .first-innerbox{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 22rem;
      margin: auto;
      .left-data{
        display: flex;
        align-items: center;
        div.date-wrapper {
          min-width: 4rem;
          .day{
            color: #F5717F;
            font-size: 13px;
          }
          .date{
            color: #000;
          }
          .month {
            font-size: 13px;
          }
        }
    
        div.info-wrapper {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          align-items: flex-start;
    
          .arrangement-type {
            font-weight: 500;
            font-size: 17px;
          }
    
          .staff-type {
            background-color: #ddd;
            color: #4A4A4A;
            font-size: 13px;
            font-weight: bold;
            padding: 0.125rem 1rem;
            border-radius: 2px;
          }
    
          .time {
            letter-spacing: 1px;
            font-weight: 500;
            color: #979797;
            font-size: 15px;
          }
        }
      }
      .right-data{
        div.icon-wrapper {
          display: flex;
          flex-direction: column;
          min-width: 4rem;
          align-items: center;
          padding: 0 1rem;
    
          p.title {
            margin-top: 0.35rem;
            font-size: 0.85rem;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .time-comment-corporation {
    margin: 0.5rem 0 1rem;
    background: #eee;
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
    line-height: 1.1rem;
    font-weight: 500;
    font-style: italic;
    overflow-wrap: break-word;
  }

  div.second-wrapper {
    margin: 0.5rem 0;
    padding: 0.5rem 0;
    background: #eeeeee;
    .time-title-container {
      display: flex;
      padding: 0 1rem;
      p.time-title {
        margin-top: 0.25rem;
        font-size: 0.5rem;
        font-weight: 500;
        max-width: 8rem;
      }
    }
  .working-hours {
      color: ${checkoutBoxParams.workingHoursColor};
      margin: 1rem 0.5rem;
      padding: 0 0.5rem;
      background: #ddd;
      text-align: center;
      border-radius: 3px;
      font-weight: 500;
    }
    .time-comment-employee {
      margin: 1rem 0.5rem;
      padding: 0.5rem 1.5rem;
      background: #fff;
      font-size: 0.85rem;
      line-height: 1.1rem;
      color: #417505;
      font-weight: 500;
      font-style: italic;
      overflow-wrap: break-word;
    }

    .checkout-time-pickers {
      display: flex;
    }
    .checkout-comment-box {
      margin: 1.5rem 0 0;
      padding: 0 1rem;
      .time-comment-title {
        color: #f5717f;
        font-weight: bolder;
      }
      textarea {
        border: 2px solid #f5717f;
        width: 100%;
        border-radius: 10px;
        font-size: 11px;
        margin-top: .5rem;
        padding:0.5rem;
        outline: none;
        resize: none;
      }
      textarea::placeholder {
        color: #98989D;
        opacity: 1;
      }
    }

    span.time-icon {
      margin-top: 1.6rem;
    }
    div.time-wrapper {
      align-items: flex-start;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
      min-width: 0;

      &:first-child {
        margin-right: 0.25rem;
      }
      &:last-child {
        margin-left: 0.25rem;
      }
      .time-boxes{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        span {
          font-weight: bold
        }
      }
      .time-label {
        margin-bottom: 0.25rem;
        font-weight: 500;
      }

      .time-selector {
        background-color: ${checkoutBoxParams.dropdownBGColor};
        color: ${checkoutBoxParams.dropdownColor};
        font-size: 1rem;
        font-weight: bold;
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        letter-spacing: 1px;

        &:disabled{
            cursor: default;
            background-color: #ddd;
            color: #417505;
            select:hover {
              border: none;
            }  
        }
        .select2-choices {
          min-height: 150px;
          max-height: 150px;
          overflow-y: auto;
        }

        & {
          -webkit-appearance: none;
          -moz-appearance: none;
        }
        &::-ms-expand {
          display: none;
        }
      }
    }
  }

  div.extra-third-wrapper {
    display: flex;
    justify-content: flex-start;
    margin: 0.5rem 0 0;
    padding: 0 1rem;
  }
    p {
      font-size: 0.65rem;
      font-weight: 500;
      letter-spacing: 1px;
    }

    span {
      font-weight: bold;
    }
  }

  div.third-wrapper {
    display: flex;
    margin-top: 0.5rem;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;

    p {
      background-color: #eeeeee;
      padding: 0.25rem .5rem;
      border-radius: 3px;
      font-size: 0.65rem;
      font-weight: 500;
      color: ${colors.grey_20};
      width: 100%;
      text-align: center;
    }

    span {
      color: black;
      font-weight: bold;
      text-space: 1px;
    }
  }

  div.fourth-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
    align-items: center;

    p {
      font-weight: 500;
      font-size: 0.75rem;
      text-align: left;
      padding: 0 1rem;
    }

    .bank-wrapper {
      width: 100%;
      background-color: ${colors.egg_shell_20};
      border-radius: 3px;
      display: flex;
      padding: 0.25rem 1rem;
      border: 1px solid ${colors.grey_5};

      label {
        font-size: 0.65rem;
      }

      .bank-box {
        display: flex;
        flex-direction: column;

        input, select {
          background-color: ${colors.grey_50};
          border-radius: 3px;
          color: ${colors.white};
          padding: 0 0.25rem;
          font-weight: 500;
        }
      }
    }
  }

  div.fifth-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    align-items: center;
    width: 100%;

    .ratting-title{
      width:100%;
      text-align: left;
      padding: 0 1rem;
    }
    p {
      font-size: 0.75rem;
      text-align: center;
      text-space: 1px;
    }
  }

  div.sixth-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    align-items: center;
    margin-top: 0.5rem;
    width: 100%;

    p {
      font-size: 0.65rem;
      font-weight: 500;

      @media screen and (max-width: ${breakPoint.sm}px) {
        text-align: center;
      }
    }

    .feedback {
      border: 2px solid #ddd;
      width: 100%;
      border-radius: 10px;
      font-size: 0.85rem;
      padding: 0.25rem;
      outline: none;
      resize: none;
      margin: 0.5rem 0;
    }
  }

  div.seven-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    .submit-btn: disabled {
      background-color: #979797 !important;
    }
    .submit-btn {
      outline: none;
      font-size: 0.75rem;
      font-weight: bold;
      color: ${checkoutBoxParams.buttonColor} !important;
      padding: 0.5rem 2.5rem;
      border-radius: 3px;
      position: relative;
      margin: 1.5rem 0;
      width: 90%;
      text-transform: uppercase;
      background-color: ${checkoutBoxParams.buttonBGColor} !important;
    }
  }

  .invalid-error {
    font-weight: 500;
    font-size: 0.65rem !important;
    color: ${colors.red_30};
  }
  @media screen and (max-width: 650px) {
    .header{
      justify-content: space-between;
    max-width: 22rem;
    margin: auto;
    width: 22rem;
    .navBack {
      position: static;
    }
    .label-container {
      width: auto;
    max-width: 100%;
    margin: initial;
    }
    }
  }
`;

export default CheckOutModal;
