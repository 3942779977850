// Libs
import React from "react";
import { css } from "emotion";

// Styles
import colors from "../../styles/colors-V-1.0";

const Button = props => {

  return (
    <button
      disabled={props.disabled}
      style={props.style && props.style}
      className={`${componentStyle(props)} ${
        props.className ? props.className : ""
      } ${props.disabled ? "disabled" : ""}`}
      name={props.value}
      onClick={props.onClick}
    >
      {props.title}
    </button>
  );
};

const componentStyle = () => css`
  outline: none;

  &.disabled {
    background-color: ${colors.grey_10} !important;
    color: ${colors.grey_20} !important;
    cursor: no-drop;
  }
`;

export default Button;
